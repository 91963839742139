import React from "react";

import './ListingCard.css';
import Button from '@mui/material/Button';
//import Img from '../../../../assets/imgs/CourseIcon.png';


{/* <ListingCard
  LineStatus={false}
  IconStatus={true}
  Icon={Img}
  Title="Imam Mohammad Ibn Saud Islamic Univers..."
  Description="What is islamic finance?"
  ChipStatus={false}
  StatusColor="#FA0"
  ChipLabel="Pending"
  ButtonStatus={true}
  ButttonLabel="Following"
  Link="#"
/> */}


function ListingCard(props) {


  return (
    <div className="ListingCard">
      <div className="ListingCardLeftCol">
        {props.IconStatus &&
          <div className="ListingCardIcon"><img src={props.Icon} /></div>
        }
        {props.LineStatus &&
          <div className="ListingCardStatusLine" style={{ background: props.StatusColor, opacity: "15%", }}></div>
        }
        <div className='ListingCardContent'>
          <div className='ListingCardTitle'>
            {props.Title}
          </div>
          <div className='ListingCardDescription'>{props.Description}</div>
        </div>
      </div>

      <div className="ListingCardRightCol">
        {props.ChipStatus &&
          <div className="ListingCardSatusChip">
            <div className="ListingCardSatusChipLine" style={{ background: props.StatusColor, }}></div>
            <div className="ListingCardSatusChipBg" style={{ background: props.StatusColor,opacity: "15%", }}></div>
            <span>{props.ChipLabel}</span>
          </div>
        }
        {props.ButtonStatus &&
          <div className="ListingCardButton">
            <Button variant="text" href={props.Link}>{props.ButttonLabel}</Button>
          </div>
        }
      </div>
    </div>
  );
}


export default ListingCard;

