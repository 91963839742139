import React, { useEffect, useState } from "react";
import "../assets/css/inbox.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import MarkunreadRoundedIcon from "@mui/icons-material/MarkunreadRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { MegaphoneFill } from "../../../../assets/imgs/IconifyIcons/MegaPhoneFill";

function AddNew(props) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const participants = props.item?.participants?.filter(
    (id) => id !== userData?.id
  );
  return (
    <div className={`${props.ButtonBlue ? "BlueButton" : "WhiteButton"}`}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="AddButton"
        style={{
          height: props.ButtonHeight,
          borderRadius: props.Radius,
          width: props.Width,
        }}
      >
        <AddRoundedIcon /> New
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <span onClick={handleClose} className="InboxAddNewButtondMenu">
          <a
            className="InboxAddNewButtondMenuList"
            href={`${
              props.item
                ? `${
                    props.item?.conversation?.course_id
                      ? `/inbox-type/message?course_id=${props.item?.conversation?.course_id}`
                      : `/inbox-type/message?participants=${
                          props.item?.conversation?.conversation_type ===
                          "email"
                            ? JSON.parse(
                                props.item?.conversation?.email_recipient_number
                              )
                            : participants
                        }`
                  }`
                : "/inbox-type/message"
            }`}
          >
            <ForumRoundedIcon /> Message
          </a>

          <a
            className="InboxAddNewButtondMenuList"
            href={`${
              props.item
                ? `${
                    props.item?.conversation?.course_id
                      ? `/inbox-type/announcement?course_id=${props.item?.conversation?.course_id}`
                      : `/inbox-type/announcement?participants=${
                          props.item?.conversation?.conversation_type ===
                          "email"
                            ? JSON.parse(
                                props.item?.conversation?.email_recipient_number
                              )
                            : participants
                        }`
                  }`
                : "/inbox-type/announcement"
            }`}
          >
            {/* <CampaignRoundedIcon />  */}
            <span className="announcmentIcon">
              <MegaphoneFill />
              Announcement
            </span>
          </a>
          <a
            className="InboxAddNewButtondMenuList"
            href={`${
              props.item
                ? `${
                    props.item?.conversation?.course_id
                      ? `/inbox-type/email?course_id=${props.item?.conversation?.course_id}`
                      : `/inbox-type/email?participants=${
                          props.item?.conversation?.conversation_type ===
                          "email"
                            ? JSON.parse(
                                props.item?.conversation?.email_recipient_number
                              )
                            : participants
                        }`
                  }`
                : "/inbox-type/email"
            }`}
          >
            <MarkunreadRoundedIcon /> Email
          </a>
        </span>
      </Menu>
    </div>
  );
}

export default AddNew;
