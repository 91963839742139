import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import emailValidation, {
  getUserData,
  getUserTimezone,
} from "../../../hooks/HelperFunctions";

import AlrtMessage from "../../../components/Form/AlrtMessage/AlertMessage";
import Table from "../../../components/Table/Table";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import Modals from "../../../components/Form/Modals/Modals";

import AddNewUser from "./forms/AddNewUser";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddOneByOne from "./forms/AddOneByOne";
import ExportFilter from "../../../components/ExportFilter/ExportFilter";
import ExportFilterUser from "../../../components/ExportFilter/ExportFilterUser";
import jsonexport from "jsonexport/dist";
import * as XLSX from "xlsx";
import CustomizedSnackbar from "../../../hooks/Snackbar";

function UserListing(props) {
  const [studentList, setStudentList] = useState([]);
  const [queryString, setQueryString] = useState("");

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const orgId = localStorage.getItem("orgId");
  const timezone = getUserTimezone();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // const onClick = () => {
  //   alert("onclick working fine!");
  // }

  // fetching course material data
  const getStudentList = (searchText) => {
    axios
      .get(
        `${baseUrl}/orgPortal/getStudents?orgId=${orgId}&timezone=${timezone}&queryString=${
          searchText ? searchText : ""
        }`
      )
      .then((result) => {
        console.log(result.data.body);
        setStudentList(result.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStudentList();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getStudentList(queryString);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [queryString]);

  const handleSearchText = (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    setQueryString(fieldData);
    // getStudentList();
  };

  const handleSearch = () => {
    // console.log(queryString);
    setQueryString(queryString);
    // setQueryString("");
    // getStudentList();
  };

  let newUserInitialObject = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  };

  const [newUserInfo, setNewUserInfo] = useState(newUserInitialObject);

  const headCells = [
    {
      id: "Name",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "20%",
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Email",
      width: "25%",
    },
    {
      id: "courses",
      numeric: true,
      disablePadding: false,
      label: "# of courses",
      width: "15%",
    },
    // { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "10%" },
    {
      id: "Joined",
      numeric: true,
      disablePadding: false,
      label: "Joined",
      width: "25%",
    },
    {
      id: "Balance",
      numeric: true,
      disablePadding: false,
      label: "Balance",
      width: "10%",
    },
    {
      id: "Action",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];

  const initailObjectFilter = {
    format: "",
    dateRange: "",
    users: [],
    stream: [],
    payment: [],
    gender: [],
    status: [],
  };

  const [age, setAge] = React.useState("");
  const [csvData, setCsvData] = useState("");
  const [filterInfo, setFilterInfo] = useState(initailObjectFilter);
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "All",
      value: "All",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },
  ];

  {
    /*==== Add new drawer */
  }
  const [OpenAddNewUser, setOpenNewUser] = useState(false);
  const handleOpenAddNewUser = () => {
    setOpenNewUser(true);
  };

  // Export Filter //
  const [openExport, setOpenExport] = useState(false);
  const handleDrawerOpenExport = () => {
    setOpenExport(true);
  };
  const handleDrawerCloseExport = () => {
    setOpenExport(false);
    setExportError(exportIntailsError);
    setExportErrorStatus(false);
    setShowSnackbar(false);
    setFilterInfo(initailObjectFilter);
  };

  const convertToCSV = async (jsonData) => {
    return jsonexport(jsonData);
  };

  const convertToExcel = async (jsonData) => {
    const resolvedData = await jsonData;
    const ws = XLSX.utils.json_to_sheet(resolvedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return excelBuffer;
  };

  const downloadFile = (fileData, fileName, fileType) => {
    const blob = new Blob([fileData], { type: fileType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const getExportUserInfo = async (filterStudent) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    let postData = {
      ...filterStudent,
      orgId: orgId,
      timezone: timezone,
    };

    axios
      .post(`${baseUrl}/orgPortal/exportUsers`, postData)
      .then(async (response) => {
        let infoToShow = response?.data?.body?.map((singleInfo) => {
          if (singleInfo.stream && singleInfo.stream != "") {
            singleInfo.stream = singleInfo.stream ? "Manual" : "Online";
          }
          return singleInfo;
        });
        if (filterStudent?.format) {
          if (filterStudent?.format == "excel") {
            let excelData = await convertToExcel(infoToShow);
            downloadFile(
              excelData,
              "users.xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
          } else if (filterStudent?.format == "csv") {
            let csvDataResponse = await convertToCSV(infoToShow);
            setCsvData(csvDataResponse);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          // handleOpenSnackbar("error", err.response.data.message);
        }
      });
  };

  const exportIntailsError = {
    format: "",
    dateRange: "",
  };
  const [exportError, setExportError] = useState(exportIntailsError);
  const [exportErrorStatus, setExportErrorStatus] = useState(false);
  const checkExportError = () => {
    let error = 0;
    let errorObject = { ...exportError };
    if (filterInfo.format === "") {
      error++;
      errorObject.format = "Required";
    } else {
      errorObject.format = "";
    }

    if (filterInfo.dateRange === "") {
      error++;
      errorObject.dateRange = "Required";
    } else {
      errorObject.dateRange = "";
    }

    setExportError({ ...exportError, ...errorObject });
    if (error > 0) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Required felids are left");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (exportErrorStatus) {
      checkExportError();
    }
  }, [exportErrorStatus, filterInfo]);

  const exportUserHandler = () => {
    setExportErrorStatus(true);
    const isValid = checkExportError();
    if (!isValid) {
      return false;
    }
    if (
      filterInfo?.payment?.length === 0 &&
      filterInfo?.gender?.length === 0 &&
      filterInfo?.stream?.length === 0 &&
      filterInfo?.users?.length === 0
    ) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please select any of the checkboxes to proceed.");
      return false;
    }
    getExportUserInfo(filterInfo);
    setExportError(exportIntailsError);
    setExportErrorStatus(false);
    handleDrawerCloseExport();
  };

  useEffect(() => {
    if (csvData != "") {
      downloadFile(csvData, "users.csv", "text/csv");
    }
  }, [csvData]);

  const resetUserInfo = () => {
    setNewUserInfo({
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    });
  };
  const [emailValidationError, setEmailValidationError] = useState(false);
  useEffect(() => {
    console.log(emailValidationError, 342);
  }, [emailValidationError]);

  const userIntialError = {
    first_name: "",
    last_name: "",
    email: "",
  };

  const [userError, setUserError] = useState(userIntialError);

  const [userErrorStatus, setUserErrorStatus] = useState(false);
  const checkUserError = () => {
    let error = 0;
    let errorObject = { ...userError };
    if (newUserInfo.first_name === "") {
      error++;
      errorObject.first_name = "Required";
    } else {
      errorObject.first_name = "";
    }

    if (newUserInfo.last_name === "") {
      error++;
      errorObject.last_name = "Required";
    } else {
      errorObject.last_name = "";
    }

    if (newUserInfo.email === "") {
      error++;
      errorObject.email = "Required";
    } else if (!emailValidation(newUserInfo.email)) {
      error++;
      errorObject.email = "Please provide a valid email";
    } else {
      errorObject.email = "";
    }

    setUserError({ ...userError, ...errorObject });
    if (error > 0) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Required felids are left");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (userErrorStatus) {
      checkUserError();
    }
  }, [userErrorStatus, newUserInfo]);

  const CloseAddNewUser = () => {
    setOpenNewUser(false);
    resetUserInfo();
    setUserErrorStatus(false);
    setUserError(userIntialError);
  };

  const inviteNewUser = async () => {
    //============================== validation part start =============================//
    setUserErrorStatus(true);
    const isValid = checkUserError();
    if (!isValid) {
      return false;
    }
    //============================== validation part start =============================//
    let data = { ...newUserInfo, orgId: orgId };
    try {
      axios
        .post(`${baseUrl}/orgPortal/inviteUser`, data)
        .then((response) => {
          console.log(response);
          if (response && response?.data && response?.data?.error == false) {
            // processResponse(response, resend);
            setShowSnackbar(true);
            setSnackbarSeverity("success");
            setSnackbarMessage("User invited");

            setOpenNewUser(false);
            resetUserInfo();
          } else {
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(response?.data?.message);
            resetUserInfo();
            setOpenNewUser(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error:", error);
    }
    setUserErrorStatus(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      <div className="TableSection">
        <Table
          title="Users"
          AddButton={true}
          addLabel="New Users"
          linkInnerButton=""
          innerHeader={true}
          showSearchBox={true}
          showSearchCount={true}
          searchBoxPlaceholder="Search Students"
          searchBoxText={queryString}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          headCells={headCells}
          rows={studentList}
          // AddButton={true}
          tableHeader={true}
          Checkbox={false}
          footer={true}
          onClick={() => handleOpenAddNewUser(true)}
          //onClick={() => handleOpenEditUser(true)}

          AddButtonInner={true}
          addLabelInner="Export"
          InnerButtonColor="#E0EAFB"
          InnerButtonTextColor="#004FE0"
          InnderButtonIcon={<FileUploadOutlinedIcon />}
          onClickInnerButton={handleDrawerOpenExport}

          // SelectField={true}
          // handleChanges={handleChanges}
          // options={options}
        />
      </div>

      {/* ============== new user drawer start ==================== */}
      <SideDrawer
        title="Add New User"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        open={OpenAddNewUser}
        handleDrawerClose={CloseAddNewUser}
        FooterWithoutTab={true}
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        Checkbox={true}
        CheckboxLabel="Add another after saving"
        clickSaveButton={(e) => inviteNewUser()}
        body={
          <>
            <AddOneByOne
              newUserInfo={newUserInfo}
              setNewUserInfo={setNewUserInfo}
              setEmailValidationError={setEmailValidationError}
              userError={userError}
            />
          </>
        }
      />
      {/* ============== new user drawer start ==================== */}

      {/* ============== Export drawer start ==================== */}
      <SideDrawer
        title="Export Users"
        ButtonLabel="Export"
        cancelButtonLabel="Cancel"
        //buttonLink={`/student-details/${studentData.stuId}`}
        sideOpen="right"
        open={openExport}
        handleDrawerClose={handleDrawerCloseExport}
        FooterWithoutTab={true}
        body={
          <ExportFilterUser
            filterInfo={filterInfo}
            setFilterInfo={setFilterInfo}
            exportError={exportError}
          />
        }
        clickSaveButton={(e) => exportUserHandler()}
      />
      {/* ============== Export drawer end ==================== */}
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default UserListing;
