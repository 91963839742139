import React, { useEffect, useState } from "react";
import InProgress from "./InProgress";
import Tracks from "./Tracks";
import HrTabsFill from "../../../components/HorizotalTabs/HrTabsFill";
import { getUserData } from "../../../hooks/HelperFunctions";
import CryptoJS from "crypto-js";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

function MyLearning(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let userData = getUserData();
  const [teachingAccounts, setTeachingAccounts] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  let redirect = searchParams.get("redirect");
  let activePortal = searchParams.get("active_portal");

  let projectSecondaryEnv = process.env.REACT_APP_TAKBIR_SECONDARY_ENV;
  let mainWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;
  const userId = userData.id;
  const userToken = localStorage.getItem("userToken");
  var signinToken = CryptoJS.AES.encrypt(
    JSON.stringify({
      email: userData.email,
      phone_number: userData.phone_number,
      userToken: userToken,
    }),
    "token-based-signin-aglbnd-854256-secret-key"
  ).toString();

  //====================create a link to targetPage courses =======================//
  if (projectSecondaryEnv == "prod") {
    mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain.", "");
  } else {
    const hasLocalhost = mainWebsiteUrl.includes("localhost");

    if (!hasLocalhost) {
      if (projectSecondaryEnv == "dev") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-dev");
      } else if (projectSecondaryEnv == "stage") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-stage");
      }
    }
  }
  mainWebsiteUrl += `?signinToken=${signinToken}&targetPage=search`;
  //====================create a link to targetPage courses =======================//

  const tabLabel = [
    {
      label: "Courses",
    },
    {
      label: "Tracks",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: <InProgress mainWebsiteUrl={mainWebsiteUrl} />,
    },
    {
      id: 1,
      panel: <Tracks mainWebsiteUrl={mainWebsiteUrl} />,
    },
  ];

  // fetching teaching accounts data
  const getTeachingAccountsList = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(`${baseUrl}/orgPortal/getUserOrgsData?userId=${userId}`)
      .then((result) => {
        console.log(result.data.body);
        setTeachingAccounts(result.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTeachingAccountsList();

    // setActivePortal(localStorage.getItem('activePortal'));
  }, []);

  useEffect(() => {
    if (redirect && activePortal != "" && teachingAccounts?.length > 0) {
      localStorage.setItem("orgId", activePortal);
      localStorage.setItem("activePortal", activePortal);
      let orgInfoObject = {};
      if (teachingAccounts) {
        orgInfoObject = teachingAccounts.find(
          (item) => item.orgId === activePortal
        );
      }
      console.log(orgInfoObject);
      if (orgInfoObject?.subDomain) {
        localStorage.setItem("subDomain", orgInfoObject.subDomain);
      }
      userData.userRole = "org";
      localStorage.setItem("userData", JSON.stringify(userData));
      window.location.href = "/inbox";
    }
  }, [teachingAccounts]);

  return (
    <>
      <HrTabsFill title="My Learning" tabLabel={tabLabel} tabBody={tabBody} />
    </>
  );
}

export default MyLearning;
