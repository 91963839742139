import axios from "axios";
import moment from "moment-timezone";

const baseUrl =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

export function sendDataToAPI(method, data) {
  axios.post(`${baseUrl}/${method}}`, data).then(
    (response) =>
      function () {
        return response.data;
      }
  );
}

export const retriveDataFromAPI = async (method, queryString) => {
  const result = await axios
    .get(`${baseUrl}/${method}?${queryString}`)
    .then((result) => {
      console.log(result.data.body);
      return result.data.body;
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};

// === get months list for select field === //
export const getMonths = () => {
  const months = [];

  var monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthArray.forEach((monthName) => {
    months.push({ value: monthName, label: monthName });
  });

  return months;
};

//generate the years
export const getYears = () => {
  const years = [];
  const d = new Date();
  const year = d.getFullYear() - 15;
  for (let i = year; i > year - 60; i--) {
    years.push({ value: i, label: i });
  }
  return years;
};

// === get data map for select/multiselect fields courses === //
export const getDataMap = (
  dataArray,
  primaryField,
  labelField,
  additionalField = "",
  separator = " ",
  conditionalField = "",
  conditionalValue = "",
  skipData = false
) => {
  const result = [];
  if (typeof dataArray !== "undefined") {
    dataArray.forEach((element) => {
      if (
        typeof element[labelField] !== "undefined" &&
        element[labelField] !== null
      ) {
        const label =
          additionalField === ""
            ? `${element[labelField]}`
            : `${element[labelField]}${separator}${element[additionalField]}`;

        if (conditionalField?.length > 0) {
          if (skipData) {
            // console.log(conditionalField, element[conditionalField], conditionalValue)
            if (element[conditionalField] != conditionalValue) {
              result.push({ value: `${element[primaryField]}`, label: label });
            }
          } else {
            if (element[conditionalField] == conditionalValue) {
              result.push({ value: `${element[primaryField]}`, label: label });
            }
          }
        } else {
          result.push({ value: `${element[primaryField]}`, label: label });
        }
      }
    });
  }
  // console.log(result);
  return result;
};

/**
 * retrive logged in user data
 */
export const getUserData = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData) {
    return userData;
  } else {
    return {};
  }
};

/**
 * capitalize First Letter
 */
export const capitalizeFirstLetter = (string) => {
  return string[0]?.toUpperCase() + string?.slice(1);
};

/**
 * retrive user auth data
 */
export const getUserAuthData = () => {
  const userAuthenticated = localStorage.getItem("userAuthenticated");
  if (userAuthenticated) {
    return true;
  } else {
    return false;
  }
};

/**
 * retrive user token data
 */
export const getUserTokenData = () => {
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    return userToken;
  } else {
    return "";
  }
};

export default function emailValidation(email) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
}

// valid url
export const isValidURL = (url) => {
  // Regular expression for URL validation
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return urlPattern.test(url);
};

export const validateYouTubeUrl = (url) => {
  const youtubePattern =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  return youtubePattern.test(url);
};

// timezone based date formatter
export const dateFormatter = (
  time,
  timezone = "",
  dateFormat = "ddd, MMM Do, YYYY z"
) => {
  if (moment(time).isValid()) {
    const momentTime =
      typeof time == "string" ? moment(time) : moment(time?.toISOString());
    if (timezone) {
      return momentTime.tz(timezone).format(dateFormat);
    } else {
      return momentTime.format(dateFormat);
    }
  } else {
    return null;
  }
};

// a JavaScript array that contains time data with a 15-minute interval from '00:00 AM' to '11:45 PM':
export const timeIn15MinsInterval = () => {
  const timeArray = [{ value: "", label: "" }];

  const addLeadingZero = (num) => (num < 10 ? `0${num}` : `${num}`);

  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const isAM = hour < 12;
      const hour12Format = hour === 0 || hour === 12 ? 12 : hour % 12;
      const timeString = `${addLeadingZero(hour12Format)}:${addLeadingZero(
        minute
      )} ${isAM ? "AM" : "PM"}`;
      // timeArray.push(timeString);
      timeArray.push({ value: timeString, label: timeString });
    }
  }

  // console.log(timeArray);
  return timeArray;
};

/**
 *
  @param {} birthDate 'January 1994'
 */

export const getAgeGroupBasedOnBirthDate = (birthDate) => {
  const ageGroupArr = [
    { age_from: 5, age_to: 9, age_group: "05-09 years" },
    { age_from: 10, age_to: 14, age_group: "10-14 years" },
    { age_from: 15, age_to: 19, age_group: "15-19 years" },
    { age_from: 20, age_to: 24, age_group: "20-24 years" },
    { age_from: 25, age_to: 29, age_group: "25-29 years" },
    { age_from: 30, age_to: 34, age_group: "30-34 years" },
    { age_from: 35, age_to: 39, age_group: "35-39 years" },
    { age_from: 40, age_to: 44, age_group: "40-44 years" },
    { age_from: 45, age_to: 49, age_group: "45-49 years" },
    { age_from: 50, age_to: 54, age_group: "50-54 years" },
    { age_from: 55, age_to: 59, age_group: "55-59 years" },
    { age_from: 60, age_to: 64, age_group: "60-64 years" },
    { age_from: 65, age_to: 69, age_group: "65-69 years" },
    { age_from: 70, age_to: 74, age_group: "70-74 years" },
    { age_from: 75, age_to: 79, age_group: "75-79 years" },
    { age_from: 80, age_to: 84, age_group: "80-84 years" },
    { age_from: 85, age_to: 89, age_group: "85-89 years" },
    { age_from: 90, age_to: 999, age_group: "90 years and over" },
  ];

  // Month is 0-based (July is 6th month)
  const currentDate = new Date();
  // Given birth date
  const birthDateObj = new Date(birthDate);
  // Calculate the age
  let age = currentDate.getFullYear() - birthDateObj.getFullYear();

  let ageGroup = "Unknown";

  for (const group of ageGroupArr) {
    if (age >= group.age_from && age <= group.age_to) {
      ageGroup = group.age_group;
    }
  }

  return ageGroup;
};

export const getUserTimezone = () => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (localStorage.getItem("timezone")) {
    timezone = localStorage.getItem("timezone");
  }
  return timezone;
};

// get plain text
export const extractPlainText = (jsonData) => {
  const textSegments = [];
  let parsedData = JSON.parse(jsonData != "" && jsonData);
  let allChildren = parsedData[0]?.children;
  allChildren?.forEach((item) => {
    if (item.text !== undefined) {
      textSegments.push(item.text);
    } else if (item.children) {
      textSegments.push(extractPlainText(item.children));
    } else if (item.text === "") {
      textSegments.push(" ");
    }
  });

  return textSegments.join("");
};
// get relative time
export const shortRelativeTime = (timestamp) => {
  const createdAt = moment(timestamp);
  const now = moment();
  const duration = moment.duration(now.diff(createdAt));

  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())}s`;
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())}m`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())}h`;
  } else {
    return `${Math.floor(duration.asDays())}d`;
  }
};
