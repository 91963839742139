import React, { useEffect, useState } from "react";
import "../assets/css/inbox.css";
import AddIcon from "@mui/icons-material/Add";
import Buttons from "../components/Button/Button";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinkIcon from "@mui/icons-material/Link";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import { Grid } from "@mui/material";
import TextField from "../components/TextField/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import MediaLibrarySelectMedia from "../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../../../components/MediaLibrary/Externaluse";
import { isValidURL } from "../../../../hooks/HelperFunctions";

function Attach({
  setInboxInfo,
  inboxInfo,
  setTotalSizeError,
  setAttachmentInfo,
  attachmentInfo,
  attachmentsInitialObject,
}) {
  const [openAddlink, setOpenAddlink] = useState(false);
  const attachmentsInitialObjectError = {
    attachment_name: "",
    attachment_type: "",
    attachment_link: "",
  };

  const [errors, setErrors] = useState(attachmentsInitialObjectError);
  const [total, setTotal] = useState(null);
  const handleDrawerOpenAddlink = () => {
    setOpenAddlink(true);
    setAttachmentInfo(attachmentsInitialObject);
    setErrors(attachmentsInitialObjectError);
  };
  const handleDrawerCloseAddlink = () => {
    setOpenAddlink(false);
    setAttachmentInfo(attachmentsInitialObject);
    setErrors(attachmentsInitialObjectError);
  };
  //======================================= checking errors =============================//
  const checkingValidaty = () => {
    let error = 0;
    let errorObject = { ...errors };
    if (attachmentInfo?.attachment_link === "") {
      errorObject = { ...errorObject, attachment_link: "Required" };
      error++;
    } else if (!isValidURL(attachmentInfo?.attachment_link)) {
      toast.error("Please enter a valid link");

      error++;
    }
    if (attachmentInfo?.attachment_name === "") {
      errorObject = { ...errorObject, attachment_name: "Required" };
      error++;
    }
    setErrors({ ...errors, ...errorObject });
    return error > 0 ? false : true;
  };
  //======================================= checking errors =============================//
  const onChangeHandler = (val, e, fieldName) => {
    let newObject = {
      ...attachmentInfo,
      [fieldName]: e.target.value,
      attachment_type: "url",
    };
    setAttachmentInfo(newObject);
  };
  const handleAddlink = () => {
    const isValid = checkingValidaty();
    if (!isValid) {
      return false;
    }
    if (attachmentInfo) {
      let newObjects = [...inboxInfo?.attachments];
      newObjects.push(attachmentInfo);
      setInboxInfo({
        ...inboxInfo,
        attachments: newObjects,
      });

      toast.success("Attachment added");
      handleDrawerCloseAddlink();
    }
    //
  };

  const [open, setOpen] = React.useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [mediaItem, setMediaItem] = useState({});

  const [openNewMedia, setOpenNewMedia] = useState(false);
  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };
  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };
  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };
  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      let newObject = {
        ...attachmentInfo,
        attachment_name: mediaItem.name,
        attachment_type: "media",
        attachment_link: mediaItem?.file_url,
        attachment_size: mediaItem.file_size,
        attachment_file_name: mediaItem.file_name,
      };
      let newObjects = [...inboxInfo?.attachments];
      newObjects.push(newObject);
      setInboxInfo({
        ...inboxInfo,
        attachments: newObjects,
      });

      setOpenSelectMedia(false);
      toast.success("Attachment added");
      let latestData = showTotalSize() / 1024;
      console.log(latestData, 345);
      setTotal(latestData);
    } else {
      alert("Please select any media");
    }
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };

  const deleteAttachment = (id) => {
    let newAttachments = inboxInfo?.attachments?.filter(
      (attachment, index) => index !== id
    );
    setInboxInfo({
      ...inboxInfo,
      attachments: newAttachments,
    });
    let latestData = showTotalSize() / 1024;
    console.log(latestData, 345);
    setTotal(latestData);
  };

  const showTotalSize = () => {
    let totalSize = 0;
    if (inboxInfo?.attachments && inboxInfo?.attachments?.length > 0) {
      totalSize = inboxInfo?.attachments.reduce(
        (sum, item) => sum + parseFloat(item.attachment_size || 0),
        0
      );
    }
    return totalSize;
  };

  useEffect(() => {
    if (total >= 25) {
      setOpen(true);
      setTotalSizeError(true);
    } else {
      setOpen(false);
      setTotalSizeError(false);
    }
  }, [total]);

  return (
    <div className="InboxFormSection">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="InboxFormSectionTitle">Attach</div>

      <div className="AlrtMesasage">
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {showTotalSize() / 1024}
            The selected file exceeds the max limit of 25.00 MB and cannot be
            uploaded.
          </Alert>
        </Collapse>
      </div>
      {inboxInfo?.attachments?.map((attachment, index) => (
        <div className="InboxAttachListing" key={index}>
          <div className="InboxAttachListingLeft">
            <div className="InboxAttachListingContent">
              <span>
                {attachment?.attachment_type === "media" ? (
                  <FileDownloadOutlinedIcon />
                ) : (
                  <LinkIcon />
                )}
              </span>
              <div className="InboxAttachListingInfo">
                <h2>{attachment?.attachment_name}</h2>
                {attachment?.attachment_type === "media" ? (
                  <p>
                    {(attachment?.attachment_size / 1024)?.toFixed(2)} MB .{" "}
                    {attachment?.attachment_file_name}
                  </p>
                ) : (
                  <p> {attachment?.attachment_link}</p>
                )}
              </div>
            </div>
          </div>
          <div className="InboxAttachListingRight">
            <Button
              className="AttachDeleteButton"
              variant="text"
              onClick={(e) => deleteAttachment(index)}
            >
              <DeleteForeverIcon />
            </Button>
          </div>
        </div>
      ))}

      <div className="InboxButtonGroup">
        <Buttons
          label="Add URL/Link"
          icon={<AddIcon />}
          buttonColor="#fff"
          border="#E4E2E6 1px solid"
          color="#0450E1"
          width=""
          height="48px"
          fontSize="14px"
          onClick={handleDrawerOpenAddlink}
        />

        <Buttons
          label="Add Media"
          icon={<AddIcon />}
          buttonColor="#fff"
          border="#E4E2E6 1px solid"
          color="#0450E1"
          width=""
          height="48px"
          fontSize="14px"
          onClick={() => handleDrawerOpenSelectMedia()}
        />
      </div>

      <SideDrawer
        title="Media Library"
        ButtonLabel="Add"
        //clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSelectMedia}
        handleDrawerClose={handleDrawerCloseSelectMedia}
        FooterWithoutTab={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        clickButtonLeft={handleDrawerOpenNewMedia}
        clickSaveButton={clickSelectMediaItem}
        body={
          <MediaLibrarySelectMedia
            onClickRadioIcon={setSelectedValue}
            onItemSelected={getMediaItem}
          />
        }
      />

      <Externaluse
        open={openNewMedia}
        handleCloseButton={handleMediaUploadClose}
        handleOpenNewMedia={handleOpenNewMedia}
      />

      <div className="addLinkContainer">
        <SideDrawer
          title="Add Link"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={openAddlink}
          handleDrawerClose={handleDrawerCloseAddlink}
          clickSaveButton={handleAddlink}
          FooterWithoutTab={true}
          footerWidth="826px"
          body={
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  // label="Text to display*"
                  label={
                    <span>
                      Text to display
                      <span style={{ color: "#ff3249" }}>*</span>
                    </span>
                  }
                  CharAlignMid="CharAlignMid"
                  Uppercase="Uppercase"
                  count={50}
                  value={attachmentInfo.attachment_name}
                  error={
                    attachmentInfo.attachment_name.length >= 1
                      ? ""
                      : errors?.attachment_name
                  }
                  helperText={
                    attachmentInfo.attachment_name.length >= 1
                      ? ""
                      : errors?.attachment_name
                      ? errors?.attachment_name
                      : ""
                  }
                  handleChange={(val, e) =>
                    onChangeHandler(val, e, "attachment_name")
                  }
                  // RequiredLabel={errors?.attachment_name ? true : false}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  // label="Link to*"
                  label={
                    <span>
                      Link to
                      <span style={{ color: "#ff3249" }}>*</span>
                    </span>
                  }
                  CharAlignMid="CharAlignMid"
                  Uppercase="Uppercase"
                  value={attachmentInfo.attachment_link}
                  handleChange={(val, e) =>
                    onChangeHandler(val, e, "attachment_link")
                  }
                  error={
                    attachmentInfo.attachment_link.length >= 1
                      ? ""
                      : errors?.attachment_link
                  }
                  helperText={
                    attachmentInfo.attachment_link.length >= 1
                      ? ""
                      : errors?.attachment_link
                      ? errors?.attachment_link
                      : ""
                  }
                  // RequiredLabel={errors?.attachment_link ? true : false}
                />
              </Grid>
            </Grid>
          }
        />
      </div>
    </div>
  );
}

export default Attach;
