import { Box, Container, Grid } from "@mui/material";
import React from "react";
import StudentInfo from "./StudentInfo/StudentInfo";
import QuickwaysGetStarts from "../../../components/QuickwaysGetStarts/QuickwaysGetStarts";
import ReminderCarousel from "./Courses/ReminderCarousel/ReminderCarousel";
import TodosCarousel from "../../../components/TodosCarousel/TodosCarousel";

import { LuSearch } from "react-icons/lu";
import { LiaMobileAltSolid } from "react-icons/lia";

import Courses from "./Courses/Courses";
import RecentEnrollment from "./RecentEnrollment/RecentEnrollment";
import Following from "./Following/Following";
import UpcomingSessions from "./UpcomingSessions/UpcomingSessions";
import FeaturedCourses from "./FeaturedCourses/FeaturedCourses";


const StudentHome = () => {
  return (
    <>
      <Grid container spacing={3}>

        <Grid item sx={12} sm={12}>
          <StudentInfo />
        </Grid>

        <Grid item sx={12} sm={12}>
          <QuickwaysGetStarts QuickwaysGetStartsCardData={QuickwaysGetStartsCardData} />
        </Grid>

        <Grid item sx={12} sm={12}>
          <Grid container spacing={3}>

            <Grid item sx={12} sm={8} md={8}>
              <Courses />
            </Grid>

            <Grid item sx={12} sm={4} md={4}>

              <Grid container spacing={3}>
                <Grid item sx={12} sm={12}>
                  <ReminderCarousel />
                </Grid>
                {/* <Grid item sx={12} sm={12}>
                  <TodosCarousel />
                </Grid> */}
                <Grid item sx={12} sm={12}>
                  <UpcomingSessions />
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        </Grid>

        <Grid item sx={12} sm={6}>
          <RecentEnrollment />
        </Grid>

        <Grid item sx={12} sm={6}>
          <Following />
        </Grid>

        <Grid item sx={12} sm={12}>
          <FeaturedCourses />
        </Grid>

        <Grid item sx={12} sm={12}>
          <div style={{height:"100px",}}></div>
        </Grid>
       
      </Grid>
    </>
  );
};

export default StudentHome;

const studentTableData = {
  tabslabel: 'tebone',
  tabslabelNumber: 4,
}

const QuickwaysGetStartsCardData = [
  {
    bgColor: '#E0EAFB',
    icon: <LuSearch />,
    cardDetials: "Explore online classes taught by well-renowned scholars.",
    buttonlabel: "Browse courses",
    buttonLink: "#",
    buttoncolor: '#004FE0'
  },

  {
    bgColor: '#E2F2E9',
    icon: <LiaMobileAltSolid style={{ fontSize: "32px" }} />,
    cardDetials: "Add a phone number to help keep your account safe",
    buttonlabel: "Add phone number",
    buttonLink: "#",
    buttoncolor: '#0B9444'
  },

  {
    bgColor: '#E0EAFB',
    icon: <LuSearch />,
    cardDetials: "Explore online classes taught by well-renowned scholars.",
    buttonlabel: "Browse courses",
    buttonLink: "#",
    buttoncolor: '#004FE0'
  },
]
