import React, { useEffect, useState } from "react";
import AlrtMessage from "../../../../components/Form/AlrtMessage/AlertMessage";
import Table from "../../../../components/Table/Table";
import TeamImg from "../../../../assets/imgs/tableImg.png";
import Crown from "../../../../assets/imgs/Crown.svg";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";

import InviteTeamMember from "./InviteTeamMember";
import axios from "axios";
import CustomizedSnackbar from "../../../../hooks/Snackbar";
import { useNavigate } from "react-router";
import { Avatar } from "@mui/material";
import { deepPurple } from "@mui/material/colors";

function createData(name, Role, Email, Status) {
  return { name, Role, Email, Status };
}

function TeamMembers(props) {
  const orgId = localStorage.getItem("orgId");
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [rows, setRows] = useState([]);
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const initialObject = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    roles: [],
    organization_name: "",
    designation: "",
    short_bio: "",
    orgId: orgId,
  };

  const [newTeamMemberInfo, setNewTeamMemberInfo] = useState(initialObject);

  const [emailValidationError, setEmailValidationError] = useState(false);
  //================================= show toast message ==============================//
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const processShowToastMessage = (response) => {
    // console.log(response.data);
    if (response.data.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);

      // getRolesist();
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.message);
      // getRolesist();
    }
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  //================================= show toast message ==============================//
  //======================================= checking errors =============================//
  const teamErrorsInitialObject = {
    first_name: "",
    last_name: "",
    roles: "",
    email: "",
  };
  const [teamErrors, setTeamErrors] = useState(teamErrorsInitialObject);
  const [errorStatus, setrrorStatus] = useState(false);
  const checkingValidaty = () => {
    let error = 0;
    let errorObject = { ...teamErrors };
    if (newTeamMemberInfo?.first_name === "") {
      error++;
      errorObject.first_name = "Required";
    } else {
      errorObject.first_name = "";
    }
    if (newTeamMemberInfo?.last_name === "") {
      error++;
      errorObject.last_name = "Required";
    } else {
      errorObject.last_name = "";
    }
    if (newTeamMemberInfo?.email === "") {
      error++;
      errorObject.email = "Required";
    } else if (emailValidationError) {
      error++;
      errorObject.email = "Enter a valid email";
    } else {
      errorObject.email = "";
    }
    if (newTeamMemberInfo?.roles?.length === 0) {
      error++;
      errorObject.roles = "Required";
    } else {
      errorObject.roles = "";
    }

    setTeamErrors({ ...teamErrors, ...errorObject });
    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (errorStatus) {
      checkingValidaty();
    }
  }, [errorStatus, newTeamMemberInfo]);

  //======================================= checking errors =============================//

  // fetching roles
  const getRolesist = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/getRolesByOrganizationId`, data)
      .then((response) => {
        // processShowToastMessage(response);
        let rolesData = response.data.body?.filter(
          (item) => item?.role_type !== "owner"
        );
        setRoles(rolesData);
        setNewTeamMemberInfo(initialObject);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  //================ get invited team members ============================================//
  const [invitedTeamMembers, setInvitedTeamMembers] = useState([]);
  useEffect(() => {
    if (invitedTeamMembers?.length > 0) {
      setRows(convertDataToCellRows(invitedTeamMembers));
    }
  }, [invitedTeamMembers]);
  // fetching invited team members
  const getInvitedTeamMemberList = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/getInvitedTeamMemberList`, data)
      .then((response) => {
        // processShowToastMessage(response);
        setInvitedTeamMembers(response.data.body);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getInvitedTeamMemberList();
  }, []);
  //================ get invited team members ============================================//
  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleOpenNewCategory = () => {
    setOpenNewCategory(true);
  };

  const handleCloseModalNewCategory = () => {
    console.log("close");
    setOpenNewCategory(false);
  };

  const handleConfirmNewCategory = () => {
    console.log("close");
    setOpenNewCategory(false);
  };

  // Modal Add Category  start//

  const onClick = () => {
    alert("onclick working fine!");
  };

  //==== Drawer Start=== //
  const [openInviteTeam, setOpenInviteTeam] = useState(false);
  const handleDrawerOpenInviteTeam = () => {
    getRolesist();
    setOpenInviteTeam(true);
  };
  const handleDrawerCloseInviteTeam = () => {
    setOpenInviteTeam(false);
    setrrorStatus(false);
    setShowSnackbar(false);
    setTeamErrors(teamErrorsInitialObject);
    setNewTeamMemberInfo(initialObject);
  };
  //==== Drawer End=== //

  const handleMoveToTheDetails = (data) => {
    if (data?.id) {
      navigate(`/team-details/${data?.id}`);
    } else {
      navigate(`/team-details/${data?.email}`);
    }
  };
  //=================== setting invited team member list in the table ========================//

  const headCells = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "30%",
    },
    {
      id: "Role",
      numeric: true,
      disablePadding: false,
      label: "Role",
      width: "20%",
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Email",
      width: "40%",
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    { id: "", numeric: true, disablePadding: false, label: "", width: "25%" },
    // { id: "", numeric: true, disablePadding: false, label: "", width: "15%" },
  ];

  const convertedRollName = (data) => {
    let names = [];
    data?.map((item) => names.push(item?.name));
    let finalName = names.join(",");
    return finalName;
  };
  const getProfileAvatarFromNameData = (user) => {
    return `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(
      0
    )}`.toUpperCase();
  };

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        //status
        let background = "";
        let color = "";
        let label = "";
        if (item?.status === "active") {
          background = "#E1F2E8";
          color = "#0B9444";
          label = "Active";
        } else if (item?.status === "invite_sent") {
          background = "#ECE0FD";
          color = "#6200EE";
          label = "Invite Sent";
        } else {
          background = "#F7E3E3";
          color = "#BA1A1A";
          label = "Inactive";
        }

        return {
          cell: [
            {
              img: item?.image,
              ImgSize: "60%",
              imgTitle: item?.name.charAt(0).toUpperCase(),
              imgStatus: true,
              imgAvatarColor: "#004FE0",
              imgText: item?.name,
            },
            item?.roles?.length > 0
              ? {
                  text: convertedRollName(item?.roles),
                  textWithIcon:
                    convertedRollName(item?.roles) === "Owner" ? (
                      <img src={Crown} />
                    ) : (
                      ""
                    ),
                  TextWithIcon: true,
                }
              : { text: "N/A" },

            { text: item?.email },
            {
              Status: true,
              background: background,
              color: color,
              label: label,
            },

            {
              Button: true,
              buttonLabel: "Details",
              disabled:
                convertedRollName(item?.roles) === "Owner" ? "disabled" : "",
              buttonTextcolor: "#004FE0",
              buttonLink: "",
              onClick: (e) => handleMoveToTheDetails(item),
              buttonTarget: "_blank",
              buttonBorder: "#E4E8EC 1px solid",
            },
          ],
        };
      });
    } else {
      return [];
    }
  };

  //=================== setting invited team member list in the table ========================//

  //=================================== inviting new team member ===============================//

  const handleInvitingNewTeamMember = () => {
    setrrorStatus(true);
    const isValid = checkingValidaty();
    if (!isValid) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Required felids are left");
      return false;
    }

    let data = { ...newTeamMemberInfo };
    axios
      .post(`${baseUrl}/orgPortal/inviteTeamMember`, data)
      .then((response) => {
        if (!response?.data?.error) {
          processShowToastMessage(response);
          getInvitedTeamMemberList();
          handleDrawerCloseInviteTeam();
          setTeamErrors(teamErrorsInitialObject);
        } else {
          processShowToastMessage(response);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <AlrtMessage
        message="Invite sent"
        //message="Information updated"
        background="#0B9444"
      />

      <div className="TableSection">
        <Table
          title="Team Members"
          addLabel="New Member"
          onClick={handleDrawerOpenInviteTeam}
          headCells={headCells}
          rows={rows}
          AddButton={true}
          tableHeader={true}
          Checkbox={false}
          footer={true}
        />
      </div>

      <SideDrawer
        title="Invite Team Member"
        ButtonLabel="Send Invite"
        cancelButtonLabel="Cancel"
        DeleteButton={true}
        sideOpen="right"
        open={openInviteTeam}
        handleDrawerClose={handleDrawerCloseInviteTeam}
        clickSaveButton={handleInvitingNewTeamMember}
        FooterWithoutTab={true}
        body={
          <>
            <InviteTeamMember
              setNewTeamMemberInfo={setNewTeamMemberInfo}
              newTeamMemberInfo={newTeamMemberInfo}
              roles={roles}
              setEmailValidationError={setEmailValidationError}
              teamErrors={teamErrors}
            />
          </>
        }
      />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default TeamMembers;
