import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SwitchButton from "../../../../../components/Form/Switch/Switch";
import MultipleSeletChip from "../../../../../components/Form/MultipleSeletChip/MultipleSeletChip";
import { getDataMap } from "../../../../../hooks/HelperFunctions";

function Prerequisite(props) {
  const {
    courseData,
    setCourseData,
    showSnackbarMessage,
    courseErrors,
    setCourseErrors,
  } = props;
  const { preCourses } = props.courseRelationalData;

  const [allPreCourses, setAllPreCourses] = React.useState(preCourses);
  const [preCoursesDatas, setPreCoursesDatas] = React.useState(
    courseData?.preCourses
  );

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = "";

    if (fieldName == "prerequisite") {
      fieldData = event.target.checked;
    } else {
      fieldData = event.target.value;
    }
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
    if (fieldName === "preCourses") {
      setPreCoursesDatas(fieldData);
    }
  };

  //==================================================================================//
  const getFilterData = (data1, data2) => {
    return data1?.filter(
      (data) =>
        !data2.some(
          (selectedData) => parseInt(selectedData.value) === data.courseId
        )
    );
  };
  useEffect(() => {
    let newGradeLevelsOption = getFilterData(preCourses, preCoursesDatas);
    setAllPreCourses(newGradeLevelsOption);
  }, [preCoursesDatas]);

  //==================================================================================//

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Prerequisite</div>

            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <SwitchButton
                  title="Prerequisite course"
                  subtitle="Enable this option if this course has prerequisite courses"
                  BorderLine={true}
                  checked={courseData.prerequisite}
                  handleChange={handleChanges("prerequisite")}
                />
              </Grid>
              {courseData.prerequisite && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <p style={{ margin: "0" }}>
                      Select course(s) that students have to complete before
                      taking this course. You must select at least 1
                      prerequisite course. If no prerequisite course then turn
                      this off from above.
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSeletChip
                      label="Select course(s)"
                      validation={false}
                      options={getDataMap(
                        allPreCourses,
                        "courseId",
                        "courseTitle"
                      )}
                      selectedOption={preCoursesDatas}
                      error={
                        preCoursesDatas?.length === 0
                          ? courseErrors?.preCourses
                          : ""
                      }
                      helperText={
                        preCoursesDatas?.length === 0
                          ? courseErrors?.preCourses
                          : ""
                      }
                      handleChange={(event, fieldData) =>
                        handleChangeWithData("preCourses", fieldData)
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Prerequisite;
