import react from "react";

import EmptyIcon from "../../assets/imgs/Empty.svg";
import WarningIcon from "../../assets/imgs/Warning.svg";
import "./EmptyCard.css";
import Buttons from "../../components/Form/Button/Button";

function EmptyCard(props) {
  return (
    <div className="EmptyCardContainer">
      {props.TableTitleStatus && (
        <div class="TableHeader">
          <h1 class="TableTitle">{props.TableTitle}</h1>
          <div></div>
        </div>
      )}
      <div className="EmptyCard">
        <img
          className="EmptyCardIcon"
          src={props.mainIcon == "warning" ? WarningIcon : EmptyIcon}
        />
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        {props.showButton && (
          <div className="EmptyCardButton">
            <Buttons
              label={props.btnLabel}
              buttonColor="#004FE0"
              color="#fff"
              width="auto"
              height="48px"
              fontSize="14px"
              link={props.btnLink}
              icon={props.icon}
              onClick={props.onClick}
              target={props.target}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EmptyCard;
