import React from "react";
import './ScrollBar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'





function ScrollBar(props) {



  return (
    <PerfectScrollbar
      className="ScrollBarContainer"
      style={{height: props.ScrollBarHeight}}
      onScrollY={container => console.log(`scrolled to: ${container.scrollBottom}.`)}

    >

      {props.body}

    </PerfectScrollbar>

  )
}


export default ScrollBar;

