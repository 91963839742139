import React, { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import "./SelectField.css";

function SelectField(props) {
  // const [age, setAge] = React.useState('');
  // const handleChanges = (event) => {
  //   setAge(event.target.value);
  // };

  // const addSessiondate = [
  //   {
  //     title: "Live",
  //     subtitle: "Add Zoom link",
  //   },

  //   {
  //     title: "Pre-recorded video",
  //     subtitle: "Uplaod video",
  //   },
  // ]

  //   < SelectField
  // label = "Month"
  // options = { options }
  // handleChange = { handleChanges }
  //   />

  const {
    label,
    validation,
    required,
    options,
    selectedOption,
    handleChange,
    error,
    helperText,
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  // useEffect(() => {
  //   if (required || validation) {
  //     if (!selectedOption) {
  //       setValidationError(true);
  //       setValidationText("Required");
  //     } else {
  //       setValidationError(false);
  //       setValidationText("");
  //     }
  //   }
  // }, [selectedOption, error, validation]);

  return (
    <div className="SelectFiled">
      <div className="FieldLabel">
        {label} {(validation || required) && <span>*</span>}
      </div>
      <FormControl error={error || validationError}>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={selectedOption}
          onChange={handleChange}
          // error={error || validationError}
          disabled={props.disabled ? props.disabled : undefined}
          error={error}
          className="SelectmenuDropdownSelectedLabel"
          MenuProps={props.MenuProps ? props.MenuProps : undefined}
        >
          {options.map((item, i) => (
            <MenuItem className="SelectmenuDropdownComp" value={item.value}>
              {item.label}
              <div className="SelctFieldSubtitle">{item.subtitle}</div>
            </MenuItem>
          ))}
        </Select>
        {(helperText === "" || validationText === "" || error) && (
          <FormHelperText>{helperText || validationText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

export default SelectField;
