import React from "react";

export function MegaphoneFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#004FE0"
        d="M21 10.063V4a1 1 0 0 0-1-1h-1c-1.979 1.979-5.697 3.087-8 3.613v10.774c2.303.526 6.021 1.634 8 3.613h1a1 1 0 0 0 1-1v-6.063a2 2 0 0 0 0-3.874M5 7a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h1l1 5h2V7z"
      ></path>
    </svg>
  );
}
