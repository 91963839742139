import React, { useState, useEffect } from "react";
import axios from 'axios';

import Grid from '@mui/material/Grid';
import SwitchButton from '../../../../../components/Form/Switch/Switch'
import ComboBox from '../../../../../components/Form/ComboBox/ComboBox';
import Datefield from '../../../../../components/Form/Datefield/Datefield';
import TextField from '../../../../../components/Form/TextField/TextField'

import { getDataMap, getMonths, getYears } from '../../../../../hooks/HelperFunctions'


function Settings(props) {

  const { courseData, setCourseData, courseRelationalData,setCourseRefIdFound } = props;
  const [courseRefId, setCourseRefId] = useState("");
  const [hasExistingCourseId, setHasExistingCourseId] = useState(false);

  const handleChanges = (fieldName, checkbox) => (event) => {
    const fieldData = checkbox ? event.target.checked : event.target.value;
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  const handleCourseIdChanges = (fieldName) => (event) => {
    let fieldData = event.target.value;
    console.log(fieldName, fieldData);
    fieldData = fieldData.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    setCourseData({ ...courseData, [fieldName]: fieldData });
  }; 

  useEffect(() => {    
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${baseUrl}/orgPortal/checkCourseRefId?courseRefId=${courseData.courseRefId}`).then((result) => {
      console.log(result.data.duplicateCourseRefId);
      if(result.data.duplicateCourseRefId){
        setHasExistingCourseId(true);     
        setCourseRefIdFound(true)   
      } else{
        setHasExistingCourseId(false);
        setCourseRefIdFound(false);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [courseData.courseRefId]);

  useEffect(() => {
    const fieldName = "courseRefIdError";
    if(hasExistingCourseId){
      setCourseData({ ...courseData, [fieldName]: true });
    } else{
      setCourseData({ ...courseData, [fieldName]: false });
    }
  }, [hasExistingCourseId]);



  return (
    <>
      <div className="formSection Section">
        <div className="MdTitle">Settings</div>
        <div className="FormBody">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <SwitchButton
                title="Course completion certificate"
                subtitle="Students can earn certificate by completing your course"
                BorderLine={true}
                handleChange={handleChanges("completionCertificate", true)}
                checked={courseData.completionCertificate}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12}>
              <SwitchButton
                title="Graded exam certificate"
                subtitle="Students can earn certificate submitting exam"
                BorderLine={true}
                ComingSoon={true}
                checked={courseData.gradedExamCertificate}
                handleChange={handleChanges("gradedExamCertificate", true)}
                disabled={true}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={12} md={12}>
              <SwitchButton
                title="Enable Attendance"
                subtitle="Enable this option if attendance is required"
                ComingSoon={true}
                checked={courseData.enableAttendance}
                handleChange={handleChanges("enableAttendance", true)}
                disabled={true}
              />
            </Grid> */}
          </Grid>
        </div>
      </div>


      <div className="formSection Section">
        <div className="MdTitle">Course ID</div>
        <p style={{ color: "#676C7B", }}>Course ID should be unique. A unique course ID helps to avoid confusion and ambiguity when referring to a specific course.</p>
        <div className="FormBody IdField">
          <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Enter Course ID"
                  validation={true}
                  error={hasExistingCourseId}
                  helperText={hasExistingCourseId? "Course ID already exists" : ""}
                  CharAlignMid="CharAlignMid"
                  // Uppercase="Uppercase"
                  count={8}
                  value={courseData.courseRefId}
                  handleChange={handleCourseIdChanges("courseRefId")}
                />
              </Grid>
          </Grid>
        </div>
      </div>


      <div className="formSection Section">
        <div className="MdTitle">Course Schedule</div>
        <div className="FormBody">
          <Grid container spacing={3}>


            <Grid item xs={12} sm={8} md={8}>
              <ComboBox
                label="Select Timezone"
                placeholder=" "
                validation={true}
                options={getDataMap(courseRelationalData.timezones, "timezone", "timezone", "gmtOffset", " | GMT ")}
                selectedOption={courseData.timezone}
                handleChange={(fieldData) => handleChangeWithData("timezone", fieldData)}

                TooltipStatus={true}
                TooltipText="Select the timezone from where course will be primarily proctored from.  All learners will see this absolute timezone on their ends."
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Datefield
                label="Enrollment Start Date"
                validation={true}
                timeZone={courseData.timezone}
                value={courseData.enrollmentStartDate}
                timezone={courseData.timezone}
                handleChange={(selectedDate) => handleChangeWithData("enrollmentStartDate", selectedDate)}
                disablePast={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Datefield
                label="Enrollment End Date"
                validation={true}
                timeZone={courseData.timezone}
                value={courseData.enrollmentEndDate}
                timezone={courseData.timezone}
                handleChange={(selectedDate) => handleChangeWithData("enrollmentEndDate", selectedDate)}
                disablePast={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Datefield
                label="Session Start Date"
                validation={true}
                timeZone={courseData.timezone}
                value={courseData.sessionStartDate}
                timezone={courseData.timezone}
                handleChange={(selectedDate) => handleChangeWithData("sessionStartDate", selectedDate)}
                disablePast={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Datefield
                label="Session End Date"
                validation={false}
                value={courseData.sessionEndDate}
                timezone={courseData.timezone}
                handleChange={(selectedDate) => handleChangeWithData("sessionEndDate", selectedDate)}
                disablePast={true}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Total Sessions"
                required={false}
                value={courseData.totalSessions}
                handleChange={handleChanges("totalSessions")}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Total Hours"
                required={false}
                value={courseData.totalHours}
                handleChange={handleChanges("totalHours")}
              />
            </Grid>  

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Total Live Sessions"
                required={false}
                value={courseData.totalLiveSessions}
                handleChange={handleChanges("totalLiveSessions")}
              />
            </Grid>

          </Grid>
        </div>
      </div>
    </>
  );
}


export default Settings;

