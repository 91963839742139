import React, { useEffect, useState } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import CallTwoToneIcon from "@mui/icons-material/CallTwoTone";
import CustomizedSnackbar from "../../hooks/Snackbar";
import DeenLogo from "../../assets/imgs/deen360-logo.png";
import "./Form.css";
import PhoneInputField from "../../components/Form/PhoneInputField/PhoneInputField";
import { useNavigate } from "react-router-dom";

function ChangeEmailAddress(props) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [email, setEmail] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [resendBtn, setResendBtn] = React.useState(false);
  const [formValidationError, setFormValidationError] = useState(false);
  const [getTheStatus, setGetTheStatus] = useState("email");

  const navigate = useNavigate();
  const handleChanges = (fieldName) => (event) => {
    const userEmail = event.target.value;
    console.log(userEmail);
    setEmail(userEmail);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setEmail(userData?.email);
  }, []);

  const processResponse = (response, resend) => {
    setResendBtn(true);
    setBtnDisabled(false);

    if (response.data.body.status == true) {
      if (resend) {
        setShowSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Email sent");
      }
      // setCurentPage("forgot-pass-result");
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }
  };

  const processResponseSingWithPhone = (response) => {
    console.log(response);
    if (response.data.body.status == true) {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.body.message);
      // redirect to sign in page
      setTimeout(() => {
        setSnackbarMessage("Redirecting to sign in page...");
        window.location.replace(`${window.location.origin}/signin`);
      }, 3000);
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }
  };
  const sendForgotPasswordRequest = (resend = false) => {
    if (formValidationError == false) {
      const baseUrl =
        process.env.REACT_APP_PROJECT_ENV === "prod"
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;
      ///===================== recapcha ==============================///
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(
            `${process.env.REACT_APP_RECAPCHA_SITE_KEY}`,
            { action: "submit" }
          );
          ///===================== recapcha ==============================///
          const postData = {
            email: email,
            passwordResetURL: `${window.location.origin}/reset-password`,
            token: token,
          };

          setBtnDisabled(true);

          axios
            .post(`${baseUrl}/forgotPassword`, postData)
            .then((response) => {
              if (response?.data.body.status) {
                processResponse(response, resend);
              } else {
                setShowSnackbar(true);
                setSnackbarSeverity("error");
                setSnackbarMessage(
                  "We can't find an account for that email address. Please try again with other information."
                );
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.message) {
                setBtnDisabled(false);
                setShowSnackbar(true);
                setSnackbarSeverity("error");
                setSnackbarMessage(err.response.data.message);
              }
            });
        } catch (error) {
          console.error("Error:", error);
        }
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      setFormValidationError(true);
    } else {
      setFormValidationError(false);
    }
  };
  //== Phone Field State start== //
  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });
  //== Phone Field State end== //

  const phoneChangeHandler = (ext, number) => {
    setPhone({
      ...phone,
      number: number,
    });
    // handleChanges("phone_number", `${number}`);
  };
  const sendForgotPasswordWithPhone = () => {
    // if (values?.phone_number === "") {
    //   setPhoneError("Enter a valid phone number");
    //   return false;
    // } else {
    //   setPhoneError("");
    // }
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    let postData = {
      phone_number: phone?.number,
    };
    axios
      .post(`${baseUrl}/resetPasswordOtpSend`, postData)
      .then((response) => {
        if (!response?.data.error) {
          navigate("/verify-otp");
          processResponseSingWithPhone(response);
        } else {
          setShowSnackbar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(
            "We can't find an account for that phone number. Please try again with other information."
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // alert(4643);
  };

  const changeEmailFromAnAccount = (resend = false) => {
    if (formValidationError == false) {
      const baseUrl =
        process.env.REACT_APP_PROJECT_ENV === "prod"
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;

      const postData = {
        email: email,
      };

      setBtnDisabled(true);

      if (email === "") {
        return;
      }

      axios
        .post(`${baseUrl}/studentPortal/changeEmailFromAnAccount`, postData)
        .then((response) => {
          // console.log(response, 345);
          if (!response?.data?.error) {
            const userData = JSON.parse(localStorage.getItem("userData"));
            userData.email = email;
            localStorage.setItem("userData", JSON.stringify(userData));

            setShowSnackbar(true);
            setSnackbarSeverity("success");
            setSnackbarMessage(response?.data?.message);
            // redirect to sign in page
            setTimeout(() => {
              setSnackbarMessage("Redirecting to settings in page...");
              window.location.replace(`${window.location.origin}/settings`);
            }, 3000);
          } else {
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(response?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err);
          if (err?.response?.data?.message) {
            setBtnDisabled(false);
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(err.response.data.message);
          }
        });
    }
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <CustomizedSnackbar
            open={showSnackbar}
            severity={snackbarSeverity}
            message={snackbarMessage}
            handleClose={handleSnackbarClose}
          />

          <div className="SignForm">
            <h1>Change email address</h1>
            <div className="formContainer">
              <div className="SignFormGrid">
                {getTheStatus === "email" && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <p style={{ textAlign: "center" }}>
                        Adding an email to your account can help you log in and
                        reset your password easily.
                      </p>
                    </Grid>

                    <Grid item xs={12} md={12} className="HideLabelClass">
                      <TextField
                        // required={true}
                        email={true}
                        value={email}
                        handleValidation={handleValidation}
                        handleChange={handleChanges("email")}
                        placeholder="Email address"
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Buttons
                        label="Change"
                        buttonColor="#004FE0"
                        border="#ccc 1px solid"
                        color="#fff"
                        width="100%"
                        height="48px"
                        fontSize="14px"
                        link=""
                        onClick={() => changeEmailFromAnAccount()}
                        disabled={btnDisabled}
                      />
                      <div className="BottomButton">
                        <Buttons
                          label="Cancel"
                          color="#004FE0"
                          width=""
                          height="48px"
                          fontSize="14px"
                          link="/settings"
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}

                {/* ======================= sign in with phone ======================= */}
              </div>
            </div>
          </div>

          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeEmailAddress;
