import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  getUserData,
  getUserTimezone,
} from "../../../../hooks/HelperFunctions";

import AlrtMessage from "../../../../components/Form/AlrtMessage/AlertMessage";
import Table from "../../../../components/Table/Table";

import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import StatusChip from "../../../../components/Form/StatusChip/StatusChip";

import OrderDetails from "./PaymentMetod/OrderDetails";
import PaymentMethodZelle from "./PaymentMetod/PaymentMethodZelle";
import PaymentMethodCash from "./PaymentMetod/PaymentMethodCash";
import PaymentMethodCheck from "./PaymentMetod/PaymentMethodCheck";
import OrderItems from "./PaymentMetod/OrderItems";

import Modals from "../../../../components/Form/Modals/Modals";

import FileUplaoder from "../../../../components/Form/FileUplaoder/FileUplaoder";

import SimpleBackdrop from "../../../../components/Form/Backdrop/SimpleBackdrop";
import CustomizedSnackbar from "../../../../hooks/Snackbar";

function PaymentHistory(props) {
  const { studentBillingData } = props;

  const [paymentData, setPaymentData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [paymentReceipt, setPaymentReceipt] = useState(null);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const timezone = getUserTimezone();

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const triggerSnackbar = (status = false, severity = "", message = "") => {
    setShowSnackbar(status);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const headCells = [
    {
      id: "course",
      numeric: true,
      disablePadding: false,
      label: "Course",
      width: "35%",
    },
    {
      id: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      width: "25%",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      width: "13%",
    },
    {
      id: "method",
      numeric: true,
      disablePadding: false,
      label: "Method",
      width: "13%",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "13%",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
      width: "12%",
      align: "center",
    },
  ];

  // == Drawer Start == //
  const [openDrawer, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // == Drawer End == //

  // == Modal Start == //
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    console.log("close");
    setOpenModal(false);
  };

  const handleConfirmModal = () => {
    console.log("confirm");

    setOpenBackdrop(true);

    let postData = {
      paymentId: paymentData.paymentId,
      attachmentFile: paymentReceipt,
      markAsPaid: 1,
      portal: "learner",
    };

    axios
      .post(`${baseUrl}/studentPortal/updatePaymentsData`, postData)
      .then((response) => {
        // Handle the response from the server
        console.log(response.data);
        setOpenBackdrop(false);
        setOpenModal(false);
        handleDrawerClose();
        setPaymentReceipt(null);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error(error);
      });
  };
  // == Drawer End == //

  const btnHandleClick = (id, operation) => {
    console.log(id, operation);
    // retrive payment data
    setOpenBackdrop(true);
    axios
      .get(
        `${baseUrl}/studentPortal/getPaymentDetails?paymentId=${id}&timezone=${timezone}`
      )
      .then((result) => {
        console.log(result.data.body);
        setPaymentData(result.data.body);
        setCourseData(result.data.courseData);
        setOpenBackdrop(false);
        handleDrawerOpen();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="TableSection">
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />

      <Table
        innerTitle="Payment History"
        innerHeader={true}
        headCells={headCells}
        rows={studentBillingData}
        btnHandleClick={btnHandleClick}
        AddButton={false}
        Checkbox={false}
        footer={true}
      />

      <SideDrawer
        title={
          <div className="TitlewituChip">
            ${paymentData.amount ? paymentData.amount / 100 : 0} USD{" "}
            <StatusChip
              Status={
                paymentData.status
                  ? paymentData.status.charAt(0).toUpperCase() +
                    paymentData.status.slice(1)
                  : ""
              }
            />
          </div>
        } // Status="Paid, Failed, Canceled, Refund" //
        // title={<div className="TitlewituChip">$200 USD <StatusChip Status="Paid" /></div>} // Status="Paid, Failed, Canceled, Refund" //
        ButtonLabel={paymentData.status == "paid" ? null : "Mark As Paid"}
        clickSaveButton={handleOpenModal}
        cancelButtonLabel="Close"
        DeleteButton={true}
        sideOpen="right"
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        clickButtonLeft={handleDrawerOpen}
        FooterWithoutTab={true}
        body={
          <>
            <OrderDetails paymentData={paymentData} />

            {paymentData.method == "Zelle" && (
              <PaymentMethodZelle paymentData={paymentData} />
            )}
            {paymentData.method == "Cash" && (
              <PaymentMethodCash paymentData={paymentData} />
            )}
            {paymentData.method == "Check" && (
              <PaymentMethodCheck paymentData={paymentData} />
            )}

            <OrderItems courseData={courseData} />
          </>
        }
      />

      <Modals
        ModalTitle="Mark as paid"
        open={openModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Submit"
        width="540px"
        textAlign="center"
        ModalBody={
          <div>
            <div>
              MUNA Academy will be notified about your payment completion.
              Please add an attachment below (optional)
            </div>
            <div>
              {/* <FileUplaoder /> */}
              <FileUplaoder
                filePath={`students/${paymentData.userId}/payment-receipt/`}
                uploadButton="Upload Photo"
                file={paymentReceipt}
                fileType="image/*"
                onUpload={(filePath) => setPaymentReceipt(filePath)}
                setSelectedFile={() => {}}
                helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                showUploadButton={false}
                showThumb={true}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default PaymentHistory;
