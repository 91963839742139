import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import styles from "./MediaFileUploader.module.css";

import AWS from "aws-sdk";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
//import deletebuttononhover icon from mui
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CustomizedSnackbar from "../../../hooks/Snackbar";
import Tooltip from "../Tooltip/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";

const CONF_S3_DIRECTORY =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_S3_DIRECTORY_PROD
    : process.env.REACT_APP_S3_DIRECTORY_DEV;

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const S3_DIRECTORY = CONF_S3_DIRECTORY;
const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
const REGION = process.env.REACT_APP_S3_REGION;

const REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL;

const s3Bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const MediaFileUplaoder = forwardRef((props, ref) => {
  const {
    filePath,
    label,
    validation,
    uploadButton,
    file,
    fileType,
    showThumb,
    visibility,
    onUpload,
    uploaded,
    setUploaded,
    getUrl,
    setGetUrl,
    filePathData,
    setFilePathData,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMessage,
    setSnackbarMessage,
    selectedFile,
    setSelectedFile,
    errorState,
    progress,
    setProgress,
  } = props;

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  useImperativeHandle(ref, () => ({
    clickButton: () => deleteUploadedFile(),
  }));

  // const [progress, setProgress] = useState(0);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const fileInputRef = useRef(null);
  const [subDomain, setSubDomain] = useState("");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [preview, setPreview] = useState(null);

  const [isImage, setIsImage] = useState(false);

  // const [showSnackbar, setShowSnackbar] = useState(false);

  // const [snackbarSeverity, setSnackbarSeverity] = useState("");
  // const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const localSubDomain = localStorage.getItem("subDomain");
    setSubDomain(localSubDomain);
  }, []);

  //======================== get the presignedUrl for uploading data in the s3 ==============//
  const getFileUploadPresignedUrl = async (fileData) => {
    let data = {
      file_type: fileData?.type.startsWith("video/")
        ? "videos"
        : fileData?.type.startsWith("image/")
        ? "images"
        : "documents",
      file_visibility: visibility,
      key: fileData?.name?.replace(/\s+/g, "-"),
    };
    const result = await axios.post(
      `${baseUrl}/getFileUploadPresignedUrl`,
      data
    );

    if (result?.status === 200) {
      setGetUrl(result?.data?.url);
      setFilePathData(result?.data?.key?.replace("\\", ""));
    }
  };
  //======================== get the presignedUrl for uploading data in the s3 ==============//

  //=========================  upload the file to the s3  ====================================//
  const xhrRef = useRef(null);
  const abortUpload = () => {
    if (xhrRef.current) {
      xhrRef.current.abort();
      console.log("Upload aborted");
    }
  };
  useEffect(() => {
    if (selectedFile && !errorState && filePathData === null) {
      getFileUploadPresignedUrl(selectedFile);
    }
    console.log(errorState, filePathData, 345);
  }, [selectedFile, errorState, filePathData]);
  useEffect(() => {
    if (!selectedFile) {
      abortUpload();
    }
    // console.log(errorState, filePathData, 345);
  }, [selectedFile]);
  useEffect(() => {
    if (selectedFile && getUrl && filePathData) {
      uploadFile();
      setUploaded(true);
    }
  }, [getUrl, selectedFile, filePathData]);

  const uploadFileWithProgress = (url, file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhrRef.current = xhr;

      xhr.open("PUT", url, true);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          console.log(progress, 404, event);
          setProgress(progress);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => reject(new Error("Upload failed"));

      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  };

  // const uploadFileWithProgress = (url, file) => {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();

  //     xhr.open("PUT", url, true);

  //     xhr.upload.onprogress = (event) => {
  //       if (event.lengthComputable) {
  //         const progress = Math.round((event.loaded * 100) / event.total);
  //         console.log(progress, 404, event);
  //         setProgress(progress);
  //       }
  //     };

  //     xhr.onload = () => {
  //       if (xhr.status >= 200 && xhr.status < 300) {
  //         resolve(xhr.response);
  //       } else {
  //         reject(new Error(`Upload failed with status: ${xhr.status}`));
  //       }
  //     };

  //     xhr.onerror = () => reject(new Error("Upload failed"));

  //     xhr.setRequestHeader("Content-Type", file.type);
  //     xhr.send(file);
  //   });
  // };

  const uploadFile = async () => {
    if (!selectedFile) return;

    const presignedUrl = getUrl;
    const formattedUrl = presignedUrl?.replace("\\", "");

    try {
      await uploadFileWithProgress(formattedUrl, selectedFile);
      setGetUrl(null);
      setUploaded(false);
      setShowSnackbar(false);
      setProgress(0);
      setSnackbarMessage("");
      setSnackbarSeverity("");
    } catch (error) {
      console.error("Error uploading file", error, 346);
    }
  };
  //========================= upload the file to the s3 ======================================//

  const handleFileDelete = (filePath) => {
    console.log(filePath);
    filePath = filePath.replace(S3_BASE_URL, "");
    const params = {
      Bucket: S3_BUCKET,
      Key: filePath,
    };
    s3Bucket.deleteObject(params, function (deleteErr, data) {
      if (deleteErr) {
        console.log("Error: " + deleteErr, filePath);
      } else {
        console.log("Successfully deleted S3 object", filePath);
        props.onUpload("");
      }
    });
  };

  const deleteUploadedFile = async () => {
    abortUpload();
    if (filePathData) {
      let data = {
        key: filePathData,
      };
      try {
        const response = await axios.delete(`${baseUrl}/deleteMediaFromS3`, {
          data: data,
        });
        if (response?.status === 200) {
          setFilePathData(null);
          setUploaded(false);
        }
      } catch (error) {
        console.error("Error uploading file", error, 346);
      }
    }
    setSelectedFile(null);
    setShowSnackbar(false);
    setSnackbarMessage("");
    setSnackbarSeverity("");
    setProgress(0);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div className={styles.MediaFileUplaoder}>
      <div className={`${styles.MediaFileUplaoderLabel} FieldLabel`}>
        {label} {props.required && <span>*</span>}
        {props.TooltipStatus && (
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoOutlinedIcon />}
          />
        )}
      </div>

      <div>
        {file ? (
          " "
        ) : (
          <div>
            <div className={props.FieldError + " " + snackbarSeverity}>
              {/* File Uploader Code start */}
              <div
                className={styles.MediaFileUplaoderInput}
                style={{ border: props.Error }}
              >
                <div className={styles.MediaFileUplaoderInputLeftCol}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FileUploadOutlinedIcon />}
                    disabled={props.disabled ? props.disabled : undefined}
                    onClick={props.handleFileUpload}
                    className={styles.MediaFileUplaoderButton}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={props.handleFileUpload}
                    />
                  </Button>

                  <div className={styles.MediaFileUplaoderInfo}>
                    <div className={styles.MediaFileUplaoderFileName}>
                      {!selectedFile ? (
                        <span>No file choosen</span>
                      ) : (
                        <p>
                          {props.filename.length > 50
                            ? `${props.filename.substring(0, 50)}...`
                            : props.filename}
                        </p>
                      )}
                    </div>
                    {selectedFile && (
                      <div className={styles.MediaFileUplaoderFileSize}>
                        {props.filesize} {props.UploadedStatus}
                      </div>
                    )}
                  </div>
                </div>

                {selectedFile && (
                  <DeleteForeverRoundedIcon
                    //if no file selected then don't show delete icon
                    // style={{ display: uploaded ? "block" : "none" }}
                    className={styles.DeleteIcon}
                    onClick={() => deleteUploadedFile()}
                  />
                )}
              </div>
              {uploaded && (
                <div className={styles.MediaFileUplaoderProgress}>
                  <LinearProgress variant="determinate" value={progress} />
                </div>
              )}

              <p className="HelperText" style={{ marginBottom: "10px" }}>
                {snackbarMessage ? snackbarMessage : props.helpertext}
              </p>
              {/* File Uploader Code End */}
            </div>

            {/* {progress > 0 && progress < 100 && (
              <CircularProgress variant="determinate" value={progress} />
            )} */}

            <Button
              variant="contained"
              ref={ref}
              onClick={() => uploadFile(selectedFile)}
              disabled={progress > 0 && progress < 100 ? true : false}
              //if props.showUploadButton is false then don't show upload button
              style={{ display: props.showUploadButton ? "block" : "none" }}
            >
              {uploadButton}
            </Button>
          </div>
        )}

        {file ? (
          <div className="FileUplaoder">
            <div className="FileUplaoderInfo">
              {fileType == "image" && showThumb ? (
                <img src={file} />
              ) : (
                <span>
                  <AttachmentIcon />
                </span>
              )}
              <span> {file.replace(/^.*[\\\/]/, "")} </span>
            </div>
            <CloseRoundedIcon
              className="CloseIcon"
              onClick={() => handleFileDelete(file)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default MediaFileUplaoder;
