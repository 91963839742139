import React, { useEffect, useState } from "react";
import VerticalTab from "../../../../components/VerticalTab/VerticalTab";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import Courses from "./Courses";
import Securityy from "./Security";
import { Person, Receipt, Security } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";

import DraggableDialog from "../../../../hooks/Dialog";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import InviteTeamMember from "./InviteTeamMember";

import TableCell from "@mui/material/TableCell";
import { ArrowForwardIos, VerifiedUser } from "@mui/icons-material";
import { useParams } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router";
import { getProfileAvatarFromName } from "../../../../utils/helpers";
import Overview from "./Overview";
import CustomizedSnackbar from "../../../../hooks/Snackbar";

function InstructorDetails(props) {
  const orgId = localStorage.getItem("orgId");
  const { userData } = useParams();
  const navigate = useNavigate();
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };
  //================================= show toast message ==============================//
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const processShowToastMessage = (response) => {
    // console.log(response.data);
    if (response.data.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);

      // getRolesist();
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.message);
      // getRolesist();
    }
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  //================================= show toast message ==============================//

  //====================== get all roles ===================================================//
  const [roles, setRoles] = useState([]);

  // fetching roles
  const getRolesist = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/getRolesByOrganizationId`, data)
      .then((response) => {
        // processShowToastMessage(response);
        let rolesData = response.data.body?.filter(
          (item) => item?.role_type !== "owner"
        );
        setRoles(rolesData);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  //====================== get all roles ===================================================//

  //================================ get userInfo ============================================//
  let initialObject = {
    first_name: "",
    middle_name: "",
    last_name: "",
    status: "",
    email: "",
    phone_number: "",
    image: "",
    organization_name: "",
    designation: "",
    short_bio: "",
    roles: "",
    team_member_roles: "", //this field for fetching data with id
    invite_token: "",
  };
  const [userInfo, setUserInfo] = useState(initialObject);

  // fetching invited team members
  const getUserDetalis = () => {
    let checkEmail = emailValidation(userData);
    if (checkEmail) {
      getUserDetalisWithEmail();
    } else {
      getUserDetalisWithID();
      getUserCourseData();
    }
  };
  const getUserDetalisWithEmail = () => {
    let data = {
      email: userData,
    };
    axios
      .post(`${baseUrl}/orgPortal/getInvitedTeamMember`, data)
      .then((response) => {
        // processShowToastMessage(response);
        let userData = response.data.body;
        setUserInfo({
          ...userInfo,
          first_name: userData?.first_name || "",
          middle_name: userData?.middle_name || "",
          last_name: userData?.last_name || "",
          status: userData?.status || "",
          email: userData?.email || "",
          phone_number: userData?.phone_number || "",
          organization_name: userData?.organization_name || "",
          designation: userData?.designation || "",
          short_bio: userData?.short_bio || "",
          roles: userData?.roles || "",
          invite_token: userData?.invite_token || "",
          image: userData?.image,
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const getUserDetalisWithID = () => {
    let data = {
      orgId: orgId,
      userId: userData,
    };
    axios
      .post(`${baseUrl}/orgPortal/getMemberDetails`, data)
      .then((response) => {
        // processShowToastMessage(response);
        // setUserInfo(response.data.body?.user);
        let userData = response.data.body;

        setUserInfo({
          ...userInfo,
          first_name: userData?.user?.first_name || "",
          middle_name: userData?.user?.middle_name || "",
          last_name: userData?.user?.last_name || "",
          email: userData?.user?.email || "",
          phone_number: userData?.user?.phone_number || "",
          organization_name: userData?.organization_name || "",
          designation: userData?.designation || "",
          short_bio: userData?.bio || "",
          team_member_roles: userData?.team_member_roles || "",
          status: userData?.status || "",
          invite_token: userData?.invite_token || "",
          image: userData?.user?.image,
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };

  //================================ get userInfo ============================================//

  //================================ get user course details ==============================//
  const [userCourseData, setUserCourseData] = useState([]);
  const getUserCourseData = () => {
    let data = {
      orgId: orgId,
      userId: userData,
    };
    axios
      .post(`${baseUrl}/orgPortal/getTeamMemberCourses`, data)
      .then((response) => {
        // processShowToastMessage(response);
        setUserCourseData(response?.data?.body);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserDetalis();
    getRolesist();
  }, []);

  //================================ get user course details ==============================//

  //============================= delete team member =======================================//
  const handleConfirmCloseDialog = () => {
    let checkEmail = emailValidation(userData);
    if (checkEmail) {
      deleteUserWithEmail();
    } else {
      deleteUserWithID();
    }
  };

  const deleteUserWithID = () => {
    let data = {
      orgId: orgId,
      userId: userData,
    };
    axios
      .post(`${baseUrl}/orgPortal/deleteTeamMember`, data)
      .then((response) => {
        if (!response?.data.error) {
          navigate("/team-listing");
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  // we are deleting data with email because this user hasn't authorized yet in our website
  const deleteUserWithEmail = () => {
    let data = {
      email: userData,
    };
    axios
      .post(`${baseUrl}/orgPortal/removeInvitation`, data)
      .then((response) => {
        if (!response?.data.error) {
          navigate("/team-listing");
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  //============================= delete team member =======================================//

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (activeTab) => {
    // console.log(activeTab);
    setCurrentTab(activeTab);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabData = [
    {
      icon: <Person />,
      label: "Overview",
    },

    // {
    //   icon: <EventRoundedIcon />,
    //   label: "Calendar",
    // },

    {
      icon: <MenuBookRoundedIcon />,
      label: "Courses",
    },
    // {
    //   icon: <Receipt />,
    //   label: "Payments",
    // },
    {
      icon: <Security />,
      label: "Security",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: <Overview userInfo={userInfo} roles={roles} />,
    },
    // {
    //   id: 1,
    //   //panel: "Calender",
    // },
    {
      id: 1,
      panel: <Courses userCourseData={userCourseData} />,
    },

    // {
    //   id: 3,
    //   //panel: "Payments",
    // },

    {
      id: 2,
      panel: <Securityy userInfo={userInfo} />,
    },
  ];

  //==== Drawer Start=== //
  const [openInviteTeam, setOpenInviteTeam] = useState(false);
  const handleDrawerOpenEditTeam = () => {
    setOpenInviteTeam(true);
  };
  const handleDrawerCloseInviteTeam = () => {
    setOpenInviteTeam(false);
  };
  //==== Drawer End=== //

  //======= Delete dialog ===== //
  const [openDialogDelete, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  //======= Delete dialog ===== //

  //======= Delete dialog ===== //
  const [openDialogRestoreAccess, setOpenDialogRestoreAccess] = useState(false);
  const handleOpenRestoreAccess = () => {
    setOpenDialogRestoreAccess(true);
  };

  const handleOpenRevokeAccess = () => {
    setOpenDialogRestoreAccess(true);
  };
  const handleCloseRestoreAccess = () => {
    setOpenDialogRestoreAccess(false);
  };
  //======= Delete dialog ===== //

  //============================= status update =======================================//
  const handleRevokeData = () => {
    let url = `${baseUrl}/orgPortal/inactiveTeamMember`;
    statusUpdate(url);
  };
  const handleRestoreData = () => {
    let url = `${baseUrl}/orgPortal/activeTeamMember`;
    statusUpdate(url);
  };
  const statusUpdate = (url) => {
    let data = {
      orgId: orgId,
      userId: userData,
    };
    axios
      .post(url, data)
      .then((response) => {
        if (!response?.data.error) {
          getUserDetalis();
          handleCloseRestoreAccess();
          processShowToastMessage(response);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  //============================= status update =======================================//
  //============================= handleOpenResendInvite =======================================//
  const handleOpenResendInvite = () => {
    let data = {
      orgId: orgId,
      email: userData,
    };
    axios
      .post(`${baseUrl}/orgPortal/resendTeamMemberInvitaion`, data)
      .then((response) => {
        if (!response?.data.error) {
          getUserDetalis();
          processShowToastMessage(response);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getProfileAvatarFromNameData = (user) => {
    return `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(
      0
    )}`.toUpperCase();
  };
  return (
    <div className="">
      <div className="UserDetails">
        <li>
          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              <Avatar
                sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                src={userInfo?.image || ""}
              >
                {" "}
                {userInfo?.image
                  ? ""
                  : getProfileAvatarFromNameData(userInfo)}{" "}
              </Avatar>
            </div>

            <div className="UserDetailsDetail">
              <h1 style={{ display: "flex", alignItems: "center" }}>
                {userInfo?.first_name} {userInfo?.last_name}
                <span style={{ marginBottom: "8px", display: "inline" }}>
                  {!userInfo?.status && (
                    <span
                      style={{
                        marginLeft: "12px",
                        padding: "2px 6px",
                        background: "rgb(236, 224, 253)",
                        borderRadius: "4px",
                        fontSize: "12px",
                        color: "rgb(98, 0, 238)",
                      }}
                    >
                      Invite sent
                    </span>
                  )}
                  {userInfo?.status === "active" && (
                    <span
                      style={{
                        marginLeft: "12px",
                        padding: "2px 6px",
                        background: "rgb(225, 242, 232)",
                        borderRadius: "4px",
                        fontSize: "12px",
                        color: "rgb(11, 148, 68)",
                      }}
                    >
                      Active
                    </span>
                  )}
                  {userInfo?.status === "inactive" && (
                    <span
                      style={{
                        marginLeft: "12px",
                        padding: "2px 6px",
                        background: "rgb(247, 227, 227)",
                        borderRadius: "4px",
                        fontSize: "12px",
                        color: "rgb(186, 26, 26)",
                      }}
                    >
                      Inactive
                    </span>
                  )}
                </span>
              </h1>
              <p>{userInfo?.email || "N/A"}</p>
            </div>
          </div>

          <div className="SessionListingAction">
            <div align="left">
              <Button
                className="lstbtn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                Action <KeyboardArrowDownRoundedIcon />
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <span onClick={handleClose}>
                  <MenuItem onClick={handleDrawerOpenEditTeam}>
                    Edit Info
                  </MenuItem>
                </span> */}
                {!userInfo?.status && (
                  <span onClick={handleClose}>
                    <MenuItem onClick={handleOpenResendInvite}>
                      Resend Invite
                    </MenuItem>
                  </span>
                )}
                {userInfo?.status === "active" && (
                  <span onClick={handleClose}>
                    <MenuItem onClick={handleOpenRevokeAccess}>
                      Revoke Access
                    </MenuItem>
                  </span>
                )}
                {userInfo?.status === "inactive" && (
                  <span onClick={handleClose}>
                    <MenuItem onClick={handleOpenRestoreAccess}>
                      Restore Access
                    </MenuItem>
                  </span>
                )}

                <span onClick={handleClose}>
                  <MenuItem onClick={handleOpenDelete}>Delete Member</MenuItem>
                </span>
              </Menu>
            </div>
          </div>
        </li>
      </div>

      <VerticalTab
        tabData={tabData}
        tabBody={tabBody}
        activeTab={currentTab}
        onTabSwitch={handleTabSwitch}
      />

      <SideDrawer
        title="Invite Team Member"
        ButtonLabel="Send Invite"
        cancelButtonLabel="Cancel"
        DeleteButton={true}
        sideOpen="right"
        open={openInviteTeam}
        handleDrawerClose={handleDrawerCloseInviteTeam}
        FooterWithoutTab={true}
        body={
          <>
            <InviteTeamMember />
          </>
        }
      />

      <DraggableDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
        title="Delete team member?"
        body="Are you sure you want to delete this team member? Deleting will permanently remove from everywhere."
        ModalFooter={true}
      />

      <DraggableDialog
        openDialog={openDialogRestoreAccess}
        handleCloseDialog={handleCloseRestoreAccess}
        title={
          userInfo?.status === "active" ? "Revoke access?" : "Restore access?"
        }
        body={`Are you sure you want to ${
          userInfo?.status === "active" ? "revoke" : "restore"
        } access of this team member? User regain access of the portal.`}
        ModalFooter={true}
        handleConfirmCloseDialog={
          userInfo?.status === "active" ? handleRevokeData : handleRestoreData
        }
      />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </div>
  );
}

export default InstructorDetails;
