import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getUserData,
  getUserTimezone,
} from "../../../../../hooks/HelperFunctions";

import { Button } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import zelle from "../../../../../assets/imgs/paymentIcon/Zelle.svg";
import check from "../../../../../assets/imgs/paymentIcon/Check.svg";
import cash from "../../../../../assets/imgs/paymentIcon/Cash.svg";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import ZellePayment from "./ZellePayment/ZellePayment";
import CheckPayment from "./CheckPayment/CheckPayment";
import CashPayment from "./CashPayment/CashPayment";

import PaymentMethodZelle from "./Summary/PaymentMethodZelle";
import PaymentMethodCheck from "./Summary/PaymentMethodCheck";
import PaymentMethodCash from "./Summary/PaymentMethodCash";

import Table from "../../../../../components/Table/Table";
import SimpleBackdrop from "../../../../../components/Form/Backdrop/SimpleBackdrop";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import StatusChip from "../../../../../components/Form/StatusChip/StatusChip";

const Payments = (props) => {
  const { userBillingData, getStudentBillingData } = props;

  const [paymentData, setPaymentData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [attachmentInfo, setAttachmentInfo] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [initActiveItem, setInitActiveItem] = useState(null);
  const [activeItem, setActiveItem] = useState(0);

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const timezone = getUserTimezone();
  const userData = getUserData();
  const userId = userData.id;

  useEffect(() => {
    // auto set previusly selected checkbox
    console.log(paymentData.method);
    switch (paymentData.method) {
      case "Zelle":
        handleCheckboxChange(0);
        break;
      case "Check":
        handleCheckboxChange(1);
        break;
      case "Cash":
        handleCheckboxChange(2);
        break;
      default:
        handleCheckboxChange(0);
    }
  }, [initActiveItem]);

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const triggerSnackbar = (status = false, severity = "", message = "") => {
    setShowSnackbar(status);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const statusOptions = [
    {
      label: "Pending",
      value: "pending",
    },

    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Failed",
      value: "failed",
    },
    {
      label: "Canceled",
      value: "canceled",
    },
    {
      label: "Refund",
      value: "refund",
    },
  ];

  const btnHandleClick = (id, operation) => {
    console.log(id, operation);
    // retrive payment data
    setOpenBackdrop(true);
    axios
      .get(
        `${baseUrl}/studentPortal/getPaymentDetails?paymentId=${id}&timezone=${timezone}`
      )
      .then((result) => {
        console.log(result.data.body);
        setInitActiveItem(result.data);
        setPaymentData(result.data.body);
        setCourseData(result.data.courseData);
        setAttachmentInfo(result.data.attachmentInfo);
        setOpenBackdrop(false);
        handleDrawerOpen();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePaymentData = () => {
    console.log(paymentData);

    if (
      paymentData.activeItem == 0 &&
      (!paymentData.amountPaid || !paymentData.status)
    ) {
      triggerSnackbar(true, "error", "Please check the required fields.");
    } else if (
      paymentData.activeItem == 1 &&
      (!paymentData.amountPaid ||
        !paymentData.status ||
        !paymentData.checkNumber ||
        !paymentData.checkBankAccNumber)
    ) {
      triggerSnackbar(true, "error", "Please check the required fields.");
    } else if (
      paymentData.activeItem == 2 &&
      (!paymentData.amountPaid || !paymentData.status)
    ) {
      triggerSnackbar(true, "error", "Please check the required fields.");
    } else {
      // send data to api

      setOpenBackdrop(true);

      axios
        .post(`${baseUrl}/studentPortal/updatePaymentsData`, paymentData)
        .then((response) => {
          // Handle the response from the server
          console.log(response.data);
          setOpenBackdrop(false);
          handleDrawerClose();
          getStudentBillingData();
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
        });
    }
  };

  const handleCheckboxChange = (selectedMethodIndex) => {
    console.log(selectedMethodIndex);
    setActiveItem(selectedMethodIndex);
    setPaymentData({ ...paymentData, activeItem: selectedMethodIndex });
  };

  const tabData = [
    {
      title: <img src={zelle} alt="zelleIcon" width={30} height={30} />,
      description: "Zelle",
    },

    {
      title: <img src={check} alt="zelleIcon" width={30} height={30} />,
      description: "Check",
    },
    {
      title: <img src={cash} alt="zelleIcon" width={30} height={30} />,
      description: "Cash",
    },
  ];

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <ZellePayment
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          options={statusOptions}
        />
      ),
      SubDrawerBodyWithTab: (
        <div>
          <PaymentMethodZelle
            paymentData={paymentData}
            courseData={courseData}
            attachmentInfo={attachmentInfo}
          />
        </div>
      ),
    },

    {
      id: 1,
      panleBody: (
        <CheckPayment
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          options={statusOptions}
        />
      ),
      SubDrawerBodyWithTab: (
        <div>
          <PaymentMethodCheck
            paymentData={paymentData}
            courseData={courseData}
            attachmentInfo={attachmentInfo}
          />
        </div>
      ),
    },

    {
      id: 2,
      panleBody: (
        <CashPayment
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          options={statusOptions}
        />
      ),
      SubDrawerBodyWithTab: (
        <div>
          <PaymentMethodCash
            paymentData={paymentData}
            courseData={courseData}
            attachmentInfo={attachmentInfo}
          />
        </div>
      ),
    },

  ]

  

  const headCells = [
    {
      id: "invoice",
      numeric: true,
      disablePadding: false,
      label: "Invoice",
      width: "40%",
    },
    {
      id: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      width: "20%",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      width: "20%",
    },
    {
      id: "method",
      numeric: true,
      disablePadding: false,
      label: "Method",
      width: "20%",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
      width: "15%",
    },
  ];

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />

      {/* ------------------------------------------- */}
      <SideDrawer
        title={
          <div className="DrawerLavelWithStatusChip">
            ${paymentData.amount ? paymentData.amount / 100 : 0} USD{" "}
            <StatusChip
              Status={
                paymentData.status
                  ? paymentData.status.charAt(0).toUpperCase() +
                    paymentData.status.slice(1)
                  : ""
              }
            />
          </div>
        }
        ButtonLabel="Save"
        clickSaveButton={updatePaymentData}
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        sideOpen="right"
        open={open}
        handleDrawerClose={handleDrawerClose}
        CheckboxLabel="Add another after saving"
        FooterWithoutTab={true}
        Checkbox={false}
        LeftButton={false}
        DeleteButton={false}
        ButtonLeft="Upload New"
        selectedCheckBoxTab={activeItem}
        handleTabChange={handleCheckboxChange}
        panelTab={panelTab}
        tabData={tabData}
        arrowIcon={false}
        CheckBoxTabsStatus={true}
        TabsPannelStatus={true}
        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={true}
        SubDrawerTabSatus={true}
        SubDrawerWithOutTab={false}
        SubDrawerWithOutTabBody={<p>Sub Drawer WithOut Tab Body</p>}
        TitleUnderHeaderStatus={true}
        TitleUnderHeader="Edit Payment"
        SubTitle="Payment Method*"

        //SubDrawerWithOutTabBody={<p>Sub Drawer WithOut TabBody</p>}
      />

      <div className="TableSection">
        <Table
          innerTitle="Payment History"
          innerHeader={true}
          headCells={headCells}
          rows={userBillingData}
          btnHandleClick={btnHandleClick}
          AddButton={false}
          Checkbox={false}
          footer={true}
        />
      </div>
    </>
  );
};

export default Payments;
