import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import PortalSwitcher from "../../../components/PortalSwitcher/PortalSwitcher";
import { useLocation } from "react-router-dom";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";

import "../../../assets/css/SideBarMenu.css";
import axios from "axios";

function SideBarMenu(props) {
  const pathname = useLocation().pathname;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = localStorage.getItem("orgId");
  const applicationStatus = process.env.REACT_APP_PROJECT_ENV;
  const [
    instructorByOrgAndUserIdInfoStatus,
    setInstructorByOrgAndUserIdInfoStatus,
  ] = useState("");

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const getInstructorByOrgAndUserId = () => {
    let data = {
      orgId: orgId,
      userId: userData?.id,
    };
    axios
      .post(`${baseUrl}/orgPortal/getInstructorByOrgAndUserId`, data)
      .then((result) => {
        setInstructorByOrgAndUserIdInfoStatus(result?.data?.body?.status);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (orgId && userData?.id) {
      getInstructorByOrgAndUserId();
    }
  }, [orgId, userData?.id]);





    //ps-collapsed
    let fullHost = window.location.hostname;
    let splittedHost = fullHost.split(".");
    let subDomain = splittedHost[0];
    const takbirLogo = "https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png";
    let takbirUrl =
      subDomain == "stage-admin"
        ? "https://main-stage.mytakbir.com/"
        : "https://mytakbir.com/";

  return (
    <div className="SideBarContainer">
      <div className="SideBarInnerContainer">
        <div className="SideBarMenu">
          <Sidebar collapsed={props.collapsed}>

          <div className="PortalLogo">
              <div className="IconMenu">{props.IconMenu}</div>
              {takbirLogo?.length > 0 && (
                <a
                  className="Branding"
                  href={takbirUrl?.length > 0 ? takbirUrl : "#"}
                >
                  <img src={takbirLogo} />
                </a>
              )}
            </div>

            <PortalSwitcher />
            {instructorByOrgAndUserIdInfoStatus === "active" && (
              <Menu>
                <MenuItem
                  className={`${
                    pathname === "/courses-listing" ? "LinkActive" : "link"
                  }`}
                  href="/courses-listing"
                >
                  {" "}
                  <MenuBookRoundedIcon />{" "}
                  <span className="MenuPer">Courses</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/courses-listing">
                      Courses
                    </a>
                  </div>
                </MenuItem>

                <MenuItem
                  className={`${pathname === "/inbox" ? "LinkActive" : "link"}`}
                  href="/inbox"
                >
                  <ForumRoundedIcon /> <span className="MenuPer">Inbox</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/inbox">
                      Inbox
                    </a>
                  </div>
                </MenuItem>

                <span className="divider"></span>

                <MenuItem
                  className={`${pathname === "/users" ? "LinkActive" : "link"}`}
                  href="/users"
                >
                  <PersonRoundedIcon /> <span className="MenuPer">Users</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/users">
                      Users
                    </a>
                  </div>
                </MenuItem>
                <MenuItem
                  className={`${
                    pathname === "/tracks" ? "LinkActive" : "link"
                  }`}
                  href="/tracks"
                >
                  <InsightsRoundedIcon />{" "}
                  <span className="MenuPer">Tracks</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/tracks">
                      Tracks
                    </a>
                  </div>
                </MenuItem>
                <MenuItem
                  className={`${
                    pathname === "/media-library-table-view"
                      ? "LinkActive"
                      : "link"
                  }`}
                  href="/media-library-table-view"
                >
                  <CollectionsRoundedIcon />{" "}
                  <span className="MenuPer">Media</span>{" "}
                  <div className="MenuTooltip">
                    <a
                      className="MenuTooltipLink"
                      href="/media-library-table-view"
                    >
                      Media
                    </a>
                  </div>
                </MenuItem>

                {/* <SubMenu className="MenuButtonPr" label={<div className="Submenue"><ForumRoundedIcon /> <span className="MenuPer">Message</span></div>}>
                        <div className="submenutitle">MESSAGES</div>
                        <MenuItem><FiberManualRecordRoundedIcon className="DotIcon" /> Sent</MenuItem>
                        <MenuItem><FiberManualRecordRoundedIcon className="DotIcon" /> Templates </MenuItem>
                    </SubMenu>  */}

                <SubMenu
                  className="MenuButtonPr"
                  label={
                    <div className="Submenue">
                      <GroupsRoundedIcon />{" "}
                      <span className="MenuPer">Team</span>
                    </div>
                  }
                  defaultOpen={
                    pathname.includes("/team-listing") ||
                    pathname.includes("/roles-permissions")
                  }
                >
                  {/* <div className="submenutitle">Team</div> */}
                  <MenuItem
                    className={`${
                      pathname === "/team-listing" ? "LinkActive" : "link"
                    }`}
                    href="/team-listing"
                  >
                    <FiberManualRecordRoundedIcon className="DotIcon" /> Members
                  </MenuItem>
                  <MenuItem
                    className={`${
                      pathname === "/roles-permissions" ? "LinkActive" : "link"
                    }`}
                    href="/roles-permissions"
                  >
                    <FiberManualRecordRoundedIcon className="DotIcon" /> Roles{" "}
                  </MenuItem>
                </SubMenu>

                <span className="divider"></span>
                <MenuItem
                  className={`${
                    pathname === "/coupons" ? "LinkActive" : "link"
                  }`}
                  href="/coupons"
                >
                  <InsightsRoundedIcon />{" "}
                  <span className="MenuPer">Coupons</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/coupons">
                      Coupons
                    </a>
                  </div>
                </MenuItem>
              </Menu>
            )}
          </Sidebar>
        </div>
      </div>
    </div>
  );
}

export default SideBarMenu;
