import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import VerticalTab from "../../../../components/VerticalTab/VerticalTab";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import CoursesListing from "./courses/CoursesListing";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";

function TrackDetails(props) {
  let { trackId } = useParams();

  const [trackDetails, setTrackDetails] = useState([]);

  const stuId = localStorage.getItem("stuId");

  // fetching course details data

  const getTrackDetails = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(`${baseUrl}/orgPortal/getTrackDetails?trackId=${trackId}`)
      .then((result) => {
        console.log(result.data.body);
        setTrackDetails(result.data.body);
        // set course timezone into local storage
        localStorage.setItem("track_timezone", result.data.body?.timezone);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTrackDetails();
    // getCourseMaterial();
  }, []);

  const tabData = [
    {
      icon: <CampaignRoundedIcon />,
      label: "Courses",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: <CoursesListing trackId={trackId} />,
    },
  ];

  function capitalizeFirstChar(inputString) {
    // Check if the inputString is not empty
    if (inputString.length > 0) {
      // Concatenate the first character in uppercase with the rest of the string
      return inputString[0].toUpperCase() + inputString.slice(1);
    } else {
      // Handle the case where the inputString is empty
      return inputString;
    }
  }

  const breadcrumbsData = [
    {
      label: "My Learning",
      link: "/my-courses",
      icon: <MenuBookRoundedIcon fontSize="small" />,
    },

    {
      label: "Tracks",
      link: "/my-courses",
    },

    {
      label: "Track details",
    },
  ];

  return (
    <div>
      <Breadcrumbs breadcrumbsData={breadcrumbsData} />
      <VerticalTab
        title={trackDetails.name}
        TabTitleStatus={true}
        name={
          trackDetails?.trackType
            ? capitalizeFirstChar(trackDetails?.trackType)
            : ""
        }
        tabData={tabData}
        tabBody={tabBody}
        // onTabSwitch={handleTabSwitch}
        // tooltipText={courseDetails.tooltipText}
        // disabled={courseDetails.buttonDisabled}
      />
    </div>
  );
}

export default TrackDetails;
