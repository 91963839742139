import React, { useRef, useState } from "react";
import "../assets/css/inbox.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SendIcon from "@mui/icons-material/Send";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

function SendDropdown({ handleSendIntialMessage, handleSendDraftMessage }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="SendButtonContainer">
        <Button
          className="SendButton"
          variant="text"
          onClick={handleSendIntialMessage}
        >
          Send
        </Button>

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="DropdownButton"
        >
          <span className="ChatActionsIcon">
            <ArrowDropDownIcon />
          </span>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <span onClick={handleClose}>
            <MenuItem
              className="InboxAddNewListing"
              onClick={handleSendIntialMessage}
            >
              <SendIcon /> Send
            </MenuItem>
            <MenuItem
              className="InboxAddNewListing"
              onClick={handleSendDraftMessage}
            >
              <InsertDriveFileIcon /> Save Draft
            </MenuItem>
          </span>
        </Menu>
      </div>
    </div>
  );
}

export default SendDropdown;
