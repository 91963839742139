
import React from "react";
import CoursesCard from '../../../../components/CoursesCard/CoursesCard';
import CourseImg from '../../../../assets/imgs/course-image.png';

const FeaturedCourses = () => {


  const sessionData = [

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
    },

  ];




  return (
    <>
      <div>
        <CoursesCard
          sessionData={sessionData}
          col={3}
        />
      </div>
    </>
  );
};

export default FeaturedCourses;

