import React from "react";
import './EmptyCard.css';
import Buttons from '../Button/Button';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import AddNew from '../../pages/AddNew';



// import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
// import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
// import MarkunreadRoundedIcon from '@mui/icons-material/MarkunreadRounded';

// const menu = [
//   {
//     label:"Message",
//     icon:<ForumRoundedIcon />,
//     link:"/inbox-messages",
//   },
//   {
//     label:"Announcement",
//     icon:<CampaignRoundedIcon />,
//     link:"/inbox-announcement",
//   },
//   {
//     label:"Email",
//     icon:<MarkunreadRoundedIcon />,
//     link:"/inbox-email",
//   },
// ]


{/* <EmptyCard
  message="No conversation selected"
  icon={<PriorityHighIcon />}
  ButtonStatus={true}
  menu={menu}
  menuStatus={true}
/> */}


function EmptyCard(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className="InboxEmptyCard" style={{paddingTop:props.SpaceTop}}>
      <div className="InboxEmptyCardIcon">
        {props.icon}
      </div>
      <p className="InboxEmptyCardDescription">{props.message}</p>

      {props.ButtonStatus &&
      <AddNew
        ButtonBlue={true}
      />}

      {/* {props.ButtonStatus &&
      
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className="AddButton"
        >
          <AddRoundedIcon /> New
        </Button>} */}

      {/* {props.menuStatus &&
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {props.menu.map((item, i) => (
            <span onClick={handleClose}>
              <a className="InboxEmptyCardMenu" href={item.link} onClick={item.menueOnclick}>{item.icon}{item.label}</a>
            </span>
          ))}
        </Menu>
      } */}

    </div>
  );
}


export default EmptyCard;

