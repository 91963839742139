import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlrtMessage from "../../../components/Form/AlrtMessage/AlertMessage";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import BasicInfo from "./add/BasicInfo";
import Schedule from "./add/Schedule";

import CustomizedSnackbar from "../../../hooks/Snackbar";
import DraggableDialog from "../../../hooks/Dialog";
import SimpleBackdrop from "../../../components/Form/Backdrop/SimpleBackdrop";
import EditTrack from "./add/EditTrack";
import { Rtt } from "@mui/icons-material";

function TracksListing(props) {
  const navigate = useNavigate();
  const [activeDrawer, setActiveDrawer] = useState(0);
  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));
  const [asc, setAsc] = useState(true);
  const [items, setItems] = useState([]);

  const [trackData, setTrackData] = useState({
    trackType: "diploma",
    status: "draft",
    enableSeatNumber: false,
  });

  //=== Edit Term Drawer ==//
  const [openEditTrackDrawer, setOpenEditTrack] = useState(false);
  const handleDrawerOpenEditTrack = () => {
    setOpenEditTrack(true);
  };
  const handleDrawerCloseEditTrack = () => {
    setOpenEditTrack(false);
  };
  //=== Edit Term Drawer ==//

  const [timezones, setTimezones] = useState([]);

  const [showSeatField, setShowSeatField] = React.useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [hasExistingTrackName, setHasExistingTrackName] = useState(false);
  const [sortActiveColumn, setSortActiveColumn] = useState("");
  const [queryString, setQueryString] = useState("");

  const trackErrorsInitialObject = {
    trackTermName: "",
    trackImage: "",
    timezone: "",
    enrollmentStartDate: "",
    enrollmentEndDate: "",
    sessionStartDate: "",
    sessionEndDate: "",
    totalMonths: "",
    seatNumber: "",
    trackType: "",
    name: "",
  };
  const [trackErrors, setTrackErrors] = useState(trackErrorsInitialObject);
  const [errorStatus, setrrorStatus] = useState(false);

  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  useEffect(() => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(
        `${baseUrl}/orgPortal/checkDuplicateTrackName?name=${trackData.name}&trackId=${trackData.trackId}`
      )
      .then((result) => {
        console.log(result.data.duplicateTrackName);
        if (result.data.duplicateTrackName) {
          setHasExistingTrackName(true);
        } else {
          setHasExistingTrackName(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [trackData.name]);

  useEffect(() => {
    const fieldName = "trackNameError";
    if (hasExistingTrackName) {
      setTrackData({ ...trackData, [fieldName]: true });
    } else {
      setTrackData({ ...trackData, [fieldName]: false });
    }
  }, [hasExistingTrackName]);

  //======= Drawer ===== //
  const [drawerAction, setDrawerAction] = useState("");
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = (rowData) => {
    console.log("row data", rowData);
    if (rowData) {
      setTrackData(rowData);
      // setDrawerAction("Update");
      handleDrawerOpenEditTrack();
      return false;
    } else {
      setTrackData({ ...trackData, orgId: orgId });
      setDrawerAction("Add");
    }
    setOpen(true);
  };

  const gotoDetailsPage = (trackId) => {
    navigate(`/track-details/${trackId}`);
  };

  const handleDrawerClose = () => {
    setTrackData({ trackType: "diploma", status: "draft" });
    setOpen(false);
    setrrorStatus(false);
    setTrackErrors(trackErrorsInitialObject);
    setTrackData({
      trackTermName: "",
      trackImage: "",
      timezone: "",
      enrollmentStartDate: "",
      enrollmentEndDate: "",
      sessionStartDate: "",
      sessionEndDate: "",
      totalMonths: "",
      seatNumber: "",
      trackType: "",
      name: "",
      description: "",
    });
  };
  //======= Drawer ===== //

  const tabData = [
    {
      lebel: "Basic Info",
      DotIcon: true,
    },

    {
      lebel: "Schedule",
      DotIcon: true,
    },
  ];

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <BasicInfo
          trackData={trackData}
          setTrackData={setTrackData}
          timezones={timezones}
          activeDrawer={activeDrawer}
          trackErrors={trackErrors}
        />
      ),
    },

    {
      id: 1,
      panleBody: (
        <Schedule
          trackErrors={trackErrors}
          trackData={trackData}
          setTrackData={setTrackData}
          timezones={timezones}
          showSeatField={showSeatField}
          setShowSeatField={setShowSeatField}
        />
      ),
    },
  ];

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  //======= Delete dialog ===== //

  const handleOpenDialog = (id) => {
    console.log(id);
    // setActiveCourseId(id);
    // setDialogTitle("Delete this track?");
    // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
    setOpenDialog(true);
  };

  const onClick = () => {
    alert("onclick working fine!");
  };

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  useEffect(() => {
    //call api to get media library data
    console.log("baseUrl", baseUrl);

    getTracks();
  }, [asc]);

  const getTracks = (searchText) => {
    setOpenBackdrop(true);
    axios
      .get(
        `${baseUrl}/orgPortal/getTracks?orgId=${orgId}?orgId=1&sortingField=${sortActiveColumn}&sortingOrder=${
          asc ? "asc" : "desc"
        }&searchingText=${queryString ? queryString : ""}`,
        {}
      )
      .then((res) => {
        showDataInTheTable(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  const showDataInTheTable = (res) => {
    console.log("res", res.data);
    console.log("type:", typeof res.data);
    // set timezones
    setTimezones(res.data.timezones);
    //traverse through the data and set the values in the table
    let data = res.data.body;

    // const data = JSON.parse(res.data);
    // setTotalItems(data);
    let temp = [];

    data.map((item, index) => {
      console.log("item", item);
      temp.push({
        cell: item.cell,
        //======= Action Date Start===== //
        action: [
          {
            label: "View",
            onClick: () => gotoDetailsPage(item.row.trackId),
          },
          { label: "Edit", onClick: () => handleDrawerOpen(item.row) },
          // { label: "Delete", onClick: handleOpenDelete, },
        ],
        //======= Action Date End===== //
      });
    });
    setItems(temp);
    setOpenBackdrop(false);
  };

  //======================================= sorting part ===================================//
  const ascendingOrDescendingSortingByColumn = (column) => {
    setSortActiveColumn(column);
    setAsc(!asc);
  };

  //======================================= sorting part ===================================//
  // useEffect(() => {
  //   console.log(trackErrors, 345);
  // }, [trackErrors]);

  const checkTrackErros = () => {
    let today = new Date();
    const enrollmentStartDate = new Date(trackData.enrollmentStartDate);
    const enrollmentEndDate = new Date(trackData.enrollmentEndDate);
    const sessionStartDate = new Date(trackData.sessionStartDate);
    const sessionEndDate = new Date(trackData.sessionEndDate);
    let errors = 0;
    let errorObject = { ...trackErrors };
    if (trackData?.trackType?.length == 0) {
      errors++;
      errorObject.trackType = "Track type required.";
    } else {
      errorObject.trackType = "";
    }

    if (!trackData.name && activeDrawer === 0) {
      errors++;
      errorObject.name = "Track name is required.";
    } else {
      errorObject.name = "";
    }
    if (!trackData.trackTermName) {
      errors++;
      errorObject.trackTermName = "Term name is required.";
    } else {
      errorObject.trackTermName = "";
    }
    if (!trackData.trackImage && activeDrawer === 0) {
      errors++;
      errorObject.trackImage = "Track image is required.";
    } else {
      errorObject.trackImage = "";
    }

    if (!trackData.timezone && activeDrawer === 0) {
      errors++;
      errorObject.timezone = "Track timezone is required.";
    } else {
      errorObject.timezone = "";
    }

    if (!trackData.enrollmentStartDate) {
      errors++;
      errorObject.enrollmentStartDate = "Enrollment start date is required.";
    } else if (
      today > enrollmentStartDate &&
      !isSameDate(today, enrollmentStartDate)
    ) {
      errors++;
      errorObject.enrollmentStartDate =
        "Enrollment start date cannot be in the past";
    } else {
      errorObject.enrollmentStartDate = "";
    }

    if (!trackData.enrollmentEndDate) {
      errors++;
      errorObject.enrollmentEndDate = "Enrollment end date is required.";
    } else if (enrollmentStartDate > enrollmentEndDate) {
      errors++;
      showSnackbarMessage("error", "Enrollment date range is not valid.");
      errorObject.enrollmentEndDate = "Enrollment date range is not valid.";
    } else {
      errorObject.enrollmentEndDate = "";
    }

    if (!trackData.sessionStartDate) {
      errors++;
      errorObject.sessionStartDate = "Session start date is required.";
    } else if (isNaN(sessionStartDate.getTime())) {
      errors++;
      errorObject.sessionStartDate = "Invalid can't be accepted.";
    } else if (
      today > sessionStartDate &&
      !isSameDate(today, sessionStartDate)
    ) {
      errors++;
      errorObject.sessionStartDate =
        "Session start date cannot be in the past.";
    } else {
      errorObject.sessionStartDate = "";
    }

    if (!trackData.sessionEndDate) {
      errors++;
      errorObject.sessionEndDate = "Session end date is required.";
    } else if (isNaN(sessionEndDate.getTime())) {
      errors++;
      errorObject.sessionEndDate = "Invalid can't be accepted";
    } else if (sessionStartDate > sessionEndDate) {
      errors++;
      errorObject.sessionEndDate = "Session date range is not valid.";
    } else {
      errorObject.sessionEndDate = "";
    }

    if (!trackData.totalMonths) {
      errors++;
      errorObject.totalMonths = "Total months field is required.";
    } else if (trackData.totalMonths < 1) {
      errors++;
      errorObject.totalMonths = "Total months can't start from zero";
    } else {
      errorObject.totalMonths = "";
    }
    if (showSeatField && !trackData.seatNumber) {
      errors++;

      errorObject.seatNumber = "Seat Number field is required.";
    } else if (showSeatField && trackData.seatNumber < 1) {
      errors++;

      errorObject.seatNumber = "Seat Number can't start from zero";
    } else {
      errorObject.seatNumber = "";
    }
    setTrackErrors({ ...trackErrors, ...errorObject });
    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (errorStatus) {
      checkTrackErros();
    }
  }, [errorStatus, trackData]);

  const updateTrackData = () => {
    setrrorStatus(true);
    const isValid = checkTrackErros();
    if (!isValid) {
      showSnackbarMessage("error", "Required felids are left");
      return false;
    } else {
      // show loader
      setOpenBackdrop(true);
      // axios
      //   .post(`${baseUrl}/orgPortal/updateTrack`, trackData)
      //   .then((response) => showDataPostMessage(response));

      axios
        .post(`${baseUrl}/orgPortal/addTrack`, trackData)
        .then((response) => showDataPostMessage(response));
    }

    setrrorStatus(false);
  };

  const showDataPostMessage = (response) => {
    console.log(response.data);
    setTrackErrors(trackErrorsInitialObject);
    setTrackData({
      trackTermName: "",
      trackImage: "",
      timezone: "",
      enrollmentStartDate: "",
      enrollmentEndDate: "",
      sessionStartDate: "",
      sessionEndDate: "",
      totalMonths: "",
      seatNumber: "",
      trackType: "",
      name: "",
      description: "",
    });
    // hide loader
    setOpenBackdrop(false);

    const error =
      typeof response.data.error == "undefined" ? true : response.data.error;

    if (error) {
      showSnackbarMessage("error", response.data.message);
    } else {
      if (drawerAction == "Add") {
        showSnackbarMessage("success", "Track added successfully");
      } else {
        showSnackbarMessage("success", "Track updated successfully");
      }

      // redirect to detail page
      setTimeout(function () {
        navigate(`/track-details/${response.data.trackId}`);
      }, 3000);
    }
  };

  //============================= show the loader while changing tab ========================//
  useEffect(() => {
    setOpenBackdrop(true);
  }, [activeDrawer]);
  useEffect(() => {
    if (openBackdrop) {
      const timer = setTimeout(() => {
        setOpenBackdrop(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [openBackdrop]);
  //============================= show the loader while changing tab ========================//

  const headCells = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Track Name",
      width: "40%",
    },
    {
      id: "Type",
      numeric: true,
      disablePadding: false,
      label: "Type",
      width: "10%",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByColumn("trackType"),
    },
    {
      id: "Track",
      numeric: true,
      disablePadding: false,
      label: "ID",
      width: "15%",
    },
    {
      id: "# of courses",
      numeric: true,
      disablePadding: false,
      label: "# Of Courses",
      width: "15%",
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "10%",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByColumn("status"),
    },
    { id: "", numeric: true, disablePadding: false, label: "", width: "5%" },
    { id: "", numeric: true, disablePadding: false, label: "", width: "5%" },
  ];

  const clickEditTrackButton = () => {
    const enrollmentStartDate = new Date(trackData.enrollmentStartDate);
    const enrollmentEndDate = new Date(trackData.enrollmentEndDate);
    if (trackData?.trackType?.length == 0) {
      showSnackbarMessage("error", "Track type required.");
    } else if (!trackData.name) {
      showSnackbarMessage("error", "Track name is required.");
    } else if (!trackData.trackImage) {
      showSnackbarMessage("error", "Track image is required.");
    } else if (!trackData.timezone) {
      showSnackbarMessage("error", "Track timezone is required.");
    } else {
      // show loader
      setOpenBackdrop(true);
      // axios
      //   .post(`${baseUrl}/orgPortal/updateTrack`, trackData)
      //   .then((response) => showDataPostMessage(response));

      axios
        .post(`${baseUrl}/orgPortal/editTrack`, trackData)
        .then((response) => {
          showDataPostMessage(response);
          getTracks();
          handleDrawerCloseEditTrack();
        });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getTracks(queryString);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [queryString]);

  const handleSearchText = (event) => {
    const fieldData = event.target.value;
    // console.log(fieldData);
    setQueryString(fieldData);
  };

  const handleSearch = () => {
    getTracks(queryString);
  };

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />

      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />

      <div className="Table">
        <AlrtMessage message="Course added" background="#0B9444" />
        <div className="TableSection">
          <Table
            title="Tracks"
            addLabel="New Track"
            headCells={headCells}
            rows={items}
            AddButton={true}
            tableHeader={true}
            Checkbox={false}
            footer={true}
            onClick={handleDrawerOpen}
            handleOpenDialog={handleOpenDialog}
            innerHeader={true}
            showSearchBox={true}
            showSearchCount={true}
            searchBoxPlaceholder="Search courses"
            searchBoxText={queryString}
            handleSearchText={handleSearchText}
            handleSearch={handleSearch}
          />
        </div>

        <SideDrawer
          title={drawerAction == "Add" ? "Add New Track" : "Edit Track"}
          ButtonLabel="Save"
          clickSaveButton={() => updateTrackData()}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={open}
          FooterWithoutTab={true}
          handleDrawerClose={handleDrawerClose}
          TabsStatus={true}
          TabsPannelStatus={true}
          padding="16px 20px 140px"
          tabData={tabData}
          panelTab={panelTab}
          setActiveDrawer={setActiveDrawer}
        />

        <DraggableDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDeleteDialog}
          title="Delete this track?"
          body={
            <div>
              Are you sure you want to delete this? <br></br>Once you delete it,
              you can't get it back.
            </div>
          }
          ModalFooter={true}
          actionButton="Delete"
        />

        <SideDrawer
          title="Edit Track"
          ButtonLabel="Save"
          clickSaveButton={clickEditTrackButton}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={openEditTrackDrawer}
          handleDrawerClose={handleDrawerCloseEditTrack}
          //onCheckboxClicked={handleCheckboxClicked}
          FooterWithoutTab={true}
          body={
            <EditTrack
              trackData={trackData}
              setTrackData={setTrackData}
              timezones={timezones}
              showSeatField={showSeatField}
              setShowSeatField={setShowSeatField}
            />
          }
        />
      </div>
    </>
  );
}

export default TracksListing;
