import React, { useEffect, useState } from "react";

import { timeIn15MinsInterval } from "../../../../../hooks/HelperFunctions";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import Grid from "@mui/material/Grid";
import SwitchButton from "../../../../../components/Form/Switch/Switch";
import SelectField from "../../../../../components/Form/SelectField/SelectField";
import ComboBox from "../../../../../components/Form/ComboBox/ComboBox";
import Datefield from "../../../../../components/Form/Datefield/Datefield";
import DateTimePickerField from "../../../../../components/Form/DateTimePicker/DateTimePicker";
import TextArea from "../../../../../components/Form/TextArea/TextArea";
import TextField from "../../../../../components/Form/TextField/TextField";
import Modals from "../../../../../components/Form/Modals/Modals";
import Button from "@mui/material/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import RedioBox from "../../../../../components/Form/RedioBox/RedioBox";
import TimePickerField from "../../../../../components/Form/TimePicker/TimePicker";
import FileUplaoder from "../../../../../components/Form/FileUplaoder/FileUplaoder";
import Modules from "../sub-components/Modules";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

//== Uload Media == //
//import sidedrawer
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../../components/Form/Button/Button";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import Avatar from "@mui/material/Avatar";
//import deletehoverrounded from mui component
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

function Curriculum(props) {
  const { courseData, setCourseData, showSnackbarMessage } = props;
  // const { courseModules } = props.courseData;
  const { gradeLevels, ageGroups } = props.courseRelationalData;

  const [addAnother, setAddAnother] = useState(false);
  const [additionalInstructors, setAdditionalInstructors] = useState([]);

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);

  // splitting the string into its components (year, month, day, hour, minute, and AM/PM indicator)
  // function parseDateString(dateString) {
  //   const [datePart, timePart] = dateString.split(' ');
  //   const [year, month, day] = datePart.split('-');
  //   const [time, period] = timePart.split(' ');
  //   const [hour, minute] = time.split(':');
  //   let adjustedHour = parseInt(hour);

  //   if (period === 'PM') {
  //     adjustedHour += 12;
  //   }

  //   return new Date(Date.UTC(year, month - 1, day, adjustedHour, parseInt(minute)));
  // }

  function parseDateString(dateString) {
    const dateParts = dateString.split(/[- :]/);
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-based (0-11)
    const day = parseInt(dateParts[2]);
    const hours = parseInt(dateParts[3]);
    const minutes = parseInt(dateParts[4]);

    const period = dateParts[5].toUpperCase();
    let adjustedHours = hours;

    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0;
    }

    return new Date(year, month, day, adjustedHours, minutes);
  }

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  //deleteCourseImageSelection
  const deleteCourseImageSelection = () => {
    // setCourseData({ ...courseData, ["courseImage"]: "" });
    //set tempResourse resourseFile and resourseLink to empty
    setTempResourse({ ...tempResourse, ["resourseLink"]: "" });
  };

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };
  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      // console.log(mediaItem);
      //set the media item
      // setImageSelection(mediaItem.file_url);
      // handleChangeWithData("resourseFile", mediaItem.name);
      handleChangeWithData("resourseFile", mediaItem.file_url);

      // console.log( courseData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
    console.log(mediaItem);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };

  const setImageSelection = (fileUrl) => {
    // console.log(fileUrl);
    let temp = courseData;
    temp.courseImage = fileUrl;
    setCourseData(temp);
    // console.log(courseData);
  };

  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = "";

    if (fieldName == "sessionLinkSetting") {
      fieldData = event.target.checked;
    } else {
      fieldData = event.target.value;
    }
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    // setCourseData({ ...courseData, [fieldName]: fieldData });
    // updateResoursesData("resourseFile", fieldData);
    setTempResourse({ ...tempResourse, [fieldName]: fieldData });
  };

  /** add new module related code */
  const [tempModuleTitle, setTempModuleTitle] = useState("");
  const [newModule, setNewModule] = useState(false);

  const handleOpenModuleAdd = () => {
    console.log("handleOpenModuleAdd");
    setNewModule(true);
  };

  const handleCloseModuleAdd = () => {
    console.log("handleCloseModuleEdit");
    setNewModule(false);
  };

  const handleConfirmModuleAdd = () => {
    console.log("handleConfirmModuleEdit");

    if (tempModuleTitle == "") {
      showSnackbarMessage("error", "Module title field is required!");
    } else {
      courseData.courseModules.push({
        id: "0",
        title: tempModuleTitle,
        courseSessions: [],
        courseResourses: [],
      });
      setNewModule(false);
      setTempModuleTitle("");
      showSnackbarMessage("success", "New module added");
    }
  };

  const addModuleTitle = () => (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    setTempModuleTitle(fieldData);
  };

  /** End: add new module related code */

  /** Module Title update related code */
  const [currentModule, setCurrentModule] = useState({});
  const [editModule, setEditModule] = useState(false);

  const handleCloseModuleEdit = () => {
    console.log("handleCloseModuleEdit");
    setEditModule(false);
  };

  const handleConfirmModuleEdit = (index) => {
    console.log("handleConfirmModuleEdit");
    if (currentModule.title == "") {
      showSnackbarMessage("error", "Module title field is required!");
    } else {
      // update main course data array
      const moduleList = [...courseData.courseModules];
      console.log(moduleList);
      const currentModuleItem = moduleList.findIndex(
        (element, objIndex, array) => {
          return objIndex == index;
        }
      );

      moduleList[currentModuleItem].title = currentModule.title;

      setEditModule(false);
      showSnackbarMessage("success", "Module Title Updated");
    }
  };

  const updateModuleTitle = (index) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    // update current module info
    const list = { ...currentModule };
    list.title = fieldData;
    setCurrentModule(list);
  };

  /** End: Module Title update related code */

  /** Module delete related code */

  const deleteModule = (index) => {
    // const moduleList = [...courseData.courseModules];
    // moduleList.splice(index, 1);
    // setInputList(list);
    courseData.courseModules.splice(index, 1);
    showSnackbarMessage("success", "Module and it's related data deleted.");
  };

  /** End: module delete related code */

  /** Sessions related code */
  const [tempSession, setTempSession] = useState({});
  const [tempModuleIndex, setTempModuleIndex] = useState(-1);
  const [tempSesIndex, setTempSesIndex] = useState(0);
  const [sessionModalTitle, setSessionModalTitle] = useState("");
  const [sessionModalConButton, setSessionModalConButton] = useState("");
  const [openSessionModal, setOpenSessionModal] = useState(false);

  const handleOpenSessionModal = (moduleIndex, sesIndex = "") => {
    console.log("handleOpenSessionModal");
    console.log(sesIndex);
    if (sesIndex >= 0) {
      setTempSesIndex(sesIndex);
      setSessionModalTitle("Edit session");
      setSessionModalConButton("Save");
      // console.log(courseData.courseModules[moduleIndex].courseSessions[sesIndex]);
      setTempSession(
        courseData.courseModules[moduleIndex].courseSessions[sesIndex]
      );
    } else {
      setTempSesIndex(-1);
      setSessionModalTitle("Add session");
      setSessionModalConButton("Add Now");
      setTempSession({
        sesId: 0,
        modId: courseData.courseModules[moduleIndex].modId,
        title: "",
        startDate: null,
        startTime: null,
        endTime: null,
        description: "",
        zoomLink: "",
        details: "",
        enableSession: "",
      });
    }

    setTempModuleIndex(moduleIndex);
    setOpenSessionModal(true);
  };

  // add another after save new one
  const addAnotherAfterSavingHandler = (event) => {
    console.log(event.target.checked);
    setAddAnother(event.target.checked);
  };

  const handleCloseSessionModal = () => {
    console.log("handleCloseSessionAdd");
    setOpenSessionModal(false);
  };

  const handleConfirmCloseSessionModal = () => {
    let isNew = false;
    console.log("handleConfirmSessionAdd");
    console.log(tempSession);
    if (!tempSession.title) {
      showSnackbarMessage("error", "Session title field is required");
    } else if (!tempSession.startDate) {
      showSnackbarMessage("error", "Start date field is required");
    } else if (!tempSession.selectedStartTime) {
      showSnackbarMessage("error", "Start date and time field is required");
    } else if (!tempSession.selectedEndTime) {
      showSnackbarMessage("error", "End date and time field is required");
    } else if (
      courseData.sessionLinkSetting == false &&
      !tempSession.zoomLink
    ) {
      showSnackbarMessage("error", "Zoom link field is required");
    } else {
      const list = { ...courseData };

      // copy startTime into startDate
      // tempSession.startDate = tempSession.startTime;
      let startDateTmp =
        typeof tempSession.startDate == "string"
          ? tempSession.startDate
          : tempSession.startDate.toISOString();
      console.log(startDateTmp);
      const truncatedDate = startDateTmp.substring(0, 10);
      console.log(truncatedDate + " " + tempSession.selectedStartTime);
      let startTime = parseDateString(
        truncatedDate + " " + tempSession.selectedStartTime
      );
      let endTime = parseDateString(
        truncatedDate + " " + tempSession.selectedEndTime
      );

      startTime = zonedTimeToUtc(startTime, courseData.timezone);
      endTime = zonedTimeToUtc(endTime, courseData.timezone);

      tempSession.startTime = startTime;
      tempSession.endTime = endTime;

      if (tempSesIndex >= 0) {
        list.courseModules[tempModuleIndex].courseSessions[tempSesIndex] =
          tempSession;
        showSnackbarMessage("success", "Session updated.");
      } else {
        isNew = true;
        list.courseModules[tempModuleIndex].courseSessions.push(tempSession);
        showSnackbarMessage("success", "New session added.");
      }

      setCourseData(list);
      setOpenSessionModal(false);
      setTempSession({});

      if (isNew && addAnother) {
        handleOpenSessionModal(tempModuleIndex, -1);
      }
    }
  };

  const handleDeleteSession = (moduleIndex, sesIndex) => {
    console.log("handleDeleteSession");
    const list = { ...courseData };
    list.courseModules[moduleIndex].courseSessions.splice(sesIndex, 1);
    setCourseData(list);
    showSnackbarMessage("success", "Session data deleted.");
  };

  const updateSessionData =
    (fieldName, fieldData = "") =>
    (event) => {
      fieldData = fieldData == "" ? event.target.value : fieldData;
      console.log(fieldName, fieldData);
      setTempSession({ ...tempSession, [fieldName]: fieldData });
    };

  const updateSessionDate = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setTempSession({ ...tempSession, [fieldName]: fieldData });
  };

  const getEnableSessionDataMap = () => {
    return [
      { value: 1, label: "At the time of session" },
      { value: 5, label: "5 minutes before" },
      { value: 10, label: "10 minutes before" },
      { value: 15, label: "15 minutes before" },
    ];
  };

  /** End: Sessions related code */

  /** start: Resourse related code */

  const [tempResourse, setTempResourse] = useState({});
  const [tempResIndex, setTempResIndex] = useState(0);
  const [resourseModalTitle, setResourseModalTitle] = useState("");
  const [resourseModalConButton, setResourseModalConButton] = useState("");
  const [openResourseModal, setOpenResourseModal] = useState(false);

  const tempResourseIntialError = {
    title: "",
    resourseLink: "",
    resourseFileName: "",
    resourseFile: "",
  };

  const [tempResourseError, setTempResourseError] = useState(
    tempResourseIntialError
  );

  const [tempResourseErrorStatus, setTempResourseErrorStatus] = useState(false);
  const checktempResourseError = () => {
    let error = 0;
    let errorObject = { ...tempResourseError };
    if (tempResourse.type === 0 && tempResourse.title === "") {
      error++;
      errorObject.title = "Required";
    } else {
      errorObject.title = "";
    }

    if (tempResourse.type === 0 && tempResourse.resourseLink === "") {
      error++;
      errorObject.resourseLink = "Required";
    } else {
      errorObject.resourseLink = "";
    }

    if (tempResourse.type === 1 && tempResourse.resourseFileName === "") {
      error++;
      errorObject.resourseFileName = "Required";
    } else {
      errorObject.resourseFileName = "";
    }

    if (tempResourse.type === 1 && tempResourse.resourseFile === "") {
      error++;
      errorObject.resourseFile = "Required";
    } else {
      errorObject.resourseFile = "";
    }

    setTempResourseError({ ...tempResourseError, ...errorObject });
    if (error > 0) {
      showSnackbarMessage(
        "error",
        "One or more fields are required or not valid!"
      );
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (tempResourseErrorStatus) {
      checktempResourseError();
    }
  }, [tempResourseErrorStatus, tempResourse]);

  const handleOpenResourseModal = (moduleIndex, resIndex = "") => {
    console.log("handleOpenResourseModal");
    // console.log(resIndex);
    if (resIndex >= 0) {
      setTempResIndex(resIndex);
      setResourseModalTitle("Update resourse data");
      setResourseModalConButton("Update Now");
      // console.log(courseData.courseModules[moduleIndex].courseSessions[sesIndex]);
      setTempResourse(
        courseData.courseModules[moduleIndex].courseResourses[resIndex]
      );
    } else {
      setTempResIndex(-1);
      setResourseModalTitle("Add resource");
      setResourseModalConButton("Add Now");
      setTempResourse({
        resId: 0,
        modId: courseData.courseModules[moduleIndex].modId,
        sesId: 0,
        type: 0,
        title: "",
        resourseLink: "",
        resourseFileName: "",
        resourseFile: "",
      });
      // console.log("Hi how are you?")
    }
    // console.log(tempResourse);
    setTempModuleIndex(moduleIndex);
    setOpenResourseModal(true);
  };

  const handleCloseResourseModal = () => {
    console.log("handleCloseResourseModal");
    setOpenResourseModal(false);
    setTempResourseErrorStatus(false);
    setTempResourseError(tempResourseIntialError);
  };

  const handleConfirmCloseResourseModal = () => {
    setTempResourseErrorStatus(true);
    const isValid = checktempResourseError();
    if (!isValid) {
      return false;
    } else {
      const list = { ...courseData };
      if (tempResIndex >= 0) {
        list.courseModules[tempModuleIndex].courseResourses[tempResIndex] =
          tempResourse;
        showSnackbarMessage("success", "Resourse updated.");
      } else {
        list.courseModules[tempModuleIndex].courseResourses.push(tempResourse);
        showSnackbarMessage("success", "New Resourse added.");
      }
      setTempResourseErrorStatus(false);
      setTempResourseError(tempResourseIntialError);
      setCourseData(list);
      setOpenResourseModal(false);
      setTempResourse({});
    }
  };

  const handleDeleteResourse = (moduleIndex, resIndex) => {
    console.log("handleDeleteSession");
    const list = { ...courseData };
    list.courseModules[moduleIndex].courseResourses.splice(resIndex, 1);
    setCourseData(list);
    showSnackbarMessage("success", "Resourses data deleted.");
  };

  const updateResoursesData =
    (fieldName, fieldData = "") =>
    (event) => {
      fieldData = fieldData == "" ? event.target.value : fieldData;
      console.log(fieldName, fieldData);
      setTempResourse({ ...tempResourse, [fieldName]: fieldData });
    };

  const updateResourseWithParemData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setTempResourse({ ...tempResourse, [fieldName]: fieldData });
  };

  const getAllSessions = () => {
    let dataArray = [];
    dataArray.push({ value: 0, label: "None" });
    if (
      tempModuleIndex >= 0 &&
      typeof courseData.courseModules[tempModuleIndex] !== "undefined"
    ) {
      const sessions = courseData.courseModules[tempModuleIndex].courseSessions;
      sessions.map((element) => {
        if (element.sesId > 0) {
          dataArray.push({ value: element.sesId, label: element.title });
        }
      });
      // console.log(dataArray);
      return dataArray;
    }

    return dataArray;
  };

  /** End: Resourse related code */

  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState("female");

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };
  //==== radio button End//

  // ==== Date Picker === //
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };

  // ==== Date Picker end === //

  // ==== Level Value === //
  const [age, setAge] = React.useState("");
  const handleChangelevel = (event) => {
    setAge(event.target.value);
  };

  const selectValue = [
    {
      label: "Beginner level",
      value: "Beginner level",
    },
    {
      label: "Intermediate level",
      value: "Intermediate level",
    },
    {
      label: "Expert level",
      value: "Expert level",
    },

    {
      label: "All levels",
      value: "All levels",
    },
  ];
  // ==== Level Value end === //

  // Modal Module //

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    console.log("close");
    setOpen(false);
  };

  const handleConfirm = () => {
    console.log("close");
    setOpen(false);
  };

  // Modal Module  End//

  const [openResource, setOpenResource] = useState(false);

  const handleOpenResource = () => {
    setOpenResource(true);
  };

  const handleCloseModalResource = () => {
    console.log("close");
    setOpenResource(false);
  };

  const handleConfirmResource = () => {
    console.log("close");
    setOpenResource(false);
  };

  // Modal Resource End //

  // Text Field //
  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });

  // const handleChanges = (prop) => (event) => {
  //   setValues({
  //     ...values,
  //     [prop]: event.target.value,
  //   });
  // };
  // Text Field //

  // Redio Box //

  const tabsData = [
    {
      title: "External Resource",
      description: "Link of an external site",
    },

    {
      title: "Downloadable File",
      description: "Upload a resource",
    },
  ];

  // Redio Box //

  const [valuesUrl, setValuesUrl] = React.useState({
    name: "https://example.com",
    number: "",
  });

  const handleChangesUrl = (prop) => (event) => {
    setValuesUrl({
      ...valuesUrl,
      [prop]: event.target.value,
    });
  };

  // console.log(tempSession, tempResourse);

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeModule = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Session Link Setting</div>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <SwitchButton
                  title="Same link for all lectures"
                  subtitle="Students can join one link for all lectures"
                  BorderLine={false}
                  handleChange={handleChanges("sessionLinkSetting")}
                  checked={courseData.sessionLinkSetting}
                />
              </Grid>
              {courseData.sessionLinkSetting && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Zoom Link"
                      required={true}
                      value={courseData.zoomLink}
                      handleChange={handleChanges("zoomLink")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextArea
                      label="Details"
                      placeholder="Enter details here."
                      validation={false}
                      helperText="Add more Zoom meeting details and instructions for students to join"
                      count={300}
                      value={courseData.zoomDescription}
                      handleChange={handleChanges("zoomDescription")}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection">
            {courseData.courseModules.map((module, index) => {
              return (
                <Modules
                  index={index}
                  id={module.id}
                  title={module.title}
                  courseSessions={module.courseSessions}
                  courseResourses={module.courseResourses}
                  setCurrentModule={setCurrentModule}
                  setEditModule={setEditModule}
                  deleteModule={deleteModule}
                  handleOpenSessionModal={handleOpenSessionModal}
                  handleDeleteSession={handleDeleteSession}
                  handleOpenResourseModal={handleOpenResourseModal}
                  handleDeleteResourse={handleDeleteResourse}
                  panel={`module-${index}`}
                  handleChange={handleChangeModule(`module-${index}`)}
                  expanded={expanded}
                />
              );
            })}
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Button
            className="SessionButton"
            style={{ width: "100%" }}
            onClick={() => handleOpenModuleAdd(true)}
          >
            <AddRoundedIcon />
            Module
          </Button>
        </Grid>
      </Grid>

      {/* Add Module */}
      <Modals
        ModalTitle="Add Module"
        handleClose={handleCloseModuleAdd}
        handleConfirm={() => handleConfirmModuleAdd()}
        open={newModule}
        ButtonClose="Cancel"
        ButtonConfirm="Add Now"
        width="552px"
        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}
        ModalBody={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Module Title"
                  required={true}
                  count={80}
                  value={tempModuleTitle}
                  handleChange={addModuleTitle()}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
      {/* Add Module */}

      {/* Edit Module */}
      <Modals
        ModalTitle="Edit Module"
        handleClose={handleCloseModuleEdit}
        handleConfirm={() => handleConfirmModuleEdit(currentModule.index)}
        open={editModule}
        ButtonClose="Cancel"
        ButtonConfirm="Save"
        width="552px"
        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}
        ModalBody={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Module Title"
                  required={true}
                  count={80}
                  value={currentModule.title}
                  handleChange={updateModuleTitle(currentModule.index)}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
      {/* Edit Module */}

      {/* Session Modal */}
      <SideDrawer
        title={sessionModalTitle}
        ButtonLabel={sessionModalConButton}
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={openSessionModal}
        handleDrawerClose={() => handleCloseSessionModal()}
        clickSaveButton={() => handleConfirmCloseSessionModal()}
        CheckboxLabel="Add another after saving"
        onCheckboxClicked={(event) => addAnotherAfterSavingHandler(event)}
        Checkbox={sessionModalTitle == "Add session" ? true : false}
        FooterWithoutTab={true}
        body={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Session Title"
                  required={true}
                  count={60}
                  value={tempSession.title}
                  handleChange={updateSessionData("title")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Datefield
                  label="Start Date"
                  validation={true}
                  value={tempSession.startDate}
                  timezone={courseData.timezone}
                  // timezone="America/New_York"
                  handleChange={(selectedDate) =>
                    updateSessionDate("startDate", selectedDate)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <ComboBox
                  label="Start Time"
                  placeholder="Search Start Time"
                  validation={true}
                  options={timeIn15MinsInterval()}
                  selectedOption={tempSession.selectedStartTime}
                  handleChange={(fieldData) =>
                    updateSessionDate("selectedStartTime", fieldData)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <ComboBox
                  label="End Time"
                  placeholder="Search End Time"
                  validation={true}
                  options={timeIn15MinsInterval()}
                  selectedOption={tempSession.selectedEndTime}
                  handleChange={(fieldData) =>
                    updateSessionDate("selectedEndTime", fieldData)
                  }
                />
              </Grid>

              {/* <Grid item xs={12} sm={6} md={6}>
                <DateTimePickerField 
                  label="Start Date & Time"
                  validation={true}
                  value={tempSession.startTime}
                  handleChange={(selectedTime) => updateSessionDate("startTime", selectedTime)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <DateTimePickerField
                  label="End Date & Time"
                  validation={true}
                  value={tempSession.endTime}
                  handleChange={(selectedTime) => updateSessionDate("endTime", selectedTime)}

                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={12}>
                <div className="FixedSizeTextArea">
                  <TextArea
                    label="Description"
                    placeholder=""
                    validation={false}
                    helperText="Add details and instruction for students to join"
                    count="200"
                    value={tempSession.description}
                    handleChange={updateSessionData("description")}
                  />
                </div>
              </Grid>
              {!courseData.sessionLinkSetting && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Zoom Link"
                      required={true}
                      //count={80}
                      value={tempSession.zoomLink}
                      handleChange={updateSessionData("zoomLink")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextArea
                      label="Details"
                      count="300"
                      validation={false}
                      helperText="Add details and instruction for students to join"
                      value={tempSession.details}
                      handleChange={updateSessionData("details")}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  label="Allow Learners to Join"
                  validation={true}
                  options={getEnableSessionDataMap()}
                  selectedOption={
                    tempSession.enableSession ? tempSession.enableSession : "1"
                  }
                  handleChange={updateSessionData("enableSession")}
                />
              </Grid>
            </Grid>
          </div>
        }
      />

      {/* <Modals
        ModalTitle={sessionModalTitle}
        handleClose={() => handleCloseSessionModal()}
        handleConfirm={() => handleConfirmCloseSessionModal()}
        open={openSessionModal}
        ButtonClose="Cancel"
        ButtonConfirm={sessionModalConButton}
        width="552px"
        ModalFooter={true}

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"

        ModalBody={
          <p></p>
        }

      /> */}
      {/* Session Modal  End*/}

      {/* Resource Modal */}

      <SideDrawer
        title={resourseModalTitle}
        ButtonLabel={resourseModalConButton}
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={openResourseModal}
        handleDrawerClose={handleCloseResourseModal}
        clickSaveButton={() => handleConfirmCloseResourseModal()}
        FooterWithoutTab={true}
        body={
          <div className="ResorceDrwContainer">
            <Grid container spacing={3}>
              {getAllSessions().length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <SelectField
                    label="Select Session (Optional)"
                    validation={false}
                    options={getAllSessions()}
                    selectedOption={
                      typeof tempResourse.sesId == "undefined"
                        ? ""
                        : tempResourse.sesId
                    }
                    handleChange={updateResoursesData("sesId")}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <RedioBox
                      label="Resource Type *"
                      tabsData={tabsData}
                      value={tempResourse.type}
                      onChange={(event, tabsData) =>
                        updateResourseWithParemData("type", tabsData)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {tempResourse.type == 0 ? (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Title"
                      required={true}
                      count={60}
                      value={tempResourse.title}
                      handleChange={updateResoursesData("title")}
                      placeholder="A descriptive title"
                      error={tempResourseError?.title}
                      helperText={tempResourseError?.title}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="URL"
                      placeholder="https://example.com"
                      required={true}
                      value={tempResourse.resourseLink}
                      handleChange={updateResoursesData("resourseLink")}
                      error={tempResourseError?.resourseLink}
                      helperText={tempResourseError?.resourseLink}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {/* <Grid item xs={12} sm={12} md={12}>
                      <FileUplaoder
                        label="Upload file"
                        required={true}
                        filePath="modules/resource-files/"
                        uploadButton="Upload File"
                        file={tempResourse.resourseFile}
                        fileType="*"
                        onUpload={(filePath) => updateResourseWithParemData("resourseFile", filePath)}
                        helpertext="Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf."
                      />
                    </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="File Name"
                      placeholder=""
                      validation={true}
                      value={tempResourse.resourseFileName}
                      handleChange={updateResoursesData("resourseFileName")}
                      count={60}
                      error={tempResourseError?.resourseFileName}
                      helperText={tempResourseError?.resourseFileName}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="FieldLabel">
                      Upload File<span>*</span>
                    </div>
                    {(
                      tempResourse.resourseFile
                        ? tempResourse.resourseFile.length
                        : false
                    ) ? (
                      <>
                        <div className="TableSection">
                          <div
                            style={{
                              padding: "0.2rem",
                              // background: "#C3C3C3",
                              border: "1px solid #E4E8EC",
                              borderRadius: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "48px",
                              padding: "0 10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span className="CellImg">
                                <Avatar
                                  variant="rounded"
                                  src={tempResourse.resourseFile}
                                  style={{ width: "28px", height: "28px" }}
                                />
                              </span>
                              <span>
                                {" "}
                                <a href={tempResourse?.resourseFile}>
                                  Download
                                </a>{" "}
                              </span>
                            </div>
                            <span
                              className="CellImg"
                              onClick={deleteCourseImageSelection}
                            >
                              <DeleteForeverRoundedIcon
                                sx={{ mb: -0.5, color: "#676C7B" }}
                              />
                            </span>
                          </div>
                          <p
                            class="HelperText"
                            style={{ marginBottom: "10px" }}
                          >
                            Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf.
                          </p>
                        </div>
                      </>
                    ) : (
                      <div>
                        <SideDrawer
                          title="Media Library"
                          ButtonLabel="Add"
                          //clickSaveButton={clickSaveButton}
                          cancelButtonLabel="Cancel"
                          sideOpen="right"
                          open={openSelectMedia}
                          handleDrawerClose={handleDrawerCloseSelectMedia}
                          FooterWithoutTab={true}
                          LeftButton={true}
                          ButtonLeft="Upload New"
                          ButtonLeftIcon={<FileUploadOutlinedIcon />}
                          clickButtonLeft={handleDrawerOpenNewMedia}
                          clickSaveButton={clickSelectMediaItem}
                          body={
                            <MediaLibrarySelectMedia
                              onClickRadioIcon={setSelectedValue}
                              onItemSelected={getMediaItem}
                            />
                          }
                        />

                        <Externaluse
                          open={openNewMedia}
                          handleCloseButton={handleMediaUploadClose}
                          handleOpenNewMedia={handleOpenNewMedia}
                        />

                        <div className="TableSection">
                          <Buttons
                            label="Add From Media Library"
                            buttonColor="transparent"
                            border="#E4E8EC 1px solid"
                            icon={<AddRoundedIcon />}
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => handleDrawerOpenSelectMedia()}
                          />
                          <p
                            class="HelperText"
                            style={{ marginBottom: "10px" }}
                          >
                            Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf.
                          </p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        }
      />

      {/* <Modals
        ModalTitle={resourseModalTitle}
        handleClose={handleCloseResourseModal}
        handleConfirm={() => handleConfirmCloseResourseModal()}
        open={openResourseModal}
        ButtonClose="Cancel"
        ButtonConfirm={resourseModalConButton}
        width="552px"

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}

        ModalBody={
          
        }

      /> */}
      {/* Resource Modal End*/}
    </>
  );
}

export default Curriculum;
