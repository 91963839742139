import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "../../../../components/Form/TextField/TextField";
import TextArea from "../../../../components/Form/TextArea/TextArea";
import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";
import MultipleSeletChip from "../../../../components/Form/MultipleSeletChip/MultipleSeletChip";

function InviteTeamMember({
  setNewTeamMemberInfo,
  newTeamMemberInfo,
  roles,
  setEmailValidationError,
  teamErrors,
}) {
  const [rolesOption, setRolesOption] = useState([]);
  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState("female");

  const handleChangeRadio = (event) => {
    radiosetValue(event.target.radiovalue);
  };
  //==== radio button End//

  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });

  // ==== Date Picker === //
  const [dateTime, dateSetValue] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleChangeDate = (newValue) => {
    dateSetValue(newValue);
  };

  // ==== Date Picker end === //

  // ==== Level Value === //
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const options = [
    { label: "Admin", value: "Admin" },
    { label: "Instructor", value: "Instructor" },
  ];

  const convertedOptions = (options) => {
    const data = options?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
    setRolesOption(data);
  };
  const handleChanges = (e, fieldName) => {
    setNewTeamMemberInfo({
      ...newTeamMemberInfo,
      [fieldName]: e.target.value,
    });
  };
  const [phoneFormatted, setphoneFormatted] = useState("");
  const handleChangesData = (phone, country) => {
    setNewTeamMemberInfo({
      ...newTeamMemberInfo,
      phone_number: phoneFormatted,
      // phone_number: `+${phone}`,
    });
  };
  const handleChangeWithData = (fieldName, fieldData) => {
    let roleId = [];
    fieldData?.map((item) => roleId.push(item?.value));
    if (roleId?.length > 0) {
      setNewTeamMemberInfo({
        ...newTeamMemberInfo,
        roles: roleId,
      });
    }
  };
  const emailValidation = (validationError) => {
    if (validationError) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };

  useEffect(() => {
    if (roles?.length > 0) {
      convertedOptions(roles);
    }
  }, [roles]);

  return (
    <div className="formSection">
      <div className="FormBody">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="First Name"
              required={true}
              value={newTeamMemberInfo?.first_name}
              handleChange={(e) => handleChanges(e, "first_name")}
              error={teamErrors?.first_name}
              helperText={teamErrors?.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Middle Name"
              value={newTeamMemberInfo?.middle_name}
              handleChange={(e) => handleChanges(e, "middle_name")}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="Last Name"
              required={true}
              value={newTeamMemberInfo?.last_name}
              handleChange={(e) => handleChanges(e, "last_name")}
              error={teamErrors?.last_name}
              helperText={teamErrors?.last_name}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Email"
              required={true}
              email={true}
              number="email"
              value={newTeamMemberInfo?.email}
              handleChange={(e) => handleChanges(e, "email")}
              handleValidation={emailValidation}
              // helperText="We will send invite to this email"
              error={teamErrors?.email}
              helperText={teamErrors?.email}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <PhoneInputField
              phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
              country={"us"}
              value={phone.number}
              countryCodeEditable={false}
              onChange={(phone, country) => handleChangesData(phone, country)}
              // onChange={(phone, country) =>
              //   console.log("onChange", phone, country, 345)
              // }
              onBlur={() => console.log("onBlur")}
              onKeyDown={(e) => setphoneFormatted(e.target.value)}
              variant="outlined"
              // onlyCountries={["us", "ca", "gb", "my"]}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "40px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              errorText={"Phone number is required"}
              errorStyles={{ marginBottom: "5px" }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              inputProps={{}}
              specialLabel={"Primary Phone"}
              // preferredCountries={[]}
              // excludeCountries={[]}
              labelFixed="Phone Number (Optional)"
              LabelStatus={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <MultipleSeletChip
              label="Select Role*"
              options={rolesOption}
              // required={true}
              error={teamErrors?.roles}
              helperText={teamErrors?.roles}
              // selectedOption={newTeamMemberInfo?.roles}
              handleChange={(event, fieldData) =>
                handleChangeWithData("preCourses", fieldData)
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Organization/Institution/Company Name"
              value={newTeamMemberInfo?.organization_name}
              handleChange={(e) => handleChanges(e, "organization_name")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Designation"
              value={newTeamMemberInfo?.designation}
              handleChange={(e) => handleChanges(e, "designation")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextArea
              validation={false}
              label="Short bio"
              count="1000"
              value={newTeamMemberInfo.short_bio}
              handleChange={(e) => handleChanges(e, "short_bio")}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default InviteTeamMember;
