import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

import CourseOverview from "./steps/CourseOverview";
import Description from "./steps/Description";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Settings from "./steps/Settings";
import Learners from "./steps/Learners";
import Curriculum from "./steps/Curriculum";
import Prerequisite from "./steps/Prerequisite";
import Track from "./steps/Track";
import Price from "./steps/Price";
import Publish from "./steps/Publish";
import Assignments from "./steps/Assignments";
import CustomizedSnackbar from "../../../../hooks/Snackbar";
import DraggableDialog from "../../../../hooks/Dialog";
import SimpleBackdrop from "../../../../components/Form/Backdrop/SimpleBackdrop";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import Buttons from "../../../../components/Form/Button/Button";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";

import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";

import Tooltip from "../../../../components/Form/Tooltip/Tooltip";
import { forEach } from "lodash";

import StepButton from "@material-ui/core/StepButton";

function getInitialValues(currentOrgId, courseId) {
  const initialCourseData = {
    orgId: currentOrgId,
    courseTitle: "",
    description: "",
    courseLanguages: [],
    level: "",
    category: "",
    leadInstructor: "",
    instructors: [],
    enrollmentStartDate: "",
    enrollmentEndDate: "",
    sessionStartDate: "",
    sessionEndDate: "",
    totalSessions: "",
    totalHours: "",
    totalLiveSessions: "",
    timezone: "",
    courseImage: "",
    courseImageName: "",
    promotionalVideo: "",
    completionCertificate: false,
    gradedExamCertificate: false,
    enableAttendance: false,
    seatsStatus: false,
    seats: "",
    ageGroup: false,
    gradeLevel: false,
    ageGroups: [],
    gradeLevels: [],
    sessionLinkSetting: true,
    zoomLink: "",
    zoomDescription: "",
    courseModules: [
      { modId: 0, title: "Module 01", courseSessions: [], courseResourses: [] },
    ],
    courseLearningObjs: [],
    courseRequirements: [],
    prerequisite: false,
    preCourses: [],
    track: false,
    tracks: [],
    priceCat: "Free",
    price: "",
    promoCodes: [],
    status: "draft",
  };

  const localCourseData = JSON.parse(localStorage.getItem("courseData"));

  // console.log(localCourseData);

  if (courseId == 0 && localCourseData && localCourseData.courseId) {
    return initialCourseData;
  }

  return localCourseData ? localCourseData : initialCourseData;
}

function AddCourse(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const orgId = localStorage.getItem("orgId");

  let { courseId } = useParams();

  const navigate = useNavigate();

  console.log(courseId);

  const [activeStep, setActiveStep] = React.useState(0);

  const [stepErrors, setStepErrors] = React.useState({
    "Course Overview": true,
    Description: false,
    Settings: true,
    Learners: true,
    Curriculum: true,
    Prerequisite: true,
    Price: false,
    Publish: false,
  });

  const [skipped, setSkipped] = React.useState(new Set());
  const [courseData, setCourseData] = useState(
    getInitialValues(orgId, courseId)
  );
  const [courseRelationalData, setCourseRelationalData] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [courseTitleFound, setCourseTitleFound] = useState(true);
  const [courseRefIdFound, setCourseRefIdFound] = useState(true);

  const [formValidationError, setFormValidationError] = useState(false);

  const steps = [
    "Course Overview",
    "Description",
    "Settings",
    "Learners",
    "Curriculum",
    "Prerequisite",
    "Price",
    "Publish",
  ];

  useEffect(() => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(
        `${baseUrl}/orgPortal/getAddCourseRelatedData?orgId=${orgId}&courseId=${courseId}`
      )
      .then((result) => {
        // console.log(result.data.body);
        setCourseRelationalData(result.data.courseRelationalData);
        if (courseId > 0) {
          let courseNewData = { ...result.data.courseData };
          courseNewData.status = "draft";
          // set course data
          setCourseData(courseNewData);
        } else {
          // setCourseData("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("courseData", JSON.stringify(courseData));
    // set validation alert
    setFormValidationError(false);
    steps.forEach((element, index) => {
      const validationResult = dataValidation(index, true);
      if (validationResult.error) {
        console.log(steps[index]);
        setFormValidationError(true);
      }
    });
  }, [courseData]);

  useEffect(() => {
    setCourseData({ ...courseData, formValidationError: formValidationError });
  }, [formValidationError]);

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const saveDraft = () => {
    if (!courseData.courseTitle) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the course title first.");
    } else {
      // make course status as draft
      setCourseData({ ...courseData, status: "draft" });
      storeData(1);
    }
  };

  const handleExit = () => {
    setDialogTitle("Unsaved changes");
    setDialogBody(
      "You have unsaved changes. You will lose these changes if you exit without saving."
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // return to course listing page
    localStorage.removeItem("courseData");
    navigate("/courses-listing", { replace: true });
  };

  const handleConfirmCloseDialog = () => {
    if (!courseData.courseTitle) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the course title first.");
    } else {
      storeData(0);
    }
  };

  /**
   *
   * @param {*} props
   * @returns
   */

  const storeData = (isDraft) => {
    let { courseId, ...restCourseInfo } = courseData;

    restCourseInfo.courseLearningObjs = restCourseInfo?.courseLearningObjs?.map(
      (singleObj) => {
        return {
          objId: 0,
          title: singleObj.title,
        };
      }
    );

    restCourseInfo.courseModules = restCourseInfo?.courseModules?.map(
      (singleObj) => {
        let courseResourses = [];
        let courseSessions = [];
        singleObj.courseResourses.map((singleCourseResource) => {
          courseResourses.push({
            modId: 0,
            resId: 0,
            resourseFile: singleCourseResource.resourseFile,
            resourseFileName: singleCourseResource.resourseFileName,
            resourseLink: singleCourseResource.resourseLink,
            sesId: singleCourseResource.sesId,
            title: singleCourseResource.title,
            type: singleCourseResource.type,
          });
        });
        singleObj.courseSessions.map((singleCourseSession) => {
          courseSessions.push({
            description: singleCourseSession.description,
            details: singleCourseSession.details,
            enableSession: singleCourseSession.enableSession,
            endTime: singleCourseSession.endTime,
            modId: 0,
            selectedEndTime: singleCourseSession.selectedEndTime,
            selectedStartTime: singleCourseSession.selectedStartTime,
            sesId: 0,
            startDate: singleCourseSession.startDate,
            startTime: singleCourseSession.startTime,
            title: singleCourseSession.title,
            zoomLink: singleCourseSession.zoomLink,
          });
        });
        return {
          modId: 0,
          title: singleObj.title,
          courseResourses: courseResourses,
          courseSessions: courseSessions,
        };
      }
    );

    restCourseInfo.courseRequirements = restCourseInfo?.courseRequirements?.map(
      (singleObj) => {
        return {
          reqId: 0,
          title: singleObj.title,
        };
      }
    );

    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    // show loader
    setOpenBackdrop(true);
    axios
      .post(`${baseUrl}/orgPortal/addCourse`, restCourseInfo)
      .then((response) => showDataPostMessage(response, isDraft));
  };

  const showDataPostMessage = (response, isDraft) => {
    console.log(response.data);

    // hide loader
    setOpenBackdrop(false);

    const error =
      typeof response.data.error == "undefined" ? true : response.data.error;

    if (error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error! can't save data, please try again.");
    } else {
      setOpenDialog(false);
      localStorage.removeItem("courseData");
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      const successMessage = isDraft
        ? "Course added as Draft! you will redirect to listing page."
        : "Course added successfully! you will redirect to listing page.";
      setSnackbarMessage(successMessage);
      setTimeout(() => {
        // Redirects to course listing page
        navigate("/courses-listing", { replace: true });
      }, 2000);
    }
  };

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  // useEffect(() => {
  //   const localCourseData = JSON.parse(localStorage.getItem('courseData'));
  //   if (localCourseData) {
  //    setCourseData(localCourseData);
  //   }
  // }, []);

  // console.log(courseRelationalData.courseLanguages);

  /**
   * Data validation function
   * @param {*} activeStep
   */
  const dataValidation = (activeStep, batchOperation = false) => {
    const activeStepLabel = steps[activeStep];
    let validationError = false;
    let validationErrorMessage = "No error found";

    if (activeStepLabel == "Course Overview") {
      if (Object.keys(courseData).length == 0) {
        validationError = true;
        validationErrorMessage = "No course data set yet";
      } else if (!courseData.courseTitle) {
        validationError = true;
        validationErrorMessage = "Course title field is required";
      } else if (courseTitleFound) {
        validationError = true;
        validationErrorMessage = "Course title already exists";
      } else if (!courseData.leadInstructor) {
        validationError = true;
        validationErrorMessage = "Lead Instructor field is required";
      } else if (!courseData.courseImage) {
        validationError = true;
        validationErrorMessage = "Course image field is required";
      }
    } else if (activeStepLabel == "Settings") {
      if (!courseData.courseRefId) {
        validationError = true;
        validationErrorMessage = "Course Id field is required";
      } else if (courseRefIdFound) {
        validationError = true;
        validationErrorMessage = "Course ID already exists";
      } else if (courseData.courseRefIdError) {
        validationError = true;
        validationErrorMessage = "Course ID already exists";
      } else if (!courseData.timezone) {
        validationError = true;
        validationErrorMessage = "Timezone field is required";
      } else if (!courseData.enrollmentStartDate) {
        validationError = true;
        validationErrorMessage = "Enrollment start date field is required";
      } else if (!courseData.enrollmentEndDate) {
        validationError = true;
        validationErrorMessage = "Enrollment end date field is required";
      } else if (!courseData.sessionStartDate) {
        validationError = true;
        validationErrorMessage = "Session start date field is required";
      }
    } else if (activeStepLabel == "Learners") {
      if (courseData.courseLearningObjs.length < 2) {
        validationError = true;
        validationErrorMessage =
          "At least 2 entries are required at learning objective";
      } else if (courseData.seatsStatus == true && !courseData.seats) {
        validationError = true;
        validationErrorMessage = "Seats field is required";
      } else if (
        courseData.ageGroup == true &&
        courseData.ageGroups.length == 0
      ) {
        validationError = true;
        validationErrorMessage = "Age group field is required";
      } else if (
        courseData.gradeLevel == true &&
        courseData.gradeLevels.length == 0
      ) {
        validationError = true;
        validationErrorMessage = "Grade level field is required";
      }
    } else if (activeStepLabel == "Curriculum") {
      if (courseData.sessionLinkSetting == true && !courseData.zoomLink) {
        validationError = true;
        validationErrorMessage = "Zoom link is required";
      }
    } else if (activeStepLabel == "Prerequisite") {
      if (
        courseData.prerequisite == true &&
        courseData.preCourses.length == 0
      ) {
        validationError = true;
        validationErrorMessage = "Select at least one prerequisite course";
      }
    } else if (activeStepLabel == "Price") {
      if (courseData.priceCat == "Paid" && !courseData.price) {
        validationError = true;
        validationErrorMessage = "Price field is required";
      }
    }

    if (validationError) {
      setStepErrors((stepErrors) => ({
        ...stepErrors,
        [activeStepLabel]: true,
      }));
    } else {
      setStepErrors((stepErrors) => ({
        ...stepErrors,
        [activeStepLabel]: false,
      }));

      if (activeStepLabel == "Publish" && batchOperation == false) {
        // it's final step to store data
        storeData(0);
      }
    }

    return { error: validationError, message: validationErrorMessage };
  };

  const getStepContent = (step, courseRelationalData) => {
    switch (step) {
      case 0:
        return (
          <CourseOverview
            courseRelationalData={courseRelationalData}
            courseData={courseData}
            setCourseData={setCourseData}
            setCourseTitleFound={setCourseTitleFound}
          />
        );
      case 1:
        return (
          <Description courseData={courseData} setCourseData={setCourseData} />
        );
      case 2:
        return (
          <Settings
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            setCourseRefIdFound={setCourseRefIdFound}
          />
        );
      case 3:
        return (
          <Learners
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            showSnackbarMessage={showSnackbarMessage}
          />
        );
      case 4:
        return (
          <Curriculum
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
            showSnackbarMessage={showSnackbarMessage}
          />
        );
      case 5:
        return (
          <Prerequisite
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
          />
        );
      // case 5:
      //   return <Track courseData={courseData} setCourseData={setCourseData} courseRelationalData={courseRelationalData} />;
      case 6:
        return (
          <Price
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
          />
        );
      case 7:
        return (
          <Publish
            courseData={courseData}
            setCourseData={setCourseData}
            courseRelationalData={courseRelationalData}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let formValidation = {};

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    formValidation = dataValidation(activeStep);

    if (formValidation.error == false) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(formValidation.message);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const breadcrumbsData = [
    {
      label: "Back",
      link: "/courses-listing",
      icon: <ArrowBackIosNewRoundedIcon fontSize="small" />,
    },
    {
      label: "Courses",
      link: "/courses-listing",
      icon: <MenuBookRoundedIcon fontSize="small" />,
    },

    {
      label: <>{courseId === "0" ? "Add New Course" : "Duplicate course"}</>,
      link: "",
    },
  ];

  //========== Stepper Click Able ==========//

  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    console.log(courseData);
    if (!courseData.courseTitle && step > 0) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the course title first.");
    } else if (courseTitleFound && step > 0) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Course title already exists");
    } else if (courseRefIdFound && step > 2) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Course ID already exists");
    } else {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  //========== Stepper Click Able ==========//

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="ButtonSaveCont">
        <div className="ButtonSave">
          <Buttons
            label="Exit"
            buttonColor="#fff"
            border="#ccc 1px solid"
            color="#004FE0"
            width=""
            height="48px"
            fontSize="14px"
            // link="/courses-listing"
            onClick={() => handleExit()}
          />
          <Buttons
            label="Save As Draft"
            buttonColor="#004FE0"
            border="#ccc 1px solid"
            color="#fff"
            width=""
            height="48px"
            fontSize="14px"
            link=""
            onClick={() => saveDraft()}
          />
        </div>
      </div>

      <Breadcrumbs breadcrumbsData={breadcrumbsData} />

      <div className="FormStepper">
        <div className="StapperContaier">
          <div className="StapperBody Section">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    {/* <Tooltip
                      TooltipText="Coming Soon"
                      Tooltip={true}
                    /> */}
                    {/* {stepErrors[label]? "Error" : ""} */}
                    <StepLabel
                      {...labelProps}
                      onClick={handleStep(index)}
                      completed={completed[index]}
                    >
                      <div className="emptyIcon">
                        {label}
                        {"\u00A0"}
                        {stepErrors[label] && <ErrorRoundedIcon />}
                      </div>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          <div className="StapperContent">
            {activeStep === steps.length ? (
              <div>
                {/* <Typography >
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} >
                Reset
              </Button> */}
              </div>
            ) : (
              <div>
                <Typography>
                  {getStepContent(activeStep, courseRelationalData)}
                </Typography>
                <div className="SpperButtonContainer">
                  <div className="StepperButton">
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className="ButtonContinue"
                    >
                      {activeStep === steps.length - 1
                        ? courseData.status == "draft" ||
                          courseData.status == "active_for_track"
                          ? "Save"
                          : courseData.status == "public"
                          ? "Publish"
                          : "Private"
                        : "Continue"}{" "}
                      <ArrowForwardRoundedIcon />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <CustomizedSnackbar
          open={showSnackbar}
          severity={snackbarSeverity}
          message={snackbarMessage}
          handleClose={handleSnackbarClose}
        />
        <DraggableDialog
          openDialog={openDialog}
          title={dialogTitle}
          body={dialogBody}
          actionButton="Save And Exit"
          cancelButton="Exit Without Saving"
          handleCloseDialog={handleCloseDialog}
          handleConfirmCloseDialog={handleConfirmCloseDialog}
          ModalFooter={true}
        />
      </div>
    </>
  );
}

export default AddCourse;
