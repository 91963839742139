import react from "react";
import Grid from "@mui/material/Grid";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import img from "../../assets/imgs/Certificate.svg";
import imggray from "../../assets/imgs/Certificate-gray.svg";
import Buttons from "../Form/Button/Button";

import "./CoursesCards.css";
import { useNavigate } from "react-router";
import moment from "moment";
import EmptyCard from "../EmptyCard/EmptyCard";

function CoursesCards(props) {
  const { sessionData, handlerShearButtonOpen, userId } = props;

  const navigate = useNavigate();
  const handlerMoveToTheDetails = (id) => {
    navigate(`/certificate-details/${userId}/${id}`);
  };

  return (
    <div>
      {sessionData?.length > 0 ? (
        <Grid container spacing={3}>
          {sessionData?.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <div className="SessionCard">
                <div
                  className="SessionCardImgs"
                  style={{
                    background: `${!item.fileLink ? "#fafafa" : "#0b94443d"}`,
                  }}
                >
                  <img
                    src={!item.fileLink ? imggray : img}
                    style={{ width: "130px", height: "74px" }}
                    alt="certificate svg"
                  />
                </div>
                <div
                  className="CourseLabel"
                  style={{
                    background: `${!item.fileLink ? "gray" : "#0B9444"}`,
                  }}
                >
                  {('courseId' in item && item.courseId !== null) && "Course"}
                  {('trackId' in item && item.trackId !== null && item?.trackType == "certificate") && "Certificate Track"}
                  {('trackId' in item && item.trackId !== null && item?.trackType == "diploma") && "Diploma Track"}
                  {/* {item.courseTrack} */}
                </div>
                <div className="SessionCardInfo">
                  {/* <div className="SessionCardDate">
                  <h3>{item.date}</h3>
                  <span>{item.price}</span>
                </div> */}

                  
                  {('courseId' in item && item.courseId !== null) && <h4 className="CourseName">{item.courseTitle}</h4>}
                  {('trackId' in item && item.trackId !== null) && <h4 className="CourseName">{item.name}</h4>}
                  <span className="orgName">{item?.teachingAccName}</span>
                  <p>
                    Issued :{" "}
                    <b>
                      {item?.certificate_issued_at
                        ? moment(item?.certificate_issued_at)?.format(
                            "DD-MMM-YYYY"
                          )
                        : "N/A"}{" "}
                    </b>
                  </p>
                  <div className="SessionCardFooter ButtonGroup ButtonGroupContainer">
                    {!item.fileLink ? (
                      <Buttons
                        label="In progress"
                        buttonColor="#004FE0"
                        color="#fff"
                        width="100%"
                        height="48px"
                        fontSize="14px"
                        // link={item.link}
                        disabled="disabled"
                      />
                    ) : (
                      <>
                        <Buttons
                          label="View Certificate"
                          buttonColor="#004FE0"
                          color="#fff"
                          width="100%"
                          height="48px"
                          fontSize="14px"
                          // link={}
                          onClick={(e) =>
                            handlerMoveToTheDetails(item.courseId)
                          }
                          //disabled="disabled"
                        />

                        <div className="ShareButton">
                          <Buttons
                            icon={<ShareRoundedIcon />}
                            buttonColor="#fff"
                            color="#676C7B"
                            width="48px"
                            height="48px"
                            fontSize="30px"
                            borderRadius="26px"
                            border="#676C7B 1px solid"
                            onClick={(e) => handlerShearButtonOpen(item)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyCard
          title="No certificate to upload yet"
          description="Certificates will be visible here"
        />
      )}
    </div>
  );
}

export default CoursesCards;
