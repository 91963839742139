import React, { useState, useRef, useEffect } from "react";
import Table from "../Table/Table";
import SideDrawer from "../SideDrawer/SideDrawer";
import UploadFromDevice from "./UploadFromDevice";
import UploadFromYoutube from "./UploadFromYoutube";
import EditMedia from "./EditMedia";
import axios from "axios";

import DraggableDialog from "../../hooks/Dialog";
import CustomizedSnackbar from "../../hooks/Snackbar";
//import internal functions
import {
  convertDate,
  convertFileSize,
  generaImageUrl,
  onFileUpload,
} from "./Functions";
import SimpleBackdrop from "../../components/Form/Backdrop/SimpleBackdrop";
import YouTubeIcon from "@mui/icons-material/YouTube";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import MediaLibrarySelectMedia from "./MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "./MediaLibrary.css";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import { fabClasses } from "@mui/material";

function MediaLibraryTableView(props) {
  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));
  const [openNewMedia, setOpenNewMedia] = useState(false);

  const [items, setItems] = useState([]);

  const [totalItems, setTotalItems] = useState([]);

  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  const [activeDrawer, setActiveDrawer] = useState(null);
  // const modalRef = useRef();
  const childRef = useRef(null);

  const [file_name, setFileName] = useState("");
  const [file_type, setFileType] = useState("");
  const [file_size, setFileSize] = useState("");
  const [file_url, setFileUrl] = useState("");
  const [file_id, setFileId] = useState("");
  const [created_at, setCreatedAt] = useState("");
  const [isPrivate, setIsPrivate] = useState("");
  const [medianame, setMediaName] = useState("");
  const [fileMeta, setFileMeta] = useState({});
  const [asc, setAsc] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(true);

  const [videoName, setVideoName] = useState("");

  const [imageName, setImageName] = useState("");

  const [videoUrl, setVideoUrl] = useState("");

  const [searchedText, setSearchedText] = useState("");

  const [addNewItem, setAddNewItem] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success

  const [deleteMedia, setDeleteMedia] = useState(false);
  const [addedMediaItems, setAddedMediaItems] = useState([]);
  const [deleteMultiMedia, setDeleteMultiMedia] = useState(false);

  const [imageNameError, setImageNameError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isfileSelected, setFileSelected] = useState(false);
  const [filePathData, setFilePathData] = useState(null);
  const [imaneNameErrorMessage, setImageNameErrorMessage] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [videoErrorState, setVideoErrorState] = useState(false);
  const [videoErros, setVideoErros] = useState({
    name: "",
    url: "",
  });

  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
    setFilePathData(null);
  };

  const handleDrawerCloseNewMedia = () => {
    setVideoErros({
      name: "",
      url: "",
    });
    setVideoName("");
    setVideoErrorState(false);
    setImageNameErrorMessage("");
    setImageNameError(false);
    setFileUploadError(false);
    setShowSnackbar(false);
    setSnackbarMessage("");
    setSnackbarSeverity("");
    setImageName("");
    setOpenNewMedia(false);
    setUploaded(false);
    setErrorState(false);
    setSelectedFile(null);
  };

  const [openEditMedia, setOpenEditMedia] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmCloseDialog = async () => {
    if (isfileSelected && filePathData) {
      let data = {
        key: filePathData,
      };
      try {
        const response = await axios.delete(`${baseUrl}/deleteMediaFromS3`, {
          data: data,
        });
        if (response?.status === 200) {
          setFilePathData(null);
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Error uploading file", error, 346);
      }
    }
    setFileSelected(null);
    handleDrawerCloseNewMedia();
    setOpenDialog(false);
  };

  const handleDrawerOpenEditMedia = (id) => (event) => {
    console.log("id", id);
    //find the data from the totalItems array
    let data = null;
    // console.log(totalItems)
    for (let i = 0; i < totalItems.length; i++) {
      if (totalItems[i].id === id) {
        data = totalItems[i];
        setFileName(data.file_name);
        setFileType(data.file_type);
        setFileSize(convertFileSize(data.file_size));
        setFileUrl(data.file_url);
        setFileId(data.id);
        setCreatedAt(convertDate(data.created_at));
        setMediaName(data.name);
        setIsPrivate(data?.is_private);
        //json parse the file_meta and set the values
        setFileMeta(data?.file_meta ? JSON.parse(data?.file_meta) : {});
        break;
      }
    }

    console.log("data", data);
    setOpenEditMedia(true);
  };
  const handleDrawerCloseEditMedia = () => {
    setAddedMediaItems([]);
    setOpenEditMedia(false);
  };

  useEffect(() => {
    //call api to get media library data

    getAllMedia();
  }, [totalItems.length]);

  const getAllMedia = () => {
    //call api to get media library data

    axios
      .get(`${baseUrl}/getAllMediaByOrgId?orgId=${orgId}`, {})
      .then((res) => {
        console.log("res", res.data);
        //traverse through the data and set the values in the table
        let data = res.data;
        setTotalItems(data);
        let temp = [];
        data.map((item, index) => {
          // console.log("item", item);
          let file_type =
            item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);
          temp.push({
            cell: [
              {
                Check: true,
                img: generaImageUrl(item),
                singleIconStatus: item?.file_type?.startsWith("video")
                  ? true
                  : false,
                singleIcon: <PlayCircleFilledOutlinedIcon />,
                imgStatus: !item?.file_type?.startsWith("video") ? true : false,
                linkText: item.name,
                linkTextStatus: true,
                onClickLinkText: handleDrawerOpenEditMedia(item.id),
                user_id: item.id,
                checkBoxColor: "success",
              },

              { text: file_type },
              {
                text: convertFileSize(item.file_size),
                textcolor: item.file_size ? "#1B1B1F" : "#A4A7B0",
                // textcolor: item.file_type.startsWith("image")
                //   ? "#1B1B1F"
                //   : "#A4A7B0",
              },
              {
                text: item?.is_private === 0 ? "Public" : "Private",
              },
              { text: convertDate(item.created_at) },
              // { text: "N/A",textcolor:"#A4A7B0" },
              {
                Button: true,
                buttonLabel: "Edit",
                buttonTextcolor: "#004FE0",
                buttonBorder: "#E4E8EC 1px solid",
                onClick: handleDrawerOpenEditMedia(item.id),
              },
            ],
          });
        });
        setItems(temp);
        setOpenBackdrop(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [open, setOpen] = useState(false);
  const [multiOpen, setMultiOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
    setDeleteMedia(false);
  };

  const handleMultiClose = () => {
    setMultiOpen(false);
    setDeleteMultiMedia(false);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Media",
      width: "40%",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Type",
      width: "15%",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByType("file_type"),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Size",
      width: "15%",
    },

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Visibility",
      width: "15%",
    },

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Date",
      width: "20%",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByType("created_at"),
    },

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
    },
  ];

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const deleteConfirm = () => {
    console.log("delete confirm clicked");
    setDeleteMedia(true);
  };

  const deleteMultiConfirm = () => {
    setDeleteMultiMedia(true);
  };
  const deleteMultiMediaItem = async () => {
    console.log(addedMediaItems);

    console.log("delete clicked");
    //open loading icon
    setOpenBackdrop(true);
    //call api to delete the media

    setDeleteMultiMedia(false);

    const response = await axios.post(baseUrl + "/deleteMultiMedia", {
      media_ids: addedMediaItems,
    });

    console.log(response);
    if (response?.data && response?.data?.error == false) {
      handleSnackbarOpen("Multiple Media deleted successfully", "success");
      getAllMedia();
      setAddedMediaItems([]);
    }
  };
  const deleteMediaItem = () => {
    console.log("delete clicked");
    //open loading icon
    setOpenBackdrop(true);
    //call api to delete the media
    axios.delete(`${baseUrl}/deleteMedia?mediaId=${file_id}`).then((res) => {
      console.log("res", res.data);
      //traverse through the data and set the values in the table
      let data = totalItems;
      let temp = [];
      let tempItem = [];
      data.map((item, index) => {
        console.log("item", item);
        if (item.id !== file_id) {
          let file_type =
            item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);
          temp.push(item);
          tempItem.push({
            cell: [
              {
                Check: true,
                img: generaImageUrl(item),
                singleIconStatus: item?.file_type?.startsWith("video")
                  ? true
                  : false,
                singleIcon: <PlayCircleFilledOutlinedIcon />,
                imgStatus: !item?.file_type?.startsWith("video") ? true : false,
                linkText: item.name,
                linkTextStatus: true,
                onClickLinkText: handleDrawerOpenEditMedia(item.id),
                user_id: item.id,
                checkBoxColor: "success",
              },

              { text: file_type },
              {
                text: convertFileSize(item.file_size),
                textcolor: item.file_type.startsWith("image")
                  ? "#1B1B1F"
                  : "#A4A7B0",
              },
              {
                text: item?.is_private === 0 ? "Public" : "Private",
              },

              { text: convertDate(item.created_at) },
              {
                Button: true,
                buttonLabel: "Edit",
                buttonTextcolor: "#004FE0",
                buttonBorder: "#E4E8EC 1px solid",
                onClick: handleDrawerOpenEditMedia(item.id),
              },
            ],
          });
        }
      });
      setTotalItems(temp);
      setItems(tempItem);
      setOpenEditMedia(false);
      setOpen(false);
      setDeleteMedia(false);
      handleSnackbarOpen("Media deleted successfully", "success");
      setOpenBackdrop(false);
      setAddedMediaItems([]);
    });
  };

  const [videoUpload, setVideoUpload] = useState(false);

  const handleTabChange = (event) => {
    if (event == 1) {
      setUploadButtonText("Import from YouTube");
      setVideoUpload(true);
    } else {
      setUploadButtonText("Upload Image");
      setVideoUpload(false);
    }
  };

  const handleSnackbarOpen = (message, type) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
    // setOpenSnackbar(true);
    setOpen(false);
    //set opennewmedia to false
    setOpenNewMedia(false);
    // alert(message);
    setTotalItems([]);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const validateYouTubeUrl = (url) => {
    const youtubePattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/|youtube\.com\/shared\?ci=|youtube\.com\/live\/)([a-zA-Z0-9_-]{11})/;
    return youtubePattern.test(url);
  };

  const validateForm = () => {
    const isYouTubeUrlValid = validateYouTubeUrl(videoUrl);
    let errorObject = { ...videoErros };
    let validationError = true;
    if (videoName === "") {
      validationError = false;
      errorObject.name = "Please enter your video name";
    } else {
      errorObject.name = "";
    }
    if (videoUrl === "") {
      validationError = false;
      errorObject.url = "Please enter your YouTube URL";
    } else if (!isYouTubeUrlValid) {
      validationError = false;
      errorObject.url = "Please enter a valid YouTube URL";
    } else {
      errorObject.url = "";
    }
    setVideoErros({ ...videoErros, ...errorObject });
    return validationError;
  };
  useEffect(() => {
    if (videoErrorState) {
      validateForm();
    }
  }, [videoErrorState, videoName, videoUrl]);
  const clickSaveButton = async () => {
    // console.log("save button clicked");

    if (videoUpload) {
      setVideoErrorState(true);
      const validation = validateForm();

      const isYouTubeUrlValid = validateYouTubeUrl(videoUrl);

      if (!isYouTubeUrlValid || !validation) {
        return false;
      }
      if (validation) {
        // save data
        setOpenBackdrop(true);
        //call to api via axios
        const response = await axios.post(baseUrl + "/videoUpload", {
          name: videoName,
          orgId: orgId,
          url: videoUrl,
        });

        setOpenBackdrop(false);
        //if response status is 200
        if (response.data.message == "success") {
          setVideoName("");
          setVideoUrl("");
          resetImageTabData();
          setVideoErros({
            name: "",
            url: "",
          });
          setVideoErrorState(false);
          //if addNewitem is true then clear the form
          if (addNewItem) {
            setShowSnackbar(true);
            setSnackbarMessage("Video uploaded successfully.");
            setSnackbarSeverity("success");
          } else {
            handleSnackbarOpen("Video uploaded successfully.", "success");
          }
          //set total items to empty
          setTotalItems([]);
        } else {
          handleSnackbarOpen(
            "Something wasn't right. Could you please try again?",
            "error"
          );
        }
      }
    } else {
      // console.log("hi")
      let error = 0;
      if (imageName === "") {
        setImageNameError(true);
        setImageNameErrorMessage("Please enter media name");
        setOpenBackdrop(false);
        error++;
      } else {
        setImageNameError(false);
        setImageNameErrorMessage("");
      }
      if (!selectedFile) {
        setFileUploadError(true);
        setOpenBackdrop(false);
        error++;
      } else {
        setFileUploadError(false);
      }
      if (error > 0) return false;
      setOpenBackdrop(true);
      if (childRef.current) {
        childRef.current.uploadFile();
      }
    }
    setOpenBackdrop(false);
  };

  const updateMedia = async () => {
    // console.log("update media");
    setOpenBackdrop(true);
    //call api to update the media
    const response = await axios.post(baseUrl + "/updateMedia", {
      name: medianame,
      orgId: orgId,
      mediaId: file_id,
    });
    setOpenBackdrop(false);
    //if response status is 200
    if (response.data.message == "success") {
      // console.log("response", response);
      handleSnackbarOpen("Media updated successfully.", "success");
      //set open edit media to false to close the edit drawer
      handleDrawerCloseEditMedia();
    } else {
      handleSnackbarOpen(
        "Something wasn't right. Could you please try again?",
        "error"
      );
    }
  };

  //this is for search text saving in the state

  const handleSearchText = (event) => {
    setSearchedText(event.target.value.toLowerCase());
    //if searchtext length is 0 then trigger handlesearch
    // if (event.target.value.length == 0) {
    handleSearch();
    // }
  };

  const handleSearch = () => {
    // console.log("search clicked");
    setOpenBackdrop(true);
    // console.log("search text", searchedText);
    //get over all the items and filter the data
    let data = totalItems;
    let temp = [];
    let tempItem = [];
    data.map((item, index) => {
      // console.log("item", item)
      if (item.name.toLowerCase().includes(searchedText)) {
        let file_type =
          item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);

        temp.push(item);
        tempItem.push({
          cell: [
            {
              Check: true,
              img: generaImageUrl(item),
              singleIconStatus: item?.file_type?.startsWith("video")
                ? true
                : false,
              singleIcon: <PlayCircleFilledOutlinedIcon />,
              imgStatus: !item?.file_type?.startsWith("video") ? true : false,
              linkText: item.name,
              linkTextStatus: true,
              onClickLinkText: handleDrawerOpenEditMedia(item.id),
              user_id: item.id,
              checkBoxColor: "success",
            },

            { text: file_type },
            {
              text: convertFileSize(item.file_size),
              textcolor: item.file_type.startsWith("image")
                ? "#1B1B1F"
                : "#A4A7B0",
            },
            {
              text: item?.is_private === 0 ? "Public" : "Private",
            },
            { text: convertDate(item.created_at) },
            {
              Button: true,
              buttonLabel: "Edit",
              buttonTextcolor: "#004FE0",
              buttonBorder: "#E4E8EC 1px solid",
              onClick: handleDrawerOpenEditMedia(item.id),
            },
          ],
        });
      }
    });
    setItems(tempItem);

    setOpenBackdrop(false);
  };

  const resetImageTabData = () => {
    setFileUploadError(false);
    setTotalItems([]);
    setImageName("");
    setFileSelected(null);
    setSelectedFile(null);
  };
  //function to process fileupload
  const processFileUpload = async (
    filePath,
    fileName,
    fileSize,
    fileType,
    width,
    height,
    is_private
  ) => {
    setOpenBackdrop(true);
    const response = await onFileUpload(
      imageName,
      filePath,
      fileName,
      fileSize,
      fileType,
      width,
      height,
      is_private
    );

    if (response) {
      //show snackbar success message
      let message = "Media added";
      let type = "success";
      setShowSnackbar(true);
      setSnackbarMessage(message);
      setSnackbarSeverity(type);

      resetImageTabData();

      setVideoName("");
      setVideoUrl("");

      if (addNewItem) {
        if (childRef.current) {
          childRef.current.clickButton();
        }
      } else {
        setOpen(false);
        setOpenNewMedia(false);
      }
    } else {
      setOpenBackdrop(false);
      setOpenNewMedia(true);
      handleSnackbarOpen(
        "Invalid format, please try with supported format files",
        "error"
      );
      return false;
    }
    setOpenBackdrop(false);
  };

  /**
   * close backdrop
   */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const fileSelected = (status) => {
    setFileSelected(status);
  };
  const handleCheckboxClicked = (event) => {
    console.log(event.target.checked);
    setAddNewItem(event.target.checked);
  };

  ///=================================== sorting part start =============//

  const ascendingOrDescendingSortingByType = (field) => {
    let sortedMediaListing = [...totalItems];

    if (asc) {
      sortedMediaListing.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedMediaListing.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setAsc(!asc);
    convertedData(sortedMediaListing);
  };
  const convertedData = (sortingItems) => {
    let data = sortingItems;
    let temp = [];
    data.map((item, index) => {
      // console.log("item", item);
      let file_type =
        item.file_type.charAt(0).toUpperCase() + item.file_type.slice(1);
      temp.push({
        cell: [
          {
            Check: true,
            img: generaImageUrl(item),
            singleIconStatus: item?.file_type?.startsWith("video")
              ? true
              : false,
            singleIcon: <PlayCircleFilledOutlinedIcon />,
            imgStatus: !item?.file_type?.startsWith("video") ? true : false,
            linkText: item.name,
            linkTextStatus: true,
            onClickLinkText: handleDrawerOpenEditMedia(item.id),
            user_id: item.id,
            checkBoxColor: "success",
          },

          { text: file_type },
          {
            text: convertFileSize(item.file_size),
            textcolor: item.file_type.startsWith("image")
              ? "#1B1B1F"
              : "#A4A7B0",
          },
          {
            text: item?.is_private === 0 ? "Public" : "Private",
          },
          { text: convertDate(item.created_at) },
          // { text: "N/A",textcolor:"#A4A7B0" },
          {
            Button: true,
            buttonLabel: "Edit",
            buttonTextcolor: "#004FE0",
            buttonBorder: "#E4E8EC 1px solid",
            onClick: handleDrawerOpenEditMedia(item.id),
          },
        ],
      });
    });
    setItems(temp);
  };
  ///=================================== sorting part end =============//

  // useEffect(() => {
  //   console.log(fileUploadError, 345);
  // }, [fileUploadError]);

  const tabData = [
    {
      lebel: "Upload from device",
      icon: <UploadFileRoundedIcon />,
    },
    !selectedFile && {
      lebel: "YouTube video",
      icon: <YouTubeIcon />,
    },
  ];

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <UploadFromDevice
          handleTabChange={handleTabChange}
          handleSnackbarOpen={handleSnackbarOpen}
          setImageName={setImageName}
          videoName={videoName}
          videoUrl={videoUrl}
          imageName={imageName}
          showImageNameError={imageNameError}
          onFileUpload={processFileUpload}
          ref={childRef}
          fileSelected={fileSelected}
          showFileUploadError={fileUploadError}
          imageErrorMessage={imaneNameErrorMessage}
          uploaded={uploaded}
          setUploaded={setUploaded}
          filePathData={filePathData}
          setFilePathData={setFilePathData}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setErrorState={setErrorState}
          errorState={errorState}
          progress={progress}
          setProgress={setProgress}
          setFileUploadError={setFileUploadError}
        />
      ),

      SaveButtonLabel: "Save",
      clickSaveButton: clickSaveButton,
      CancelButtonLabel: "Cancel",
      disabled: uploaded || fileUploadError ? true : false,
      Checkbox: true,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: "Delete Permanently",
      onClickCheckbox: handleCheckboxClicked,
      handleTabChange: handleTabChange,
      //onClickDelete:onClickDelete,
    },

    !selectedFile && {
      id: 1,
      panleBody: (
        <UploadFromYoutube
          setVideoName={setVideoName}
          setVideoUrl={setVideoUrl}
          handleSnackbarOpen={handleSnackbarOpen}
          onCheckboxClicked={handleCheckboxClicked}
          videoName={videoName}
          videoUrl={videoUrl}
          videoErros={videoErros}
          setVideoErros={setVideoErros}
        />
      ),
      SaveButtonLabel: "Import From YouTube",
      clickSaveButton: clickSaveButton,
      cancelButtonLabel: "Cancel",
      videoUrl: videoUrl,
      videoName: videoName,
      CancelButtonLabel: "Cancel",
      handleTabChange: handleTabChange,
      Checkbox: true,
      CheckboxLabel: "Add another after saving",
      onClickCheckbox: handleCheckboxClicked,
    },
  ];

  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setAddedMediaItems((prevUsers) => [...prevUsers, item[0].user_id]);
    } else {
      // Remove the user from the array
      setAddedMediaItems((prevUsers) =>
        prevUsers.filter((user) => user !== item[0].user_id)
      );
    }
  };

  useEffect(() => {
    console.log(addedMediaItems, 345);
  }, [addedMediaItems]);

  const deleteMultipleMediaItemsHandler = () => {
    deleteMultiConfirm(true);
  };

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="TableSection">
        <CustomizedSnackbar
          open={showSnackbar}
          severity={snackbarSeverity}
          message={snackbarMessage}
          handleClose={handleSnackbarClose}
        />
        <Table
          title="Media Library"
          tableHeader={true}
          addLabel="Add New"
          headCells={headCells}
          rows={items}
          AddButton={true}
          onClick={handleDrawerOpenNewMedia}
          Checkbox={false}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          FilterButton={false}
          searchBoxPlaceholder="Search"
          handleRowClick={handleRowClick}
          handleCheckItem={setAddedMediaItems}
          selectedCheckList={addedMediaItems}
          customCellObjectId="user_id"
          AddButtonInner={addedMediaItems.length > 0 ? true : false}
          addLabelInner="Delete Permanently"
          onClickInnerButton={(e) => deleteMultipleMediaItemsHandler()}
          InnerButtonColor="#E0EAFB"
          InnerButtonTextColor="#E00000"
        />
      </div>
      {/* <div className="TableSection">
        <button onClick={() => handleDrawerOpenSelectMedia()}>Select Media</button>
      </div> */}
      <SideDrawer
        title="Media Library"
        ButtonLabel="Add"
        //clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSelectMedia}
        handleDrawerClose={handleDrawerCloseSelectMedia}
        FooterWithoutTab={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        clickButtonLeft={handleDrawerOpenNewMedia}
        body={<MediaLibrarySelectMedia />}
      />
      <SideDrawer
        title="Upload New Media"
        ButtonLabel={uploadButtonText}
        clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openNewMedia}
        handleDrawerClose={
          selectedFile || imageName?.length > 0
            ? handleOpenDelete
            : handleDrawerCloseNewMedia
        }
        CheckboxLabel="Add another after saving"
        Checkbox={true}
        onCheckboxClicked={handleCheckboxClicked}
        FooterWithoutTab={false}
        handleTabChange={handleTabChange}
        //=== Tabs Codes ===//
        TabsStatus={true}
        TabsPannelStatus={true}
        panelTab={panelTab}
        tabData={tabData}
        setActiveDrawer={setActiveDrawer}
      />
      <div>
        <SideDrawer
          title="Edit Media"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          deleteText="Delete Permanently"
          onClickDelete={deleteConfirm}
          clickSaveButton={updateMedia}
          DeleteButton={true}
          sideOpen="right"
          open={openEditMedia}
          handleDrawerClose={handleDrawerCloseEditMedia}
          CheckboxLabel="Add another after saving"
          FooterWithoutTab={true}
          body={
            <EditMedia
              file_name={file_name}
              file_type={file_type}
              file_size={file_size}
              file_url={file_url}
              file_id={file_id}
              created_at={created_at}
              medianame={medianame}
              setMediaName={setMediaName}
              fileMeta={fileMeta}
              isPrivate={isPrivate}
            />
          }
        />
      </div>
      <DraggableDialog
        title="Delete this media?"
        body={
          <div>
            Are you sure you want to delete this?<br></br> Once you delete it,
            you can't get it back.
          </div>
        }
        openDialog={deleteMedia}
        handleCloseDialog={handleClose}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Delete"
        width="460px"
        textAlign="center"
        handleConfirmCloseDialog={deleteMediaItem}
      />
      <DraggableDialog
        title="Delete these media?"
        body={
          <div>
            Are you sure you want to delete these?<br></br> Once you delete
            them, you can't get them back.
          </div>
        }
        openDialog={deleteMultiMedia}
        handleCloseDialog={handleMultiClose}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Delete"
        width="460px"
        textAlign="center"
        handleConfirmCloseDialog={deleteMultiMediaItem}
      />
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
        title="Cancel upload"
        body={<div>Are you sure you want to cancel this upload?</div>}
        ModalFooter={true}
        actionButton="Delete"
      />{" "}
    </>
  );
}

export default MediaLibraryTableView;
