import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { Button, Chip } from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import moment from "moment";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

// ================= styles ====================//

const verifyContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const ShowButtonStyle = { color: "#004FE0", textTransform: "capitalize" };

const GroupChipStyle = {
  marginRight: "8px",
  "& .MuiChip-label": {
    marginTop: "2px",
    fontWeight: "500",
  },
};

const PendingChipStyle = {
  padding: "0 4px",
  background: "#FFECE0",
  color: "#FF6200",
  "& .MuiChip-label": {
    marginTop: "2px",
  },
};
const VerifyChipStyle = {
  padding: "0 4px",
  background: "#E1F2E8",
  color: "#0B9444",
  "& .MuiChip-label": {
    marginTop: "2px",
  },
};
// ================= Chip style ====================//

function Overview(props) {
  const { studentData, timeZones } = props;
  const [viewPhoneNumber, setViewPhoneNumber] = useState(true);

  function createData(label, data) {
    return { label, data };
  }

  const rows = [
    createData(
      "Full Name",
      studentData.firstName ? (
        <span>
          {studentData.firstName} {studentData.lastName}
        </span>
      ) : (
        ""
      )
    ),
    createData(
      "Gender",
      studentData.gender ? <span>{studentData.gender}</span> : ""
    ),
    createData(
      "Birthday",
      studentData.birthdate ? <span>{studentData.birthdate}</span> : ""
    ),
    createData(
      "Address",
      studentData.address ? <span>{studentData.address}</span> : ""
    ),
    createData(
      "Current Grade",
      studentData.currentGrade ? <span>{studentData.currentGrade}</span> : ""
    ),
    createData(
      "Age Group",
      studentData.ageGroup ? <span>{studentData.ageGroup}</span> : ""
    ),
    createData(
      "Timezone",
      studentData.timezone ? <span>{studentData.timezone}</span> : ""
    ),
  ];

  //============================ calculation of age group ===================================//
  const [userAgeGroup, setUserAgeGroup] = useState(null);
  const [userBirthday, setUserBirthday] = useState(null);

  // Function to calculate age group based on age
  const calculateAgeGroup = (age) => {
    if (age >= 5 && age <= 9) return "05-09 years";
    else if (age >= 10 && age <= 14) return "10-14 years";
    else if (age >= 15 && age <= 19) return "15-19 years";
    else if (age >= 20 && age <= 24) return "20-24 years";
    else if (age >= 25 && age <= 29) return "25-29 years";
    else if (age >= 30 && age <= 34) return "30-34 years";
    else if (age >= 35 && age <= 39) return "35-39 years";
    else if (age >= 40 && age <= 44) return "40-44 years";
    else if (age >= 45 && age <= 49) return "45-49 years";
    else if (age >= 50 && age <= 54) return "50-54 years";
    else if (age >= 55 && age <= 59) return "55-59 years";
    else if (age >= 60 && age <= 64) return "60-64 years";
    else if (age >= 65 && age <= 69) return "65-69 years";
    else if (age >= 70 && age <= 74) return "70-74 years";
    else if (age >= 75 && age <= 79) return "75-79 years";
    else if (age >= 80 && age <= 84) return "80-84 years";
    else if (age >= 85 && age <= 89) return "85-89 years";
    else return "90 years and over";
  };

  // Function to calculate age based on birthday (ignoring day)
  const calculateAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const convertBirthdate = (birthdate) => {
    if (!birthdate) return null;
    const [month, year] = birthdate?.split("-");

    const monthNumber = new Date(`${month} 1, 2022`).getMonth() + 1; // Adding any year and date
    const formattedBirthdate = `${year}-${monthNumber
      .toString()
      .padStart(2, "0")}`;

    return formattedBirthdate;
  };

  useEffect(() => {
    const formattedBirthdate = convertBirthdate(studentData?.birthdate);
    const birthday = new Date(studentData?.birthdate);
    setUserBirthday(birthday);

    // Calculate age group based on birthday
    const age = calculateAge(formattedBirthdate); // Use formatted birthdate for age calculation
    const ageGroup = calculateAgeGroup(age);
    setUserAgeGroup(ageGroup);
  }, [studentData?.birthdate]);
  //============================ calculation of age group ===================================//

  const formattedTimeZone = (timezone) => {
    const findData = timeZones?.find((data) => data?.timezone === timezone);
    // console.log(fieldName, fieldData, 345, findData);
    let formattedTimeZone = `${timezone} | GMT ${findData?.gmtOffset}`;
    return formattedTimeZone;
  };

  return (
    <div className="TableContainer oddEvenTable">
      <div className="TableInnerHeader">
        <h3>Profile</h3>
      </div>
      <Divider />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="personal info">
          <TableBody>
            {/* ============ previous functional table =============== */}
            {/* {rows.map((row) => (
              <TableRow
                key={row.label}
                hover={true}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="left">{row.data}</TableCell>
              </TableRow>
            ))} */}
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Joined
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {`${moment(studentData.created_at)
                  .tz("America/New_York")
                  .format("DD-MMM-YYYY, h:mm A")} (EST)` || (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Country
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {studentData.countryName || (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                State/Province/District
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {studentData.sate || (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Gender
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {studentData.gender || (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Age Group
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {studentData?.birthdate ? (
                  userAgeGroup
                ) : (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}
              </StyledTableCell>
            </StyledTableRow>

            {/* <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Groups
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                <Chip
                  label="Quran Level 1 - Qaida Nuraniyah"
                  sx={GroupChipStyle}
                />
                <Chip label="Team 1" sx={GroupChipStyle} />
               
              </StyledTableCell>
            </StyledTableRow> */}
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Email address
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {studentData.email || (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}{" "}
                &nbsp;
                {studentData.email_verified_at !== null ? (
                  <>
                    <Chip
                      color="error"
                      size="small"
                      variant="filled"
                      label="Verified"
                      icon={<DoneRoundedIcon fontSize="small" />}
                      sx={VerifyChipStyle}
                    />
                    &nbsp;
                  </>
                ) : (
                  <Chip
                    color="error"
                    size="small"
                    variant="filled"
                    label="Pending"
                    icon={<ReportProblemRoundedIcon fontSize="small" />}
                    sx={PendingChipStyle}
                  />
                )}
                {/* <span style={{ color: "#A4A7B0" }}>No data available</span> */}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Phone number
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                <div style={verifyContainerStyle}>
                  {studentData.phone_number_formatted ? (
                    <>
                      <div>
                        {" "}
                        {viewPhoneNumber
                          ? `Ends with ${
                              studentData.phone_number_formatted
                                ? studentData.phone_number_formatted?.slice(-4)
                                : "1234"
                            }`
                          : studentData.phone_number_formatted}
                        &nbsp;
                        {studentData.phone_verified_at !== null ? (
                          <>
                            <Chip
                              color="error"
                              size="small"
                              variant="filled"
                              label="Verified"
                              icon={<DoneRoundedIcon fontSize="small" />}
                              sx={VerifyChipStyle}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <Chip
                            color="error"
                            size="small"
                            variant="filled"
                            label="Pending"
                            icon={<ReportProblemRoundedIcon fontSize="small" />}
                            sx={PendingChipStyle}
                          />
                        )}
                      </div>
                      <div>
                        <Button
                          variant="text"
                          sx={ShowButtonStyle}
                          onClick={() => setViewPhoneNumber(!viewPhoneNumber)}
                        >
                          {viewPhoneNumber ? "Show Number" : "Hide Number"}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <span style={{ color: "#A4A7B0" }}>No data available</span>
                  )}
                </div>
                {/* <span style={{ color: "#A4A7B0" }}>No data available</span> */}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" className="TableLabel">
                Timezone
              </StyledTableCell>
              <StyledTableCell align="left" className="TableInfo">
                {studentData?.timezone ? (
                  formattedTimeZone(studentData?.timezone)
                ) : (
                  <span style={{ color: "#A4A7B0" }}>No data available</span>
                )}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Overview;
