import React, { useState } from "react";
import AlertMessageTwo from '../../../../components/Form/AlrtMessage/AlertMessageTwo';



function InfoAlert(props) {

  return (
    <AlertMessageTwo
      Message={<span>Learners can't see this track yet. <br/>Please fill all the required fields and change the status to "Publish" from</span>}
      linkText="track settings"
      onClick={(e) => props.setCurrentTab(3)}
    />
  );
}


export default InfoAlert;

