import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import AWS from "aws-sdk";

import "./FileUplaoder.css";
import "./FileUplaoderField.css";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
//import deletebuttononhover icon from mui
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CustomizedSnackbar from "../../../hooks/Snackbar";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./MediaFileUploader.module.css";
import { toast } from "react-toastify";
const baseUrl =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

const FileUplaoder = forwardRef((props, ref) => {
  const {
    filePath,
    label,
    validation,
    uploadButton,
    file,
    fileType,
    showThumb,
    onUpload,
  } = props;

  useImperativeHandle(ref, () => ({
    clickButton: () => deleteUploadedFile(),
  }));

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [subDomain, setSubDomain] = useState("");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [preview, setPreview] = useState(null);

  const [isImage, setIsImage] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [uploaded, setUploaded] = useState(false);
  const [getUrl, setGetUrl] = useState(null);
  const [filePathData, setFilePathData] = useState(null);

  useEffect(() => {
    const localSubDomain = localStorage.getItem("subDomain");
    setSubDomain(localSubDomain);
  }, []);

  //======================== get the presignedUrl for uploading data in the s3 ==============//
  const getFileUploadPresignedUrl = async (fileData) => {
    let data = {
      file_type: fileData?.type.startsWith("video/")
        ? "videos"
        : fileData?.type.startsWith("image/")
        ? "images"
        : "documents",
      file_visibility: "Public",
      key: fileData?.name?.replace(/\s+/g, "-"),
    };
    const result = await axios.post(
      `${baseUrl}/getFileUploadPresignedUrl`,
      data
    );

    if (result?.status === 200) {
      setGetUrl(result?.data?.url);
      setFilePathData(result?.data?.key?.replace("\\", ""));
    }
  };
  //======================== get the presignedUrl for uploading data in the s3 ==============//

  const handleFileInput = (e) => {
    const item = e.target.files[0];
    const maxSize = Number(10 * 1024 * 1024); // 15MB in bytes
    if (
      !(fileType?.startsWith("image/") === item?.type?.startsWith("image/"))
    ) {
      setSnackbarMessage("Only image files are accepted.");
      setSnackbarSeverity("FieldError");
      setSelectedFile(null);
      fileInputRef.current.value = "";
      return false;
    }
    if (Number(item?.size) > maxSize) {
      setSnackbarMessage(
        "The selected file exceeds the max limit of 10 MB(10,000KB)"
      );
      setSnackbarSeverity("FieldError");
      setShowSnackbar(true);
      setSelectedFile(null);
      return "";
    } else {
      setShowSnackbar(false);
      setSnackbarMessage("");
      setSnackbarSeverity("");
    }

    setSelectedFile(e.target.files[0]);

    if (props.setSelectedFile) {
      props.setSelectedFile(e.target.files[0]);
    }

    console.log(e.target.files[0]);
    // return '' ;
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = reader.result;
      const isImage = result?.startsWith("data:image/");

      if (isImage) {
        const img = new Image();
        setIsImage(true);

        img.onload = function () {
          setWidth(img.naturalWidth);
          setHeight(img.naturalHeight);

          //check if props.setWidth is defined then set width
          if (props.setWidth) {
            props.setWidth(img.naturalWidth);
          }

          //check if props.setHeight is defined then set height
          if (props.setHeight) {
            props.setHeight(img.naturalHeight);
          }
        };

        img.src = event.target.result;
      }
    };

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    reader?.readAsDataURL(e?.target?.files[0]);
  };
  //=========================  upload the file to the s3  ====================================//
  useEffect(() => {
    if (selectedFile) {
      getFileUploadPresignedUrl(selectedFile);
    }
  }, [selectedFile]);
  useEffect(() => {
    if (file) {
      setFilePathData(file);
    }
  }, [file]);
  useEffect(() => {
    if (selectedFile && getUrl && filePathData) {
      uploadFile();
      setUploaded(true);
    }
  }, [getUrl, selectedFile, filePathData]);
  const uploadFileWithProgress = (url, file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", url, true);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          console.log(progress, 404, event);
          setProgress(progress);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => reject(new Error("Upload failed"));

      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  };

  const uploadFile = async () => {
    //set is_private = 0 because visibility is Public
    let is_private = 0;
    if (!selectedFile) return;

    const presignedUrl = getUrl;
    const formattedUrl = presignedUrl?.replace("\\", "");

    try {
      await uploadFileWithProgress(formattedUrl, selectedFile);
      props.onUpload(
        filePathData,
        selectedFile?.name,
        Number(selectedFile?.size) / 1024,
        selectedFile?.type,
        width,
        height,
        is_private
      );
      setGetUrl(null);
      setUploaded(false);
      setShowSnackbar(false);
      setProgress(0);
      setSnackbarMessage("");
      setSnackbarSeverity("");
    } catch (error) {
      console.error("Error uploading file", error, 346);
    }
  };

  //=========================  upload the file to the s3  ====================================//

  // const uploadFile = (file) => {
  //   const nameMandatory = props.nameMandatory ? props.nameMandatory : false;

  //   console.log(nameMandatory);

  //   if (nameMandatory) {
  //     //if props.mediaName is empty then return
  //     if (props?.mediaName?.length === 0) {
  //       alert("Please enter media name");
  //       return "";
  //     }
  //   }

  //   let objFilePath = subDomain ? S3_DIRECTORY + `${subDomain}/` : S3_DIRECTORY;
  //   // objFilePath += filePath + randString(5) + "-" + file.name;
  //   objFilePath += filePath + file.name;

  //   console.log(file, 342);

  //   // return '';

  //   const params = {
  //     ACL: "public-read",
  //     Body: file,
  //     Bucket: S3_BUCKET,
  //     Key: objFilePath,
  //   };

  //   s3Bucket
  //     .putObject(params)
  //     .on("httpUploadProgress", (evt) => {
  //       setProgress(Math.round((evt.loaded / evt.total) * 100));
  //     })
  //     .send((err) => {
  //       if (err) console.log(err);
  //       props.onUpload(
  //         REACT_APP_CDN_URL + objFilePath,
  //         file.name,
  //         Number(file.size) / 1024,
  //         file.type,
  //         width,
  //         height
  //       );
  //     });
  // };

  const handleFileDelete = async () => {
    if (filePathData) {
      let data = {
        key: filePathData,
      };
      try {
        const response = await axios.delete(`${baseUrl}/deleteMediaFromS3`, {
          data: data,
        });
        if (response?.status === 200) {
          setGetUrl(null);
          setFilePathData(null);
          setUploaded(false);
          props.onUpload("");
        }
      } catch (error) {
        console.error("Error uploading file", error, 346);
      }
    }
    // console.log(filePath);
    // filePath = filePath.replace(S3_BASE_URL, "");
    // const params = {
    //   Bucket: S3_BUCKET,
    //   Key: filePath,
    // };
    // s3Bucket.deleteObject(params, function (deleteErr, data) {
    //   if (deleteErr) {
    //     console.log("Error: " + deleteErr, filePath);
    //   } else {
    //     console.log("Successfully deleted S3 object", filePath);
    //     props.onUpload("");
    //   }
    // });
    setSelectedFile(null);
  };

  const deleteUploadedFile = () => {
    //delete from local
    fileInputRef.current.value = "";
    setSelectedFile(null);
  };

  return (
    <div className="TextField">
      <div className="FieldLabelUpload">
        {label} {validation && <span>*</span>}
      </div>
      <div>
        {file ? (
          " "
        ) : (
          <div className="FileUplaoderFieldContainer">
            <div className="FileUplaoderField">
              <div className={props.FieldError + " " + snackbarSeverity}>
                <input
                  className="uploadfield"
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                  accept={fileType}
                  // accept={
                  //   fileType == "image"
                  //     ? "image/png, image/jpeg,image/webp, application/pdf"
                  //     : "*"
                  // }
                />
                {uploaded && (
                  <div className={styles.MediaFileUplaoderProgress}>
                    <LinearProgress variant="determinate" value={progress} />
                  </div>
                )}
                <p className="HelperText" style={{ marginBottom: "10px" }}>
                  {snackbarMessage ? snackbarMessage : props.helpertext}
                </p>
              </div>
              <DeleteForeverRoundedIcon
                //if no file selected then don't show delete icon
                style={{ display: selectedFile ? "block" : "none" }}
                className="DeleteIcon"
                onClick={() => deleteUploadedFile()}
              />
            </div>
            {/* {progress > 0 && progress < 100 && (
              <CircularProgress variant="determinate" value={progress} />
            )} */}

            <Button
              variant="contained"
              ref={ref}
              onClick={() => uploadFile(selectedFile)}
              disabled={progress > 0 && progress < 100 ? true : false}
              //if props.showUploadButton is false then don't show upload button
              style={{ display: props.showUploadButton ? "block" : "none" }}
            >
              {uploadButton}
            </Button>
          </div>
        )}

        {file ? (
          <div className="FileUplaoder">
            <div className="FileUplaoderInfo">
              {fileType == "image" && showThumb ? (
                <img src={file} />
              ) : (
                <span>
                  <AttachmentIcon />
                </span>
              )}
              <span> {file.replace(/^.*[\\\/]/, "")} </span>
            </div>
            <CloseRoundedIcon
              className="CloseIcon"
              onClick={() => handleFileDelete()}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default FileUplaoder;
