import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Inprogress from './Inprogress';
import Upcoming from './Upcoming';
import Completed from './Completed';







function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Courses = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='CardSection'>
      <div className="CardSectionTitle">Courses <Button className='SeaAllButton' variant="text">See all</Button></div>

      <div className="StudentTableContainer">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={<div className='tableLabelContainer'>In progress</div>} {...a11yProps(0)} />
          <Tab label={<div className='tableLabelContainer'>Upcoming</div>} {...a11yProps(1)} />
          <Tab label={<div className='tableLabelContainer'>Completed</div>} {...a11yProps(1)} />
        </Tabs>
      </div>

      <TabPanel value={value} index={0}>
        <Inprogress />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Upcoming />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Completed />
      </TabPanel>


      


      


    </div>


  );
}

export default Courses