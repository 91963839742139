import React from "react";
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import ListingCard from '../../../../components/ListingCard/ListingCard';





const RecentEnrollment = (props) => {

  const cardData = [
    {
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      chipLabel: "Pending",
      color: "#FA0",
    },
    {
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      chipLabel: "Paid",
      color: "#0B9444",
    },
    {
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      chipLabel: "Free",
      color: "#004FE0",
    },
    {
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      chipLabel: "Pending",
      color: "#FA0",
    },
    {
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      chipLabel: "Paid",
      color: "#0B9444",
    },
    {
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      chipLabel: "Free",
      color: "#004FE0",
    },
  ]




  return (
    <div className='CardSection'>
      <div className="CardSectionTitle">Recent enrollment <Button className='SeaAllButton' variant="text">See all</Button></div>
      <div className="Scroll-Y" style={{height: "324px",}}>
        <Grid container spacing={1}>
          {cardData.map((item, i) => (
            <Grid item sx={12} sm={12}>
              <ListingCard
                LineStatus={true}
                Title={item.title}
                Description={item.description}
                ChipStatus={true}
                StatusColor={item.color}
                ChipLabel={item.chipLabel}
              />
            </Grid>
          ))}

        </Grid>
      </div>
    </div>
  );
};

export default RecentEnrollment;
