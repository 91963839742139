import React, { useEffect, useRef, useState } from "react";
import "../assets/css/ChatRoom.css";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";

import Avatar from "@mui/material/Avatar";
import img from "../assets/images/user-img.jpg";

import TextField from "@mui/material/TextField";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import axios from "axios";
import moment from "moment-timezone";
import SimpleBackdrop from "../../../../components/Form/Backdrop/SimpleBackdrop";
import LinkIcon from "@mui/icons-material/Link";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
function Chat({ item, formattedText }) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = localStorage.getItem("orgId");
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const sendIntialObject = {
    conversation_id: item?.conversation_id,
    sender_id: userData?.id,
    message_text: "",
    attachments: [],
  };
  const [sendMessage, setSendMessage] = useState(sendIntialObject);
  const [getMeassageData, setGetMeassageData] = useState([]);
  const [conversation_info, setConversation_info] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const lastMessageRef = useRef();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //======================================= get meassages ==================================//

  useEffect(() => {
    // Function to make the AJAX call
    const getMeassages = async () => {
      let data = {
        conversation_id: item?.conversation_id,
        user_id: userData?.id,
        message_from: 0, //it defines from which message we want our conversation it will work for scroll up operation
      };
      try {
        // Your AJAX call logic here
        const response = await axios.post(
          `${baseUrl}/orgPortal/getSingleConversation`,
          data
        );

        if (response?.status === 200) {
          setGetMeassageData(response?.data?.conversationMessages);
          setConversation_info(response?.data?.conversation?.conversation_info);
        } else {
          console.error("Something went wrong");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // // Cleanup the interval when the component unmounts
    // return () => clearInterval(intervalId);

    // Set up an interval to make the AJAX call every 5 seconds

    const intervalId = setInterval(getMeassages, 4000);
    // const intervalId = setInterval(getMeassages, 60000);

    return () => clearInterval(intervalId);
  }, [item?.conversation_id, sendMessage?.message_text]);
  //======================================= get meassages ==================================//
  useEffect(() => {
    if (getMeassageData?.length === 0) {
      setOpenBackdrop(true);
    } else {
      setOpenBackdrop(false);
    }
    // console.log(conversation_info, 345);
  }, [getMeassageData]);

  //============================== send converstations ======================================//
  const handleChange = (e) => {
    setSendMessage({ ...sendMessage, message_text: e.target.value });
  };
  const handleSendConversation = () => {
    let data = { ...sendMessage };
    axios
      .post(
        `${baseUrl}/orgPortal/${
          orgId
            ? "sendTeacherConversationMessage"
            : "sendStudentConversationMessage"
        }`,
        data
      )
      .then((result) => {
        if (result?.status === 200) {
          setSendMessage(sendIntialObject);
        } else {
          console.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //============================== send converstations ======================================//

  //==================== fomatted messages by dates ===========================================//
  // const lastMessageRef = useRef();

  // useEffect(() => {
  //   setTimeout(() => {
  //     lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  //   }, 100);
  // }, [getMeassageData]);

  const groupByMessage = (messages) => {
    const groupByMessages = {};
    messages?.forEach((message) => {
      const date = moment(message.created_at).format("DD-MMM-YYYY");
      if (!groupByMessages[date]) {
        groupByMessages[date] = [];
      }
      groupByMessages[date].push(message);
    });

    return groupByMessages;
  };
  const senderName = (id) => {
    let senderData = conversation_info?.participants?.find(
      (user) => user?.user_id === id
    );
    return `${senderData?.user?.first_name} ${senderData?.user?.last_name}`;
  };
  const senderImg = (id) => {
    let senderData = conversation_info?.participants?.find(
      (user) => user?.user_id === id
    );

    return senderData?.user?.image;
  };

  const handleDownload = (name, data) => {
    if (data) {
      const link = document.createElement("a");
      link.href = data;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("File link not available");
      // alert();
    }
  };
  const renderGroupedMessages = (groupedMessages) => {
    return Object.keys(groupedMessages).map((date) => (
      <div key={date}>
        <div className="InboxChatDate">
          <span>{date}</span>
        </div>
        {groupedMessages[date].map((message, index) => (
          <div
            key={message?.id}
            className={`InboxChatBoxContainer ${
              message.sender_id === userData?.id ? "yourChat" : ""
            }`}
            // ref={lastMessageRef}
          >
            {message.sender_id !== userData?.id && (
              <div className="InboxChatAvatar">
                <Avatar alt="Remy Sharp" src={senderImg(message.sender_id)} />
              </div>
            )}

            {/* Render your chat box here */}
            <div className="InboxChatingboxListing">
              <div className="InboxChatDateTime">
                {message.sender_id !== userData?.id
                  ? senderName(message.sender_id)
                  : "You"}
                <FiberManualRecordRoundedIcon />{" "}
                {message.sender_id !== userData?.id
                  ? moment(message.created_at).format("h:mm A")
                  : moment(message.created_at).format("DD-MMM-YYYY, h:mm A")}
              </div>
              <div className="InboxChatingboxContainer">
                <div>
                  <div className="InboxChatingbox">
                    <div
                      dangerouslySetInnerHTML={{ __html: message.message_text }}
                    />
                    {/* {formattedText(message.message_text)} */}
                  </div>
                  {message?.attachments?.map((attachment, index) => (
                    <div className="InboxAttachListing" key={index}>
                      <div className="InboxAttachListingLeft">
                        <div className="InboxAttachListingContent">
                          <span>
                            {attachment?.attachment_type === "media" ? (
                              <AttachFileIcon />
                            ) : (
                              <LinkIcon />
                            )}
                          </span>
                          <div className="InboxAttachListingInfo">
                            <h2>{attachment?.attachment_name}</h2>
                            {attachment?.attachment_type === "media" ? (
                              <p>
                                {(attachment?.attachment_size / 1024)?.toFixed(
                                  2
                                )}{" "}
                                MB . {attachment?.attachment_file_name}
                              </p>
                            ) : (
                              <p>
                                <a href={attachment?.attachment_link}>
                                  {attachment?.attachment_link}
                                </a>{" "}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="InboxAttachListingRight">
                        <Button
                          className="AttachDeleteButton"
                          variant="text"
                          onClick={() =>
                            handleDownload(
                              attachment?.attachment_name,
                              attachment.attachment_link
                            )
                          }
                        >
                          <FileDownloadOutlinedIcon />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };
  //==================== fomatted messages by dates ===========================================//
  return (
    <div className="InboxChatContainer">
      <SimpleBackdrop open={openBackdrop} />
      {/* ======================== functionality ======================================== */}

      {getMeassageData?.length > 0 &&
        renderGroupedMessages(groupByMessage(getMeassageData))}

      {/* ======================== functionality ======================================== */}

      <div className="InboxChatForm">
        <div className="InboxChatFormInner">
          {item?.conversation?.conversation_type !== "message" ? (
            <>
              <p sx={{ color: "gray" }}>You can’t reply this conversation</p>
            </>
          ) : (
            <>
              <div className="ChatFieldBox">
                {/* <Button variant="text" className="InboxChatFormAddButton">
                  <AddIcon />
                </Button> */}

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Message"
                  value={sendMessage?.message_text}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="SendButtonContainer Rounded">
                <Button
                  className="SendButton ChatDesktopView"
                  variant="text"
                  onClick={handleSendConversation}
                >
                  Send
                </Button>

                {/* <Button
               id="basic-button"
               aria-controls={open ? "basic-menu" : undefined}
               aria-haspopup="true"
               aria-expanded={open ? "true" : undefined}
               onClick={handleClick}
               className="DropdownButton"
             >
               <span className="ChatActionsIcon ChatDesktopView">
                 <ArrowDropDownIcon />
               </span>
               <span className="ChatActionsIcon ChatMobileView">
                 <SendRoundedIcon />
               </span>
             </Button>
             <Menu
               id="basic-menu"
               anchorEl={anchorEl}
               open={open}
               onClose={handleClose}
               MenuListProps={{
                 "aria-labelledby": "basic-button",
               }}
             >
               <span onClick={handleClose}>
                 <MenuItem className="InboxAddNewListing">
                   <EditRoundedIcon /> Edit
                 </MenuItem>
                 <MenuItem className="InboxAddNewListing">
                   <ContentCopyRoundedIcon /> Copy
                 </MenuItem>
                 <MenuItem className="InboxAddNewListing">
                   <ReplyRoundedIcon /> Reply
                 </MenuItem>
               </span>
             </Menu> */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chat;
