import React, { useState } from "react";

import CourseSession from "./CourseSession";
import CourseResourse from "./CourseResourse";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

function CourseModule(props) {
  const {
    courseModuleData,
    index,
    showZoomModal,
    orgPortal,
    changeSessionStatus,
    startStopSessionHandler,
  } = props;

  const [moduleExpanded, setModuleExpanded] = React.useState("");

  const handleChangeModulePannel = (panel) => (event, isExpanded) => {
    setModuleExpanded(isExpanded ? panel : false);
  };

  console.log(courseModuleData);

  return (
    <Accordion
      expanded={moduleExpanded === `module-${index}`}
      onChange={handleChangeModulePannel(`module-${index}`)}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardRoundedIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <div className="accTitleSection">
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {courseModuleData.title}
            <p className="ItemCount">
              {typeof courseModuleData?.courseSessions?.length !==
                "undefined" && (
                <span>
                  {" "}
                  {courseModuleData.courseSessions.length}{" "}
                  {courseModuleData.courseSessions.length > 1
                    ? "sessions"
                    : "session"}{" "}
                  <FiberManualRecordRoundedIcon className="SmallDot" />
                </span>
              )}
              {typeof courseModuleData?.courseResourses?.length !==
                "undefined" && (
                <span>
                  {" "}
                  {courseModuleData.courseResourses.length}{" "}
                  {courseModuleData.courseResourses.length > 1
                    ? "resources"
                    : "resource"}{" "}
                </span>
              )}
            </p>
          </Typography>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div className="AccrodionInner">
          <CourseSession
            modIndex={index}
            courseSessions={courseModuleData.courseSessions}
            sessionCompleteCounter={courseModuleData.sessionCompleteCounter}
            totalSession={courseModuleData.totalSession}
            showZoomModal={showZoomModal}
            orgPortal={orgPortal}
            changeSessionStatus={changeSessionStatus}
            startStopSessionHandler={startStopSessionHandler}
          />
          <CourseResourse
            modIndex={index}
            courseResourses={courseModuleData.courseResourses}
            courseSessions={courseModuleData.courseSessions}
            moduleTitle={courseModuleData.title}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default CourseModule;
