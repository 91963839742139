import * as React from "react";
import { CgSearch } from 'react-icons/cg';
import "./SearchBox.css";

export default function SearchBox(props) {
  return (



    <div className='SearchBox'>
      <input
        className='SearchBoxField'
        type='text'
        placeholder={props.placeholder}
        value={props.searchText}
        onChange={props.handleSearchText}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.handleSearch()
          }
        }}
      />

      <div
        className='SearchBoxButton'
        onClick={props.handleSearch}
        onMouseDown={props.handleSearch}
        edge="end"
      >
        <CgSearch />
      </div>

    </div>


























    // <div className="SearchBox">
    //   <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
    //     <OutlinedInput
    //       id="outlined-adornment-password"
    //       placeholder={props.placeholder}
    //       value={props.searchText}
    //       type="text"
    //       onChange={props.handleSearchText}
    //       endAdornment={
    //         <InputAdornment position="end">
    //           <IconButton
    //             aria-label="toggle password visibility"
    //             onClick={props.handleSearch}
    //             // onMouseDown={props.handleSearch}
    //             edge="end"
    //           >
    //             {props.searchText ? <ClearIcon /> : <SearchIcon />}
    //           </IconButton>
    //         </InputAdornment>
    //       }
    //     />
    //   </FormControl>
    // </div>
  );
}
