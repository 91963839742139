import React from "react";
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import ListingCard from '../../../../components/ListingCard/ListingCard';
import Img from '../../../../assets/imgs/CourseIcon.png';




const Following = (props) => {

  const cardData = [
    {
      img:Img,
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      color: "#FA0",
      buttonLabel:"Following",
      link:"#",
    },
    {
      img:Img,
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      color: "#FA0",
      buttonLabel:"Following",
      link:"#",
    },
    {
      img:Img,
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      color: "#FA0",
      buttonLabel:"Following",
      link:"#",
    },
    {
      img:Img,
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      color: "#FA0",
      buttonLabel:"Following",
      link:"#",
    },
    {
      img:Img,
      title: "Imam Mohammad Ibn Saud Islamic Univers...",
      description: "What is islamic finance?",
      color: "#FA0",
      buttonLabel:"Following",
      link:"#",
    },
  ]




  return (
    <div className='CardSection'>
      <div className="CardSectionTitle">Following <Button className='SeaAllButton' variant="text">See all</Button></div>
      <div className="Scroll-Y" style={{ height: "324px", }}>
        <Grid container spacing={1}>
          {cardData.map((item, i) => (
            <Grid item sx={12} sm={12}>
              <ListingCard
                IconStatus={true}
                Icon={item.img}
                Title={item.title}
                Description={item.description}
                ButtonStatus={true}
                ButttonLabel={item.buttonLabel}
                Link={item.link}
              />
            </Grid>
          ))}

        </Grid>
      </div>
    </div>
  );
};

export default Following;
