import React, { useState, useRef, useEffect } from "react";
import SideDrawer from "../SideDrawer/SideDrawer";
import CustomizedSnackbar from "../../hooks/Snackbar";
import UploadFromDevice from "./UploadFromDevice";
import UploadFromYoutube from "./UploadFromYoutube";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import YouTubeIcon from "@material-ui/icons/YouTube";
import SimpleBackdrop from "../Form/Backdrop/SimpleBackdrop";
import axios from "axios";
import { onFileUpload } from "./Functions";

import "./MediaLibrary.css";
import DraggableDialog from "../../hooks/Dialog";

function Externaluse(props) {
  const childRef = useRef(null);

  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV == "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));

  const [uploadButtonText, setUploadButtonText] = useState("Upload");
  const [openNewMedia, setOpenNewMedia] = useState(props.open);
  const [videoName, setVideoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  const [addNewItem, setAddNewItem] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [filePathData, setFilePathData] = useState(null);
  const [imageNameError, setImageNameError] = useState(false);
  const [isfileSelected, setFileSelected] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [imaneNameErrorMessage, setImageNameErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeDrawer, setActiveDrawer] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const [videoErrorState, setVideoErrorState] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoErros, setVideoErros] = useState({
    name: "",
    url: "",
  });

  const fileSelected = (status) => {
    setFileSelected(status);
  };

  const tabData = [
    {
      lebel: "Upload from device",
      icon: <InsertDriveFileIcon />,
    },

    !selectedFile && {
      lebel: "YouTube video",
      icon: <YouTubeIcon />,
    },
  ];

  const handleTabChange = (event) => {
    if (event == 1) {
      setUploadButtonText("Import from YouTube");
      setVideoUpload(true);
    } else {
      setUploadButtonText("Upload Image");
      setVideoUpload(false);
    }
  };
  const handleCheckboxClicked = (event) => {
    console.log(event.target.checked);
    setAddNewItem(event.target.checked);
  };
  const handleSnackbarOpen = (message, type) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
  };
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const [openEditMedia, setOpenEditMedia] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmCloseDialog = async () => {
    if (isfileSelected && filePathData) {
      let data = {
        key: filePathData,
      };
      try {
        const response = await axios.delete(`${baseUrl}/deleteMediaFromS3`, {
          data: data,
        });
        if (response?.status === 200) {
          setFilePathData(null);
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Error uploading file", error, 346);
      }
    }
    setFileSelected(null);
    handleDrawerCloseNewMedia();
    setOpenDialog(false);
  };
  const handleDrawerCloseNewMedia = () => {
    setVideoErros({
      name: "",
      url: "",
    });
    setVideoName("");
    setVideoErrorState(false);
    setImageNameErrorMessage("");
    setFileUploadError(false);
    setImageNameError(false);
    setShowSnackbar(false);
    setSnackbarMessage("");
    setSnackbarSeverity("");
    setImageName("");
    setOpenNewMedia(false);
    setErrorState(false);
    setUploaded(false);
    setSelectedFile(null);

    props.handleCloseButton();
  };
  const validateYouTubeUrl = (url) => {
    const youtubePattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/|youtube\.com\/shared\?ci=|youtube\.com\/live\/)([a-zA-Z0-9_-]{11})/;
    return youtubePattern.test(url);
  };

  const validateForm = () => {
    const isYouTubeUrlValid = validateYouTubeUrl(videoUrl);
    let errorObject = { ...videoErros };
    let validationError = true;
    if (videoName === "") {
      validationError = false;
      errorObject.name = "Please enter your video name";
    } else {
      errorObject.name = "";
    }
    if (videoUrl === "") {
      validationError = false;
      errorObject.url = "Please enter your YouTube URL";
    } else if (!isYouTubeUrlValid) {
      validationError = false;
      errorObject.url = "Please enter a valid YouTube URL";
    } else {
      errorObject.url = "";
    }
    setVideoErros({ ...videoErros, ...errorObject });
    return validationError;
  };
  useEffect(() => {
    if (videoErrorState) {
      validateForm();
    }
  }, [videoErrorState, videoName, videoUrl]);
  const clickSaveButton = async () => {
    if (videoUpload) {
      setVideoErrorState(true);
      const validation = validateForm();
      if (!validation) {
        return false;
      } else {
        // save data
        // console.log("save data");
        // console.log(videoName);
        setOpenBackdrop(true);
        //call to api via axios
        const response = await axios.post(baseUrl + "/videoUpload", {
          name: videoName,
          orgId: orgId,
          url: videoUrl,
        });

        console.log(response);

        setOpenBackdrop(false);
        //if response status is 200
        if (response.data.message == "success") {
          setVideoName("");
          setVideoUrl("");
          setVideoErrorState(false);
          resetImageTabData();
          setVideoErros({
            name: "",
            url: "",
          });
          //if addNewitem is true then clear the form
          if (addNewItem) {
            setShowSnackbar(true);
            setSnackbarMessage("Video uploaded successfully.");
            setSnackbarSeverity("success");
          } else {
            handleSnackbarOpen("Video uploaded successfully.", "success");
          }
          //set total items to empty
          // setTotalItems([]);
          //close the drawer
          handleDrawerCloseNewMedia();

          //open the drawer
          props.handleOpenNewMedia(true);
        } else {
          handleSnackbarOpen("Something went wrong.", "error");
        }

        handleSnackbarOpen("Video uploaded successfully", "success");
        setVideoName("");
        setVideoUrl("");
      }
    } else {
      let error = 0;
      if (imageName === "") {
        setImageNameError(true);
        setImageNameErrorMessage("Please enter media name");
        setOpenBackdrop(false);
        error++;
      } else {
        setImageNameError(false);
        setImageNameErrorMessage("");
      }
      if (!selectedFile) {
        setFileUploadError(true);
        setOpenBackdrop(false);
        error++;
      } else {
        setFileUploadError(false);
      }
      if (error > 0) return false;
      if (childRef.current) {
        childRef.current.uploadFile();
      }
    }
  };

  const handlePostUploadAction = () => {
    handleDrawerCloseNewMedia();

    //open the drawer
    props.handleOpenNewMedia(true);

    //set imageName empty
    setImageName("");
    setFilePathData(null);
  };

  //loadingIconStatusChange
  const loadingIconStatusChange = (status) => {
    setOpenBackdrop(status);
  };

  const resetImageTabData = () => {
    setFileUploadError(false);
    // setTotalItems([]);
    setImageName("");
    setFileSelected(null);
    setSelectedFile(null);
  };
  //function to process fileupload
  const processFileUpload = async (
    filePath,
    fileName,
    fileSize,
    fileType,
    width,
    height,
    is_private
  ) => {
    setOpenBackdrop(true);
    const response = await onFileUpload(
      imageName,
      filePath,
      fileName,
      fileSize,
      fileType,
      width,
      height,
      is_private
    );

    if (response) {
      //show snackbar success message
      let message = "Media added";
      let type = "success";

      setShowSnackbar(true);
      setSnackbarMessage(message);
      setSnackbarSeverity(type);
      //set total items to empty
      //   setTotalItems([]);
      //set opennewmedia to false

      resetImageTabData();
      setVideoName("");
      setVideoUrl("");
      if (addNewItem) {
        if (childRef.current) {
          childRef.current.clickButton();
        }
      } else {
        // setOpen(false);
        setOpenNewMedia(false);
        handleDrawerCloseNewMedia();

        //open the drawer
        props.handleOpenNewMedia(true);
      }
    } else {
      handleSnackbarOpen("Something went wrong.", "error");
    }
    setOpenBackdrop(false);
  };

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <UploadFromDevice
          handleTabChange={handleTabChange}
          handleSnackbarOpen={handleSnackbarOpen}
          setVideoName={setVideoName}
          setVideoUrl={setVideoUrl}
          setImageName={setImageName}
          imageName={imageName}
          postUploadAction={handlePostUploadAction}
          loadingIconStatusChange={loadingIconStatusChange}
          fileSelected={fileSelected}
          showFileUploadError={fileUploadError}
          imageErrorMessage={imaneNameErrorMessage}
          showImageNameError={imageNameError}
          onFileUpload={processFileUpload}
          ref={childRef}
          uploaded={uploaded}
          setUploaded={setUploaded}
          filePathData={filePathData}
          setFilePathData={setFilePathData}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setErrorState={setErrorState}
          errorState={errorState}
          progress={progress}
          setProgress={setProgress}
          setFileUploadError={setFileUploadError}
        />
      ),

      SaveButtonLabel: "Save",
      clickSaveButton: clickSaveButton,
      CancelButtonLabel: "Cancel",
      disabled: uploaded || fileUploadError ? true : false,
      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: "Delete Permanently",
      onClickCheckbox: handleCheckboxClicked,
      handleTabChange: handleTabChange,
      //onClickDelete:onClickDelete,
    },

    !selectedFile && {
      id: 1,
      panleBody: (
        <UploadFromYoutube
          setVideoName={setVideoName}
          setVideoUrl={setVideoUrl}
          handleSnackbarOpen={handleSnackbarOpen}
          onCheckboxClicked={handleCheckboxClicked}
          videoName={videoName}
          videoUrl={videoUrl}
          videoErros={videoErros}
          setVideoErros={setVideoErros}
        />
      ),
      SaveButtonLabel: "Import From YouTube",
      clickSaveButton: clickSaveButton,
      cancelButtonLabel: "Cancel",
      videoUrl: videoUrl,
      videoName: videoName,
      CancelButtonLabel: "Cancel",
      handleTabChange: handleTabChange,
      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      onClickCheckbox: handleCheckboxClicked,
    },
  ];
  //closing the loading icon
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <>
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <SideDrawer
        title="Upload New Media"
        ButtonLabel={uploadButtonText}
        clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={props.open}
        handleDrawerClose={
          selectedFile || imageName?.length > 0
            ? handleOpenDelete
            : handleDrawerCloseNewMedia
        }
        // CheckboxLabel="Add another after saving"
        Checkbox={true}
        onCheckboxClicked={handleCheckboxClicked}
        FooterWithoutTab={false}
        handleTabChange={handleTabChange}
        //=== Tabs Codes ===//
        TabsStatus={true}
        TabsPannelStatus={true}
        panelTab={panelTab}
        tabData={tabData}
        setActiveDrawer={setActiveDrawer}
      />
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
        title="Cancel upload"
        body={<div>Are you sure you want to cancel this upload?</div>}
        ModalFooter={true}
        actionButton="Delete"
      />{" "}
    </>
  );
}

export default Externaluse;
