import React, { useState, useEffect } from "react";

import {
  getDataMap,
  getMonths,
  getYears,
} from "../../../../../hooks/HelperFunctions";

import Grid from "@material-ui/core/Grid";
import TextField from "../../../../../components/Form/TextField/TextField";
import GooglePlaceField from "../../../../../components/Form/GooglePlaceField/GooglePlaceField";
// import GooglePlacesAutoField from '../../../../../components/Form/GooglePlaceField/GooglePlacesAutoField';

// import SelectField from "../../../../../components/Form/SelectField/SelectField";
import ComboBox from "../../../../../components/Form/ComboBox/ComboBox";

import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import FileUplaoder from "../../../../../components/Form/FileUplaoder/FileUplaoder";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import PhoneInputField from "../../../../../components/Form/PhoneInputField/PhoneInputField";
import { type } from "@testing-library/user-event/dist/type";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../../components/Form/Button/Button";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import "../../../../../assets/css/editprofile.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { states } from "../../../../../hooks/JsonData";

function UpdatePersonalInfo(props) {
  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });

  const {
    personalInfo,
    setPersonalInfo,
    gradeLevels,
    ageGroups,
    timeZones,
    countries,
    highestEducation,
    setDisableSaveBtn,
    manualAddress,
    setManualAddress,
  } = props;

  const [openSelectMedia, setOpenSelectMedia] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [openNewMedia, setOpenNewMedia] = useState(false);

  const [mediaItem, setMediaItem] = useState({});
  // const [manualAddress, setManualAddress] = useState(false);

  const [selectedFIle, setSelectedFIle] = useState({});

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      // console.log(mediaItem);
      //set the media item
      setImageSelection(mediaItem.file_url);
      // handleChangeWithData("courseImageName", mediaItem.name);

      // console.log( courseData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
    setDisableSaveBtn(false);
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
    setDisableSaveBtn(false);
  };

  const checkJsonStringOrNot = (address) => {
    if (typeof address === "string") {
      if (address.length == 0) {
        return "";
      }

      if (
        /^[\],:{}\s]*$/.test(
          address
            .replace(/\\["\\\/bfnrtu]/g, "@")
            .replace(
              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
              "]"
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
        )
      ) {
        return JSON.parse(address);
      } else {
        return {};
      }
    } else {
      return address;
    }
  };

  const setImageSelection = (fileUrl) => {
    // console.log(fileUrl);

    // setCourseData(temp);
    // console.log(courseData);
    handleChangeWithData("image", fileUrl);
  };

  // const fileSelectedHandler = (e) => {

  //   if( props.selectedFile){
  //     props.selectedFile(e);
  //   }

  // }

  return (
    <div className="ModalForm EditProfile">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="First Name"
            required={true}
            value={personalInfo.firstName}
            handleChange={handleChanges("firstName")}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="Middle Name"
            required={false}
            value={personalInfo.middleName}
            handleChange={handleChanges("middleName")}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="Last Name"
            required={true}
            value={personalInfo.lastName}
            handleChange={handleChanges("lastName")}
          />
        </Grid>

        <Grid item xs={12} sm={9} md={12}>
          <div className="UpdateUseravatar">
            <div>
              <div className="FieldLabel">Profile Photo</div>
              <div className="EditPhotoContainer">
                <div className="UserDetailsrIcon EditPhoto">
                  <div className="EditPhotoAvatar">
                    <Avatar
                      sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                      src={personalInfo?.image ? personalInfo?.image : ""}
                    >
                      {personalInfo?.image
                        ? ""
                        : Array.from(personalInfo?.firstName)[0]}
                    </Avatar>
                  </div>
                </div>
              </div>
            </div>
            <div className="UplaodPhotoModal UploadPhotoSectionContainer">
              <FileUplaoder
                filePath={`students/${personalInfo.id}/profile-images/`}
                uploadButton="Upload Photo"
                file={personalInfo.image}
                fileType="image/*"
                onUpload={(filePath) => handleChangeWithData("image", filePath)}
                // setSelectedFile={fileSelectedHandler}
                helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                showUploadButton={false}
                showThumb={false}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="RedioButton">
            <FormControl>
              <FormLabel
                id="demo-radio-buttons-group-label"
                className="FieldLabel"
              >
                Gender
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={personalInfo.gender}
                onChange={handleChanges("gender")}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>

        {/* country drop-down */}
        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            label="Select country"
            validation={true}
            options={getDataMap(countries, "countryId", "name")}
            selectedOption={personalInfo.countryId}
            handleChange={(fieldData) =>
              handleChangeWithData("countryId", fieldData)
            }
          />
        </Grid>

        {manualAddress ? (
          <Grid item xs={12} sm={12} md={12}>
            {/* ============= manual address fields ============= */}
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <TextField
                  label="Street Address"
                  required={true}
                  value={personalInfo.streetAddress}
                  handleChange={handleChanges("streetAddress")}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Fl/Suite No."
                  required={false}
                  value={personalInfo.flOrSuiteNo}
                  handleChange={handleChanges("flOrSuiteNo")}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="City/Town/Village"
                  required={true}
                  value={personalInfo.city}
                  handleChange={handleChanges("city")}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="State/Province/Territory"
                  required={true}
                  value={personalInfo.state}
                  handleChange={handleChanges("state")}
                />
                {/* <SelectField
                  label="State"
                  validation={true}
                  selectedOption={state}
                  options={states}
                  handleChange={handleState}
                /> */}
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Zip Code"
                  required={true}
                  value={personalInfo.zipCode}
                  handleChange={handleChanges("zipCode")}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12}>
            <div className="GooglePlaceField">
              <GooglePlaceField
                label="Address"
                validation={true}
                placeholder="Your current resident address"
                value={checkJsonStringOrNot(personalInfo.addressObj)}
                handleChange={(address) =>
                  handleChangeWithData("addressObj", address)
                }
              />
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12}>
          <Button
            variant="text"
            sx={{ padding: "0px", textTransform: "initial" }}
            onClick={() => {
              setDisableSaveBtn(false);
              setManualAddress(!manualAddress);
            }}
          >
            {manualAddress
              ? "Enter address in one line"
              : "Enter address manually"}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            value={personalInfo.whatsAppNumber}
            countryCodeEditable={false}
            onChange={(value, country, e, formattedValue) =>
              handleChangeWithData("whatsAppNumber", formattedValue)
            }
            onBlur={() => console.log("onBlur")}
            // onKeyDown={handleChanges("whatsAppNumber")}
            variant="outlined"
            // onlyCountries={["us", "ca", "gb", "my"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="WhatsApp number"
            LabelStatus={true}
            //helpertext="Helper text"
            //error="error"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          {/* <SelectField
            label="Birthday"
            validation={false}
            placeholder="Month"
            options={getMonths()}
            selectedOption={personalInfo.birthMonth}
            handleChange={handleChanges("birthMonth")}
          /> */}

          <ComboBox
            label="Birthday"
            placeholder="Month"
            validation={false}
            options={getMonths()}
            selectedOption={personalInfo.birthMonth}
            handleChange={(fieldData) =>
              handleChangeWithData("birthMonth", fieldData)
            }
          />
        </Grid>

        {/* <Grid item xs={12} sm={3} md={3}>
          <TextField
            placeholder="Day"
            value={personalInfo.birthDay}
            handleChange={handleChanges("birthDay")}
          />
        </Grid> */}

        <Grid item xs={12} sm={6} md={6}>
          {/* <SelectField
            placeholder="Year"
            value={personalInfo.birthYear}
            handleChange={handleChanges("birthYear")}
            options={getYears()}
            selectedOption={personalInfo.birthYear? personalInfo.birthYear : "2007"}
          /> */}
          <ComboBox
            placeholder="Year"
            validation={false}
            options={getYears()}
            selectedOption={personalInfo.birthYear}
            handleChange={(fieldData) =>
              handleChangeWithData("birthYear", fieldData)
            }
          />
        </Grid>

        {/* height education drop-down */}
        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            label="Highest education"
            validation={false}
            options={getDataMap(highestEducation, "heduId", "title")}
            selectedOption={personalInfo.heduId}
            handleChange={(fieldData) =>
              handleChangeWithData("heduId", fieldData)
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Current profession"
            required={false}
            value={personalInfo.currentProfession}
            handleChange={handleChanges("currentProfession")}
          />
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="WhatsApp number"
            required={false}
            placeholder="+1"
            value={personalInfo.whatsAppNumber}
            handleChange={handleChanges("whatsAppNumber")}
          />
        </Grid> */}

        {/* <Grid item xs={12} sm={12} md={12}>
          <ComboBox
              label="Current Grade"
              validation={false}
              options={getDataMap(gradeLevels, "gradeId","title")}
              selectedOption={personalInfo.currentGrade}
              handleChange={ (fieldData) => handleChangeWithData("currentGrade", fieldData)}
          />
        </Grid> */}

        {/* <Grid item xs={12} sm={12} md={12}>
          <ComboBox
              label="Age Group"
              validation={false}
              options={getDataMap(ageGroups, "ageId","title")}
              selectedOption={personalInfo.ageGroup}
              handleChange={ (fieldData) => handleChangeWithData("ageGroup", fieldData)}
          />
        </Grid> */}

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            label="Select Timezone"
            validation={true}
            options={getDataMap(
              timeZones,
              "timezone",
              "timezone",
              "gmtOffset",
              " | GMT "
            )}
            selectedOption={personalInfo.timezone}
            handleChange={(fieldData) =>
              handleChangeWithData("timezone", fieldData)
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default UpdatePersonalInfo;
