import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
// import { PhoneInputField } from "master-react-lib";
import TextField from "../../../../components/Form/TextField/TextField";
import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";

const AddOneByOne = (props) => {
  const { newUserInfo, setNewUserInfo, setEmailValidationError, userError } =
    props;

  const [phone, setPhone] = useState({
    country: "",
    number: "",
  });

  const onChangeHandler = (e, fieldName) => {
    setNewUserInfo({ ...newUserInfo, [fieldName]: e.target.value });
  };

  const changePhoneNumberHandler = (phoneNumber) => {
    setNewUserInfo({ ...newUserInfo, phone_number: phoneNumber });
  };

  const emailValidation = (validationError) => {
    if (validationError) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            label="First Name"
            value={newUserInfo.first_name}
            handleChange={(e) => onChangeHandler(e, "first_name")}
            required={true}
            error={userError?.first_name}
            helperText={userError?.first_name}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Middle Name"
            value={newUserInfo.middle_name}
            handleChange={(e) => onChangeHandler(e, "middle_name")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Last Name"
            value={newUserInfo.last_name}
            required={true}
            handleChange={(e) => onChangeHandler(e, "last_name")}
            error={userError?.last_name}
            helperText={userError?.last_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email Address"
            value={newUserInfo.email}
            required={true}
            helperText={
              newUserInfo.email?.length === 0
                ? "Add email to send an invite"
                : userError?.email
                ? userError?.email
                : ""
            }
            handleChange={(e) => onChangeHandler(e, "email")}
            handleValidation={emailValidation}
            error={userError?.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            value={newUserInfo.phone_number}
            countryCodeEditable={false}
            onChange={(phone, country) => changePhoneNumberHandler(phone)}
            onBlur={() => console.log("onBlur")}
            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
            variant="outlined"
            // onlyCountries={["us", "ca", "gb", "my"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            error={false}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone Number"
            LabelStatus={true}
            // helpertext="Helper text"
            // error="error"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddOneByOne;
