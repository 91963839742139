import React from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import "./Search.css";

function Search(props) {
  return (
    <div className="InboxSearchContainer">
      <button className="InboxSearchButton">
        <SearchRoundedIcon />
      </button>
      <input
        className="InboxSearchInput"
        type="text"
        value={props.searchedText}
        placeholder="Search..."
        onChange={props.handleSearchText}
      />
    </div>
  );
}

export default Search;
