import React, { useEffect, useState } from "react";
import axios from "axios";

import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import CallTwoToneIcon from "@mui/icons-material/CallTwoTone";
import CustomizedSnackbar from "../../hooks/Snackbar";
import DeenLogo from "../../assets/imgs/deen360-logo.png";
import "./Form.css";
import PhoneInputField from "../../components/Form/PhoneInputField/PhoneInputField";
import { useNavigate, useSearchParams } from "react-router-dom";

function ForgotPassword(props) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [resendBtn, setResendBtn] = React.useState(false);
  const [formValidationError, setFormValidationError] = useState(false);
  const [getTheStatus, setGetTheStatus] = useState("email");
  let signInWith = searchParams.get("signInWith");
  const navigate = useNavigate();
  const handleChanges = (fieldName) => (event) => {
    const userEmail = event.target.value;
    console.log(userEmail);
    setEmail(userEmail);
  };

  useEffect(() => {
    if (signInWith) {
      setGetTheStatus(" ");
    }
  }, [signInWith]);

  const processResponse = (response, resend) => {
    setResendBtn(true);
    setBtnDisabled(false);

    if (response.data.body.status == true) {
      if (resend) {
        setShowSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Email sent");
      }
      // setCurentPage("forgot-pass-result");
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }
  };

  const processResponseSingWithPhone = (response) => {
    if (response.data.body.status == true) {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.body.message);
      // redirect to sign in page
      setTimeout(() => {
        setSnackbarMessage("Redirecting to sign in page...");
        window.location.replace(`${window.location.origin}/signin`);
      }, 3000);
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }
  };
  const sendForgotPasswordRequest = (resend = false) => {
    if (formValidationError == false) {
      const baseUrl =
        process.env.REACT_APP_PROJECT_ENV === "prod"
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;
      ///===================== recapcha ==============================///
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(
            `${process.env.REACT_APP_RECAPCHA_SITE_KEY}`,
            { action: "submit" }
          );
          ///===================== recapcha ==============================///
          const postData = {
            email: email,
            passwordResetURL: `${window.location.origin}/reset-password`,
            token: token,
          };

          setBtnDisabled(true);

          axios
            .post(`${baseUrl}/forgotPassword`, postData)
            .then((response) => {
              if (response?.data.body.status) {
                processResponse(response, resend);
              } else {
                setShowSnackbar(true);
                setSnackbarSeverity("error");
                setSnackbarMessage(
                  "We can't find an account for that email address. Please try again with other information."
                );
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.message) {
                setBtnDisabled(false);
                setShowSnackbar(true);
                setSnackbarSeverity("error");
                setSnackbarMessage(err.response.data.message);
              }
            });
        } catch (error) {
          console.error("Error:", error);
        }
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      setFormValidationError(true);
    } else {
      setFormValidationError(false);
    }
  };
  //== Phone Field State start== //
  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });
  //== Phone Field State end== //

  const phoneChangeHandler = (ext, number) => {
    setPhone({
      ...phone,
      number: number,
    });
    // handleChanges("phone_number", `${number}`);
  };
  const sendForgotPasswordWithPhone = () => {
    if (phone?.number === "") {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "We can't find an account for that phone number. Please try again with other information."
      );
      return;
    }
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    let postData = {
      phone_number: phone?.number,
    };
    axios
      .post(`${baseUrl}/resetPasswordOtpSend`, postData)
      .then((response) => {
        if (!response?.data.error) {
          navigate("/verify-otp");
          processResponseSingWithPhone(response);
        } else {
          setShowSnackbar(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(
            "We can't find an account for that phone number. Please try again with other information."
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // alert(4643);
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <CustomizedSnackbar
            open={showSnackbar}
            severity={snackbarSeverity}
            message={snackbarMessage}
            handleClose={handleSnackbarClose}
          />
          {!resendBtn ? (
            <div className="SignForm">
              <h1>Forgot password?</h1>
              <div className="formContainer">
                <div className="SignFormGrid">
                  {/* ======================= sign in with email ======================= */}
                  {(getTheStatus === "email" || signInWith === "email") && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <p style={{ textAlign: "center" }}>
                          Enter the email address below that you use with your
                          TAKBIR account.
                        </p>
                      </Grid>

                      <Grid item xs={12} md={12} className="HideLabelClass">
                        <TextField
                          required={true}
                          email={true}
                          value={email}
                          handleValidation={handleValidation}
                          handleChange={handleChanges("email")}
                          placeholder="Email address"
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Buttons
                          label="Send reset email"
                          buttonColor="#004FE0"
                          border="#ccc 1px solid"
                          color="#fff"
                          width="100%"
                          height="48px"
                          fontSize="14px"
                          link=""
                          onClick={() => sendForgotPasswordRequest()}
                          disabled={btnDisabled}
                        />
                        <div className="BottomButton">
                          <Buttons
                            label="Cancel"
                            color="#004FE0"
                            width=""
                            height="48px"
                            fontSize="14px"
                            link="/signin"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {/* ======================= sign in with phone ======================= */}
                  {(getTheStatus === "phone_number" ||
                    signInWith === "phone_number") && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <p style={{ textAlign: "center" }}>
                          Enter the email address below that you use with your
                          TAKBIR account.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={12} className="HideLabelClass">
                        <PhoneInputField
                          phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                          country={"us"}
                          value={phone.number}
                          countryCodeEditable={false}
                          onChange={(phone, country) =>
                            phoneChangeHandler(country.dialCode, phone)
                          }
                          onBlur={() => console.log("onBlur")}
                          onKeyDown={(e) =>
                            console.log("onKeyDown", e.target.value)
                          }
                          variant="outlined"
                          // onlyCountries={["us", "ca", "gb", "my"]}
                          containerStyle={{}}
                          buttonStyle={{}}
                          inputStyle={{
                            marginLeft: "0px",
                            height: "56px",
                            width: "100%",
                            fontFamily: "Roboto-Regular",
                            fontSize: "16px",
                          }}
                          containerClass={{}}
                          // errorText={"Enter a valid phone number"}
                          errorStyles={{ marginBottom: "5px" }}
                          autoFormat={true}
                          disableDropdown={false}
                          disabled={false}
                          disableCountryCode={false}
                          enableSearch={false}
                          disableSearchIcon={false}
                          placeholder={""}
                          inputProps={{}}
                          specialLabel={"Primary Phone"}
                          // preferredCountries={[]}
                          // excludeCountries={[]}
                          // error="error"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Buttons
                          label="Send code"
                          buttonColor="#004FE0"
                          border="#ccc 1px solid"
                          color="#fff"
                          width="100%"
                          height="48px"
                          fontSize="14px"
                          // link="/verify-otp"
                          onClick={sendForgotPasswordWithPhone}
                        />
                        <div className="BottomButton">
                          <Buttons
                            label="Cancel"
                            color="#004FE0"
                            width=""
                            height="48px"
                            fontSize="14px"
                            link="/signin"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="OrBox" style={{ marginTop: "-12px" }}>
                        <span>or</span>
                      </div>
                    </Grid>
                    {getTheStatus === "phone_number" && (
                      <Grid item xs={12} md={12}>
                        <div
                          className="SocialButton"
                          onClick={() => setGetTheStatus("email")}
                        >
                          <Buttons
                            label="Reset with email"
                            buttonColor="#fff"
                            border="#E4E8EC 1px solid"
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            onClick={(e) => setGetTheStatus("email")}
                            icon={<MailOutlineRoundedIcon />}
                          />
                        </div>
                      </Grid>
                    )}
                    {getTheStatus === "email" && (
                      <Grid item xs={12} md={12}>
                        <div
                          className="SocialButton"
                          onClick={() => setGetTheStatus("phone_number")}
                        >
                          <Buttons
                            label="Reset with phone"
                            buttonColor="#fff"
                            border="#E4E8EC 1px solid"
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            onClick={(e) => setGetTheStatus("phone_number")}
                            icon={<CallTwoToneIcon />}
                            IconAlign="left"
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <div className="SignForm">
              <h1 className="FontMedium">
                Thanks, email has been sent <br></br>with reset password
                instructions
              </h1>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <CustomizedSnackbar
                    open={showSnackbar}
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    handleClose={handleSnackbarClose}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0px",
                      fontSize: "14px",
                    }}
                  >
                    If you haven't received an email in 5 minutes, check your
                    spam or
                  </p>

                  <div style={{ paddingTop: "4px", marginBottom: "5px" }}>
                    <Buttons
                      label="resend"
                      color="#004FE0"
                      width="100%"
                      height="48px"
                      fontSize="14px"
                      link=""
                      onClick={() => sendForgotPasswordRequest(true)}
                    />
                  </div>

                  <Buttons
                    label="Back To Sign In"
                    buttonColor="#004FE0"
                    border="#ccc 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link="/signin"
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {/* {
            <div className="SignForm">
              <h1>Forgot password?</h1>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <p style={{ textAlign: "center" }}>
                    Enter the email address below that you use with your TAKBIR
                    account.
                  </p>
                </Grid>
              </Grid>
            </div>
          } */}

          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
