import React from "react";
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import ListingCard from '../../../../components/ListingCard/ListingCard';


const UpcomingSessions = (props) => {

  const cardData = [
    {
      title: "Wed, Mar 1, 10:30 AM (EST)",
      description: "What is islamic finance?",
      color: "#FA0",
    },
    {
      title: "Wed, Mar 1, 10:30 AM (EST)",
      description: "What is islamic finance?",
      color: "#0B9444",
    },
    {
      title: "Wed, Mar 1, 10:30 AM (EST)",
      description: "What is islamic finance?",
      color: "#004FE0",
    },
    {
      title: "Wed, Mar 1, 10:30 AM (EST)",
      description: "What is islamic finance?",
      color: "#FA0",
    },
    {
      title: "Wed, Mar 1, 10:30 AM (EST)",
      description: "What is islamic finance?",
      chipLabel: "Paid",
      color: "#0B9444",
    },
    {
      title: "Wed, Mar 1, 10:30 AM (EST)",
      description: "What is islamic finance?",
      color: "#004FE0",
    },
  ]

  return (
    <div className='CardSection' style={{ background: "#F8F9FB", }}>
      <div className="CardSectionTitle">Upcoming sessions</div>
      <div className="Scroll-Y" style={{ height: "324px", }}>
        <Grid container spacing={1}>
          {cardData.map((item, i) => (
            <Grid item sx={12} sm={12}>
              <ListingCard
                LineStatus={true}
                Title={item.title}
                Description={item.description}
                StatusColor={item.color}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default UpcomingSessions;
