import react from "react";
import Grid from '@mui/material/Grid';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import Buttons from '../Form/Button/Button';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import './CoursesCard.css';


function CoursesCard(props) {

    const { sessionData, title } = props;

    // const sessionData = [

    //     {
    //         img: img,
    //         date: "Sun, Mar 13",
    //         price: "Free",
    //         title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
    //         name: "Sheikh Muhammad Jamal Uddin",
    //         link: "/course-details",
    //     },

    //     {
    //         img: img,
    //         date: "Sun, Mar 13",
    //         price: "$9.99",
    //         title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
    //         name: "Sheikh Muhammad Jamal Uddin",
    //         link: "/course-details",
    //     },

    //     {
    //         img: img,
    //         date: "Sun, Mar 13",
    //         price: "Free",
    //         title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
    //         name: "Sheikh Muhammad Jamal Uddin",
    //         link: "/course-details",
    //     },

    //     {
    //         img: img,
    //         date: "Sun, Mar 13",
    //         price: "Free",
    //         title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
    //         name: "Sheikh Muhammad Jamal Uddin",
    //         link: "/course-details",
    //     },

    // ];

    return (
        <div className={props.ScrollView}>
            <Grid container spacing={3}>
                {sessionData.map((item, i) => (

                    <Grid item xs={12} sm={6} md={props.col}>

                        <div className="SessionCard">
                            <div className="SessionCardImg">
                                <img src={item.img} />
                                {props.TrackSection &&
                                    <div className="TrackSection">{item.tracklabel}</div>
                                }
                            </div>

                            <div className="SessionCardInfo">

                                {props.DataStatus &&
                                    <div className="SessionCardDate">
                                        <h3>{item.date}</h3>
                                        <span>{item.price}</span>
                                    </div>
                                }
                                <h4>{item.title}</h4>
                                <p>{item.name}</p>



                                {props.CardButton &&
                                    <div className="SessionCardFooter ButtonGroup">
                                        <Buttons
                                            label="Add to cart"
                                            buttonColor="#004FE0"
                                            color="#fff"
                                            width="100%"
                                            height="48px"
                                            fontSize="14px"
                                            link={item.link}
                                        //disabled="disabled"
                                        />

                                        <div className="ShareButton">
                                            <Buttons
                                                icon={<ShareRoundedIcon />}
                                                buttonColor="#fff"
                                                color="#676C7B"
                                                width="48px"
                                                height="48px"
                                                fontSize="30px"
                                                borderRadius="100px"
                                                border="#676C7B 1px solid"
                                            />
                                        </div>
                                    </div>
                                }
                                <ProgressBar
                                    value={50}
                                />
                            </div>

                        </div>

                    </Grid>

                ))}
            </Grid>


        </div>
    );
}

export default CoursesCard;
