import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getUserAuthData, getUserData } from "../hooks/HelperFunctions";

import Header from "../components/Header/Header";
import Sidebar from "../pages/student-portal/sidebar/Sidebar";
import SideBarDrawer from "../components/SideBarDrawer/SideBarDrawer";
import CryptoJS from "crypto-js";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Buttons from "../components/Form/Button/Button";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AlrtMessageFullWidth from "../components/Form/AlrtMessage/AlrtMessageFullWidth";
import RunningCourseAlertMessage from "../components/Form/AlrtMessage/RunningCourseAlertMessage";
import EmptyPage from "../components/EmptyPage/EmptyPage";
import SideBarMenuOrg from "../pages/org-portal/sidebar/SidebarMenu";

const StudentAuth = ({ collapsed, children, BodyFullWidth, BgColor }) => {
  const [open, setOpen] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(true);

  let fullHost = window.location.hostname;
  let splittedHost = fullHost.split(".");
  let subDomain = splittedHost[0];
  const handleMyCourses = () => {
    redirectToPortal("");
  };
  // retrive user data
  const userData = getUserData();
  const takbirUrl =
    subDomain == "stage-admin"
      ? "https://main-stage.mytakbir.com/search"
      : "https://mytakbir.com/search";

  let projectSecondaryEnv = process.env.REACT_APP_TAKBIR_SECONDARY_ENV;
  let mainWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;

  const userToken = localStorage.getItem("userToken");
  var signinToken = CryptoJS.AES.encrypt(
    JSON.stringify({
      email: userData.email,
      phone_number: userData.phone_number,
      userToken: userToken,
    }),
    "token-based-signin-aglbnd-854256-secret-key"
  ).toString();
  //====================create a link to targetPage courses =======================//
  if (projectSecondaryEnv == "prod") {
    mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain.", "");
  } else {
    const hasLocalhost = mainWebsiteUrl.includes("localhost");

    if (!hasLocalhost) {
      if (projectSecondaryEnv == "dev") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-dev");
      } else if (projectSecondaryEnv == "stage") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-stage");
      }
    }
  }
  mainWebsiteUrl += `?signinToken=${signinToken}&targetPage=search`;
  //====================create a link to targetPage courses =======================//

  const redirectToPortal = (path) => {
    // redirect to portal sign in page
    const userToken = localStorage.getItem("userToken");
    const tokenData = {
      email: userData.email,
      phone_number: userData.phone_number,
      userToken: userToken,
    };
    var token = CryptoJS.AES.encrypt(
      JSON.stringify([tokenData]),
      "login-854256-secret-key"
    ).toString();
    if (typeof window !== "undefined") {
      window.open(`${takbirUrl}/?token=${token}${path}`, "_blank");
    }
  };

  useEffect(() => {
    getStudentAccountVerificationInfo();
  }, []);

  const getStudentAccountVerificationInfo = () => {
    // check user profile completed or not if not then show alert message
    const localUserVerified = localStorage.getItem("userVerified");
    console.log(localUserVerified);
    if (localUserVerified == "true") {
      // const stuId = localStorage.getItem('stuId');
      const userData = JSON.parse(localStorage.getItem("userData"));
      const stuId = userData?.id;
      const baseUrl =
        process.env.REACT_APP_PROJECT_ENV === "prod"
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;
      axios
        .get(`${baseUrl}/studentPortal/checkProfileCompleted?userId=${stuId}`)
        .then((result) => {
          console.log(result.data.profileCompleted);
          setProfileCompleted(result.data.profileCompleted);
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    }
  };

  const getAlertComponent = () => {
    return (
      <>
        {profileCompleted == false && (
          <AlrtMessageFullWidth
            message="Add missing information to your profile"
            width="60%"
            icon={<PersonRoundedIcon />}
            //ButtonLable={alertBtnLabel}
            // onClick={resendEmailHandler}
            link="/settings?editProfile=true"
            background="#004FE0"
            ButtonColor="#0D3FB3"
            ButtonStatus={true}
            ButtonLable="Complete Profile"
            duration="8000000"
          />
        )}

        <RunningCourseAlertMessage portal="student" />
      </>
    );
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const userAuthenticated = getUserAuthData();

  // assign user role
  const navigate = useNavigate();
  const handleChange = () => {
    localStorage.setItem("activePortal", 0);
    localStorage.removeItem("orgId");
    localStorage.removeItem("subDomain");
    userData.userRole = "student";
    localStorage.setItem("userData", JSON.stringify(userData));
    navigate("/my-courses");
    window.location.reload();
  };

  const userRole = userData.userRole === "undefined" ? "" : userData.userRole;

  if (userAuthenticated && userRole === "student") {
    return (
      <>
        <div className="DesktopView">
          <Header
            AlertBody={getAlertComponent()}
            ButtonBody={
              <div>
                <Buttons
                  label="Browse Courses"
                  buttonColor="#fff"
                  border="#E4E8EC 1px solid"
                  color="#004FE0"
                  width=""
                  height="40px"
                  fontSize="14px"
                  target="_blank"
                  link={mainWebsiteUrl?.length > 0 ? mainWebsiteUrl : "#"}
                  // onClick={() => handleMyCourses()}
                />
              </div>
            }
            SidebarBody={<Sidebar collapsed={collapsed} />}
          />
        </div>

        <div className="ResponsiveMenu">
          <Header
            AlertBody={getAlertComponent()}
            IconMenu={
              <a onClick={handleDrawerOpen}>
                <MenuRoundedIcon />
              </a>
            }
            ButtonBody={
              <div>
                <Buttons
                  label="Browse Courses"
                  buttonColor="#fff"
                  border="#E4E8EC 1px solid"
                  color="#004FE0"
                  width=""
                  height="40px"
                  fontSize="14px"
                  // link="https://3.basecamp.com/5718042/buckets/35923152/card_tables/cards/7209276098"
                  link={mainWebsiteUrl?.length > 0 ? mainWebsiteUrl : "#"}
                  // onClick={() => handleMyCourses()}
                />
              </div>
            }
          />
          <SideBarDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            DrawerBody={<Sidebar />}
          />
        </div>

        <div className={BodyFullWidth}>
          <div className="app-body" style={{ background: BgColor }}>
            {" "}
            {children}{" "}
          </div>
        </div>
      </>
    );
  } else if (userAuthenticated) {
    return (
      <>
        <div className="DesktopView">
          <Header
            AlertBody={getAlertComponent()}
            ButtonBody={
              <div>
                <Buttons
                  label="Browse Courses"
                  buttonColor="#fff"
                  border="#E4E8EC 1px solid"
                  color="#004FE0"
                  width=""
                  height="40px"
                  fontSize="14px"
                  target="_blank"
                  link={mainWebsiteUrl?.length > 0 ? mainWebsiteUrl : "#"}
                  // onClick={() => handleMyCourses()}
                />
              </div>
            }
            SidebarBody={<SideBarMenuOrg />}
          />
        </div>

        <div className="ResponsiveMenu">
          <Header
            AlertBody={getAlertComponent()}
            IconMenu={
              <a onClick={handleDrawerOpen}>
                <MenuRoundedIcon />
              </a>
            }
            ButtonBody={
              <div>
                <Buttons
                  label="Browse Courses"
                  buttonColor="#fff"
                  border="#E4E8EC 1px solid"
                  color="#004FE0"
                  width=""
                  height="40px"
                  fontSize="14px"
                  link={mainWebsiteUrl?.length > 0 ? mainWebsiteUrl : "#"}
                  // onClick={() => handleMyCourses()}
                />
              </div>
            }
          />
          <SideBarDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            DrawerBody={<SideBarMenuOrg />}
          />
        </div>
        <EmptyPage
          pageHeading="Page not found"
          pageSubtitle="Either this page doesn't exist or you don't have permission to access it."
          showButton={true}
          btnLabel="Back to learner portal"
          // btnLink={"/my-coursesList"}
          onClick={handleChange}
        />
      </>
    );
  }

  return <Navigate to="/signin" state={{ from: location }} replace></Navigate>;
};

export default StudentAuth;
