import React, { useEffect, useState } from "react";
import { Avatar, Grid } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import certificate from "../../../assets/imgs/certificate-temp-takbir.png";
import "../../../assets/css/certificates.css";
import Buttons from "../../../components/Form/Button/Button";
import Modals from "../../../components/Form/Modals/Modals";
import ShearModalBody from "../../../components/ShearModalBody/ShearModalBody";
import SimpleBackdrop from "../../../components/Form/Backdrop/SimpleBackdrop";
import axios from "axios";
import { getUserData } from "../../../hooks/HelperFunctions";
import moment from "moment";
import { useParams } from "react-router";

const CertificatesDetails = () => {
  const [shearModal, setShearModal] = React.useState(false);
  const { userId, id } = useParams();

  // get usr data
  const userData = getUserData();
  const userName = `${userData?.firstName} ${userData?.lastName}`;

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [seletedItem, setSeletedItem] = useState({});
  const [certificateDetailsData, setCertificateDetailsData] = useState({
    courseTitle: "",
    courseSlug: "",
    teachingAccName: "",
    subDomain: "",
    certificate_issued_at: "",
    grade: "",
    fileLink: "",
  });
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const getCertificateListingData = () => {
    setOpenBackdrop(true);
    axios
      .get(
        `${baseUrl}/studentPortal/getStudentCourseCertificateDetails?userId=${userId}&courseId=${id}`
      )
      // .get(
      //   `${baseUrl}/studentPortal/getStudentCourseCertificateDetails?userId=139&courseId=178`
      // )
      .then((result) => {
        // console.log(result.data.body);

        setCertificateDetailsData({
          ...certificateDetailsData,
          courseTitle: result.data.body?.courseTitle,
          courseSlug: result.data.body?.courseSlug,
          teachingAccName: result.data.body?.teachingAccName,
          subDomain: result.data.body?.subDomain,
          certificate_issued_at: result.data.body?.certificate_issued_at,
          grade: result.data.body?.grade,
          fileLink: result.data.body?.fileLink,
        });
        setOpenBackdrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCertificateListingData();
  }, []);

  useEffect(() => {
    if (certificateDetailsData) {
      setSeletedItem(certificateDetailsData);
    }
  }, [certificateDetailsData]);

  const getProfileAvatarFromName = (user) => {
    return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(
      0
    )}`.toUpperCase();
  };

  const handleDownload = () => {
    if (certificateDetailsData?.fileLink) {
      const link = document.createElement("a");
      link.href = certificateDetailsData.fileLink;
      link.download = "certificate.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("Certificate file link not available");
      // alert();
    }
  };

  const breadcrumbsData = [
    {
      label: "Certificates",
      link: "/certificates",
      icon: <EmojiEventsIcon fontSize="small" />,
    },

    {
      label: "Certificate Details",
    },
  ];

  return (
    <div>
      <SimpleBackdrop open={openBackdrop} />
      <Breadcrumbs breadcrumbsData={breadcrumbsData} />
      <div className="CertificatesDetailsContainer">
        <div className="CertificatesCourseTitle">
          {" "}
          <h1>
            <a href="#">{certificateDetailsData?.courseTitle || "N/A"}</a>
          </h1>
          <a href={`https://${certificateDetailsData?.subDomain}.mytakbir.com`}>
            {certificateDetailsData?.teachingAccName || "N/A"}
          </a>
        </div>
        <Grid container sx={{ marginTop: "24px" }} spacing={2}>
          <Grid item xs={12} md={12} lg={4}>
            {/* =========== student info box =========== */}
            <div className="CertificateStudentInfo">
              <div className="CertificateStudentStatus">
                <Avatar
                  sx={{ bgcolor: "#004FE0", width: 56, height: 56 }}
                  src={userData?.image || ""}
                >
                  {" "}
                  {userData?.image
                    ? ""
                    : getProfileAvatarFromName(userData)}{" "}
                </Avatar>
                <span
                  className="status rounded statussmall"
                  style={{
                    background: "#E1F2E8",
                    color: "#0B9444",
                  }}
                >
                  {/* DIPLOMA */}
                  Course
                </span>
              </div>
              <h3>
                Completed by <b>{userName || "N/A"}</b>
              </h3>
              <p>
                {userName || "N/A"}'s account is verified. Takbir certifies
                their successful completion of{" "}
                <a
                  href={`https://${certificateDetailsData?.subDomain}.mytakbir.com`}
                >
                  {certificateDetailsData?.courseTitle || "N/A"}
                </a>
              </p>
              {/* ===== Issued Box ===== */}
              <div className="InfoInnerBoxContainer">
                <div className="InfoInnerBox">
                  <span>Issued</span>
                  <p>
                    {certificateDetailsData?.certificate_issued_at
                      ? moment(
                          certificateDetailsData?.certificate_issued_at
                        )?.format("MMM DD, YYYY")
                      : "N/A"}{" "}
                    {/* Dec 25, 2022 */}
                  </p>
                </div>
                {/* <div className="InfoInnerBox">
                  <span>Grade achieved</span>
                  <p> {certificateDetailsData?.grade || "N/A"}</p>
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <div className="CertificateImageContainer">
              <img
                src={certificateDetailsData?.fileLink || certificate}
                alt="course certificate"
              />
            </div>
            <div className="CertificateBtnContainer">
              <Buttons
                label="Download Certificate"
                buttonColor="#004FE0"
                color="#fff"
                width="100%"
                height="48px"
                fontSize="14px"
                // link={item.link}
                onClick={handleDownload}
                //disabled="disabled"
              />
              <Buttons
                label="Share Certificate"
                buttonColor="#fff"
                color="#004FE0"
                width="100%"
                height="48px"
                fontSize="14px"
                border="1px solid #E4E8EC"
                // link={item.link}
                onClick={() => setShearModal(true)}
                //disabled="disabled"
              />
            </div>
          </Grid>
        </Grid>
      </div>

      {/* ================ modals ================= */}
      {/* ================== Modal ===================== */}
      <Modals
        ModalTitle="Share this certificate"
        // handleConfirm={() => handleConfirm()}
        handleClose={() => setShearModal(false)}
        open={shearModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={false}
        ModalBody={
          <div>
            <ShearModalBody seletedItem={seletedItem} />
          </div>
        }
      />
    </div>
  );
};

export default CertificatesDetails;
