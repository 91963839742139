import React, { useState, useEffect } from "react";
import TextFieldCom from "@mui/material/TextField";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Tooltip from "../Tooltip/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./TextField.css";

{
  /* <TextField
    label="Enter Course Id"
    validation={false}
    CharAlignMid="CharAlignMid"
    Uppercase="Uppercase"
    count={6}
    value={courseData.Id}
    handleChange={handleChanges("Id")}
  /> */
}

function TextField(props) {
  const {
    label,
    required,
    validation,
    email,
    number,
    error,
    placeholder,
    value,
    handleChange,
    handleValidation,
    helperText,
    count,
    disabled,
    CharAlignMid,
    onkeyDown,
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  // useEffect(() => {
  //   if (required || validation) {
  //     if (!value) {
  //       setValidationError(true);
  //       setValidationText("Required");
  //     } else {
  //       setValidationError(false);
  //       setValidationText("");
  //     }
  //   }
  // }, [value]);

  const handleOnChange = (event) => {
    const value = event.target.value;
    let validationErrorFlag = false;

    // required field validation
    if (required || validation) {
      if (value?.length > 0) {
        setValidationText("");
      } else {
        validationErrorFlag = true;
        setValidationText("Required");
      }
    }

    // email field validation
    if (email) {
      if (emailValidation(value)) {
        setValidationText("");
      } else {
        validationErrorFlag = true;
        setValidationText("Email is not valid");
      }
    }

    setValidationError(validationErrorFlag);

    if (handleValidation) {
      handleValidation(validationErrorFlag);
    }

    handleChange(event);
  };

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  const handleOnInput = (e) => {
    let maxNum = props.NumberLimit;
    if (e.target.value.length > maxNum) {
      e.target.value = e.target.value.slice(0, maxNum);
    }
  };

  return (
    <div className="TextField">
      <div className="FieldLabel">
        {label} {(required || validation) && <span>*</span>}
        {props.TooltipStatus && (
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoRoundedIcon />}
          />
        )}
      </div>
      <div className="FieldContainer">
        <div className={CharAlignMid}>
          <TextFieldCom
            onInput={handleOnInput}
            id="outlined-basic"
            variant="outlined"
            type={number ? (number === "number" ? "number" : number) : "text"}
            error={error || validationError}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
            helperText={helperText || validationText}
            disabled={disabled ? disabled : false}
            inputProps={{
              maxLength: count,
            }}
            // className={Uppercase}
            onkeyDown={onkeyDown}
          />
          <span className="FieldCount">
            {count && (
              <span
                className="CharInner"
                style={{ color: "#B6B9B9", alignSelf: "flex-end" }}
              >
                {" "}
                <span className="CHAR">CHAR</span> {!value ? "0" : value.length}
                /{count}
              </span>
            )}
          </span>
        </div>
      </div>
      {/* temporary disabled by sujan */}
      {/* <a className="AvailableButton">Available?</a> */}
    </div>
  );
}

export default TextField;
