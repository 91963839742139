import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Table from "../../../../components/Table/Table";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { toTitleCase } from "../../../../utils/helpers";
import { getUserTimezone } from "../../../../hooks/HelperFunctions";
import axios from "axios";
const AddUsers = ({
  setGroupUsers,
  groupUsers,
  addGroupUsersOptions,
  setGroupUsersToUpdate,
  groupUsersToUpdate,
  studentList,
}) => {
  const [rows, setRows] = useState([]);
  const orgId = localStorage.getItem("orgId");
  useEffect(() => {
    if (studentList?.length > 0) {
      setRows(convertDataToCellRows(studentList));
    }
  }, [studentList]);

  //====================================== search part =======================================//
  const [searchedText, setSearchedText] = useState("");
  const handleSearchText = (event) => {
    let textToSearch = event.target.value.toLowerCase()
    setSearchedText(textToSearch);

    let emptyData = [];
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    let data = {
      searchText: textToSearch,
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/searchTeamMember`, data)
      .then((res) => {
        if (!res?.data?.error) {
          setRows(convertDataToCellRows(res?.data?.body));
        } else {
          setRows(convertDataToCellRows(emptyData));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSearch = (event) => {
    let emptyData = [];
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    let data = {
      searchText: searchedText,
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/searchTeamMember`, data)
      .then((res) => {
        if (!res?.data?.error) {
          setRows(convertDataToCellRows(res?.data?.body));
        } else {
          setRows(convertDataToCellRows(emptyData));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  //====================================== search part =======================================//

  const headCells = [
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "70%",
      textAlign: "left",
    },
    {
      id: "Email",
      numeric: false,
      disablePadding: true,
      label: "Email",
      width: "50%%",
      textAlign: "right",
    },
    {
      id: "Email",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "50%%",
      textAlign: "right",
    },
  ];
  // console.log(groupUsers, 322, groupUsersToUpdate);
  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            Check: true,
            checkBoxColor: "success",
            defaultChecked:
              groupUsersToUpdate?.find((user) => user.id === item.id) !==
              undefined,
            textBold: item?.name,
            user_id: item?.id,
            user: {
              name: item?.name,
              id: item?.id,
              roles: item?.roles,
              email: item?.email,
              image: item?.image,
              status: item?.status,
            },
          },
          { text: item?.email || "N/A" },
          {},
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    let addUsers = [];
    groupUsers?.map((users) => {
      let foundUser = studentList.find((user) => user?.id === users);

      if (foundUser) {
        addUsers.push({
          name: foundUser.name,
          id: foundUser?.id,
          roles: foundUser?.roles,
          email: foundUser?.email,
          image: foundUser?.image,
          status: foundUser?.status || "",
        });
      }
    });
    // console.log(groupUsers);
    setGroupUsersToUpdate(addUsers);
  }, [groupUsers]);

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setGroupUsers((prevUsers) => [...prevUsers, item[0].user_id]);
    } else {
      // Remove the user from the array
      setGroupUsers((prevUsers) =>
        prevUsers.filter((user) => user !== item[0].user_id)
      );
    }
  };

  return (
    <>
      <div className="TableSection AddUserTable">
        <Table
          YearLabel="May 2023"
          headCells={headCells}
          rows={rows}
          innerHeader={true}
          FilterButtonDrawer={false}
          footer={true}
          emptyRowsText="No matching records found"
          RowsPerPage={10}
          //onClickFilterButton={onClickFilterButton}
          showSearchBox={true}
          handleRowClick={handleRowClick}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          // isDataLoading={isLoading || addGroupUsersOptions.isLoading}
          handleCheckItem={setGroupUsers}
          selectedCheckList={groupUsers}
          customCellObjectId="user_id"
          CheckboxDelete={true}
        />
      </div>
    </>
  );
};

export default AddUsers;
