import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RoleDetails from "./RoleDetails";
import Permissions from "./Permissions";
import UsersRole from "./UsersRole";
// import { useCheckRoleNameExistedMutation, useGetRoleDetailsQuery } from "../../../../redux/features/roles/roleApi";
import { useEffect } from "react";
import { Badge } from "@mui/material";
import useProtected from "../../../../hooks/useProtected";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const CreateRole = (props) => {
  const {
    rolesPermissionsObject,
    setRolesPermissionsObject,
    errors,
    setErrors,
    roleUsers,
    setRoleUsers,
    setRoleUsersToUpdate,
    roleUsersToUpdate,
    roleId,
    permissions,
    roleUsersToRemove,
    setRoleUsersToRemove,
    openUserTab,
    setOpenUserTab,
    setExistNameChecked,
    orgId,
    setShowSnackbar,
    setSnackbarSeverity,
    setSnackbarMessage,
    baseUrl,
    studentList,
  } = props;

  // const { data: roleDetails, isLoading: isRoleDetailsLoading, isFetching: isRoleDetailsFetching, error: roleDetailsError } = useGetRoleDetailsQuery(roleId, {
  //   skip: !roleId,
  //   refetchOnMountOrArgChange: true,
  // });
  // const [checkRoleName, checkRoleNameOptions] = useCheckRoleNameExistedMutation()
  // useProtected(checkRoleNameOptions.error)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (openUserTab) {
      setValue(2);
      setOpenUserTab(false);
    }
  }, [openUserTab]);

  //================ existing name checking part start =================//
  useEffect(() => {
    const fetchData = () => {
      if (value === 1) {
        let data = {
          name: rolesPermissionsObject?.name,
          orgId: orgId,
        };

        axios
          .post(`${baseUrl}/orgPortal/checkRoleName`, data)
          .then((response) => {
            if (response?.data?.body && !roleId) {
              setShowSnackbar(true);
              setSnackbarSeverity("error");
              setSnackbarMessage("Role name already exists");
              // toast.error("Name already exists");
              setExistNameChecked(true);
            } else {
              setExistNameChecked(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    fetchData();
  }, [value]);

  return (
    <>
      <div className="StudentDetailsContainer">
        <ToastContainer></ToastContainer>
        <div className="H_Tab_c">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  <Badge
                    variant="dot"
                    color="error"
                    sx={{
                      "&.MuiBadge-root .MuiBadge-badge.MuiBadge-dot": {
                        marginRight: "-8px !important",
                      },
                    }}
                    invisible={rolesPermissionsObject.name <= 1 ? false : true} // here you can conditionally visible or invisible the [ badge ]
                  >
                    Details
                  </Badge>
                }
                {...a11yProps(0)}
              />

              <Tab
                label={
                  <Badge
                    variant="dot"
                    color="error"
                    sx={{
                      "&.MuiBadge-root .MuiBadge-badge.MuiBadge-dot": {
                        marginRight: "-8px !important",
                      },
                    }}
                    invisible={
                      rolesPermissionsObject?.permissions?.length === 0
                        ? false
                        : true
                    } // here you can conditionally visible or invisible the [ badge ]
                  >
                    Permissions
                  </Badge>
                }
                {...a11yProps(1)}
              />
              <Tab label="Users" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <RoleDetails
              rolesPermissionsObject={rolesPermissionsObject}
              setRolesPermissionsObject={setRolesPermissionsObject}
              errors={errors}
              setErrors={setErrors}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Permissions
              rolesPermissionsObject={rolesPermissionsObject}
              setRolesPermissionsObject={setRolesPermissionsObject}
              errors={errors}
              setErrors={setErrors}
              permissions={permissions}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <UsersRole
              rolesPermissionsObject={rolesPermissionsObject}
              setRolesPermissionsObject={setRolesPermissionsObject}
              errors={errors}
              setErrors={setErrors}
              roleUsers={roleUsers}
              setRoleUsers={setRoleUsers}
              setRoleUsersToUpdate={setRoleUsersToUpdate}
              roleUsersToUpdate={roleUsersToUpdate}
              roleUsersToRemove={roleUsersToRemove}
              setRoleUsersToRemove={setRoleUsersToRemove}
              studentList={studentList}
            />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default CreateRole;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
