  import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import EmptyCard from '../../../../components/EmptyCard/EmptyCard';



function createData(Invoice, Date, Amount, Status, Action,) {
  return { Invoice, Date, Amount, Status, Action, };
}

function Completed(props) {


  const onClick = () => {
    alert("onclick working fine!");
  }

  const headCells = [
    { id: 'course', numeric: true, disablePadding: false, label: 'Course', width: "60%" },
    { id: 'Action', numeric: true, disablePadding: false, label: 'Completed', width: "40%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "15%" },
  ];

  const rows = [
    {
      cell: [
        { textBold: "Quran Level 1 - Qaida Nuraniyah", },
        { text: "Dec-26-2023", },
        { linkText: "View Certificate", linkTextStatus: true, link: "#", },
      ],
    },

    {
      cell: [
        { textBold: "Quran Level 1 - Qaida Nuraniyah", },
        { text: "Dec-26-2023", },
        { linkText: "View Certificate", linkTextStatus: true, link: "#", },
      ],
    },

  ];


  return (
    <>
      <div className="TableSection">
        <Table
          title=""
          tableHeader={false}
          innerTitle="Payment History"
          AddButtonInner={false}
          addLabelInner="Add Payment"
          innerHeader={false}
          addLabel="New User"
          link="add-courses"
          headCells={headCells}
          rows={rows}
          AddButton={false}
          Checkbox={false}
          footer={true}
        />

<div className="EmptyCardWithoutBorder">
          <EmptyCard
            description="You will find completed courses here"
            showButton={true}
            btnLabel="Explore Courses"
          />
        </div>
      </div>
    </>
  );
}






export default Completed;

