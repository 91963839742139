import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "../../../../../components/Form/TextField/TextField";
import SelectField from "../../../../../components/Form/SelectField/SelectField";
import SlateEditor from "../../../../../components/SlateEditor/SlateEditor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddCourses(props) {
  const { orgCourses, selectedCourse, setSelectedCourse } = props;

  const handleChanges = (event) => {
    console.log(event.target.value);
    setSelectedCourse(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <SelectField
            value={selectedCourse}
            label="Select course"
            options={orgCourses}
            MenuProps={MenuProps}
            handleChange={handleChanges}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="infoBox">
            Don't see your courses? <a href="/add-courses/0">Add new course</a>{" "}
            from <a href="/courses-listing">Courses</a> menu
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddCourses;
