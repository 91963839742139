import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Table from "../../../../../components/Table/Table";
import tableImg from "../../../../../assets/imgs/tableImg.png";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import DraggableDialog from "../../../../../hooks/Dialog";
import axios from "axios";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import { TbFileDownload } from "react-icons/tb";
import MediaLibrayForCourseCertificate from "../../../../../components/MediaLibrary/MediaLibrayForCourseCertificate";
import moment from "moment";

const Certificates = ({
  studentsDataForCertificate,
  courseId,
  getStudentsDataForCertificate,
}) => {
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [assignDialog, setAssignDialog] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  // ? ==== Character limit ==== * //
  const truncateText = (text, limit) => {
    return text.length > 20 ? text.substring(0, 20) + "..." : text;
  };

  //================================= show toast message ==============================//
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const processShowToastMessage = (response) => {
    // console.log(response.data);
    if (response.data.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);
      getStudentsDataForCertificate();
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.message);
      getStudentsDataForCertificate();
    }
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  //================================= show toast message ==============================//

  //================================== upload certificate ===================================//
  const [updateId, setUpdateId] = useState("");
  const handleDrawerOpenForUploaded = (id) => {
    setUpdateId(id);
    setOpenSelectMedia(true);
  };
  const uploadedCertificate = (data) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios.post(`${baseUrl}/orgPortal/uploadCertificate`, data).then((res) => {
      processShowToastMessage(res);
    });
  };

  //================================== upload certificate ===================================//

  //=================================== media libary part ==============================//
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [rows, setRows] = useState([]);

  const onClick = () => {
    alert("onclick working fine!");
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      let data = {
        userId: updateId,
        courseId: courseId,
        fileLink: mediaItem.file_url,
        fileName: mediaItem.name,
      };
      uploadedCertificate(data);
      setChecked(false);
      setAssignDialog(false);
      // console.log( trackData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
    setChecked(false);
  };
  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };
  //=================================== media libary part ==============================//

  //======================================= delete certificate ===========================//
  const handleOpenDailogBoxForDelete = (id) => {
    setUpdateId(id);
    setDeleteDialog(true);
  };
  const handleRemoveCertiFicate = () => {
    let deleteData = {
      userId: updateId,
      courseId: courseId,
    };
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .delete(`${baseUrl}/orgPortal/deleteCertificate`, { data: deleteData })
      .then((response) => processShowToastMessage(response));
    setDeleteDialog(false);
  };
  const handleOpenNewMedia = async () => {
    // setDeleteDialog(false);
  };

  //======================================= delete certificate ===========================//

  //=================================== handle send email ===================================//
  const handleOpenDailogBoxForSendEmail = (id) => {
    setUpdateId(id);
    setEmailDialog(true);
  };
  const handleSendEmail = () => {
    let sendEmailData = {
      users: [updateId],
      courseId: parseInt(courseId),
      domain: window.location.origin,
    };
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .post(`${baseUrl}/orgPortal/sendCertificates`, { ...sendEmailData })
      .then((response) => {
        processShowToastMessage(response);
        setEmailDialog(false);
      });
  };

  const assignAndSendEmail = async () => {
    clickSelectMediaItem();
    handleSendEmail();
    setChecked(false);
    setAssignDialog(false);
  };
  //=================================== handle send email ===================================//

  const handleDownload = (data) => {
    if (data) {
      const link = document.createElement("a");
      link.href = data;
      link.download = "certificate.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Certificate file link not available");
      // alert();
    }
  };

  //============================= data showing in the table ==================================//

  useEffect(() => {
    if (studentsDataForCertificate) {
      setRows(convertCellRows(studentsDataForCertificate));
    }
  }, [studentsDataForCertificate]);

  const headCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "35%",
    },
    {
      id: "Completion Certificate",
      numeric: false,
      disablePadding: true,
      label: "Completion Certificate",
      width: "40%",
    },
    {
      id: "Last Activity",
      numeric: false,
      disablePadding: true,
      label: "Last Activity",
      width: "20%",
    },
    {
      id: "Action",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "5%",
    },
  ];

  const convertCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => ({
        cell: item?.fileLink
          ? [
              {
                img: item?.image,
                imgStatus: true,
                imgText: `${item?.first_name} ${item?.last_name}`,
                align: "left",
              },
              {
                text: (
                  <Button
                    variant="text"
                    onClick={(e) => handleDownload(item?.fileLink)}
                    sx={{ textTransform: "initial" }}
                    startIcon={<TbFileDownload />}
                  >
                    {truncateText(
                      item?.fileName ? item?.fileName : item?.fileLink
                    )}
                  </Button>
                ),
              },

              {
                text: item?.last_activity_at
                  ? moment(item?.last_activity_at).format("DD-MMM-YYYY")
                  : "N/A",
              },
              {
                ThreeDotButton: true,
                buttonLabel: <MoreVertIcon />,
                buttonTextcolor: "#004FE0",
                // buttonLink: "#",
                // buttonTarget: "_blank",
              },
            ]
          : [
              {
                img: item?.image,
                imgStatus: true,
                imgText: `${item?.first_name} ${item?.last_name}`,
                align: "left",
              },

              {
                Button: true,
                buttonAlign: "left",
                padding: "0px",
                buttonLabel: "Upload",
                buttonColor: "#FFFFFF",
                // buttonBorder: "#E4E8EC 1px solid",
                buttonTextcolor: "#004FE0",
                buttonIcon: "PlusIcon",
                justifyContent: "flex-start",
                onClick: (e) => handleDrawerOpenForUploaded(item?.id),
              },
              {},
              {},
            ],

        //======= Action Date Start===== //
        action: [
          {
            label: "Send Email",
            onClick: (e) => handleOpenDailogBoxForSendEmail(item?.id),
          },
          // { label: "Edit", onClick: onClick },
          {
            label: "Delete",
            onClick: (e) => handleOpenDailogBoxForDelete(item?.id),
          },
        ],
        //======= Action Date End===== //
      }));
    } else {
      return [];
    }
  };
  //============================= data showing in the table ==================================//

  //====================================== search part =======================================//
  const [searchedText, setSearchedText] = useState("");
  const handleSearchText = (event) => {
    setSearchedText(event.target.value.toLowerCase());
  };

  const handleSearch = (event) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .get(
        `${baseUrl}/orgPortal/getCourseStudents?courseId=${courseId}&queryString=${searchedText}`
      )
      .then((res) => {
        if (!res?.data?.error) {
          setRows(convertCellRows(res?.data?.body));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  //====================================== search part =======================================//

  const rowss = [
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Shayan Mahmud",
          align: "left",
        },
        {
          text: (
            <Button
              variant="text"
              sx={{ textTransform: "initial" }}
              startIcon={<TbFileDownload />}
            >
              {truncateText("CC-shayanmahmuds hayanm ahmudshay anmahmud.pdf")}
            </Button>
          ),
        },
        {
          text: "10-Apr-2023",
        },
        // {
        //   Button: true,
        //   buttonLabel: "Upload",
        //   buttonColor: "#FFFFFF",
        //   buttonBorder: "#E4E8EC 1px solid",
        //   buttonTextcolor: "#004FE0",
        //   buttonIcon: "PlusIcon",
        //   //   onclick: (e) => console.log(e),
        // },
        {
          ThreeDotButton: true,
          buttonLabel: <MoreVertIcon />,
          buttonTextcolor: "#004FE0",
          // buttonLink: "#",
          // buttonTarget: "_blank",
        },
      ],

      //======= Action Date Start===== //
      action: [
        { label: "Send Email", onClick: () => setEmailDialog(true) },
        // { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: (e) => handleOpenDailogBoxForDelete(e) },
      ],
      //======= Action Date End===== //
    },
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Shayan Mahmud",
          align: "left",
        },
        // {
        //   text: (
        //     <Button
        //       variant="text"
        //       sx={{ textTransform: "initial" }}
        //       startIcon={<TbFileDownload />}
        //     >
        //       {truncateText("CC-shayanmahmuds hayanm ahmudshay anmahmud.pdf")}
        //     </Button>
        //   ),
        // },
        // {
        //   text: "10-Apr-2023",
        // },
        {
          Button: true,
          buttonLabel: "Upload",
          buttonColor: "#FFFFFF",
          buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0",
          buttonIcon: "PlusIcon",
          buttonJustifyContent: "left",
          onClick: (e) => handleDrawerOpenForUploaded(e),
        },
        {},
        {},
        // {
        //   ThreeDotButton: true,
        //   buttonLabel: <MoreVertIcon />,
        //   buttonTextcolor: "#004FE0",
        //   // buttonLink: "#",
        //   // buttonTarget: "_blank",
        // },
      ],

      //======= Action Date Start===== //
      action: [
        { label: "Send Email", onClick: () => setEmailDialog(true) },
        // { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: (e) => handleOpenDailogBoxForDelete(e) },
      ],
      //======= Action Date End===== //
    },
  ];

  return (
    <div>
      <div className="TableSection CertificatesTable">
        <Table
          AddButtonInner={false}
          innerTitle=""
          addLabelInner="+ Announcement"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          //   onClickInnerButton={() => handleOpenAddAnnoucement(true)}
          innerHeader={true}
          headCells={headCells}
          rows={rows}
          perPageItems={10}
          Checkbox={false}
          footer={true}
          showSearchBox={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          //   iconButtonHandler={iconButtonHandler}
        />
      </div>

      {/* ========== Media Lib =========== */}
      <div>
        <SideDrawer
          title="Media Library"
          ButtonLabel="Assign"
          arrowIcon={true}
          //clickSaveButton={clickSaveButton}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={openSelectMedia}
          handleDrawerClose={() => setOpenSelectMedia(false)}
          FooterWithoutTab={true}
          LeftButton={true}
          ButtonLeft="Upload New"
          ButtonLeftIcon={<FileUploadOutlinedIcon />}
          clickButtonLeft={() => setOpenNewMedia(true)}
          clickSaveButton={() => setAssignDialog(true)}
          body={
            <MediaLibrayForCourseCertificate
              onClickRadioIcon={setSelectedValue}
              onItemSelected={getMediaItem}
            />
          }
        />

        <Externaluse
          open={openNewMedia}
          handleCloseButton={() => setOpenNewMedia(false)}
          handleOpenNewMedia={handleOpenNewMedia}
        />
      </div>
      {/* ======== Assign Modal ============= */}
      <DraggableDialog
        openDialog={assignDialog}
        title={"Assign certificate to the learner?"}
        body={
          <div>
            The certificate will be available for the learner to view and
            download from their portal.
            <br />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckBox} />}
              label="Send email to the learner"
              labelPlacement="end"
            />
          </div>
        }
        actionButton={checked ? "Assign & Send Email" : "Assign"}
        handleCloseDialog={() => {
          setChecked(false);
          setAssignDialog(false);
        }}
        handleConfirmCloseDialog={
          !checked ? clickSelectMediaItem : assignAndSendEmail
        }
        ModalFooter={true}
      />
      {/* ======== Delete Modal ============= */}
      <DraggableDialog
        openDialog={emailDialog}
        title={"Send email to the learner?"}
        body={
          "Email the learner to inform them that their certificate is now available for download."
        }
        actionButton="Send Email"
        handleCloseDialog={() => setEmailDialog(false)}
        handleConfirmCloseDialog={handleSendEmail}
        ModalFooter={true}
      />

      {/* ======== Delete Modal ============= */}
      <DraggableDialog
        openDialog={deleteDialog}
        title={"Delete certificate"}
        body={"Are you sure, you want to delete this student certificate?"}
        actionButton="Delete"
        handleCloseDialog={() => setDeleteDialog(false)}
        handleConfirmCloseDialog={handleRemoveCertiFicate}
        ModalFooter={true}
      />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </div>
  );
};

export default Certificates;
