import React from "react";
import Buttons from "../Form/Button/Button";
import "./EmptyPage.css";

const EmptyPage = (props) => {
  return (
    <div className="EmptyPageContainer">
      <div className="EmptyPageBody">
        <img
          src="https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png"
          alt="takbir logo"
          height={50}
          width={170}
        />
        <h1>{props.pageHeading}</h1>
        <span>{props.pageSubtitle}</span>
        {props.showButton && (
          <div className="EmptyCardButton">
            <Buttons
              label={props.btnLabel}
              buttonColor="#004FE0"
              color="#fff"
              width="auto"
              height="48px"
              fontSize="14px"
              link={props.btnLink}
              icon={props.icon}
              onClick={props.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyPage;
