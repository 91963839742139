import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";

import "./Tabs.css";

function InboxTabs(props) {
  const { tabLabel, tabBody, menuLabel, handleChange, value } = props;

  // const tabData = [
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  // ]

  // const tabBody = [
  //   {
  //     id: 0,
  //     panel: "dsfsdf",
  //   },
  //   {
  //     id: 1,
  //     panel: "dsfsdfdsfsdv",
  //   },
  //   {
  //     id: 2,
  //     panel: "dsfsddsvsdvsdvsdvf",
  //   },

  // ]

  // <InboxTabs
  //   tabLabel={tabLabel}
  //   tabBody={tabBody}
  //   TabMenu={true}
  //   menuLabel={menuLabel}
  //   handleChange={handleChangeTab}
  //   value={tabValue}
  //   TabMenuLabel={
  //     <p>
  //       {tabValue === 0 &&
  //         "General"
  //       }
  //       {tabValue === 1 &&
  //         "Important"
  //       }
  //       {tabValue === 2 &&
  //         "Draft"
  //       }
  //     </p>
  //   }
  // />

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showtab = true;

  return (
    <div className="InboxTabs">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        sx={{ borderBottom: "1px solid #E6EAF0" }}
      >
        {tabLabel.map((item, i) => (
          <Tab
            label={
              <div className="InboxTabsLabel">
                {item?.label}{" "}
                {item?.Notification && (
                  <div className="InboxTabsNotification">
                    {item?.NotificationLabel}
                  </div>
                )}
              </div>
            }
            icon={item?.icon}
            {...a11yProps(0)}
          />
        ))}

        <div className="InboxTabMenuLabelBox">
          <div className="InboxTabMenuLabel">{props.TabMenuLabel}</div>
          {props.TabMenu && (
            <div className="InboxTabsMenu">
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <FilterListIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div className="InboxMobileMenuTabs">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    {tabLabel.map((item, i) => (
                      <Tab
                        onClick={handleClose}
                        label={
                          <div className="InboxTabsLabel">
                            {item.label}{" "}
                            {item.Notification && (
                              <div className="InboxTabsNotification">
                                {item.NotificationLabel}
                              </div>
                            )}
                          </div>
                        }
                        icon={item.icon}
                        {...a11yProps(0)}
                      />
                    ))}
                  </Tabs>
                </div>

                {menuLabel.map((item, i) => (
                  <span onClick={handleClose}>
                    <MenuItem
                      className="InboxAddNewListing"
                      onClick={item.onClick}
                    >
                      {item.label}
                    </MenuItem>
                  </span>
                ))}
              </Menu>
            </div>
          )}
        </div>
      </Tabs>

      <div className="InboxTabsBody">
        {tabBody.map((item, i) => (
          <TabPanel value={value} index={item.id}>
            {props.alertBox}
            {item.panel}
          </TabPanel>
        ))}
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default InboxTabs;
