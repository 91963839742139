import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import VerticalTab from "../../../../components/VerticalTab/VerticalTab";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import Announcements from "./announcements/Announcements";
import CourseMaterial from "./coursematerials/CourseMaterial";
import StudentsListing from "./students/StudentsListing";
import Resources from "./resources/Resources";
import ComingSoon from "../../../../components/ComingSoon/ComingSoon";

import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";

import EmptyCard from "../../../../components/EmptyCard/EmptyCard";

function StudentCourseDetails(props) {
  let { courseId } = useParams();

  const [currentTab, setCurrentTab] = useState(0);

  const [courseDetails, setCourseDetails] = useState([]);
  const [courseMaterial, setCourseMaterial] = useState([]);
  const [courseResources, setCourseResources] = useState([]);

  const [orgWebsiteLink, setOrgWebsiteLink] = useState("");

  const orgId = localStorage.getItem("orgId");
  const stuId = localStorage.getItem("stuId");

  useEffect(() => {
    const subDomain = localStorage.getItem("subDomain");
    let orgWebsite = process.env.REACT_APP_TAKBIR_SUBDOMAIN;
    orgWebsite = orgWebsite.replace("sub-domain", subDomain);
    setOrgWebsiteLink(orgWebsite);
  }, []);

  // update course data per/1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      getCourseDetails();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const privateCourseCard = () => {
    return (
      <EmptyCard
        TableTitle="My courses"
        TableTitleStatus={false}
        mainIcon="warning"
        title="Course is not available"
        description={`${showOrgOrIndName()} made this course private. If you have any questions, please contact the course admin.`}
        showButton={true}
        btnLabel="Contact link"
        btnLink={orgWebsiteLink}
      />
    );
  };

  // fetching course details data

  const getCourseDetails = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .get(
        `${baseUrl}/studentPortal/getCourseDetails?courseId=${courseId}&stuId=${stuId}`
      )
      .then((result) => {
        console.log(result.data.body);
        const courseDetails = result.data.body.courseDetails;
        setCourseDetails(courseDetails);
        // set course timezone into local storage
        localStorage.setItem("course_timezone", courseDetails?.timezone);
        localStorage.setItem("courseImage", courseDetails?.courseImage);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetching course material data
  const getCourseMaterial = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .get(
        `${baseUrl}/studentPortal/getCourseMaterial?courseId=${courseId}&${stuId}`
      )
      .then((result) => {
        console.log(result.data.body);
        setCourseMaterial(result.data.body);
        setCourseResources(result.data.resources);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCourseDetails();
    getCourseMaterial();
  }, []);

  const tabData = [
    // {
    //   icon: <HomeRoundedIcon />,
    //   label: "Home",
    // },
    {
      icon: <CampaignRoundedIcon />,
      label: "Announcements",
    },
    {
      icon: <EventRoundedIcon />,
      label: "Calendar",
    },

    {
      icon: <MenuRoundedIcon />,
      label: "Sessions",
    },
    // {
    //   icon: <MenuBookRoundedIcon />,
    //   label: "Assignments & Exams",
    // },
    {
      icon: <FolderRoundedIcon />,
      label: " Resources",
    },

    // {
    //   icon: <SchoolRoundedIcon />,
    //   label: "Grades",
    // },

    // {
    //   icon: <EventAvailableIcon />,
    //   label: "Attendance",
    // },

    {
      icon: <InsightsRoundedIcon />,
      label: "Track",
    },
  ];

  const tabBody = [
    // {
    //   id: 0,
    //   panel: "Home",
    // },
    {
      id: 0,
      panel: <Announcements courseId={courseId} />,
    },
    {
      id: 1,
      panel: <ComingSoon title="Calendar" />,
    },

    {
      id: 2,
      panel: <CourseMaterial courseMaterial={courseMaterial} />,
    },

    // {
    //   id: 4,
    //   panel: "Assignments & Exams",
    // },

    {
      id: 3,
      panel: <Resources courseResources={courseResources} />,
    },
    // {
    //   id: 3,
    //   panel: "Attendance",
    // },

    // {
    //   id: 4,
    //   panel: <StudentsListing />,
    // },
    {
      id: 4,
      panel: <ComingSoon title="Track" />,
    },
  ];

  /** get Organization or Individual Name */
  const showOrgOrIndName = () => {
    if (courseDetails.orgType === "org") {
      return courseDetails.orgFullName;
    } else {
      if (courseDetails.indFirstName) {
        return `${courseDetails.indFirstName} ${courseDetails.indLastName}`;
      } else {
        return "";
      }
    }
  };

  const breadcrumbsData = [
    {
      label: "Courses",
      link: "/my-courses",
      icon: <MenuBookRoundedIcon fontSize="small" />,
    },

    {
      label: "Course details",
      link: "",
    },
  ];

  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  };

  // enable this when you need to test private card
  // courseDetails.status = "private";

  return (
    <div>
      <Breadcrumbs breadcrumbsData={breadcrumbsData} />
      <VerticalTab
        TabTitleStatus={true}
        title={courseDetails.courseTitle}
        name={showOrgOrIndName()}
        //link="#"
        tabData={courseDetails.status == "private" ? [] : tabData}
        tabBody={courseDetails.status == "private" ? [] : tabBody}
        tabExtraBody={
          courseDetails.status == "private" ? privateCourseCard() : null
        }
        onTabSwitch={handleTabSwitch}
        Button={courseDetails.status == "private" ? false : true}
        ButtonLabel="Join Session"
        buttonLink={courseDetails.buttonLink}
        buttonTarget="_blank"
        tooltipText={courseDetails.tooltipText}
        disabled={courseDetails.buttonDisabled}
      />
    </div>
  );
}

export default StudentCourseDetails;
