import React, { useEffect, useState } from "react";
import AlrtMessage from "../../../../../components/Form/AlrtMessage/AlertMessage";
import Table from "../../../../../components/Table/Table";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import DraggableDialog from "../../../../../hooks/Dialog";
import tableImg from "../../../../../assets/imgs/tableImg.png";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Progress from "./Progress";
import Grid from "@material-ui/core/Grid";
import InfoAlert from "../InfoAlert";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import ExportFilterLearners from "./ExportFilterLearners";
import EmptyCard from "../../../../../components/EmptyCard/EmptyCard";
import jsonexport from "jsonexport/dist";
import * as XLSX from "xlsx";
import axios from "axios";
import Profile from "./Profile";
import { toast } from "react-toastify";
function Learners(props) {
  const { studentList, trackId, getStudentList } = props;
  const [csvData, setCsvData] = useState("");
  const [rows, setRows] = useState([]);
  const [isVisibibleStudentDetails, setIsVisibibleStudentDetails] =
    useState(false);
  const [showDetailUserId, setShowDetailUserId] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (studentList?.length > 0) {
      setRows(convertDataToCellRows(studentList));
    }
  }, [studentList]);

  const seeDetailsClickHandler = (item) => {
    setShowDetailUserId(item?.userId);
    setIsVisibibleStudentDetails(true);
  };

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        // Given timestamp string
        let timestampString = item?.enrolled_at;

        // Convert string to Date object
        let date = new Date(timestampString);

        // Format the date
        let formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }).format(date);

        let statusBackground = "";
        let statusColor = "";
        let statusText = "";
        if (item?.studentStatusToTrack == "active") {
          statusBackground = "#E1F2E8";
          statusColor = "#0B9444";
          statusText = "Active";
        } else if (item?.studentStatusToTrack == "dropped") {
          statusBackground = "#FFF5E0";
          statusColor = "#FFAA00";
          statusText = "Dropped";
        } else if (item?.studentStatusToTrack == "blocked") {
          statusBackground = "#F7E3E3";
          statusColor = "#BA1A1A";
          statusText = "Blocked";
        }

        return {
          cell: [
            { textBold: `${item?.first_name} ${item?.last_name}` },
            { text: formattedDate },
            // { text: "$100", },
            {
              Status: true,
              background: statusBackground,
              color: statusColor,
              label: statusText,
            },
            // { linkText: <div style={{ display: "flex", alignItems: "center", }}>1/5 <ArrowForwardIosRoundedIcon style={{ fontSize: "13px", marginLeft: "8px", }} /></div>, linkTextStatus: true, onClickLinkText: handleDrawerOpen, },
            {
              Button: true,
              buttonLabel: "Details",
              buttonTextcolor: "#004FE0",
              buttonLink: "#",
              padding: "0 6px",
              width: "initial",
              onClick: (e) => {
                seeDetailsClickHandler(item);
              },
            },
          ],
        };
      });
    } else {
      return [];
    }
  };

  //======= Drawer ===== //
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setIsVisibibleStudentDetails(false);
  };
  //======= Drawer ===== //

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  //======= Delete dialog ===== //

  const handleOpenDialog = (id) => {
    console.log(id);
    // setActiveCourseId(id);
    // setDialogTitle("Delete this track?");
    // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
    setOpenDialog(true);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const onClick = () => {
    alert("onclick working fine!");
  };

  const headCells = [
    {
      id: "Seq",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "33%",
    },
    {
      id: "Type",
      numeric: true,
      disablePadding: false,
      label: "Enrolled",
      width: "33%",
    },
    // { id: 'Track', numeric: true, disablePadding: false, label: 'Due', width: "18%" },
    {
      id: "Track",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "33%",
    },
    {
      id: "Track",
      numeric: true,
      disablePadding: false,
      label: "Action",
      width: "27%",
      align: "center",
    },
    // { id: '', numeric: true, disablePadding: false, label: '', align: "right", width: "18%", },
  ];

  const [age, setAge] = React.useState("");
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Term Name",
      value: "Term Name",
    },
  ];

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //================================= export file ========================================//

  const initailObjectFilter = {
    format: "excel",
    dateRange: "",
    user_and_courses: [],
    gender: [],
    status: [],
  };
  const [filterInfo, setFilterInfo] = useState(initailObjectFilter);
  //======= Export Drawer ===== //
  const [openExport, setOpenExport] = useState(false);
  const handleDrawerOpenExport = () => {
    setOpenExport(true);
  };
  const handleDrawerCloseExport = () => {
    setOpenExport(false);
    setExportError(exportIntailsError);
    setExportErrorStatus(false);
    setShowSnackbar(false);
    setFilterInfo(initailObjectFilter);
  };
  //======= Drawer ===== //
  const convertToCSV = async (jsonData) => {
    return jsonexport(jsonData);
  };
  const convertToExcel = async (jsonData) => {
    const resolvedData = await jsonData;
    const ws = XLSX.utils.json_to_sheet(resolvedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return excelBuffer;
  };

  const downloadFile = (fileData, fileName, fileType) => {
    const blob = new Blob([fileData], { type: fileType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    console.log("filterInfo : ", filterInfo, 345);
  }, [filterInfo]);
  const exportIntailsError = {
    format: "",
    dateRange: "",
  };
  const [exportError, setExportError] = useState(exportIntailsError);
  const [exportErrorStatus, setExportErrorStatus] = useState(false);
  const checkExportError = () => {
    let error = 0;
    let errorObject = { ...exportError };
    if (filterInfo.format === "") {
      error++;
      errorObject.format = "Required";
    } else {
      errorObject.format = "";
    }

    if (filterInfo.dateRange === "") {
      error++;
      errorObject.dateRange = "Required";
    } else {
      errorObject.dateRange = "";
    }

    setExportError({ ...exportError, ...errorObject });
    if (error > 0) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Required felids are left");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (exportErrorStatus) {
      checkExportError();
    }
  }, [exportErrorStatus, filterInfo]);
  const exportStudentHandler = () => {
    setExportErrorStatus(true);
    const isValid = checkExportError();
    if (!isValid) {
      return false;
    }
    if (
      filterInfo?.payment?.length === 0 &&
      filterInfo?.gender?.length === 0 &&
      filterInfo?.stream?.length === 0 &&
      filterInfo?.users?.length === 0
    ) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please select any of the checkboxes to proceed.");
      return false;
    }
    getExportStudentInfo(filterInfo);
    setExportError(exportIntailsError);
    setExportErrorStatus(false);
    handleDrawerCloseExport();
  };

  const getExportStudentInfo = async (filterStudent) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    let postData = {
      ...filterStudent,
      trackId: trackId,
    };

    axios
      .post(`${baseUrl}/orgPortal/exportTrackLearners`, postData)
      .then(async (response) => {
        let infoToShow = response?.data?.body?.map((singleInfo) => {
          if (singleInfo.hasOwnProperty("stream")) {
            singleInfo.stream = singleInfo.stream == "" ? "Online" : "Manual";
          }
          if (singleInfo.hasOwnProperty("status")) {
            singleInfo.status =
              singleInfo?.status?.charAt(0).toUpperCase() +
              singleInfo?.status?.slice(1);
          }
          return singleInfo;
        });
        console.log(infoToShow, "infoToShow");
        if (filterStudent?.format) {
          if (filterStudent?.format == "excel") {
            let excelData = await convertToExcel(infoToShow);
            downloadFile(
              excelData,
              "enrolled_students.xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
          } else if (filterStudent?.format == "csv") {
            let csvDataResponse = await convertToCSV(infoToShow);
            setCsvData(csvDataResponse);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          console.error("error", err.response.data.message);
        }
      });
  };
  //================================= export file ========================================//

  // useEffect(() => {
  //   console.log(filterInfo, 345);
  // }, [filterInfo]);

  return (
    <>
      {/* <CustomizedSnackbar message="Track removed" severity="success" /> */}

      <AlrtMessage message="Course added" background="#0B9444" />

      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={12} md={12}>
          <InfoAlert />
        </Grid> */}

        <Grid item xs={12} sm={12} md={12}>
          {studentList?.length > 0 ? (
            <div className="TableSection">
              <Table
                headCells={headCells}
                rows={rows}
                footer={true}
                innerHeader={true}
                AddButtonInner={true}
                addLabelInner="Export"
                InnerButtonColor="#E0EAFB"
                InnerButtonTextColor="#004FE0"
                InnderButtonIcon={<FileUploadOutlinedIcon />}
                onClickInnerButton={handleDrawerOpenExport}
                handleOpenDialog={handleOpenDialog}
                showSearchBox={false}
                // showSearchBox={true}
                SearchWidth="360px"
                SelectField={false}
                options={options}
                handleChanges={handleChange}
              />
            </div>
          ) : (
            <EmptyCard
              title="No learners enrolled yet"
              description="Enrolled learners will be visible here"
            />
          )}
        </Grid>
      </Grid>

      <SideDrawer
        title="Progress"
        ButtonLabel="View More Details"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={open}
        FooterWithoutTab={true}
        handleDrawerClose={handleDrawerClose}
        body={<Progress />}
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Remove course from track?"
        body={
          <div>
            Are you sure you want to remove this course? <br></br>You can add
            the course again to this track.
          </div>
        }
        ModalFooter={true}
        actionButton="Delete"
      />

      <SideDrawer
        title="Export Students"
        ButtonLabel="Export"
        cancelButtonLabel="Cancel"
        //buttonLink={`/student-details/${studentData.stuId}`}
        sideOpen="right"
        open={openExport}
        handleDrawerClose={handleDrawerCloseExport}
        FooterWithoutTab={true}
        body={
          <ExportFilterLearners
            filterInfo={filterInfo}
            setFilterInfo={setFilterInfo}
            exportError={exportError}
          />
        }
        clickSaveButton={(e) => exportStudentHandler()}
      />

      <SideDrawer
        title="Student Details"
        buttonLabel="View More Details"
        sideOpen="right"
        open={isVisibibleStudentDetails}
        handleDrawerClose={handleDrawerClose}
        FooterWithoutTab={true}
        ButtonLabel="View More Details"
        clickSaveButton={() =>
          (window.location.href = `/student-details/${showDetailUserId}`)
        }
        cancelButtonLabel="Close"
        body={
          <>
            {/* <AddOneByOne/> */}
            <Profile
              userId={showDetailUserId}
              trackId={trackId}
              getStudentList={getStudentList}
            />
          </>
        }
      />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default Learners;
