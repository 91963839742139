import React, { useEffect, useState } from "react";
import { Container, Grid, TextField } from "@mui/material";
import { FacebookShareButton, EmailShareButton } from "react-share";
import Facebook from "../../assets/imgs/Facebook-Icon.svg";
import Email from "../../assets/imgs/email_black.svg";
import Buttons from "../Form/Button/Button";
import "./ShearModalBody.css";

const ShearModalBody = ({ seletedItem }) => {
  const [isCopied, setIsCopied] = useState(false);

  const [shareUrl, setshareUrl] = useState("");

  useEffect(() => {
    setshareUrl(window.location.href);
  }, [seletedItem]);

  const handleCopy = (data) => {
    if (data) {
      navigator.clipboard.writeText(data);
      setIsCopied(true);
    }
  };
  return (
    <div className="ShareThisCourse WhiteSection" id="share">
      <Container maxWidth="sm" sx={{ py: 2 }}>
        <div className="SocialLinks">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="SocialButtons">
                <FacebookShareButton
                  url={seletedItem.fileLink}
                  //   quotes={trackDetails.name}
                  hashtag={"#hashtag"}
                  //   description={trackDetails.description}
                  style={{ minHeight: "64px", paddingLeft: "19px" }}
                  className="ShareButton"
                >
                  <img src={Facebook} /> &nbsp; Facebook
                </FacebookShareButton>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="SocialButtons">
                <EmailShareButton
                  subject={seletedItem.courseTitle}
                  body={seletedItem.fileLink}
                  separator=" "
                  url={""}
                  style={{ minHeight: "64px", paddingLeft: "19px" }}
                  className="ShareButton"
                >
                  <img src={Email} /> &nbsp; Email
                </EmailShareButton>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="HelperTextSocial">
                Copy and paste this link anywhere
              </p>
              <div className="TextField ModalCopyField">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={seletedItem.fileLink}
                  readOnly
                />
                <div style={{ width: "100%", margin: "0 auto" }}>
                  <Buttons
                    label={isCopied ? "Copied!" : "Copy Link"}
                    buttonColor="#004FE0"
                    color="#fff"
                    height="48px"
                    fontSize="14px"
                    link=""
                    width="100%"
                    // onClick={() => setIsCopied(!isCopied)}
                    onClick={() => handleCopy(seletedItem.fileLink)}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}></div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default ShearModalBody;
