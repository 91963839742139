import React from "react";
import Button from "@mui/material/Button";
import "./Button.css";

function Buttons(props) {
  return (
    <div className="ButtonContainer">
      <Button
        href={props.link}
        target={props.target}
        disabled={props.disabled}
        className={props.className}
        style={{
          background: props.buttonColor,
          color: props.color,
          height: props.height,
          fontSize: props.fontSize,
          fontWeight: props.fontWeight,
          border: props.border,
          width: props.width,
          borderRadius: props.borderRadius,
          padding: props.padding,
          float: props.align,
          minWidth: props.minWidth,
          justifyContent: props.justifyContent,
        }}
        onClick={() => (props.onClick ? props.onClick() : undefined)}
        aria-controls={props.controls}
        aria-haspopup={props.haspopup}
        aria-expanded={props.expanded}
      >
        {props.icon}
        {props.label}
        <div className="ButtonIconAfter">{props.iconAfter}</div>
      </Button>

      {/* import AddRoundedIcon from '@mui/icons-material/AddRounded'; */}
      {/* <Buttons
        label="Join for Free"
        icon={}
        buttonColor="#004FE0"
        border="#E4E8EC 1px solid"
        color="#fff"
        width=""
        height="60px"
        fontSize="20px"
        link=""
        onClick={}
      /> */}
    </div>
  );
}

export default Buttons;
