import React, { useState, useEffect } from "react";

import "./VerticalTab.css";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Buttons from "../Button/Button";
import Tooltip from "../Tooltip/Tooltip";

import Box from "@mui/material/Box";
import TabsMobile, { tabsClasses } from "@mui/material/Tabs";
import TabMobile from "@mui/material/Tab";

import StickyBox from "react-sticky-box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import AddNew from "../../pages/AddNew";

import ScrollBar from "../ScrollBar/ScrollBar";

function InboxVerticalTab(props) {
  const {
    tabData,
    tabBody,
    tabExtraBody,
    handleChange,
    value,
    addNewMenuListing,
  } = props;

  // const tabData = [
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  // ]

  // const tabBody = [
  //   {
  //     id: 0,
  //     panel: "dsfsdf",
  //   },
  //   {
  //     id: 1,
  //     panel: "dsfsdfdsfsdv",
  //   },
  //   {
  //     id: 2,
  //     panel: "dsfsddsvsdvsdvsdvf",
  //   },

  // ]

  // <InboxVerticalTab
  //       title="Introduction to Islamic Economics and Finance"
  //       name="MUNA Academy"
  //ButtonLabel=""
  //Button={true}
  //       link="#"
  //       tabData={tabData}
  //       tabBody={tabBody}
  //     />

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const addNewMenuListing = [
  //   {
  //     label: "Archived Conversations",
  //   },
  //   {
  //     label: "Unread Conversations",
  //   },
  // ];

  return (
    <div className="InboxVerticalTab">
      <div className="TabListingContainer">
        <StickyBox offsetTop={120} offsetBottom={20}>
          {props.TitleStatus && (
            <div className="TabTitle">
              <h1>{props.title}</h1>
              <p>
                <a href={props.link} target="_blank" rel="noreferrer">
                  {props.name}
                </a>
              </p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginBottom: "20px",
            }}
          >
            {props.AddNewButton && (
              <AddNew
                ButtonBlue={true}
                ButtonHeight="58px"
                Radius="16px"
                Width="105px"
              />
            )}
          </div>

          {/* {props.AddNewButton &&
            <div className="InboxAddNewMenuButton">

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <AddRoundedIcon /> New
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {addNewMenuListing.map((item, i) => (
                  <span onClick={handleClose}>
                    <a className="InboxAddNewListing" href={item.link} onClick={item.menueOnclick}>{item.icon}{item.label}</a>
                  </span>
                ))}
              </Menu>

            </div>
          } */}

          {/* {props.Button &&
            <div style={{ marginTop: "20px" }}>
              <Tooltip
                TooltipText={props.tooltipText}
                Tooltip={
                  <Buttons
                    label={props.ButtonLabel}
                    buttonColor="#004FE0"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link={props.buttonLink}
                    target={props.buttonTarget}
                    onClick={props.buttonOnClick}
                    disabled={props.disabled}
                  />
                }
              />

            </div>
          } */}
          <ScrollBar
            ScrollBarHeight={props.ScrollBarHeight}
            body={
              <>
                {props.TabData && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    {tabData.map((item, i) => (
                      <Tab
                        icon={item.icon}
                        {...a11yProps(0)}
                        label={
                          <div className="InboxVtabListing">
                            <Stack direction="row" spacing={2}>
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <div>
                                    {item.Badge && (
                                      <div
                                        className="InboxUserBadge"
                                        style={{
                                          background: item.badgecolor,
                                          color: "#fff",
                                        }}
                                      >
                                        {item.badgeIcon}
                                      </div>
                                    )}
                                  </div>
                                }
                              >
                                <Avatar
                                  alt={item.name}
                                  src={item.img}
                                  sx={{ bgcolor: item.iconcolor }}
                                >
                                  {item.usericon}
                                </Avatar>
                              </Badge>
                            </Stack>
                            <div className="InboxVtabListingInfo">
                              <div className="ImboxVtabListName">
                                {item.name}

                                <span>
                                  {item.dotIcon}
                                  {/* <FiberManualRecordRoundedIcon /> */}
                                </span>
                              </div>
                              <div
                                className="ImboxVtabSubTitle "
                                style={{ fontWeight: item.fontWeight }}
                              >
                                {item.subtitle?.length > 50
                                  ? `${item.subtitle.substring(0, 50)}...`
                                  : item.subtitle}
                              </div>
                              <div className="ImboxVtabListDisBox">
                                <div className="ImboxVtabListDiscription">
                                  {item.description?.length > 30
                                    ? `${item.description.substring(0, 30)}...`
                                    : item.description}
                                </div>
                                <div className="ImboxVtabListTime">
                                  {item.time}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        onClick={item.onClick}
                      />
                    ))}
                  </Tabs>
                )}
              </>
            }
          />
        </StickyBox>
      </div>

      {props.TabBody && (
        <div className="VerticalTabBody">
          {tabBody.map((item, i) => (
            <TabPanel value={value} index={item.id}>
              {props.alertBox}
              {item.panel}
            </TabPanel>
          ))}
          {tabExtraBody ? tabExtraBody : ""}
        </div>
      )}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default InboxVerticalTab;
