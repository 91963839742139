import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '../../../../../components/Form/TextField/TextField'
import SelectField from '../../../../../components/Form/SelectField/SelectField'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import PaidIcon from '@mui/icons-material/Paid';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';

import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function Price(props) {

  const { courseData, setCourseData } = props;

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = event.target.value;
    if(fieldName === 'price'){
      fieldData = fieldData < 0 ? 0 : fieldData
      setCourseData({ ...courseData, [fieldName]: fieldData });
    }else{
      setCourseData({ ...courseData, [fieldName]: fieldData });
    }
    
  };


  const [value, setValue] = React.useState(() => {
    if (typeof courseData.priceCat !== 'undefined' && courseData.priceCat == "Paid") {
      return 1;
    }
    return 0;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    const fieldName = "priceCat";
    const fieldData = newValue == 0 ? "Free" : "Paid";
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };


  // ==== Promo code Value === //
  const getPromoCodes = () => {
    const promoCodes = [{ value: 1, label: "AlhamduLillah" }, { value: 2, label: "SubhaNallah" }];
    return promoCodes;
  }


  return (
    <>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Price</div>


            <div className="CardTabs">
              <div className="RedioBox">
                <Tabs value={value} onChange={handleChange}>

                  <Tab label={
                    <div className="RedioContents">
                      <div className="RedioIcon">
                        <FiberManualRecordOutlinedIcon className="UnSelectedRedio" />
                        <FiberManualRecordIcon className="SelectedRedio" />
                      </div>
                      <div className="RedioLebel"><h3>Free</h3> <p>This course is free</p></div>
                    </div>
                  }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <div className="RedioContents">
                        <div className="RedioIcon">
                          <FiberManualRecordOutlinedIcon className="UnSelectedRedio" />
                          <FiberManualRecordIcon className="SelectedRedio" />
                        </div>
                        <div className="RedioLebel"><h3>Paid</h3> <p>This course is paid</p></div>
                      </div>
                    }
                      {...a11yProps(1)}
                    />
                </Tabs>
              </div>
              <div className="CardTabsContent">
                <TabPanel value={value} index={0}>
                </TabPanel>
                <TabPanel value={value} index={1}>

                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={12} md={12}>
                      <div className="priceFiled">
                      <AttachMoneyRoundedIcon className="priceICon" />
                        <TextField
                          label="Enter Price"
                          required={true}
                          count={4}
                          value={courseData.price}
                          handleChange={handleChanges("price")}
                          number="number"
                        />
                        
                      </div>

                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12}>
                      <div className="promoCodeFiled">
                        <SelectField
                          label="Select promocode"
                          validation={false}
                          selectValue={getPromoCodes()}
                          options={courseData.promoCodes}
                          handleChange={handleChanges("promoCodes")}
                        />
                      </div>
                    </Grid> */}

                  </Grid>

                </TabPanel>
              </div>
            </div>


          </div>

        </Grid>

      </Grid>

    </>
  );
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default Price;

