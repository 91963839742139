import React, { useEffect, useState } from "react";
import '../assets/css/inbox.css';
import InboxVerticalTab from '../components/Tabs/VerticalTab';



function InboxListing(props) {




  return (

    <div>
      <InboxVerticalTab
        TitleStatus={props.TitleStatus}
        title={props.title}
        name={props.subtitle}
        ButtonLabel={props.ButtonLabel}
        Button={props.Button}
        tabData={props.tabData}
        tabBody={props.tabBody}
        TabData={props.TabData}
        TabBody={props.TabBody}
        handleChange={props.handleChange}
        value={props.value}
        Welcome={props.Welcome}
        addNewMenuListing={props.addNewMenuListing}
        AddNewButton={props.AddNewButton}
        ScrollBarHeight={props.ScrollBarHeight}

      />

    </div>

  );
}


export default InboxListing;





































