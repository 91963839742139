import React, { useEffect, useRef, useState } from "react";
import "../assets/css/inbox.css";
import { Grid } from "@mui/material";
import FullLayout from "../components/FullLayout/FullLayout";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TextField from "../components/TextField/TextField";
import TextEditor from "../components/TextEditor/TextEditor";
import InboxTabs from "../components/Tabs/Tabs";
import Courses from "./Courses";
import Individuals from "./Individuals";
import SendDropdown from "./SendDropdown";
import Attach from "./Attach";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import EmailIcon from "@mui/icons-material/Email";
import { MegaphoneFill } from "../../../../assets/imgs/IconifyIcons/MegaPhoneFill";

function InboxMessage(props) {
  const [searchParams] = useSearchParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { type } = useParams();
  const orgId = localStorage.getItem("orgId");
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const intitalObeject = {
    conversation_name: "",
    conversation_type: type, // this could be three types message, announcement, email
    initiated_user_id: userData?.id,
    initiated_instructor_id: 0,
    initiated_org_id: orgId,
    participants: [],
    message_text: "",
    attachments: [],
    course_id: "",
  };
  const inboxInfoError = {
    conversation_name: "",
    message_text: "",
    participants: "",
    attachments: "",
  };
  const navigate = useNavigate();
  const [errors, setErrors] = useState(inboxInfoError);
  const [inboxInfo, setInboxInfo] = useState(intitalObeject);
  let [messageText, setMessageText] = useState(inboxInfo?.message_text);
  const [
    allCoursesOfAnOrganizationWithStudents,
    setAllCoursesOfAnOrganizationWithStudents,
  ] = useState([]);
  const [
    allIndividualsOfOrganizationUsers,
    setAllIndividualsOfOrganizationUsers,
  ] = useState([]);
  const [customText, setCustomText] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [conversation_id, setConversation_id] = React.useState(null);
  const [course_id, setCourse_id] = useState("");
  const [participants, setParticipants] = useState([]);
  const [participantsUsers, setParticipantsUsers] = useState([]);
  const [participantsUsersData, setParticipantsData] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [formattedUserData, setFormattedUserData] = useState([]);

  const [totalSizeError, setTotalSizeError] = React.useState(false);
  const [copyPaste, setCopyPaste] = React.useState(false);
  const [draftStatus, setDraftStatus] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [tabValue, tabsetValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    tabsetValue(newValue);
  };

  //================= attachment =================================//
  const attachmentsInitialObject = {
    id: 0,
    attachment_name: "",
    attachment_type: "",
    attachment_link: "",
    attachment_size: "",
    attachment_file_name: "",
  };

  const [attachmentInfo, setAttachmentInfo] = useState(
    attachmentsInitialObject
  );
  //================= attachment =================================//

  //================================ get params data ===========================================//
  useEffect(() => {
    const courseId = searchParams.get("course_id");
    const participants = searchParams.get("participants");
    const conversation_id = searchParams.get("conversation_id");
    if (courseId) {
      setCourse_id(courseId);
    }
    if (conversation_id) {
      setConversation_id(conversation_id);
    }

    if (participants) {
      const participantsArray = participants
        .split(",")
        .map((item) => parseInt(item.trim(), 10));
      setParticipants(participantsArray);
      tabsetValue(1);
    }
  }, [searchParams]);
  //============================= get params data =========================================//

  //========================== get single draft data =========================================//
  useEffect(() => {
    if (conversation_id) {
      getSingleDraftConversation(conversation_id);
    }
  }, [conversation_id]);
  //========================== get single draft data =========================================//

  //============================= delete draft message from draft section ==============//
  useEffect(() => {
    if (conversation_id && draftStatus) {
      deleteDraftConversation(conversation_id);
    }
  }, [conversation_id, draftStatus]);
  //============================= delete draft message from draft section ==============//
  useEffect(() => {
    let addUsers = [];
    participants?.map((users) => {
      let foundUser = allIndividualsOfOrganizationUsers.find(
        (user) => user?.id === users
      );
      if (foundUser) {
        addUsers.push({
          first_name: foundUser?.first_name,
          last_name: foundUser?.last_name,
          id: foundUser?.id,
          role: foundUser?.role,
          image: foundUser?.image,
        });
      }
    });
    setParticipantsUsers(addUsers);
    // console.log(participants, 345);
  }, [participants]);

  //=============================== fetch data =========================================//
  const getInstructorByOrgAndUserId = () => {
    let data = {
      orgId: orgId,
      userId: userData?.id,
    };
    axios
      .post(`${baseUrl}/orgPortal/getInstructorByOrgAndUserId`, data)
      .then((result) => {
        setInboxInfo({
          ...inboxInfo,
          initiated_instructor_id: result?.data?.body?.instId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCoursesOfAnOrganizationWithStudents = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(
        `${baseUrl}/orgPortal/getAllCoursesOfAnOrganizationWithStudents`,
        data
      )
      .then((result) => {
        // console.log(result, 345);
        setAllCoursesOfAnOrganizationWithStudents(result?.data?.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllIndividualsOfOrganization = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/getAllIndividualsOfOrganization`, data)
      .then((result) => {
        const withOutIdNull = result?.data?.data?.filter(
          (item) => item?.id !== null
        );
        // console.log(withOutIdNull.length, 345);

        setAllIndividualsOfOrganizationUsers(withOutIdNull);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSingleDraftConversation = (id) => {
    let data = {
      draft_conversation_id: id,
      user_id: userData?.id,
      message_from: 0,
    };
    axios
      .post(`${baseUrl}/orgPortal/getSingleDraftConversation`, data)
      .then((result) => {
        if (result?.status === 200) {
          let conversation_info = result?.data?.conversation?.conversation_info;

          let conversationParticipants =
            result?.data?.conversation?.conversation_info?.participants;
          conversationParticipants = conversationParticipants.slice(1);
          let participants = conversationParticipants?.map((participant) => {
            return {
              user_id: participant?.user_id,
              participant_type: participant?.participant_type,
            };
          });
          setParticipantsData(participants);
          let conversationMessages =
            result?.data?.conversationMessages[0]?.message_text;

          setInboxInfo({
            ...inboxInfo,
            conversation_name: conversation_info?.conversation_name,
            conversation_type: conversation_info?.conversation_type,
            initiated_user_id: conversation_info?.initiated_user_id,
            initiated_instructor_id: conversation_info?.initiated_instructor_id,
            initiated_org_id: conversation_info?.initiated_org_id,
            participants: participants,
            message_text: conversationMessages,
            attachments: result?.data?.conversationMessages[0]?.attachments,
            course_id: conversation_info?.course_id,
          });
          setCourse_id(conversation_info?.course_id);
          setMessageText(conversationMessages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteDraftConversation = (id) => {
    let data = {
      draft_conversation_id: id,
    };
    axios
      .post(`${baseUrl}/orgPortal/deleteDraftConversation`, data)
      .then((result) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (orgId && userData?.id) {
      getInstructorByOrgAndUserId();
      getAllCoursesOfAnOrganizationWithStudents();
      getAllIndividualsOfOrganization();
    }
  }, [orgId, userData?.id]);

  //=============================== fetch data =========================================//
  useEffect(() => {
    let addUsers = [];
    if (participants?.length > 0) {
      participants?.map((users) => {
        let foundUser = allIndividualsOfOrganizationUsers?.find(
          (user) => user?.id === users?.user_id
        );

        if (foundUser) {
          addUsers.push({
            first_name: foundUser?.first_name,
            last_name: foundUser?.last_name,
            id: foundUser?.id,
            role: foundUser?.role,
            image: foundUser?.image,
          });
        }
      });
    }
  }, [participantsUsersData]);

  const tabLabel = [
    {
      label: "Courses",
    },

    {
      label: "Individuals",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: (
        <Courses
          allCoursesOfAnOrganizationWithStudents={
            allCoursesOfAnOrganizationWithStudents
          }
          inboxInfo={inboxInfo}
          setInboxInfo={setInboxInfo}
          course_id={course_id}
        />
      ),
    },
    {
      id: 1,
      panel: (
        <Individuals
          allIndividualsOfOrganizationUsers={allIndividualsOfOrganizationUsers}
          inboxInfo={inboxInfo}
          setInboxInfo={setInboxInfo}
          participants={participants}
          participantsUsers={participantsUsers}
          formattedUserData={formattedUserData}
          setFormattedUserData={setFormattedUserData}
          setSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          setSelectedUsersData={setSelectedUsersData}
          selectedUsersData={selectedUsersData}
        />
      ),
    },
  ];

  //============================= insert data =========================================//

  const textAreaRef = useRef(null);

  const textEditorChangeHandler = (val) => {
    const newObject = { ...inboxInfo, message_text: val };
    setInboxInfo(newObject);
  };

  const onChangeHandler = (val, e, fieldName) => {
    let newObject = { ...inboxInfo, [fieldName]: e.target.value };
    setInboxInfo(newObject);
  };
  //============================= insert data =========================================//

  const extractPlainText = (jsonData) => {
    const textSegments = [];

    // Check if jsonData is a valid JSON string
    let parsedData;
    try {
      parsedData = JSON.parse(jsonData);
    } catch (error) {
      console.error("Invalid JSON string:", error);
      return ""; // Return empty string if jsonData is not valid JSON
    }

    const processChildren = (children) => {
      children.forEach((item) => {
        if (item.text !== undefined) {
          textSegments.push(item.text);
        } else if (item.children) {
          processChildren(item.children); // Recursively process nested children
        } else if (item.text === "") {
          textSegments.push(" ");
        }
      });
    };

    let allChildren = parsedData ? parsedData[0]?.children : null;
    if (allChildren) {
      processChildren(allChildren);
    }

    return textSegments.join("");
  };
  //=============================== copy paste part start ===============================//
  const handleCopyCode = () => {
    let text = extractPlainText(inboxInfo?.message_text);
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied");
          setCopyPaste(true);
        })
        .catch((error) => {
          console.error("Error copying to clipboard: ", error);
          toast.error("Failed to copy code");
        });
    } else {
      toast.warning("No code to copy");
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    navigator.clipboard
      .readText()
      .then((text) => {
        let pasteData = [
          ...text.split(",").filter((char) => char.trim() !== ""),
        ];
        setCustomText(pasteData);
      })
      .catch((err) => {
        console.error("Failed to read clipboard contents: ", err);
      });
  };
  //=============================== copy paste part end ===============================//

  //======================================= checking errors =============================//
  const checkingValidaty = () => {
    let error = 0;
    let errorObject = { ...errors };
    if (inboxInfo?.conversation_name === "") {
      errorObject = { ...errorObject, conversation_name: "Required" };
      error++;
    }
    if (extractPlainText(inboxInfo?.message_text)?.length === 0) {
      errorObject = { ...errorObject, message_text: "Required" };
      error++;
    }
    if (inboxInfo?.participants?.length === 0) {
      toast.error("Please specify at least one recipient.");
      error++;
    }
    if (totalSizeError) {
      error++;
    }

    setErrors({ ...errors, ...errorObject });
    return error > 0 ? false : true;
  };
  const checkingDraftValidaty = () => {
    let error = 0;
    let errorObject = { ...errors };
    if (inboxInfo?.conversation_name === "") {
      errorObject = { ...errorObject, conversation_name: "Required" };
      toast.error("Please enter the message name to save draft.");
      error++;
    }

    setErrors({ ...errors, ...errorObject });
    return error > 0 ? false : true;
  };
  //======================================= checking errors =============================//

  //================================ send message=========================================//
  const handleSendIntialMessage = () => {
    const isValid = checkingValidaty();
    if (!isValid) {
      return false;
    }
    let data = { ...inboxInfo };
    axios
      .post(`${baseUrl}/orgPortal/initiateConversation`, data)
      .then((response) => {
        if (!response?.data?.error) {
          setInboxInfo(intitalObeject);
          setErrors(inboxInfoError);
          toast.success("Message sent");
          // setFormattedUserData([]);

          setCustomText(null);
          setMessageText("");

          setTimeout(() => {
            window.location.reload();
          }, 1000);

          setDraftStatus(true);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //================================ send message=========================================//

  //================================ send draft message=========================================//
  const handleSendDraftMessage = () => {
    const isValid = checkingDraftValidaty();
    if (!isValid) {
      return false;
    }
    let data = { ...inboxInfo };
    axios
      .post(`${baseUrl}/orgPortal/draftConversation`, data)
      .then((response) => {
        if (!response?.data?.error) {
          toast.success("Draft saved");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //================================ send message=========================================//

  useEffect(() => {
    console.log(copyPaste, 342);
  }, [copyPaste]);

  return (
    <div>
      <FullLayout
        Title={
          inboxInfo?.conversation_type === "message"
            ? "Messages"
            : inboxInfo?.conversation_type === "announcement"
            ? "Announcement"
            : "Email"
        }
        TitleIcon={
          inboxInfo?.conversation_type === "message" ? (
            <QuestionAnswerIcon />
          ) : inboxInfo?.conversation_type === "announcement" ? (
            <MegaphoneFill />
          ) : (
            <EmailIcon />
          )
        }
        // TitleIcon={<QuestionAnswerIcon />}
        CloseLink="/inbox"
        HeaderRightSection={
          <>
            <SendDropdown
              handleSendIntialMessage={handleSendIntialMessage}
              handleSendDraftMessage={handleSendDraftMessage}
            />
          </>
        }
        Body={
          <>
            <div className="InboxFormSection">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    label={
                      inboxInfo?.conversation_type === "message" ? (
                        <span>
                          Message name
                          <span style={{ color: "#ff3249" }}>*</span>
                        </span>
                      ) : inboxInfo?.conversation_type === "announcement" ? (
                        <span>
                          Announcement title
                          <span style={{ color: "#ff3249" }}>*</span>
                        </span>
                      ) : (
                        <span>
                          Subject
                          <span style={{ color: "#ff3249" }}>*</span>
                        </span>
                      )
                    }
                    CharAlignMid="CharAlignMid"
                    RequiredAsterisk
                    Uppercase="Uppercase"
                    count={50}
                    value={inboxInfo.conversation_name}
                    handleChange={(val, e) =>
                      onChangeHandler(val, e, "conversation_name")
                    }
                    RequiredLabel={
                      errors?.conversation_name &&
                      inboxInfo.conversation_name?.length === 0
                        ? true
                        : false
                    }
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextEditor
                    height="150px"
                    // FieldLabel="Message*"
                    FieldLabel={
                      <span>
                        Message<span style={{ color: "#ff3249" }}>*</span>
                      </span>
                    }
                    onChange={textEditorChangeHandler}
                    value={copyPaste ? inboxInfo?.message_text : messageText}
                    // value={inboxInfo?.message_text}
                    ref={textAreaRef}
                    customText={customText}
                    EditorError={
                      extractPlainText(inboxInfo?.message_text)?.length === 0
                        ? errors?.message_text
                          ? "InboxEditorError"
                          : ""
                        : ""
                    }
                    helperText={
                      <div className="TextpastSection">
                        <a className="P_Active" onClick={(e) => handlePaste(e)}>
                          PASTE
                        </a>
                        <span>|</span>{" "}
                        <a
                          className={
                            extractPlainText(inboxInfo?.message_text)?.length >
                            0
                              ? `P_Active`
                              : `P_Defaulf`
                          }
                          onClick={handleCopyCode}
                        >
                          Copy message
                        </a>
                      </div>
                    }
                    charLimit={
                      inboxInfo?.conversation_type === "message"
                        ? 1000
                        : inboxInfo?.conversation_type === "announcement"
                        ? 500
                        : 2000
                    }
                    RequiredLabel={
                      errors?.message_text &&
                      extractPlainText(inboxInfo?.message_text)?.length === 0
                        ? true
                        : false
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <Attach
              inboxInfo={inboxInfo}
              setInboxInfo={setInboxInfo}
              setTotalSizeError={setTotalSizeError}
              setAttachmentInfo={setAttachmentInfo}
              attachmentInfo={attachmentInfo}
              attachmentsInitialObject={attachmentsInitialObject}
            />
          </>
        }
        SideBar={true}
        SideBarBody={
          <div className="InboxSideBarBody">
            <div className="InboxSideBarTitle">
              Recipient{inboxInfo?.participants?.length > 1 ? "s" : ""} (
              {inboxInfo?.participants?.length} selected)
            </div>

            <InboxTabs
              tabLabel={tabLabel}
              tabBody={tabBody}
              handleChange={handleChangeTab}
              value={tabValue}
            />
          </div>
        }
      />
    </div>
  );
}

export default InboxMessage;
