import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "../../../../../components/Form/TextField/TextField";
import AddTextField from "../../../../../components/Form/AddTextField/AddTextField";
import SwitchButton from "../../../../../components/Form/Switch/Switch";
import MultipleSeletChip from "../../../../../components/Form/MultipleSeletChip/MultipleSeletChip";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Button from "@mui/material/Button";
import Buttons from "../../../../../components/Form/Button/Button";

import { getDataMap } from "../../../../../hooks/HelperFunctions";

function Learners(props) {
  const {
    courseData,
    setCourseData,
    showSnackbarMessage,
    courseErrors,
    setCourseErrors,
  } = props;
  const { gradeLevels, ageGroups } = props.courseRelationalData;

  const [expanded, setExpanded] = React.useState("panel1");
  const [allGradeLevels, setAllGradeLevels] = React.useState(gradeLevels);
  const [allAgeGroups, setAllAgeGroups] = React.useState(ageGroups);
  const [courseLearningObjsDatas, setCourseLearningObjsDatas] = React.useState(
    courseData?.courseLearningObjs
  );

  const [courseRequirementsDatas, setCourseRequirementsDatas] = React.useState(
    courseData?.courseRequirements
  );
  const [ageGroupsDatas, setAgeGroupsDatas] = React.useState(
    courseData?.ageGroups
  );
  const [gradeLevelsDatas, setGradeLevelsDatas] = React.useState(
    courseData?.gradeLevels
  );
  const [seatsData, setSeatsData] = React.useState(courseData?.seats);
  const [cancelSataus, setCancelSataus] = React.useState(false);
  const [saveSataus, setSaveSataus] = React.useState(false);
  const [errorSataus, setErrorSataus] = React.useState(false);
  const handleChangePannel = (panel, showAlert) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [learnersErrors, setLearnersErrors] = useState({
    ageGroups: false,
    gradeLevels: false,
    seats: false,
  });

  //================================== setting data to respective fields ==================//
  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = "";

    if (
      fieldName == "seatsStatus" ||
      fieldName == "ageGroup" ||
      fieldName == "gradeLevel"
    ) {
      fieldData = event.target.checked;
    } else {
      fieldData = event.target.value;
    }
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    if (fieldName === "courseLearningObjs") {
      setCourseLearningObjsDatas(fieldData);
    } else if (fieldName === "courseRequirements") {
      setCourseRequirementsDatas(fieldData);
    } else if (fieldName === "ageGroups") {
      setAgeGroupsDatas(fieldData);
    } else if (fieldName === "gradeLevels") {
      setGradeLevelsDatas(fieldData);
    } else {
      setCourseData({ ...courseData, [fieldName]: fieldData });
    }
  };
  const handleChangesSeats = (event, fieldName) => {
    let fieldData = event.target.value;
    const numberRegex = /^[0-9]*\.?[0-9]*$/;
    if (!numberRegex.test(fieldData)) {
      return false;
    }
    setSeatsData(fieldData);
  };
  //================================== setting data to respective fields ==================//

  //==================================================================================//
  const getFilterData = (data1, data2) => {
    return data1?.filter(
      (data) => !data2.some((selectedData) => selectedData.label === data.title)
    );
  };
  useEffect(() => {
    let newGradeLevelsOption = getFilterData(gradeLevels, gradeLevelsDatas);
    setAllGradeLevels(newGradeLevelsOption);
  }, [gradeLevelsDatas]);
  useEffect(() => {
    let newAgeGroupsOption = getFilterData(ageGroups, ageGroupsDatas);
    setAllAgeGroups(newAgeGroupsOption);
  }, [ageGroupsDatas]);
  //==================================================================================//

  // useEffect(() => {
  //   console.log(courseLearningObjsDatas, 342);
  // }, [courseLearningObjsDatas]);

  //------------------------------------- checking errors ------------------------------------//
  const checkingErros = (panel) => {
    let error = 0;
    let errorObject = { ...learnersErrors };
    if (
      panel === "panel3" &&
      courseData.seatsStatus == true &&
      !courseData.seats
    ) {
      error++;
      errorObject.seats = true;
    } else {
      errorObject.seats = false;
    }
    if (
      panel === "panel4" &&
      ageGroupsDatas?.length === 0 &&
      courseData.ageGroup === true
    ) {
      error++;
      errorObject.ageGroups = true;
    } else {
      errorObject.ageGroups = false;
    }

    if (
      panel === "panel5" &&
      gradeLevelsDatas?.length === 0 &&
      courseData.gradeLevel === true
    ) {
      error++;
      errorObject.gradeLevels = true;
    } else {
      errorObject.gradeLevels = false;
    }
    setLearnersErrors({ ...learnersErrors, ...errorObject });
    return error > 0 ? false : true;
  };

  //------------------------------------- checking errors ------------------------------------//
  //------------------------------------- saving data ------------------------------------//
  const handleSave = (panel) => {
    setErrorSataus(true);
    if (panel === "panel1") {
      let filterData = courseLearningObjsDatas?.filter(
        (item) => item?.title !== ""
      );
      setCourseLearningObjsDatas(filterData);
      setCourseData((prevCourseData) => ({
        ...prevCourseData,
        courseLearningObjs: [...filterData], // Update with the filtered data
      }));
      setSaveSataus(true);
    } else if (panel === "panel2") {
      let filterData = courseRequirementsDatas?.filter(
        (item) => item?.title !== ""
      );
      setCourseRequirementsDatas(filterData);
      setCourseData((prevCourseData) => ({
        ...prevCourseData,
        courseRequirements: [...filterData], // Update with the filtered data
      }));
      setSaveSataus(true);
    } else if (panel === "panel3") {
      setCourseData({
        ...courseData,
        seats: seatsData,
      });
    } else if (panel === "panel4") {
      setCourseData({
        ...courseData,
        ageGroups: ageGroupsDatas,
      });
    } else if (panel === "panel5") {
      setCourseData({
        ...courseData,
        gradeLevels: gradeLevelsDatas,
      });
    }
    const isValid = checkingErros(panel);
    if (!isValid) return false;

    showSnackbarMessage("success", "Changes have been saved!");
    setCancelSataus(false);
    setExpanded(false);
    setErrorSataus(false);
  };

  //------------------------------------- saving data ------------------------------------//

  //------------------------------------- canceling data ------------------------------------//
  const handleCancel = (panel) => {
    if (panel === "panel1") {
      setCancelSataus(true);
    } else if (panel === "panel2") {
      setCancelSataus(true);
      setCourseRequirementsDatas(courseData?.courseRequirements);
    } else if (panel === "panel3") {
      setSeatsData(courseData?.seats);
    } else if (panel === "panel4") {
      setAgeGroupsDatas(courseData?.ageGroups);
    } else if (panel === "panel5") {
      setGradeLevelsDatas(courseData?.gradeLevels);
    }
    setSaveSataus(false);
    setExpanded(false);
  };
  //------------------------------------- canceling data ------------------------------------//

  return (
    <div className="formSection">
      <div className="FormBody">
        <div className="AccrodionThreeStep">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangePannel("panel1")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Learning objectives
                  {typeof courseData?.courseLearningObjs !== "undefined" &&
                    expanded !== "panel1" && (
                      <p className="ItemCount">
                        {courseData?.courseLearningObjs.length}{" "}
                        {courseData?.courseLearningObjs.length > 1
                          ? "items"
                          : "item"}
                      </p>
                    )}
                </Typography>

                {expanded !== "panel1" && (
                  <div>
                    <Button className="SessionButtonEdit">Edit</Button>
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                What will students learn in your course? You must enter at least
                2 learning objectives or outcomes that learners can expect to
                achieve after completing your course.
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <AddTextField
                    placeholder="Type here"
                    options={courseLearningObjsDatas}
                    handleChange={(event, fieldData) =>
                      handleChangeWithData("courseLearningObjs", fieldData)
                    }
                    maxText="80"
                    minItem={2}
                    maxItem={6}
                    panel="panel1"
                    cancelSataus={cancelSataus}
                    setCancelSataus={setCancelSataus}
                    saveSataus={saveSataus}
                    setSaveSataus={setSaveSataus}
                    primaryField="objId"
                    textField="title"
                    addButtonLabel="Add More Objective"
                  />
                </Grid>
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  fontWeight="500"
                  onClick={() => handleCancel("panel1")}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleSave("panel1")}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChangePannel("panel2")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Requirements
                  {typeof courseData?.courseRequirements !== "undefined" &&
                    expanded !== "panel2" && (
                      <p className="ItemCount">
                        {courseData?.courseRequirements?.length}{" "}
                        {courseData?.courseRequirements?.length > 1
                          ? "items"
                          : "item"}
                      </p>
                    )}
                </Typography>

                {expanded !== "panel2" && (
                  <div>
                    <Button className="SessionButtonEdit">Edit</Button>
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                List the required skills, experience, tools or equipment
                learners should have prior to taking your course. If there are
                no requirements, use this space as an opportunity to lower the
                barrier for beginners.
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <AddTextField
                    placeholder="Type here"
                    options={courseRequirementsDatas}
                    handleChange={(event, fieldData) =>
                      handleChangeWithData("courseRequirements", fieldData)
                    }
                    maxText="80"
                    minItem={1}
                    maxItem={6}
                    panel="panel2"
                    cancelSataus={cancelSataus}
                    setCancelSataus={setCancelSataus}
                    saveSataus={saveSataus}
                    setSaveSataus={setSaveSataus}
                    primaryField="reqId"
                    textField="title"
                    addButtonLabel="Add More Requirements"
                  />
                </Grid>
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleCancel("panel2")}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleSave("panel2")}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChangePannel("panel3")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Number of Seats
                  {expanded !== "panel3" && (
                    <p className="ItemCount">
                      {courseData.seatsStatus ? courseData.seats : "Unlimited"}
                    </p>
                  )}
                </Typography>

                {expanded !== "panel3" && (
                  <div>
                    <Button className="SessionButtonEdit">Edit</Button>
                  </div>
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <SwitchButton
                    title="Limit the number of students that can enroll to this course"
                    subtitle="You can enter the max number of seats by enabling this"
                    checked={courseData.seatsStatus}
                    handleChange={handleChanges("seatsStatus")}
                  />
                </Grid>
                {courseData.seatsStatus && (
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Max number of students"
                      number={false}
                      required={true}
                      // count="4"
                      value={seatsData}
                      // value={courseData.seats}
                      handleChange={(e) => handleChangesSeats(e, "seats")}
                      error={
                        (courseErrors?.seats || learnersErrors?.seats) &&
                        seatsData?.length === 0
                          ? "Required"
                          : ""
                      }
                      helperText={
                        (courseErrors?.seats || learnersErrors?.seats) &&
                        seatsData?.length === 0
                          ? "Required"
                          : ""
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleCancel("panel3")}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleSave("panel3")}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChangePannel("panel4")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Age Group
                  {expanded !== "panel4" && (
                    <p className="ItemCount">
                      {courseData.ageGroup
                        ? Object.keys(courseData.ageGroups)
                            .map(function (k) {
                              return courseData.ageGroups[k]["label"];
                            })
                            .join(", ")
                        : "All age groups"}
                    </p>
                  )}
                </Typography>

                {expanded !== "panel4" && (
                  <div>
                    <Button className="SessionButtonEdit">Edit</Button>
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <SwitchButton
                    title="Age group applicable"
                    subtitle="Enable this if this course is for a specific age group"
                    checked={courseData.ageGroup}
                    handleChange={handleChanges("ageGroup")}
                  />
                </Grid>
                {courseData.ageGroup == true && (
                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSeletChip
                      label="Select age group"
                      required={true}
                      options={getDataMap(allAgeGroups, "ageId", "title")}
                      selectedOption={ageGroupsDatas}
                      handleChange={(event, fieldData) =>
                        handleChangeWithData("ageGroups", fieldData)
                      }
                      error={
                        (courseErrors?.ageGroups ||
                          learnersErrors?.ageGroups) &&
                        ageGroupsDatas?.length === 0
                          ? "Required"
                          : ""
                      }
                      helperText={
                        (courseErrors?.ageGroups ||
                          learnersErrors?.ageGroups) &&
                        ageGroupsDatas?.length === 0
                          ? "Required"
                          : ""
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleCancel("panel4")}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleSave("panel4")}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChangePannel("panel5")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Grade Level
                  {expanded !== "panel5" && (
                    <p className="ItemCount">
                      {courseData.gradeLevel
                        ? Object.keys(courseData.gradeLevels)
                            .map(function (k) {
                              return courseData.gradeLevels[k]["label"];
                            })
                            .join(", ")
                        : "All grade levels"}
                    </p>
                  )}
                </Typography>

                {expanded !== "panel5" && (
                  <div>
                    <Button className="SessionButtonEdit">Edit</Button>
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <SwitchButton
                    title="Grade applicable"
                    subtitle="Enable this if grade is applicable"
                    BorderLine={false}
                    checked={courseData.gradeLevel}
                    handleChange={handleChanges("gradeLevel")}
                  />
                </Grid>

                {courseData.gradeLevel == true && (
                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSeletChip
                      label="Select grade"
                      required={true}
                      options={getDataMap(allGradeLevels, "gradeId", "title")}
                      selectedOption={gradeLevelsDatas}
                      handleChange={(event, fieldData) =>
                        handleChangeWithData("gradeLevels", fieldData)
                      }
                      error={
                        (courseErrors?.gradeLevels ||
                          learnersErrors?.gradeLevels) &&
                        gradeLevelsDatas?.length === 0
                          ? "Required"
                          : ""
                      }
                      helperText={
                        (courseErrors?.gradeLevels ||
                          learnersErrors?.gradeLevels) &&
                        gradeLevelsDatas?.length === 0
                          ? "Required"
                          : ""
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <div className="accFooter">
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#004FE0"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleCancel("panel5")}
                />
                <Buttons
                  label="Save"
                  buttonColor="#004FE0"
                  border="#004FE0 1px solid"
                  color="#fff"
                  fontWeight="500"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  onClick={() => handleSave("panel5")}
                  // onClick={handleChangePannel(
                  //   "panel5",
                  //   courseData.gradeLevels?.length > 0 ? true : false
                  // )}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Learners;
