import React, { useState, useRef, useEffect } from "react";
import TextField from "../Form/TextField/TextField";
import { Grid } from "@material-ui/core/";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import FileUplaoder from "../Form/FileUplaoder/FileUplaoder";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import CustomizedSnackbar from "../../hooks/Snackbar";
import "./MediaLibrary.css";
import axios from "axios";

import SelectField from "../Form/SelectField/SelectField";

function UploadFromYoutube(props) {
  const [formValidationError, setFormValidationError] = useState(false);

  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      setFormValidationError(true);
    } else {
      setFormValidationError(false);
    }
  };

  const handleChanges = (fieldName) => (event) => {
    if (fieldName === "videoName") {
      // setVideoName(event.target.value);
      props.setVideoName(event.target.value);
    } else if (fieldName === "videoUrl") {
      // setVideoUrl(event.target.value);
      props.setVideoUrl(event.target.value);
    } else if (fieldName === "imageName") {
      // setImageName(event.target.value);
      props.setImageName(event.target.value);
    }
  };

  const [age, setAge] = React.useState("");
  const handleChangesVisibility = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Public",
      value: "Public",
      subtitle: "subtitle text will add here",
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className="MediaLibPanelTextField">
            <TextField
              label="Media Name"
              required={true}
              // email={true}
              value={props.videoName}
              handleValidation={handleValidation}
              handleChange={handleChanges("videoName")}
              placeholder="Media Name"
              count={50}
              error={props?.videoErros?.name}
              helperText={props?.videoErros?.name}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="MediaLibPanelTextField">
            <TextField
              label="YouTube video URL"
              required={true}
              // email={true}
              value={props.videoUrl}
              handleValidation={handleValidation}
              handleChange={handleChanges("videoUrl")}
              placeholder="YouTube video URL"
              count={50}
              error={props?.videoErros?.url}
              helperText={props?.videoErros?.url}
            />
          </div>
        </Grid>

        {/* <Grid item xs={12} md={12}>
          <div className="MediaLibPanelTextField">
            <SelectField
              label="Visibility*"
              options={options}
              handleChange={handleChangesVisibility}
            />
          </div>
        </Grid> */}

        <Grid item xs={12} sm={12} md={12}>
          <div className="MediaLibPanelInfoCont">
            <div className="MediaLibPanelInfoText">
              <InfoRoundedIcon /> Where can you find a video URL?
            </div>
            <div className="MediaLibPanelInfoList">
              1. Start watching a video on
              <a href="https://www.youtube.com/" target="_blank">
                youtube.com <LaunchRoundedIcon />
              </a>
            </div>
            <div className="MediaLibPanelInfoList">
              2. Under the video, click Share{" "}
              <ReplyRoundedIcon className="ReplyIcon" />
            </div>
            <div className="MediaLibPanelInfoList">
              3. A panel will appear, presenting different sharing options.
              Click the Copy button to copy a link to the video that you can
              paste in the field above.
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default UploadFromYoutube;
