import React, { useState, useEffect } from "react";
import Table from "../Table/Table";

import axios from "axios";
import { generateTableRows } from "./Functions";
import SimpleBackdrop from "../Form/Backdrop/SimpleBackdrop";

function MediaLibrarySelectMedia(props) {
  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV == "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));

  const [items, setItems] = useState([]);

  const [totalItems, setTotalItems] = useState([]);
  const [searchResultsStatus, setSearchResultsStatus] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchTextData, setSearchTextData] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const handleChangeRedio = (event) => {
    console.log("handleChangeRedio", event.target.value, items, 607);

    let data = searchResultsStatus ? searchResults : totalItems;
    // setTotalItems(data);
    let temp = generateTableRows(data, handleChangeRedio, event.target.value);
    setRows(temp);
    setSelectedValue(event.target.value);
    props.onClickRadioIcon(event.target.value);
    //find the selected item from totalItems array
    let selectedItem = totalItems.find((item) => item.id == event.target.value);
    props.onItemSelected(selectedItem);
  };

  //useeffect
  useEffect(() => {
    //get media data from api
    setOpenBackdrop(true);
    axios
      .get(`${baseUrl}/getAllMediaByOrgId?orgId=${orgId}`, {})
      .then(function (response) {
        // console.log(response.data.data);

        let data = response.data;
        setTotalItems(data);
        setSearchResults(data);
        setItems(data);
        let temp = generateTableRows(data, handleChangeRedio, selectedValue);
        setRows(temp);
        setOpenBackdrop(false);
      })
      .catch(function (error) {
        setOpenBackdrop(false);
        console.log(error);
      });
  }, [totalItems.length]);
  console.log("fdsgfdg", 406);

  const headCells = [
    {
      id: "Media",
      numeric: false,
      disablePadding: true,
      label: "Media",
      width: "65%",
    },
    {
      id: "Type",
      numeric: true,
      disablePadding: false,
      label: "Type",
      width: "20%",
    },

    {
      id: "Type",
      numeric: true,
      disablePadding: false,
      label: "Visibility",
      width: "20%",
    },

    {
      id: "Size",
      numeric: true,
      disablePadding: false,
      label: "Size",
      width: "20%",
    },
  ];

  const handleSearchText = (event) => {
    // console.log("handleSearchText", event.target.value)
    event.preventDefault();
    setPageNumberFromOutSide(true);
    let searchText = event.target.value;
    let data = totalItems;
    let temp = [];
    if (searchText.length > 0) {
      data.map((item, index) => {
        if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
          temp.push(item);
        }
      });
    } else {
      temp = data;
    }
    setSearchTextData(searchText);

    // setRows(temp);
    // setItems(temp);
    setSearchResults(temp);
    // handleSearch();
  };

  useEffect(() => {
    handleSearch();
  }, [searchTextData]);
  useEffect(() => {
    console.log(pageNumberFromOutSide, 345);
  }, [pageNumberFromOutSide]);
  const handleSearch = () => {
    setSearchResultsStatus(true);
    let temp = generateTableRows(
      searchResults,
      handleChangeRedio,
      selectedValue
    );
    setRows(temp);
    setPageNumberFromOutSide(false);
  };

  return (
    <>
      <div className="TableSection">
        <SimpleBackdrop open={openBackdrop} />
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          FilterButton={props?.FilterButton}
          showSearchBox={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          pageNumberFromOutSide={pageNumberFromOutSide}
        />
      </div>
    </>
  );
}

export default MediaLibrarySelectMedia;
