import React, { useEffect, useState } from "react";
import "../assets/css/ChatRoom.css";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import img from "../assets/images/user-img.jpg";
import AnnouncementIcon from "../assets/images/announcement-icon.svg";
import Buttons from "../components/Button/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import MarkunreadRoundedIcon from "@mui/icons-material/MarkunreadRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import CampaignIcon from "@mui/icons-material/Campaign";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import EmailIcon from "@mui/icons-material/Email";
import Chat from "./Chat";
import AddNew from "./AddNew";
import {
  capitalizeFirstLetter,
  extractPlainText,
  shortRelativeTime,
} from "../../../../hooks/HelperFunctions";
import { useConversation } from "../../../../context/ConversationContext";
import axios from "axios";
import { IconButton } from "@mui/material";

import ScrollBar from "../components/ScrollBar/ScrollBar";

function ChatRoom({
  item,
  formattedText,
  getGeneralConversations,
  getImportantConversations,
  setBadgeStatus,
}) {
  const { setConversation } = useConversation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = localStorage.getItem("orgId");
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isToggeled, setToggele] = useState(item?.isImportant);
  const handleToggle = () => {
    setToggele(!isToggeled);
  };

  //================================== make covertsation important =======================//
  const handleMakeConversatationImportant = () => {
    let data = {
      user_id: userData?.id,
      conversation_id: item?.conversation_id,
    };
    axios
      .post(
        `${baseUrl}/orgPortal/${
          orgId
            ? "makeConversationImportantForTeacher"
            : "makeConversationImportantForStudent"
        }`,
        data
      )
      .then((result) => {
        getResponse(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMakeConversatationUnImportant = () => {
    setBadgeStatus(true);
    let data = {
      user_id: userData?.id,
      conversation_id: item?.conversation_id,
    };
    axios
      .post(
        `${baseUrl}/orgPortal/${
          orgId
            ? "makeConversationUnimportantForTeacher"
            : "makeConversationUnimportantForStudent"
        }`,
        data
      )
      .then((result) => {
        getResponse(result);
        setBadgeStatus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResponse = (data) => {
    if (data?.status === 200) {
      getGeneralConversations();
      getImportantConversations();
    } else {
      console.error("Something went wrong");
    }
  };
  //================================== make covertsation important =======================//

  useEffect(() => {
    if (isToggeled !== item?.isImportant) {
      if (isToggeled) {
        handleMakeConversatationImportant();
      } else {
        handleMakeConversatationUnImportant();
      }
    }
  }, [isToggeled]);

  return (
    <div className="ChatRoomContainer">
      {/* ===== Chat room header Start==*/}
      <div className="ChatRoomHeader">
        <div className="ChatRoomHeaderLeft">
          <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: "#004FE0" }}>
              {item?.conversation?.conversation_type === "announcement" ? (
                <CampaignIcon />
              ) : item?.conversation?.conversation_type === "email" ? (
                <EmailIcon />
              ) : (
                <QuestionAnswerIcon />
              )}
            </Avatar>
          </Stack>
          <div className="ChatRoomUserInfo">
            <div className="ChatRoomUserName">
              {capitalizeFirstLetter(item?.conversation?.conversation_name)}
            </div>
            <div className="ChatRoomUserDisBox">
              <div className="ChatRoomUserDiscription">
                {item?.conversation?.course?.courseTitle
                  ? capitalizeFirstLetter(
                      item?.conversation?.course?.courseTitle
                    )
                  : ""}{" "}
                •
                {item?.participants?.length > 1
                  ? `${
                      item?.conversation?.conversation_type === "email"
                        ? parseInt(item?.participants?.length) - 1
                        : item?.participants?.length
                    } recipients`
                  : `${item?.participants?.length} recipient`}
              </div>
            </div>
          </div>
        </div>
        <div className="ChatRoomHeaderRight">
          <div className="ChatRoomHeaderRightContent">
            {/* {orgId && <AddNew ButtonBlue={false} item={item} />} */}

            <div
              className={`ChatRoomHeaderStartBadge ${
                isToggeled || item?.isImportant ? "BadgeActive" : ""
              }`}
              // onClick={handleMakeConversatationImportant}
              onClick={handleToggle}
            >
              {" "}
              {/*== for yeelow start use class  "BadgeActive"  ==*/}
              <span className="StartDefault">
                <StarBorderRoundedIcon />
              </span>
              <span
                role="button"
                style={{ cursor: "pointer" }}
                className="StartActive"
              >
                <StarRoundedIcon />
              </span>
            </div>

            <div className="ChatRoomHeaderMenu">
              {/* <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreHorizRoundedIcon />
              </Button> */}
              {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >

                <span onClick={handleClose} className="InboxMenuListing">
                  <a className="InboxAddNewListing" href="/inbox-messages"><ForumRoundedIcon /> Message</a>
                  <a className="InboxAddNewListing" href="/inbox-announcement"><CampaignRoundedIcon /> Announcement</a>
                  <a className="InboxAddNewListing" href="/inbox-email"><MarkunreadRoundedIcon /> Email</a>
                </span>

              </Menu> */}
            </div>
          </div>
        </div>
      </div>
      {/* ===== Chat room header Start==*/}

      <ScrollBar
        ScrollBarHeight="600px"
        body={
          <div className="ChatRoomBody">
            <Chat item={item} formattedText={formattedText} />
          </div>
        }
      />
    </div>
  );
}

export default ChatRoom;
