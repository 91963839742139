import React, { useState } from "react";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';



import './SideDrawer.css';





function InboxDrawer(props) {
  
  const {open, handleClose ,body ,sideOpen} = props;

  const theme = useTheme();

  return (
    <div className="InboxDrawer" style={{ paddingTop: "0px" }}>

      <div>
        <Drawer
          variant="persistent"
          anchor={sideOpen}
          open={open}
        >
          <div className="InboxDrawerIconClose">
            <IconButton onClick={handleClose}>
              <ChevronLeftIcon /> Back
            </IconButton>
          </div>

          {body}

        </Drawer>

      </div>
    </div>
  );
}


export default InboxDrawer;

