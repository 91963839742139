import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import Crown from "../../../../assets/imgs/vip-crown-fill.svg";
import CreateRole from "./CreateRole";
import DraggableDialog from "../../../../hooks/Dialog";

//import { capitalizeFirstLetter } from '../../../../hooks/HelperFunctions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { isTheActionPermitted } from '../../../../hooks/HelperFunctionsPermission';
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import axios from "axios";
import CustomizedSnackbar from "../../../../hooks/Snackbar";
import { capitalizeFirstLetter } from "../../../../hooks/HelperFunctions";

const RolesPermissions = () => {
  //============================= data fetching ===========================================//
  const orgId = localStorage.getItem("orgId");
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  // fetching roles
  const getRolesist = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/getRolesByOrganizationId`, data)
      .then((response) => {
        // processShowToastMessage(response);
        setRoles(response.data.body);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  //=============================== fetching permission data ==================================//
  const getpermissionList = () => {
    axios
      .get(`${baseUrl}/orgPortal/getPermissions`)
      .then((response) => {
        setPermissions(response.data.body);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  //=============================== fetching permission data ==================================//

  useEffect(() => {
    getRolesist();
    getpermissionList();
    getStudentList();
  }, []);
  //============================= data fetching ===========================================//
  //================================= show toast message ==============================//
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const processShowToastMessage = (response) => {
    // console.log(response.data);
    if (response.data.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);

      // getRolesist();
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.message);
      // getRolesist();
    }
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  //================================= show toast message ==============================//

  let initialRolesPermissionsObject = {
    orgId: orgId,
    name: "",
    description: "",
    status: "",
    permissions: [],
    users: [],
    removedUsers: [],
    // order: 0,
    role_type: "custom",
  };
  let initialRolesPermissionsObjectError = {
    name: "",
    description: "",
    status: "",
    permissions: "",
    users: "",
    // order: "",
  };

  const [rolesPermissionsObject, setRolesPermissionsObject] = useState(
    initialRolesPermissionsObject
  );
  const [rolesPermissionsErrorObject, setRolesPermissionsErrorObject] =
    useState(initialRolesPermissionsObjectError);

  //=========================== showing data on the table ===================================//
  const headCells = [
    {
      id: "Role",
      numeric: false,
      disablePadding: true,
      label: "Role name",
      width: "40%",
      textAlign: "left",
    },
    {
      id: "Users",
      numeric: false,
      disablePadding: true,
      label: "Users",
      width: "60%",
      textAlign: "left",
    },
    {
      id: "Users",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "right",
    },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        let roleTitle = "";
        if (item?.name != "") {
          roleTitle =
            item?.name == "Owner" ? (
              <div className="IconCell">
                {capitalizeFirstLetter(item?.name)} <img src={Crown} />
              </div>
            ) : (
              capitalizeFirstLetter(item?.name)
            );
        } else {
          roleTitle = "N/A";
        }

        return {
          cell: [
            { textBold: roleTitle },

            {
              linkText: `${item?.role_users?.length}  ${
                item?.role_users?.length === 1 ? "user" : "users"
              }`,
              linkTextStatus: true,
              onClick: (e) => userLinkHandler(item?.id),
            },
            {
              ActionButtonDotIcon:
                item?.role_type && item?.role_type === "owner" ? false : true,
              ActionButtonLabel: "Action",
              TextAlign: "right",
            },
          ],

          action:
            item?.role_type && item?.role_type === "admin"
              ? [{ label: "Edit", onClick: (e) => roleEditHandler(item?.id) }]
              : [
                  { label: "Edit", onClick: (e) => roleEditHandler(item?.id) },

                  {
                    label: "Delete",
                    onClick: (e) => handleOpenDelete(item?.id),
                  },
                ],
          //======= Action Date End===== //
        };
      });
    } else {
      return [];
    }
  };
  //=========================== showing data on the table ===================================//

  let DeletedConfirmWord = "CONFIRM";
  const [openModal, setOpenModal] = useState(false);
  const [textValue, setTextValue] = React.useState("");
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [rows, setRows] = useState([]);
  // const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [roleUsers, setRoleUsers] = useState([]);
  const [OpenRole, setOpenRole] = useState(false);
  const [openUserTab, setOpenUserTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [roleUsersToUpdate, setRoleUsersToUpdate] = useState([]);
  const [roleUsersToRemove, setRoleUsersToRemove] = useState([]);
  const [existNameChecked, setExistNameChecked] = useState(false);

  const handleDrawerOpenRole = () => {
    setOpenRole(true);
  };

  const handleDrawerCloseRole = () => {
    setRoleId("");
    setRoleUsers([]);
    setRolesPermissionsObject(initialRolesPermissionsObject);
    setRolesPermissionsErrorObject(initialRolesPermissionsObjectError);
    setRoleUsersToUpdate([]);
    setOpenRole(false);
  };

  const roleEditHandler = (id) => {
    setRoleId(id);
    getSingleLRoleData(id);
    handleDrawerOpenRole();
  };

  //============= user link tab part start ========================//

  //=============================== fetching student data ==================================//
  // fetching course material data
  const [studentList, setStudentList] = useState([]);

  const getStudentList = () => {
    let data = {
      orgId: orgId,
    };
    axios
      .post(`${baseUrl}/orgPortal/getTeamMemberList`, data)
      .then((result) => {
        setStudentList(result.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //=============================== fetching student data ==================================//
  //=============================== fetching single role data ==================================//

  const getSingleLRoleData = (id) => {
    let usersDatas = [];
    let data = {
      id: id,
    };
    axios
      .post(`${baseUrl}/orgPortal/getSingleRole`, data)
      .then((response) => {
        // console.log(response.data.body, 328);

        if (!response.data.error) {
          const { name, description, status, permissions, users, role_type } =
            response.data.body;
          users?.map((usesData) => usersDatas?.push(usesData?.user));

          setRolesPermissionsObject({
            role_type,
            name,
            description,
            status,
            permissions,
            users: usersDatas,
          });

          let newRoleUsers = usersDatas.map(({ id }) => id);
          console.log(newRoleUsers, usersDatas, 345, users);
          // setRoleUsers(usersDatas);
          setRoleUsers(newRoleUsers);
          setRoleUsersToUpdate(usersDatas);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   console.log(roleUsersToUpdate, 345);
  // }, [roleUsersToUpdate]);
  //=============================== fetching single role data ==================================//

  const userLinkHandler = (id) => {
    setOpenUserTab(true);
    setRoleId(id);
    handleDrawerOpenRole();
  };
  //============= user link tab part end ========================//

  //======= Delete dialog ===== //
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const handleOpenDelete = (id) => {
    // if (!isTheActionPermitted('deleteRolesPermissions')) {
    //   toast.error("You don't have access to this feature")
    //   return false
    // }
    setDeleteId(id);
    setOpenDialogDelete(true);
  };
  const deleteRoleHandler = () => {
    let data = {
      id: deleteId,
    };
    axios
      .post(`${baseUrl}/orgPortal/deleteRole`, data)
      .then((response) => {
        processShowToastMessage(response);
        getRolesist();
      })

      .catch((err) => {
        console.log(err);
      });
    handleCloseDialogDelete();
  };
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (roles?.length > 0) {
      setRows(convertDataToCellRows(roles));
    }
  }, [roles]);

  const searchRole = async () => {};
  const handleSearch = async () => {
    const result = await searchRole({ term: searchTerm });
    if (result?.data) {
      setRoles(result.data.body.data);
    }
  };

  const onClick = () => {
    alert("onclick working fine!");
  };

  const getAllPermissionNumber = () => {
    let permissionNumber = 0;
    rolesPermissionsObject?.permissions?.map((permission) => {
      permissionNumber += permission?.operations?.length;
    });
    return permissionNumber;
  };
  const checkRolesPermissionValidity = async () => {
    let errorObject = { ...rolesPermissionsErrorObject };
    let error = 0;
    if (rolesPermissionsObject.name == "") {
      errorObject = { ...errorObject, name: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, name: "" };
    }

    if (getAllPermissionNumber() === 0) {
      // toast.error("Please select at least one permission");
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please select at least one permission");
      errorObject = { ...errorObject, permissions: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, permissions: "" };
    }
    setRolesPermissionsErrorObject({
      ...rolesPermissionsErrorObject,
      ...errorObject,
    });

    return error > 0 ? false : true;
  };
  // console.log(rolesPermissionsObject, 345);
  const createRoleHandler = () => {
    // let errorObject = { ...rolesPermissionsErrorObject };
    let newUsersList = [];
    rolesPermissionsObject?.users?.map((userData) =>
      newUsersList?.push(userData?.id)
    );
    let data = { ...rolesPermissionsObject, users: newUsersList };

    data.status = "active";
    let valid = checkRolesPermissionValidity();
    if (!valid) {
      return false;
    }

    if (rolesPermissionsObject?.permissions?.length === 0) {
      return false;
    }
    if (existNameChecked) {
      return false;
    }

    let updateRoleData = {
      id: roleId,
      orgId: orgId,
      name: rolesPermissionsObject?.name,
      description: rolesPermissionsObject?.description,
      status: "active", // active, inactive
      // role_type: "admin", // role type : admin, owner, custom
      role_type:
        rolesPermissionsObject?.role_type === "admin" ? "admin" : "custom", // role type : admin, owner, custom
      permissions: rolesPermissionsObject?.permissions,
      users: newUsersList,
    };

    if (roleId != "") {
      axios
        .post(`${baseUrl}/orgPortal/updateRole`, updateRoleData)
        .then((response) => {
          if (!response?.data.error) {
            getTheResponse(response);
          } else {
            processShowToastMessage(response);
          }
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      delete data.removedUsers;
      axios
        .post(`${baseUrl}/orgPortal/addRole`, data)
        .then((response) => {
          if (!response?.data.error) {
            getTheResponse(response);
          } else {
            processShowToastMessage(response);
          }
        })

        .catch((err) => {
          console.log(err);
        });
    }

    // if (existNameChecked) {
    //   return false;
    // }
  };
  // console.log(rolesPermissionsObject, 345);
  const getTheResponse = (response) => {
    if (!response?.data?.error) {
      setRolesPermissionsObject(initialRolesPermissionsObject);
      setRolesPermissionsErrorObject(initialRolesPermissionsObjectError);
      getRolesist();
      processShowToastMessage(response);
      setRoleUsers([]);
      setRoleUsersToUpdate([]);
      handleDrawerCloseRole();
    }
  };
  // useEffect(() => {
  //   console.log("role permission object:", rolesPermissionsObject, 345);
  // }, [rolesPermissionsObject]);

  useEffect(() => {
    setRolesPermissionsObject({
      ...rolesPermissionsObject,
      users: roleUsersToUpdate,
    });
  }, [roleUsers, roleUsersToUpdate]);

  return (
    <>
      <div className="TableSection">
        <Table
          title="Roles & Permissions"
          tableHeader={true}
          addLabel="Create New Role"
          AddButton={true}
          // icon={<AddRoundedIcon />}
          headCells={headCells}
          rows={rows}
          onClick={handleDrawerOpenRole}
          footer={true}
          innerHeader={true}
          showSearchBox={false}
          FilterButton={false}
          searchBoxPlaceholder="Search users"
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          RowsPerPage={10}
          // isDataLoading={isLoading || createRoleOptions.isLoading || deleteRoleOptions.isLoading || searchRoleOptions.isLoading || updateRoleOptions.isLoading}
          isDataLoading={false}
          emptyTableTitle="No Roles  added yet"
          emptyTableNote="No items available"
          btnLabel="Create new"
          showButton={true}
          onClickEmptyCard={handleDrawerOpenRole}
        />
      </div>

      {/* Create Role drawer */}
      <div className="PermissonsDrawer">
        <SideDrawer
          open={OpenRole}
          handleDrawerClose={handleDrawerCloseRole}
          clickSaveButton={createRoleHandler}
          title={roleId ? "Edit Role" : "Create Role"}
          sideOpen="right"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={true}
          padding="0"
          body={
            <>
              <CreateRole
                rolesPermissionsObject={rolesPermissionsObject}
                setRolesPermissionsObject={setRolesPermissionsObject}
                errors={rolesPermissionsErrorObject}
                setErrors={setRolesPermissionsErrorObject}
                roleUsers={roleUsers}
                setRoleUsers={setRoleUsers}
                setRoleUsersToUpdate={setRoleUsersToUpdate}
                roleUsersToUpdate={roleUsersToUpdate}
                roleUsersToRemove={roleUsersToRemove}
                setRoleUsersToRemove={setRoleUsersToRemove}
                roleId={roleId}
                permissions={permissions}
                openUserTab={openUserTab}
                setOpenUserTab={setOpenUserTab}
                baseUrl={baseUrl}
                orgId={orgId}
                setExistNameChecked={setExistNameChecked}
                setShowSnackbar={setShowSnackbar}
                setSnackbarSeverity={setSnackbarSeverity}
                setSnackbarMessage={setSnackbarMessage}
                studentList={studentList}
              />
            </>
          }
        />
      </div>

      {/* Delete section*/}
      <DraggableDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        handleConfirmCloseDialog={(e) => {
          deleteRoleHandler();
        }}
        title="Delete role?"
        body={
          <div>
            Are you sure you want to delete this role?
            <br /> Once you delete it, you can't get it back.
          </div>
        }
        ModalFooter={true}
        actionButton="Delete"
      />

      {/* <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirm={deleteRoleHandler}
      /> */}
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};

export default RolesPermissions;
