import React from "react";
import './FullLayout.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';




function FullLayout(props) {
  const { HeaderRightSection, TitleIcon, Title, Body, CloseLink, SideBar, SideBarBody } = props;


  return (
    <div className="InboxFullLayout">

    <div className="InboxFullLayoutHeaderOuter">
      <div className="InboxFullLayoutHeader">
        <div className="InboxFullLayoutHeaderLeft">
          <Button href={CloseLink} className="CloseButton" variant="text"><CloseIcon /></Button>
          <div className="InboxPageTitle">
            <span>{TitleIcon}</span>
            {Title}
          </div>
        </div>
        <div className="InboxFullLayoutHeaderRight"><div className="RightColButtonContainer">{HeaderRightSection}</div></div>
      </div>
      </div>

      <div className="InboxFullLayoutBodyContainer">
        <div className="InboxFullLayoutBody">{Body}</div>
        {SideBar &&
          <div className="InboxFullLayoutSideBar">
            <div className="InboxFullLayoutSideBarInner">
              {SideBarBody}
            </div>
          </div>
        }
      </div>

    </div>
  );
}


export default FullLayout;

