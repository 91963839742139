import React, { useState } from "react";
import AlrtMessage from '../../../../../components/Form/AlrtMessage/AlertMessage';
import Table from '../../../../../components/Table/Table';
import SideDrawer from '../../../../../components/SideDrawer/SideDrawer';
import CustomizedSnackbar from '../../../../../hooks/Snackbar';
import DraggableDialog from '../../../../../hooks/Dialog';
import tableImg from '../../../../../assets/imgs/tableImg.png';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';


function Progress(props) {

  //======= Drawer ===== //
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //======= Drawer ===== //


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //


  const handleOpenDialog = (id) => {
    console.log(id);
    // setActiveCourseId(id);
    // setDialogTitle("Delete this track?");
    // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
    setOpenDialog(true);
  }




  const onClick = () => {
    alert("onclick working fine!");
  }


  const headCells = [

    { id: 'Seq', numeric: true, disablePadding: false, label: 'Course', width: "50%" },
    { id: 'Session Starts', numeric: true, disablePadding: false, label: 'Session Starts', width: "30%" },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: "30%" },
  ];

  const rows = [

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Introduction to Islamic Economics and Finance", align: "left", },
        { date: "Jan 12, 2022", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Introduction to Islamic Economics and Finance", align: "left", },
        { date: "Jan 12, 2022", },
        { Status: true, background: "#FCE7E9", color: "#E63946", label: "Not Enrolled", },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Introduction to Islamic Economics and Finance", align: "left", },
        { date: "Jan 12, 2022", },
        { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "In Progress", },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Introduction to Islamic Economics and Finance", align: "left", },
        { date: "Jan 12, 2022", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "Enrolled", },
      ],
    },




  ];



  return (

    <>

      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          addLabelInner="Add Courses"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          onClickInnerButton={handleDrawerOpen}
          handleOpenDialog={handleOpenDialog}
        />
      </div>
    </>
  );
}


export default Progress;

