import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import FieldPassword from "../../components/Form/FieldPassword/FieldPassword";
import fbIcon from "../../assets/imgs/fb-icon.png";
import google from "../../assets/imgs/google.png";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import CallTwoToneIcon from "@mui/icons-material/CallTwoTone";
import Header from "./header/Header";
import CustomizedSnackbar from "../../hooks/Snackbar";

import "./Form.css";
import PhoneInputField from "../../components/Form/PhoneInputField/PhoneInputField";

function SignIn(props) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [signInWith, setSignInWith] = useState("email");

  const [emailValidationError, setEmailValidationError] = useState(false);

  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    phone_number: "",
  });

  const handleChanges = (fieldName) => (event) => {
    let fieldValue = event.target.value;
    fieldValue = fieldName == "email" ? fieldValue.toLowerCase() : fieldValue;

    setValues({
      ...values,
      [fieldName]: fieldValue,
    });
  };

  const redirectToPortal = (result) => {
    // console.log(result);
    const response = result.data;

    if (response.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Sign in failed, Email, password incorrect");
    } else {
      const userData = {
        id: response.body.id,
        firstName: response.body.first_name,
        lastName: response.body.last_name,
        email: response.body.email,
        image: response.body.image,
        phone_number: response.body.phone_number,
        // "orgId": response.body.org_id,
        userRole: response.body.user_role,
      };

      // clear old data
      localStorage.clear();

      // set user data and user access token
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("userToken", response.token);
      // set user as authorized
      localStorage.setItem("userAuthenticated", true);
      // set user id related data
      // localStorage.setItem('orgId', response.body.org_id);
      // localStorage.setItem('stuId', response.body.stuId);
      // user acc verification data
      const userVerified = !response.body.email_verified_at ? false : true;
      localStorage.setItem("userVerified", userVerified);

      // if (response?.orgData?.subDomain) {
      //   localStorage.setItem('subDomain', response.orgData.subDomain);
      // }

      if (response?.body?.timezone) {
        localStorage.setItem("timezone", response.body.timezone);
      }

      // show success message
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Sign in Success! Redirecting to the portal...");

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.token}`;

      setTimeout(() => {
        // Redirects to portal page
        switch (userData.userRole) {
          case "org":
            navigate("/courses-listing");
            break;
          case "student":
            navigate("/my-courses");
            break;
          case "owner":
            navigate("/customer-listing");
            break;
          default:
            console.log("User role mismatching!");
        }
      }, 2000);
    }
  };

  const signInHandler = (userData) => {
    // if (emailValidationError === false && signInWith === "email") {
    //   return false;
    // }
    // console.log(345, "rdterter");

    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(
          `${process.env.REACT_APP_RECAPCHA_SITE_KEY}`,
          { action: "submit" }
        );
        ///===================== recapcha ==============================///
        userData.token = token;
        userData["sign_in_with"] = signInWith;
        userData["token"] = token;
        console.log(345, "rdterter", userData);
        axios
          .post(`${baseUrl}/login`, userData)
          .then((response) => {
            redirectToPortal(response);
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.message) {
              setShowSnackbar(true);
              setSnackbarSeverity("error");
              setSnackbarMessage(err.response.data.message);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const emailValidation = (validationError) => {
    // set form validation error
    console.log(validationError);
    if (validationError) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };
  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });
  const phoneChangeHandler = (ext, number) => {
    setValues({
      ...values,
      phone_number: number,
    });
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Sign in</h1>

            <div className="SignFormGrid">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <CustomizedSnackbar
                    open={showSnackbar}
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    handleClose={handleSnackbarClose}
                  />
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <TextField
                    placeholder="Email Address"
                    // required={true}
                    email={true}
                    value={values.email}
                    handleChange={handleChanges("email")}
                    handleValidation={emailValidation}
                  />
                </Grid> */}
                <Grid item xs={12} md={12}>
                  {signInWith === "email" && (
                    <TextField
                      placeholder="Email Address"
                      // required={true}
                      email={true}
                      value={values.email}
                      handleChange={handleChanges("email")}
                      handleValidation={emailValidation}
                    />
                  )}

                  {signInWith === "phone_number" && (
                    <PhoneInputField
                      phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                      country={"us"}
                      value={phone.number}
                      countryCodeEditable={false}
                      onChange={(phone, country) =>
                        phoneChangeHandler(country.dialCode, phone)
                      }
                      onBlur={() => console.log("onBlur")}
                      onKeyDown={(e) =>
                        console.log("onKeyDown", e.target.value)
                      }
                      variant="outlined"
                      // onlyCountries={["us", "ca", "gb", "my"]}
                      containerStyle={{}}
                      buttonStyle={{}}
                      inputStyle={{
                        marginLeft: "0px",
                        height: "56px",
                        width: "100%",
                        fontFamily: "Roboto-Regular",
                        fontSize: "16px",
                      }}
                      containerClass={{}}
                      // errorText={"Enter a valid phone number"}
                      errorStyles={{ marginBottom: "5px" }}
                      autoFormat={true}
                      disableDropdown={false}
                      disabled={false}
                      disableCountryCode={false}
                      enableSearch={false}
                      disableSearchIcon={false}
                      placeholder={""}
                      inputProps={{}}
                      specialLabel={"Primary Phone"}
                      // preferredCountries={[]}
                      // excludeCountries={[]}
                      // error="error"
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={12}>
                  <FieldPassword
                    placeholder="Password"
                    value={values.password}
                    handleChange={handleChanges("password")}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Buttons
                    label="Sign In"
                    buttonColor="#004FE0"
                    border="#ccc 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link=""
                    onClick={() => signInHandler(values)}
                  />
                  <div className="BottomButton">
                    <Buttons
                      label="Forgot your password?"
                      color="#004FE0"
                      width="auto"
                      height="48px"
                      fontSize="14px"
                      link={`/forgot-password?signInWith=${signInWith}`}
                    />
                  </div>
                </Grid>

                {/* for student sign in, we will show social login */}

                {true && (
                  <>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <div className="OrBox">
                        <span>or</span>
                      </div>
                    </Grid>
                    {signInWith === "phone_number" && (
                      <Grid item xs={12} md={12}>
                        <div
                          className="SocialButton"
                          onClick={() => setSignInWith("email")}
                        >
                          <Buttons
                            label="Sign in with email"
                            buttonColor="#fff"
                            border="#E4E8EC 1px solid"
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            onClick={(e) => setSignInWith("email")}
                            icon={<MailOutlineRoundedIcon />}
                          />
                        </div>
                      </Grid>
                    )}

                    {signInWith === "email" && (
                      <Grid item xs={12} md={12}>
                        <div
                          className="SocialButton"
                          onClick={() => setSignInWith("phone_number")}
                        >
                          <Buttons
                            label="Sign in with phone"
                            buttonColor="#fff"
                            border="#E4E8EC 1px solid"
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            onClick={(e) => setSignInWith("phone_number")}
                            icon={<CallTwoToneIcon />}
                            IconAlign="left"
                          />
                        </div>
                      </Grid>
                    )}
                  </>
                )}

                {false && (
                  <>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <div className="OrBox">
                        <span>or</span>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className="SocialButton">
                        <Buttons
                          icon={<img src={fbIcon} />}
                          label="Continue with Facebook"
                          buttonColor="#fff"
                          border="#A4A7B0 1px solid"
                          color="#0B132B"
                          width="100%"
                          height="48px"
                          fontSize="14px"
                          link=""
                          // onClick={}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className="SocialButton">
                        <Buttons
                          icon={<img src={google} />}
                          label="Continue with Google"
                          buttonColor="#fff"
                          border="#A4A7B0 1px solid"
                          color="#0B132B"
                          width="100%"
                          height="48px"
                          fontSize="14px"
                          link=""
                          // onClick={}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className="AlreadyText">
                        You don't have an account? <a href="#">Sign Up</a>
                      </div>
                    </Grid>
                  </>
                )}

                {/* <Grid item xs={12} md={12}>
                <div className="TosText">
                  This site is protected by reCAPTCHA Enterprise and the Google  <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a> apply.
                </div>
              </Grid> */}
              </Grid>
            </div>
          </div>
          <div className="SignFormFooter Iftwo">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
