import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import AlertMessage from "../../components/Form/AlrtMessage/AlertMessage";
import "./Form.css";

function TokenBasedSignIn(props) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");

  const navigate = useNavigate();

  const redirectToPortal = (result) => {
    const response = result.data;

    if (response.error) {
      setAlertSeverity("error");
      setAlertMessage("Sign in failed, token is not valid");
      setAlertOpen(true);
    } else {
      console.log(response.body, 345);
      const userData = {
        id: response.body.id,
        firstName: response.body.first_name,
        lastName: response.body.last_name,
        email: response.body.email,
        image: response.body.image,
        phone_number: response.body.phone_number,
        // "orgId": response.body.org_id,
        userRole: response.body.user_role,
      };

      // clear old data
      localStorage.clear();

      // set user data and user access token
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("userToken", response.token);
      // set user as authorized
      localStorage.setItem("userAuthenticated", true);
      // set user id related data
      // localStorage.setItem('orgId', response.body.org_id);
      // localStorage.setItem('stuId', response.body.stuId);
      // user acc verification data
      const userVerified = !response.body.email_verified_at ? false : true;
      localStorage.setItem("userVerified", userVerified);

      // if(response?.orgData?.subDomain){
      //   localStorage.setItem('subDomain', response.orgData.subDomain);
      // }

      if (response?.body?.timezone) {
        localStorage.setItem("timezone", response.body.timezone);
      }

      // student portal, after sign in url
      let stuAfterSigninUrl = `/my-courses`;

      if (enrollment) {
        stuAfterSigninUrl += `?enrollment=${enrollment}`;
      } else if (editProfile) {
        stuAfterSigninUrl = `/settings?editProfile=${editProfile}`;
      } else if (courseDetailId) {
        stuAfterSigninUrl = `/my-course-details/${courseDetailId}`;
      }

      // show success message
      // setAlertSeverity("success");
      // setAlertMessage("Sign in Success! Redirecting to the portal...");
      // setAlertOpen(true);

      setTimeout(() => {
        // Redirects to portal page
        switch (userData.userRole) {
          case "org":
            navigate("/courses-listing");
            break;
          case "student":
            navigate(stuAfterSigninUrl);
            break;
          case "owner":
            navigate("/customer-listing");
            break;
          default:
            console.log("User role mismatching!");
        }
      }, 2000);
    }
  };

  const signInHandler = (userData) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    console.log(userData);

    const userToken = localStorage.getItem("userToken");
    axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

    axios
      .post(`${baseUrl}/tokenBasedLogin`, userData)
      .then((response) => redirectToPortal(response, true))
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          setAlertSeverity("error");
          setAlertMessage(err.response.data.message);
          setAlertOpen(true);
        }
      });
  };

  const alertCloseHandler = () => {
    setAlertOpen(false);
  };

  // handle token based signin
  let [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token");
  let enrollment = searchParams.get("enrollment");
  let editProfile = searchParams.get("editProfile");
  let courseDetailId = searchParams.get("courseDetailId");
  console.log(token);

  useEffect(() => {
    if (token) {
      token = token.split(" ").join("+");
      console.log(token);

      try {
        const bytes = CryptoJS.AES.decrypt(token, "login-854256-secret-key");
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (decryptedData) {
          console.log(decryptedData);
          const userAuthData = decryptedData[0];
          const authDataObj = {
            email: userAuthData.email,
            phone_number: userAuthData.phone_number,
            userToken: userAuthData.userToken,
          };
          localStorage.setItem("userToken", userAuthData.userToken);
          signInHandler(authDataObj, true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <div className="Enrolling">
      <div>
        <AlertMessage
          open={alertOpen}
          message={alertMessage}
          severity={alertSeverity}
          closeHandler={alertCloseHandler}
        />
        <CircularProgress />
        <p>Processing your data for Sign In...</p>
        <h1>"The seeking of knowledge is obligatory for every Muslim."</h1>
        <h2>- Al-Tirmidhi, 74</h2>
      </div>
    </div>
  );
}

export default TokenBasedSignIn;
