import axios from "axios";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
const baseUrl =
  process.env.REACT_APP_PROJECT_ENV == "dev"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

const orgId = localStorage.getItem("orgId");

const userToken = localStorage.getItem("userToken");

axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

//function to convert date from this format "2021-03-04T10:00:00.000Z" to this format "04/03/2021"
export const convertDate = (date) => {
  let newDate = new Date(date);
  let day = newDate.getDate();
  let month = newDate.toLocaleString("en-US", { month: "long" });
  let year = newDate.getFullYear();
  let fullDate = day + "-" + month + "-" + year;
  return fullDate;
};

//function to convert file size from kilo bytes to KB/MB/GB
export const convertFileSize = (kiloBytes) => {
  if (kiloBytes < 1) {
    return "N/A";
  } else {
    let fileSize = Number(kiloBytes);
    let fileSizeType = "KB";
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeType = "MB";
    }
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeType = "GB";
    }
    return fileSize.toFixed(1) + " " + fileSizeType;
  }
};

export const getVideoId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2]) {
    return match[2];
  } else {
    return null;
  }
};

export const generateTableRows = (data, handleChangeRedio, selectedValue) => {
  let temp = [];
  data.map((item, index) => {
    // console.log("item", item)
    //make the item.file_type first letter capital
    let file_type =
      item.file_type?.charAt(0)?.toUpperCase() + item.file_type?.slice(1);
    temp?.push({
      cell: [
        {
          img: item.file_url,
          singleIconStatus: item?.file_type?.startsWith("video") ? true : false,
          singleIcon: <PlayCircleFilledOutlinedIcon />,
          imgStatus: !item?.file_type?.startsWith("video") ? true : false,
          imgText: item.name,
          align: "left",
          textLink: "#",
          TextLight: true,
          Radio: true,
          handleChangeRedio: handleChangeRedio,
          RedioName: "rizwan",
          RedioValue: item.id,
          selectedValue: selectedValue == item.id,
        },
        { text: file_type },
        {
          text: item?.is_private === 0 ? "Public" : "Private",
        },
        {
          text: convertFileSize(item.file_size),
          textcolor: item.file_type?.startsWith("image")
            ? "#1B1B1F"
            : "#A4A7B0",
        },
      ],
    });
  });
  return temp;
};
export const generaImageUrl = (data) => {
  //if data.file_type starts with image then return data.file_url
  //else if data.file_type starts with video then return youtube image
  //else return file image
  if (data.file_type.startsWith("image")) {
    return data.file_url;
  } else if (data.file_type.startsWith("video")) {
    return "https://cdn.mytakbir.com/static-assets/images/YouTube.svg";
  } else {
    return "https://cdn.mytakbir.com/static-assets/images/Attachment.svg";
  }
};

export const onFileUpload = async (
  imageName,
  filePath,
  fileName,
  fileSize,
  fileType,
  width,
  height,
  is_private
) => {
  try {
    console.log("filePath", filePath);
    // props.loadingIconStatusChange(true);
    //call to api via axios
    const response = await axios.post(baseUrl + "/imageUpload", {
      name: imageName,
      orgId: orgId,
      file_url: filePath,
      file_name: fileName,
      file_size: fileSize,
      width: width,
      height: height,
      file_type: fileType,
      is_private: is_private,
    });

    // console.log( response );
    // props.loadingIconStatusChange(false);
    //if response status is 200
    if (response.status == "200") {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};
