import React, { useState, useEffect } from "react";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Buttons from "../../../../../components/Form/Button/Button";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import AddTerm from "./AddTerm";
import AddCourses from "./AddCourses";
import axios from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import TermImg from "../../../../../assets/imgs/course-image.png";

import DraggableDialog from "../../../../../hooks/Dialog";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import { capitalizeFirstLetter } from "../../../../../hooks/HelperFunctions";

function Term(props) {
  const {
    term,
    terms,
    setTerms,
    timezones,
    orgCourses,
    selectedCourse,
    setSelectedCourse,
    orgId,
    getTrackDetailsInfo,
    index,
  } = props;
  const [expanded, setExpanded] = React.useState("");
  const [deletedId, setDeletedId] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV == "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const handleChangePannel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [termCourses, setTermCourses] = useState([]);
  const [termsData, setTermData] = useState([]);
  const [courseDeleteId, setDeleteCourseId] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [trackData, setTrackData] = useState({});

  const [showSeatField, setShowSeatField] = React.useState(false);

  //======= Drawer ===== //
  const [open2, setOpen2] = useState(false);
  const handleDrawerOpen = () => {
    setOpen2(true);
  };
  const handleDrawerClose = () => {
    setOpen2(false);
  };
  //======= Drawer ===== //

  //=== Edit Term Drawer ==//
  const [openTermDrawer, setOpenTerm] = useState(false);
  const handleDrawerOpenTerm = () => {
    setOpenTerm(true);
  };
  const handleDrawerCloseTerm = () => {
    setOpenTerm(false);
  };
  //=== Edit Term Drawer ==//

  useEffect(() => {
    setTermCourses(term?.courses);
    if (term?.enableSeatNumber) {
      setShowSeatField(true);
    } else {
      setShowSeatField(false);
    }
  }, [term]);

  useEffect(() => {
    setTermData(convertForPresentation(termCourses));
  }, [termCourses]);

  const convertForPresentation = (termCourses) => {
    if (termCourses.length > 0) {
      let data = termCourses?.map((course) => {
        return {
          courseId: course?.courseId,
          Img: course?.course?.courseImage,
          Title: course?.course?.courseTitle
            ? course?.course?.courseTitle
            : "N/A",
          StatusLabel: course?.course?.status
            ? capitalizeFirstLetter(course?.course?.status)
            : "N/A",
          StatusColor: "#0B9444",
          StatusBodyColor: "#E1F2E8",
          Id: course?.course?.courseRefId ? course?.course?.courseRefId : "N/A",
          Price: course?.course?.price ? `$${course?.course?.price}` : "$0",
        };
      });
      return data;
    } else {
      return [];
    }
  };

  useEffect(() => {
    setTrackData(term);
  }, [term]);

  const showSnackbarMessage = (severity, message) => {
    setShowSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  /**
   * When click on snackbar close btn
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const handleAddNewCourse = () => {
    console.log(selectedCourse); //courseId
    console.log(term);
    console.log(orgId);

    if (!selectedCourse) {
      showSnackbarMessage("error", "Please select a Course");
    } else {
      let alreadyAdded = false;
      if (term?.courses?.length > 0) {
        term?.courses?.map((course) => {
          if (course?.courseId == selectedCourse) {
            alreadyAdded = true;
          }
        });
      }
      if (alreadyAdded) {
        showSnackbarMessage("error", "This course already added");
        return false;
      }
      // show loader
      setOpenBackdrop(true);
      const postData = {
        orgId: orgId,
        termId: term?.id,
        courseId: selectedCourse,
      };
      axios
        .post(`${baseUrl}/orgPortal/addCourseToTerm`, postData)
        .then((response) => {
          showDataPostMessage(response);
        });
    }
  };

  const showDataPostMessage = (response) => {
    console.log(response.data, response.data.error);

    // hide loader
    setOpenBackdrop(false);

    if (response.data.error) {
      showSnackbarMessage("error", response.data.message);
    } else {
      showSnackbarMessage("success", "Course added to track.");
      handleDrawerClose();
      getTrackDetailsInfo();
    }
  };

  const showDataPostMessageTerm = (response) => {
    console.log(response.data, response.data.error);

    // hide loader
    setOpenBackdrop(false);

    if (response.data.error) {
      showSnackbarMessage("error", response.data.message);
    } else {
      showSnackbarMessage("success", "Course updated.");
      handleDrawerClose();
      getTrackDetailsInfo();
    }
  };

  const showRemoveCourseMessage = (response) => {
    // hide loader
    setOpenBackdrop(false);

    if (response.data.error) {
      showSnackbarMessage("error", response.data.message);
    } else {
      showSnackbarMessage("success", "Course removed.");
      handleDrawerClose();
      getTrackDetailsInfo();
      handleCloseDeleteDialog();
    }
  };

  //======= Delete dialog ===== //
  const [openDeleteDialog, setOpenDialog] = useState(false);
  const [openTermDeleteDialog, setTermOpenDialog] = useState(false);
  const [openUnableDeleteCourseDialog, setOpenUnableDeleteCourseDialog] =
    useState(false);
  const [openUnableDeleteTermDialog, setOpenUnableDeleteTermDialog] =
    useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDeleteTerm = () => {
    setTermOpenDialog(true);
  };
  const handleCloseDeleteDialogTerm = () => {
    setTermOpenDialog(false);
  };

  //======= Delete dialog ===== //

  console.log(trackData, 345);
  const clickSaveButton = () => {
    console.log("Track datas", trackData);
    const enrollmentStartDate = new Date(trackData.enrollmentStartDate);
    const enrollmentEndDate = new Date(trackData.enrollmentEndDate);
    const sessionStartDate = new Date(trackData.sessionStartDate);
    const sessionEndDate = new Date(trackData.sessionEndDate);

    const isSessionStartBeforeEnrollmentStart =
      sessionStartDate < enrollmentStartDate;
    const isSessionStartBeforeEnrollmentEnd =
      sessionStartDate < enrollmentEndDate;
    const isSessionEndBeforeEnrollmentStart =
      sessionEndDate < enrollmentStartDate;
    const isSessionEndBeforeEnrollmentEnd = sessionEndDate < enrollmentEndDate;

    if (!trackData.trackTermName) {
      showSnackbarMessage("error", "Term name is required.");
    } else if (!trackData.enrollmentStartDate) {
      showSnackbarMessage("error", "Enrollment start date is required.");
    } else if (!trackData.enrollmentEndDate) {
      showSnackbarMessage("error", "Enrollment end date is required.");
    } else if (enrollmentStartDate > enrollmentEndDate) {
      showSnackbarMessage("error", "Enrollment date range is not valid.");
    } else if (!trackData.sessionStartDate) {
      showSnackbarMessage("error", "Session start date is required.");
    } else if (!trackData.sessionEndDate) {
      showSnackbarMessage("error", "Session end date is required.");
    } else if (
      (isSessionStartBeforeEnrollmentStart ||
        isSessionStartBeforeEnrollmentEnd) &&
      !trackData?.id
    ) {
      showSnackbarMessage("error", "Backdate cant be accepted");
    } else if (
      (isSessionStartBeforeEnrollmentStart ||
        isSessionStartBeforeEnrollmentEnd) &&
      !trackData?.id
    ) {
      showSnackbarMessage("error", "Backdate cant be accepted");
    } else if (!trackData.totalMonths) {
      showSnackbarMessage("error", "Total months field is required.");
    } else if (showSeatField && !trackData.seatNumber) {
      showSnackbarMessage("error", "Seat Number field is required.");
    } else {
      // show loader
      setOpenBackdrop(true);
      // axios
      //   .post(`${baseUrl}/orgPortal/updateTrack`, trackData)
      //   .then((response) => showDataPostMessage(response));

      axios
        .post(`${baseUrl}/orgPortal/addTermToTrack`, trackData)
        .then((response) => {
          showDataPostMessageTerm(response);
          getTrackDetailsInfo();
          handleDrawerCloseTerm();
        });
    }
  };

  const allCoursesPriceInTheTerm = () => {
    let price = 0;
    if (term?.courses?.length > 0) {
      term?.courses?.map((course) => {
        price = price + course?.course?.price;
      });
    }
    return price;
  };

  const checkCourseEnrollment = async (termId, courseId) => {
    let data = {
      term_id: termId,
      course_id: courseId,
    };
    let alreadyEnrolled = false;
    let result = await axios.post(
      `${baseUrl}/orgPortal/checkTermCourseEnrollment`,
      data
    );

    if (result && result.data) {
      alreadyEnrolled = result?.data?.error;
    }
    return alreadyEnrolled;
  };

  const handleDeleteModalShow = async (id) => {
    let alreadyEnrolled = await checkCourseEnrollment(term?.id, id);

    if (alreadyEnrolled) {
      setOpenUnableDeleteCourseDialog(true);
      return false;
    }
    setDeleteCourseId(id);
    handleOpenDelete();
  };

  const deleteCourseHandler = () => {
    setOpenBackdrop(true);
    const postData = { termId: term?.id, courseId: courseDeleteId };
    axios
      .post(`${baseUrl}/orgPortal/removeCourseFromATerm`, postData)
      .then((response) => {
        showRemoveCourseMessage(response);
      });
  };

  const handleOpenEditTermDrawer = () => {
    handleDrawerOpenTerm();
  };

  const checkTermEnrollment = async (termId) => {
    let data = {
      term_id: termId,
    };
    let alreadyEnrolled = false;
    let result = await axios.post(
      `${baseUrl}/orgPortal/checkTermEnrollment`,
      data
    );

    if (result && result.data) {
      alreadyEnrolled = result?.data?.error;
    }
    return alreadyEnrolled;
  };

  //================================== delete term =========================================//
  const handleOpenDeleteTermDrawer = async (id) => {
    let alreadyEnrolled = await checkTermEnrollment(id);

    if (alreadyEnrolled) {
      setOpenUnableDeleteTermDialog(true);
      return false;
    }
    handleOpenDeleteTerm();
    setDeletedId(id);
  };

  const deleteTermeHandler = () => {
    setOpenBackdrop(true);
    const postData = { term_id: deletedId };
    axios
      .post(`${baseUrl}/orgPortal/deleteATermFromATrack`, postData)
      .then((response) => {
        if (!response?.data?.error) {
          let latesteTerm = terms?.filter((term) => term?.id !== deletedId);
          setTerms(latesteTerm);
          console.log(response, 345, latesteTerm, terms);
          handleCloseDeleteDialogTerm();
        } else {
          console.error("Something went wrong");
        }

        // showRemoveCourseMessage(response);
      });
  };
  //================================== delete term =========================================//
  return (
    <div className="formSection">
      <div className="FormBody">
        <div className="AccrodionThreeStep">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangePannel("panel1")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardRoundedIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="accTitleSection">
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {term?.trackTermName}
                  <div className="TermListingDetailsStatus">
                    <span>{term?.courses?.length} courses</span> <CircleIcon />{" "}
                    ${allCoursesPriceInTheTerm()}
                  </div>
                </Typography>

                <div className="AccActionButtons">
                  <Buttons
                    label="Course"
                    icon={<AddRoundedIcon />}
                    buttonColor="#004FE0"
                    border="#E4E8EC 1px solid"
                    color="#fff"
                    width=""
                    height="40px"
                    fontSize="14px"
                    onClick={(e) => handleDrawerOpen()}
                  />

                  <div className="ActionMenudotButton">
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <MoreHorizRoundedIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <span onClick={handleClose} className="MenuListing">
                        <a
                          className="MenuListingItem"
                          onClick={(e) => handleOpenEditTermDrawer()}
                        >
                          Edit
                        </a>
                        {index !== 0 && (
                          <a
                            className="MenuListingItem"
                            onClick={(e) =>
                              handleOpenDeleteTermDrawer(term?.id)
                            }
                          >
                            Delete
                          </a>
                        )}
                      </span>
                    </Menu>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {termsData.map((item, i) => (
                <div className="TermListing">
                  <div className="TermListingLeftCol">
                    <div className="TermAvatar">
                      <img src={item.Img} />
                    </div>
                    <div className="TermListingDetails">
                      <h1>{item.Title}</h1>
                      <div className="TermListingDetailsStatus">
                        <div
                          className="TermStatusChip"
                          style={{
                            color: item.StatusColor,
                            background: item.StatusBodyColor,
                          }}
                        >
                          {item.StatusLabel}
                        </div>
                        <CircleIcon /> <span>{item.Id}</span> <CircleIcon />{" "}
                        {item.Price}
                      </div>
                    </div>
                  </div>

                  <Button
                    className="ItemDeleteIcon"
                    onClick={(e) => handleDeleteModalShow(item?.courseId)}
                  >
                    <DeleteForeverIcon />
                  </Button>
                </div>
              ))}

              {termsData?.length == 0 && (
                <div className="AccEmptyCard">Add courses to this term</div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>

        {/* <div >
          <Buttons
            label="Term"
            icon={<AddRoundedIcon />}
            buttonColor="#004FE0"
            border="#E4E8EC 1px solid"
            color="#fff"
            width="100%"
            height="38px"
            fontSize="14px"
            onClick={handleDrawerOpenTerm}
          />
        </div> */}
      </div>

      <SideDrawer
        title="Edit term"
        ButtonLabel="Save"
        clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openTermDrawer}
        handleDrawerClose={handleDrawerCloseTerm}
        //onCheckboxClicked={handleCheckboxClicked}
        FooterWithoutTab={true}
        body={
          <AddTerm
            trackData={trackData}
            setTrackData={setTrackData}
            timezones={timezones}
            showSeatField={showSeatField}
            setShowSeatField={setShowSeatField}
          />
        }
      />

      <SideDrawer
        title="Add courses to track"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={open2}
        FooterWithoutTab={true}
        clickSaveButton={handleAddNewCourse}
        handleDrawerClose={handleDrawerClose}
        body={
          <AddCourses
            orgCourses={orgCourses}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
          />
        }
      />

      <DraggableDialog
        openDialog={openDeleteDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={(e) => deleteCourseHandler()}
        title="Remove course from track?"
        body={
          <div>
            Are you sure you want to remove this course? <br />
            You can add this course again to this track
          </div>
        }
        ModalFooter={true}
        actionButton="Remove"
      />
      <DraggableDialog
        openDialog={openTermDeleteDialog}
        handleCloseDialog={handleCloseDeleteDialogTerm}
        handleConfirmCloseDialog={(e) => deleteTermeHandler()}
        title="Delete this term"
        body={
          <div>
            Are you sure you want to remove this term? <br />
            Once you delete it, you can't get it back.
          </div>
        }
        ModalFooter={true}
        actionButton="Delete"
      />

      <DraggableDialog
        openDialog={openUnableDeleteCourseDialog}
        handleCloseDialog={(e) => setOpenUnableDeleteCourseDialog(false)}
        handleConfirmCloseDialog={(e) => setOpenUnableDeleteCourseDialog(false)}
        title="Unable to remove course"
        body={
          <div>
            It is not possible to delete this course because there are one or
            more active learners enrolled in it.
          </div>
        }
        ModalFooter={true}
        actionButton="Ok"
        hideCancelButton={true}
      />

      <DraggableDialog
        openDialog={openUnableDeleteTermDialog}
        handleCloseDialog={(e) => setOpenUnableDeleteTermDialog(false)}
        handleConfirmCloseDialog={(e) => setOpenUnableDeleteTermDialog(false)}
        title="Unable to delete term"
        body={
          <div>
            This term can not be deleted because activer learner(s) are in one
            or multiple courses.
          </div>
        }
        ModalFooter={true}
        actionButton="Ok"
        hideCancelButton={true}
      />

      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </div>
  );
}

export default Term;
