import React, { useState, useEffect } from "react";

import { getDataMap } from "../../../../hooks/HelperFunctions";

import Grid from "@material-ui/core/Grid";
import RedioBox from "../../../../components/Form/RedioBox/RedioBox";
import TextField from "../../../../components/Form/TextField/TextField";
import SlateEditor from "../../../../components/SlateEditor/SlateEditor";
import Datefield from "../../../../components/Form/Datefield/Datefield";
import ComboBox from "../../../../components/Form/ComboBox/ComboBox";

//import sidedrawer
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../components/Form/Button/Button";
import Externaluse from "../../../../components/MediaLibrary/Externaluse";
import Avatar from "@mui/material/Avatar";
//import deletehoverrounded from mui component
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

function BasicInfo(props) {
  const { trackData, setTrackData, timezones, trackErrors } = props;

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldName, fieldData);
    setTrackData({ ...trackData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    if (fieldName == "enrollmentStartDate") {
      console.log(fieldData.getDate());
    }
    setTrackData({ ...trackData, [fieldName]: fieldData });
  };

  // start: track image related code

  const [openSelectMedia, setOpenSelectMedia] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [mediaItem, setMediaItem] = useState({});

  const [openNewMedia, setOpenNewMedia] = useState(false);

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  //deleteCourseImageSelection
  const deleteCourseImageSelection = () => {
    setTrackData({ ...trackData, trackImage: "" });
  };

  const setImageSelection = (fileUrl) => {
    // console.log(fileUrl);
    let temp = trackData;
    temp.trackImage = fileUrl;
    setTrackData(temp);
    // console.log(trackData);
  };

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };
  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      // console.log(mediaItem);
      //set the media item
      setImageSelection(mediaItem.file_url);
      handleChangeWithData("trackImageName", mediaItem.name);

      // console.log( trackData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  // start: track image related code

  const tabsData = [
    {
      title: "Diploma",
      description: "It's a diploma track",
    },

    {
      title: "Certificate",
      description: "It's a certificate track",
    },
  ];

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <RedioBox
            label="Choose Track Type"
            tabsData={tabsData}
            // panelDate={panelDate}
            value={trackData.trackType == "diploma" ? 0 : 1}
            // onChange={handleChanges("trackType")}
            onChange={(event, selectedDate) =>
              handleChangeWithData(
                "trackType",
                selectedDate == 0 ? "diploma" : "certificate"
              )
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Track Name"
            validation={true}
            trackNameError
            error={trackErrors?.name}
            helperText={
              trackData?.trackNameError
                ? trackErrors?.name
                  ? "Track name already exists"
                  : ""
                : ""
            }
            count={50}
            value={trackData.name}
            handleChange={handleChanges("name")}
          />
        </Grid>

        {/* track image */}
        <Grid item xs={12} sm={12} md={12}>
          <div className="FieldLabel">
            Track Image<span>*</span>
          </div>
          {(trackData?.trackImage ? trackData.trackImage.length : false) ? (
            <>
              <div className="TableSection">
                <div
                  style={{
                    padding: "0.2rem",
                    // background: "#C3C3C3",
                    border: "1px solid #A4A7B0",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span className="CellImg">
                      <Avatar variant="rounded" src={trackData.trackImage} />
                    </span>
                    <span>{trackData?.trackImageName}</span>
                  </div>
                  <span className="CellImg">
                    <DeleteForeverRoundedIcon
                      onClick={deleteCourseImageSelection}
                      sx={{ mb: -0.5, color: "#676C7B" }}
                    />
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div>
              <SideDrawer
                title="Media Library"
                ButtonLabel="Add"
                //clickSaveButton={clickSaveButton}
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={openSelectMedia}
                handleDrawerClose={handleDrawerCloseSelectMedia}
                FooterWithoutTab={true}
                LeftButton={true}
                ButtonLeft="Upload New"
                ButtonLeftIcon={<FileUploadOutlinedIcon />}
                clickButtonLeft={handleDrawerOpenNewMedia}
                clickSaveButton={clickSelectMediaItem}
                body={
                  <MediaLibrarySelectMedia
                    onClickRadioIcon={setSelectedValue}
                    onItemSelected={getMediaItem}
                  />
                }
              />

              <Externaluse
                open={openNewMedia}
                handleCloseButton={handleMediaUploadClose}
                handleOpenNewMedia={handleOpenNewMedia}
              />

              <div className="TableSection">
                <Buttons
                  label="Add From Media Library"
                  buttonColor="transparent"
                  // border="#ccc 1px solid"
                  border={
                    trackErrors?.trackImage ? "red 1px solid" : "#ccc 1px solid"
                  }
                  icon={<AddRoundedIcon />}
                  color="#004FE0"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={() => handleDrawerOpenSelectMedia()}
                />
                {trackErrors?.trackImage && (
                  <span style={{ fontSize: "12px", color: "#d32f2f" }}>
                    Required
                  </span>
                )}
              </div>
            </div>
          )}
        </Grid>
        {/* track image end */}

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            label="Select Timezone"
            validation={true}
            placeholder="Search Timezone"
            options={getDataMap(
              timezones,
              "timezone",
              "timezone",
              "gmtOffset",
              " | GMT "
            )}
            selectedOption={trackData.timezone}
            handleChange={(fieldData) =>
              handleChangeWithData("timezone", fieldData)
            }
            error={trackErrors?.timezone}
            helperText={trackErrors?.timezone}
            TooltipStatus={true}
            TooltipText="Select the timezone from where track will be primarily proctored from.  All learners will see this absolute timezone on their ends."
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <SlateEditor
            label="Description"
            required={false}
            placeholder="Enter description here..."
            onChange={(fieldData) =>
              handleChangeWithData("description", fieldData)
            }
            value={trackData.description}
            readOnly={false}
            charLimit={1500}
            height="150px"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default BasicInfo;
