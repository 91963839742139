import React, { useEffect, useState } from "react";
import "../assets/css/inbox.css";

import Table from "../../../../components/Table/Table";
import img from "../assets/images/user-img.jpg";
import Search from "../components/Search/Search";
import EmptyCard from "../components/EmptyCard/EmptyCard";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import axios from "axios";

function Individuals({
  allIndividualsOfOrganizationUsers,
  inboxInfo,
  setInboxInfo,
  participants,
  participantsUsers,
  setSelectedUsers,
  selectedUsers,
  setSelectedUsersData,
  selectedUsersData,
  formattedUserData,
  setFormattedUserData,
}) {
  const orgId = localStorage.getItem("orgId");
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const [rows, setRows] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  // const [selectedUsersData, setSelectedUsersData] = useState([]);
  // const [formattedUserData, setFormattedUserData] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    if (allIndividualsOfOrganizationUsers?.length > 0) {
      setRows(convertRowToCell(allIndividualsOfOrganizationUsers));
    }
  }, [allIndividualsOfOrganizationUsers]);
  useEffect(() => {
    if (participants) {
      setSelectedUsers(participants);
    }
    // console.log(participants, 345);
  }, [participants]);
  // useEffect(() => {
  //   console.log(selectedUsers, 345, selectedUsersData);
  // }, [selectedUsersData, selectedUsers]);

  const convertRowToCell = (array) => {
    if (array?.length > 0) {
      return array?.map((item, index) => ({
        cell: [
          {
            img: item?.image,
            imgAvatarColor: "#004FE0",
            // imgName: "RD",
            // user_id: `${item?.id}#${index + 2}`,
            user_id: `${item?.id}#${item?.role}`,
            defaultChecked:
              selectedUsersData?.find((user) => user === item.id) !== undefined,
            checked: true,
            user: {
              userUniqId: `${item?.id}#${index + 2}`,
              first_name: item?.first_name,
              last_name: item?.last_name,
              id: item?.id,
              role: item?.role,
              image: item?.image,
            },
            ImgSize: "60%",
            imgStatus: true,
            imgTitle: item?.first_name?.charAt(0)?.toUpperCase(),
            imgText: `${item?.first_name} ${item?.last_name}`,
            imgAvatarColor: "#004FE0",
            subText: item?.role,
            Check: true,
            // defaultChecked: true,
          },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    let addUsers = [];
    let formattedUserID = [];
    selectedUsers.map((item) => {
      if (typeof item === "string") {
        formattedUserID.push(parseInt(item?.split("#")[0]));
      } else {
        formattedUserID.push(item);
      }
    });

    formattedUserID?.map((users) => {
      let foundUser = allIndividualsOfOrganizationUsers.find(
        (user) => user?.id === users
      );
      console.log(formattedUserID, 345, foundUser, selectedUsers);
      if (foundUser) {
        addUsers.push({
          first_name: foundUser?.first_name,
          last_name: foundUser?.last_name,
          id: foundUser?.id,
          role: foundUser?.role,
          image: foundUser?.image,
        });
      }
    });
    setSelectedUsersData(addUsers);
    // console.log(selectedUsers, 345);
  }, [selectedUsers, allIndividualsOfOrganizationUsers]);
  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the user to the array
      setSelectedUsers((prevUsers) => [...prevUsers, item[0].user_id]);
    } else {
      // Remove the user from the array
      setSelectedUsers((prevUsers) =>
        prevUsers.filter((user) => user !== item[0].user_id)
      );
    }
  };

  useEffect(() => {
    let formattedData = [];
    selectedUsersData?.map((item) =>
      formattedData.push({
        user_id: item?.id,
        participant_type: item?.role === "Learner" ? "student" : "teacher",
      })
    );
    setFormattedUserData(formattedData);
  }, [selectedUsersData]);
  useEffect(() => {
    setInboxInfo({
      ...inboxInfo,
      participants: formattedUserData,
    });
  }, [formattedUserData]);

  //==================================== search part ==========================================//
  const handleSearchText = (event) => {
    event.preventDefault();
    setSearchedText(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getAllIndividualsOfOrganization(searchedText);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [searchedText]);

  const getAllIndividualsOfOrganization = async (searchData) => {
    let data = {
      orgId: orgId,
      searchText: searchData,
    };
    const totalDatas = await axios.post(
      `${baseUrl}/orgPortal/searchIndividualsForConversation`,
      data
    );
    const withOutIdNull = totalDatas?.data?.data?.filter(
      (item) => item?.id !== null
    );
    setRows(convertRowToCell(withOutIdNull));
  };
  //==================================== search part ==========================================//
  return (
    <div className="InboxTableSection">
      <Search handleSearchText={handleSearchText} searchedText={searchedText} />
      <div className="InboxTypeTableContainer">
        <Table
          rows={rows}
          footer={true}
          handleRowClick={handleRowClick}
          handleCheckItem={setSelectedUsers}
          selectedCheckList={selectedUsers}
          customCellObjectId="user_id"
          CheckboxDelete={true}
          // handleSearchText={handleSearchText}
          // handleSearch={handleSearch}
        />
      </div>

      <EmptyCard
        message="No public courses or tracks found"
        icon={<PriorityHighIcon />}
      />
    </div>
  );
}

export default Individuals;
