import React, { useEffect, useState } from "react";
import img from "../../../assets/imgs/Certificate.svg";
import imggray from "../../../assets/imgs/Certificate-gray.svg";
import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import HrTabsFill from "../../../components/HorizotalTabs/HrTabsFill";
import CoursesCards from "../../../components/CoursesCard/CoursesCards";
import Modals from "../../../components/Form/Modals/Modals";
import ShearModalBody from "../../../components/ShearModalBody/ShearModalBody";
import { getUserData } from "../../../hooks/HelperFunctions";
import axios from "axios";
import SimpleBackdrop from "../../../components/Form/Backdrop/SimpleBackdrop";

function Certificates(props) {
  const [value, setValue] = React.useState(0);
  const [shearModal, setShearModal] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // get usr data
  const userData = getUserData();
  const userId = userData.id;
  const userEmail = userData.email;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [allCertificateList, setAllCertificateList] = React.useState([]);
  const [completedCertificateList, setCompletedCertificateList] =
    React.useState([]);
  const [inPorgressCertificateList, setInPorgressCertificateList] =
    React.useState([]);
  const [seletedItem, setSeletedItem] = React.useState({});
  // console.log(userId, 328);

  // fetching certificate data

  const getCertificateListingData = () => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    setOpenBackdrop(true);
    axios
      .get(
        `${baseUrl}/studentPortal/getStudentCoursesWithTrackCoursesDataOnly?userId=${userId}`
      )
      .then((result) => {
        // console.log(result.data.body);
        const allCertificateData = [];
        result.data.body?.map((certificate) => {
          if (
            ("courseId" in certificate && certificate.courseId !== null) ||
            ("trackId" in certificate && certificate.trackId !== null)
          ) {
            allCertificateData.push(certificate);
          }
        });
        const filterCertificateData = allCertificateData?.filter(
          (course) => course?.completionCertificate !== 0
        );
        setAllCertificateList(filterCertificateData);
        setOpenBackdrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCertificateListingData();
  }, []);

  useEffect(() => {
    if (allCertificateList?.length > 0) {
      const inPorgressCertificateListData = allCertificateList?.filter(
        (item) => !item.fileLink
      );

      setInPorgressCertificateList(inPorgressCertificateListData);
      const completedCertificateListData = allCertificateList?.filter(
        (item) => item.fileLink
      );
      setCompletedCertificateList(completedCertificateListData);
    }
  }, [allCertificateList]);

  const handlerShearButtonOpen = (item) => {
    setSeletedItem(item);
    setShearModal(true);
  };

  const tabLabel = [
    {
      label: "All",
    },
    {
      label: "Completed",
    },
    {
      label: "In Progress",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: (
        <CoursesCards
          sessionData={allCertificateList}
          handlerShearButtonOpen={handlerShearButtonOpen}
          userId={userId}
        />
      ),
    },
    {
      id: 1,
      panel: (
        <CoursesCards
          sessionData={completedCertificateList}
          handlerShearButtonOpen={handlerShearButtonOpen}
          userId={userId}
        />
      ),
    },
    {
      id: 2,
      panel: (
        <CoursesCards
          sessionData={inPorgressCertificateList}
          handlerShearButtonOpen={handlerShearButtonOpen}
          userId={userId}
        />
      ),
    },
  ];

  return (
    <>
      {/* <h1>hello</h1> */}
      {/* <ComingSoon title="Certificates" /> */}
      <SimpleBackdrop open={openBackdrop} />
      <div>
        <HrTabsFill
          title="Certificates"
          tabLabel={tabLabel}
          tabBody={tabBody}
        />
      </div>

      {/* ===== */}
      {/* ================== Modal ===================== */}
      <Modals
        ModalTitle="Share this certificate"
        // handleConfirm={() => handleConfirm()}
        handleClose={() => setShearModal(false)}
        open={shearModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={false}
        ModalBody={
          <div>
            <ShearModalBody userEmail={userEmail} seletedItem={seletedItem} />
          </div>
        }
      />
    </>
  );
}

export default Certificates;
