import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import "./SideBarDrawer.css";

function SideBarDrawer(props) {
  const { open, handleDrawerClose } = props;
  const theme = useTheme();

  return (
    <div className="SideBarDrawer" style={{ paddingTop: "0px" }}>
      <div>
        <CssBaseline />

        <Drawer variant="persistent" anchor="left" open={open}>
          <div className="MenuIconClose">
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <CloseRoundedIcon />
              ) : (
                <CloseRoundedIcon />
              )}
              {/* <span
                style={{
                  marginLeft: "2px",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Back
              </span> */}
            </IconButton>
          </div>

          {props.DrawerBody}
        </Drawer>
      </div>
    </div>
  );
}

export default SideBarDrawer;
