import React, { useEffect, useState } from "react";

import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

function Overview({ userInfo, roles }) {
  const getRoleName = (data) => {
    let convertedArray = data?.split(",");
    let findData = convertedArray?.map((itemData) =>
      roles?.find((item) => item?.id == parseInt(itemData))
    );
    let names = [];
    if (findData) {
      findData?.map((item) => names?.push(item?.name));
    }
    return names?.join(", ");
  };

  const getRoleName2 = (data) => {
    let names = [];
    if (data) {
      data?.map((item) => names?.push(item?.role?.name));
    }
    return names?.join(", ");
  };

  return (
    <>
      <div className="TableContainer oddEvenTable">
        <div className="TableInnerHeader">
          <h3>Access details</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Full name
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {userInfo?.first_name} {userInfo?.last_name}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Role
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {userInfo?.roles
                    ? getRoleName(userInfo?.roles)
                    : getRoleName2(userInfo?.team_member_roles) || "N/A"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Organization
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {userInfo?.organization_name || "N/A"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Designation
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {userInfo?.designation || "N/A"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Short bio
                </StyledTableCell>
                <StyledTableCell align="left" className="TableDescriotion">
                  {userInfo?.short_bio || "N/A"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Overview;
