import React, { useEffect, useState } from "react";
import "../assets/css/inbox.css";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import Buttons from "../components/Button/Button";
import InboxTabs from "../components/Tabs/Tabs";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import InboxListing from "./InboxListing";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import MarkunreadRoundedIcon from "@mui/icons-material/MarkunreadRounded";
import CampaignIcon from "@mui/icons-material/Campaign";
import img from "../assets/images/user-img.jpg";
import AnnouncementIcon from "../assets/images/announcement-icon.svg";
import EmptyCard from "../components/EmptyCard/EmptyCard";
import ChatRoom from "./ChatRoom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AddNew from "./AddNew";
import SideDrawer from "../components/SideDrawer/SideDrawer";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import EmailIcon from "@mui/icons-material/Email";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import axios from "axios";
import moment from "moment-timezone";
import { shortRelativeTime } from "../../../../hooks/HelperFunctions";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import { useNavigate } from "react-router-dom";

function Inbox(props) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = localStorage.getItem("orgId");
  const [generalData, setGeneralData] = useState([]);
  const [generalConversationDatas, setGeneralConversationDatas] = useState([]);
  const [importantData, setImportantData] = useState([]);
  const [importantConversationDatas, setImportantConversationDatas] = useState(
    []
  );
  const [tabBodyUsers, setTabBodyUsers] = useState([]);
  const [importantTabBodyUsers, setImportantTabBodyUsers] = useState([]);
  const [draftConverstionLists, setDraftConverstionLists] = useState([]);
  const [draftConverstionListsBodyUsers, setDraftConverstionListsBodyUsers] =
    useState([]);
  const [startConverstionsStatus, setstartConverstionsStatus] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState(0);
  const [
    generalUnreadConversationsNumberOfATeacher,
    setGeneralUnreadConversationsNumberOfATeacher,
  ] = useState(null);
  const [
    importantUnreadConversationsNumberOfATeacher,
    setImportantUnreadConversationsNumberOfATeacher,
  ] = useState(null);
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const [tabValue, tabsetValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    tabsetValue(newValue);
  };
  const navigate = useNavigate();
  //== Chat room responsive view drawer start==//
  const [open, setOpen] = useState(false);

  const handleDraftDrawerOpen = (type, conversation_id) => {
    navigate(`/inbox-type/${type}?conversation_id=${conversation_id}`);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setstartConverstionsStatus(false);
  }, [tabValue]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //== Chat room responsive view drawer end ==//
  const extractPlainText = (jsonData) => {
    const textSegments = [];
    let parsedData = JSON.parse(jsonData != "" && jsonData);
    let allChildren = parsedData[0]?.children;
    allChildren?.forEach((item) => {
      if (item.text !== undefined) {
        textSegments.push(item.text);
      } else if (item.children) {
        textSegments.push(extractPlainText(item.children));
      } else if (item.text === "") {
        textSegments.push(" ");
      }
    });

    return textSegments.join("");
  };

  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  const convertHTMLToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const formattedText = (text) => {
    let textData;
    if (!isJSON(text)) {
      // If text is not valid JSON, set textData to the original text
      textData = convertHTMLToPlainText(text);
    } else {
      // If text is valid JSON, proceed with extracting plain text
      textData = extractPlainText(text);
    }

    return textData;
  };
  const convertIntoArray = (data) => {
    let getArray = JSON.parse(data);

    return parseInt(getArray?.length);
  };

  const handleDrawerOpen = () => {
    getUnreadConversationsNumberOfATeacher();
    getImportantUnreadConversationsNumberOfATeacher();
    getGeneralConversations();
    getImportantConversations();
    setstartConverstionsStatus(true);
    setOpen(true);
  };

  //========================== get Unread Conversations Number ================================//
  const getUnreadConversationsNumberOfATeacher = async () => {
    let data = {
      user_id: userData?.id,
      org_id: parseInt(orgId),
    };
    const result = await axios.post(
      `${baseUrl}/orgPortal/${
        orgId
          ? "getUnreadConversationsNumberOfATeacher"
          : "getUnreadConversationsNumberOfAStudent"
      }`,
      data
    );
    if (!result?.data?.error) {
      setGeneralUnreadConversationsNumberOfATeacher(
        result?.data?.unread_conversation_number
      );
    }
  };
  const getImportantUnreadConversationsNumberOfATeacher = async () => {
    let data = {
      user_id: userData?.id,
      org_id: parseInt(orgId),
    };
    const result = await axios.post(
      `${baseUrl}/orgPortal/${
        orgId
          ? "getImportantUnreadConversationsNumberOfATeacher"
          : "getImportantUnreadConversationsNumberOfAStudent"
      }`,
      data
    );
    if (!result?.data?.error) {
      setImportantUnreadConversationsNumberOfATeacher(
        result?.data?.allImportantConversationsUnreadOfThisUser
      );
    }
  };
  //========================== get Unread Conversations Number ================================//

  //========================= get General Conversations part start ========================//
  const getGeneralConversations = async () => {
    let data = {
      user_id: userData?.id,
      orgId: orgId,
    };
    let studentData = {
      user_id: userData?.id,
    };
    try {
      let result;
      if (orgId) {
        result = await axios.post(
          `${baseUrl}/orgPortal/getTeacherConversations`,
          data
        );
      } else {
        result = await axios.post(
          `${baseUrl}/orgPortal/getStudentConversations`,
          studentData
        );
      }
      setGeneralConversationDatas(result?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (generalConversationDatas?.length > 0) {
      setingGeneralCovnstations(generalConversationDatas);
    }
  }, [generalConversationDatas]);
  const setingGeneralCovnstations = (generalDataTypes) => {
    const getConverstaionsData = generalDataTypes;
    if (getConverstaionsData?.length > 0) {
      let tabToShow = [];
      let tabBodyToShow = [];
      for (let i = 0; i < getConverstaionsData?.length; i++) {
        let item = getConverstaionsData[i]?.conversation;
        tabToShow.push({
          usericon:
            item?.conversation_type === "announcement" ? (
              <CampaignIcon />
            ) : item?.conversation_type === "email" ? (
              <EmailIcon />
            ) : (
              <QuestionAnswerIcon />
            ),
          iconcolor: "#004FE0",
          name: item?.conversation_name,
          subtitle: formattedText(
            getConverstaionsData[i]?.lastMessage?.message_text
          ),
          fontWeight: getConverstaionsData[i]?.is_unread === 1 ? "600" : "400",
          dotIcon:
            getConverstaionsData[i]?.is_unread === 1 ? (
              <FiberManualRecordRoundedIcon />
            ) : (
              " "
            ),
          description: `${shortRelativeTime(
            getConverstaionsData[i]?.lastMessage?.created_at
          )} ${
            item?.course?.courseTitle ? `• ${item?.course?.courseTitle}` : ""
          } • ${
            item?.conversation_type === "email"
              ? convertIntoArray(item?.email_recipient_number) === 1
                ? `${convertIntoArray(item?.email_recipient_number)} recipient`
                : `${convertIntoArray(item?.email_recipient_number)} recipients`
              : getConverstaionsData[i]?.participants?.length > 1
              ? `${getConverstaionsData[i]?.participants?.length} recipients`
              : `${getConverstaionsData[i]?.participants?.length} recipient`
          }`,

          Status: true,
          badgeIcon: getConverstaionsData[i]?.isImportant ? (
            <StarRoundedIcon />
          ) : (
            <img src={AnnouncementIcon} />
          ),
          Badge: getConverstaionsData[i]?.isImportant ? true : false,
          badgecolor: getConverstaionsData[i]?.isImportant ? "#FF9902" : "",
          onClick: handleDrawerOpen,
        });
        tabBodyToShow.push({
          id: i,
          panel: startConverstionsStatus ? (
            <ChatRoom
              item={getConverstaionsData[i]}
              key={i}
              formattedText={formattedText}
              getGeneralConversations={getGeneralConversations}
              getImportantConversations={getImportantConversations}
              tabValue={tabValue}
              importantData={importantData}
              setImportantData={setImportantData}
              setBadgeStatus={setBadgeStatus}
            />
          ) : (
            <EmptyCard
              message="No conversation selected"
              icon={<FeedbackOutlinedIcon />}
              ButtonStatus={false}
              SpaceTop="300px"
            />
          ),
        });
      }
      setGeneralData(tabToShow);
      setTabBodyUsers(tabBodyToShow);
      // setGeneralData(result?.data?.data);
      // setTabBodyUsers(tabBodyToShow);
    }
  };
  //========================= get General Conversations part end ========================//

  //========================= get General Important part start ========================//
  const getImportantConversations = async () => {
    let data = {
      user_id: userData?.id,
      orgId: orgId,
    };
    let studentData = {
      user_id: userData?.id,
    };
    try {
      let result;
      if (orgId) {
        result = await axios.post(
          `${baseUrl}/orgPortal/getImportantConversationsTeacher`,
          data
        );
      } else {
        result = await axios.post(
          `${baseUrl}/orgPortal/getImportantConversationsStudent`,
          studentData
        );
      }
      const getConverstaionsData = result?.data?.data;
      setImportantConversationDatas(getConverstaionsData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (importantConversationDatas?.length > 0) {
      setingImportantCovnstations(importantConversationDatas);
    }
  }, [importantConversationDatas]);
  const setingImportantCovnstations = (importantDataTypes) => {
    const getConverstaionsData = importantDataTypes;
    if (getConverstaionsData?.length > 0) {
      let tabToShow = [];
      let tabBodyToShow = [];
      for (let i = 0; i < getConverstaionsData?.length; i++) {
        let item = getConverstaionsData[i]?.conversation;
        tabToShow.push({
          usericon:
            item?.conversation_type === "announcement" ? (
              <CampaignIcon />
            ) : item?.conversation_type === "email" ? (
              <EmailIcon />
            ) : (
              <QuestionAnswerIcon />
            ),
          iconcolor: "#004FE0",
          name: item?.conversation_name,

          subtitle: formattedText(
            getConverstaionsData[i]?.lastMessage?.message_text
          ),
          dotIcon:
            getConverstaionsData[i]?.is_unread === 1 ? (
              <FiberManualRecordRoundedIcon />
            ) : (
              " "
            ),
          description: `${shortRelativeTime(
            getConverstaionsData[i]?.lastMessage?.created_at
          )} ${
            item?.course?.courseTitle ? `• ${item?.course?.courseTitle}` : ""
          } • ${
            item?.conversation_type === "email"
              ? convertIntoArray(item?.email_recipient_number) === 1
                ? `${convertIntoArray(item?.email_recipient_number)} recipient`
                : `${convertIntoArray(item?.email_recipient_number)} recipients`
              : getConverstaionsData[i]?.participants?.length > 1
              ? `${getConverstaionsData[i]?.participants?.length} recipients`
              : `${getConverstaionsData[i]?.participants?.length} recipient`
          }`,

          Status: true,
          badgeIcon: <StarRoundedIcon />,
          Badge: true,
          badgecolor: "#FF9902",
          onClick: handleDrawerOpen,
        });
        tabBodyToShow.push({
          id: i,
          panel: startConverstionsStatus ? (
            <ChatRoom
              item={getConverstaionsData[i]}
              key={i}
              formattedText={formattedText}
              getGeneralConversations={getGeneralConversations}
              getImportantConversations={getImportantConversations}
              setstartConverstionsStatus={setstartConverstionsStatus}
              tabValue={tabValue}
              importantData={importantData}
              setImportantData={setImportantData}
              setBadgeStatus={setBadgeStatus}
            />
          ) : (
            <EmptyCard
              message="No conversation selected"
              icon={<FeedbackOutlinedIcon />}
              ButtonStatus={false}
              SpaceTop="300px"
            />
          ),
        });
      }
      setImportantData(tabToShow);
      setImportantTabBodyUsers(tabBodyToShow);
    }
  };

  useEffect(() => {
    let empty = [];
    if (!badgeStatus && importantConversationDatas?.length === 0) {
      setImportantData(empty);
      setImportantTabBodyUsers(empty);
    }
  }, [badgeStatus, importantConversationDatas]);
  //========================= get General Important part end ========================//
  const getDraftConversationList = async () => {
    let data = {
      user_id: userData?.id,
      orgId: orgId,
      from: 0,
    };

    try {
      let result;
      if (orgId) {
        result = await axios.post(
          `${baseUrl}/orgPortal/getDraftConversationList`,
          data
        );
      }
      const getConverstaionsData = result?.data?.body;
      if (getConverstaionsData?.length > 0) {
        let tabToShow = [];
        let tabBodyToShow = [];
        for (let i = 0; i < getConverstaionsData?.length; i++) {
          let item = getConverstaionsData[i];

          tabToShow.push({
            usericon:
              item?.conversation_type === "announcement" ? (
                <CampaignIcon />
              ) : item?.conversation_type === "email" ? (
                <EmailIcon />
              ) : (
                <QuestionAnswerIcon />
              ),
            iconcolor: "#BBBFC9",
            name: item?.conversation_name,
            time: shortRelativeTime(item?.created_at),
            onClick: (e) =>
              handleDraftDrawerOpen(item?.conversation_type, item?.id),
          });
          tabBodyToShow.push({
            id: i,
            panel: (
              <EmptyCard
                message="No conversation selected"
                icon={<FeedbackOutlinedIcon />}
                ButtonStatus={false}
                SpaceTop="300px"
              />
            ),
          });
        }
        setDraftConverstionLists(tabToShow);
        setDraftConverstionListsBodyUsers(tabBodyToShow);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getGeneralConversations();
    getImportantConversations();
    getUnreadConversationsNumberOfATeacher();
    getImportantUnreadConversationsNumberOfATeacher();
    getDraftConversationList();
  }, [startConverstionsStatus]);

  const tabLabel = [
    {
      label: "General",
      Notification: true,
      NotificationLabel: generalUnreadConversationsNumberOfATeacher,
    },
    {
      label: "Important",
      Notification: true,
      NotificationLabel: importantUnreadConversationsNumberOfATeacher,
    },
    orgId && {
      label: "Draft",
    },
  ];

  const tabBody = [
    {
      id: 0,
      panel: (
        <div>
          <InboxListing
            TitleStatus={true}
            title={generalData?.length === 0 ? "Welcome to your inbox!" : ""}
            subtitle={generalData?.length === 0 ? "No message found." : ""}
            tabData={generalData}
            TabData={true}
            handleChange={handleChange}
            value={value}
            Welcome={false}
            ButtonLabel="New"
            AddNewButton={false}
            ScrollBarHeight="680px"
          />
        </div>
      ),
    },
    {
      id: 1,
      panel: (
        <div>
          <InboxListing
            TitleStatus={true}
            title={generalData?.length === 0 ? "Welcome to your inbox!" : ""}
            subtitle={generalData?.length === 0 ? "No message found." : ""}
            tabData={importantData}
            TabData={true}
            handleChange={handleChange}
            value={value}
            Welcome={false}
            ButtonLabel="New"
            AddNewButton={false}
            ScrollBarHeight="680px"
          />
        </div>
      ),
    },
    {
      id: 2,
      panel: (
        <div>
          <InboxListing
            TitleStatus={true}
            title={generalData?.length === 0 ? "Welcome to your inbox!" : ""}
            subtitle={generalData?.length === 0 ? "No message found." : ""}
            tabData={draftConverstionLists}
            TabData={true}
            handleChange={handleChange}
            value={value}
            Welcome={false}
            ButtonLabel="New"
            AddNewButton={false}
            ScrollBarHeight="680px"
          />
        </div>
      ),
    },
  ];

  // const menuLabel = [
  //   {
  //     label: "Archived Conversations",
  //   },
  //   {
  //     label: "Unread Conversations",
  //   },
  // ];

  return (
    <div className="InboxContainer">
      <div className="InboxInnerContainer">
        {/* === LEFT SECTION START == */}
        <div className="InboxLeftCol">
          {/* === HEADER START == */}
          <div className="InboxHeader">
            <h1>Inbox</h1>
            {orgId && <AddNew ButtonBlue={true} />}
          </div>
          {/* === HEADER END == */}

          {/* === TAB START == */}
          <div className="InboxTabSection">
            <InboxTabs
              tabLabel={tabLabel}
              tabBody={tabBody}
              TabMenu={false}
              // menuLabel={menuLabel}
              handleChange={handleChangeTab}
              value={tabValue}
              // TabMenuLabel={
              //   <p>
              //     {tabValue === 0 && "General"}
              //     {tabValue === 1 && "Important"}
              //     {tabValue === 2 && "Draft"}
              //   </p>
              // }
            />
          </div>
          {/* === TAB END == */}
        </div>

        {/* === LEFT SECTION DESKTOP VIEW START == */}
        <div className="InboxDekstopView">
          <div className="InboxRightCol">
            {tabValue === 0 && (
              <>
                <InboxListing
                  tabBody={tabBodyUsers}
                  TabBody={true}
                  handleChange={handleChange}
                  value={value}
                />
              </>
            )}
            {tabValue === 1 && (
              <>
                <InboxListing
                  tabBody={importantTabBodyUsers}
                  TabBody={true}
                  handleChange={handleChange}
                  value={value}
                />
              </>
            )}

            {tabValue === 2 && (
              <>
                <InboxListing
                  tabBody={draftConverstionListsBodyUsers}
                  TabBody={true}
                  handleChange={handleChange}
                  value={value}
                />
              </>
            )}
          </div>
        </div>
        {/* === LEFT SECTION DESKTOP VIEW END == */}

        {/* === LEFT SECTION MOBILE VIEW START == */}
        <div className="InboxMobileView">
          <SideDrawer
            open={open}
            handleClose={handleClose}
            sideOpen="right"
            body={
              <>
                <div className="InboxRightCol">
                  {tabValue === 0 && (
                    <>
                      <InboxListing
                        tabBody={tabBodyUsers}
                        TabBody={true}
                        handleChange={handleChange}
                        value={value}
                      />
                    </>
                  )}
                  {tabValue === 1 && (
                    <>
                      <InboxListing
                        tabBody={importantTabBodyUsers}
                        TabBody={true}
                        handleChange={handleChange}
                        value={value}
                      />
                    </>
                  )}

                  {tabValue === 2 && (
                    <>
                      <InboxListing
                        tabBody={draftConverstionListsBodyUsers}
                        TabBody={true}
                        handleChange={handleChange}
                        value={value}
                      />
                    </>
                  )}
                </div>
                {/* === LEFT SECTION MOBILE VIEW END == */}
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Inbox;
