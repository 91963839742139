import React, { useEffect, useState } from "react";
import AlrtMessage from "../../../../../components/Form/AlrtMessage/AlertMessage";
import { TbFileDownload } from "react-icons/tb";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Table from "../../../../../components/Table/Table";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import CustomizedSnackbar from "../../../../../hooks/Snackbar";
import DraggableDialog from "../../../../../hooks/Dialog";
import tableImg from "../../../../../assets/imgs/tableImg.png";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

import EmptyCard from "../../../../../components/EmptyCard/EmptyCard";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import MediaLibrayForCourseCertificate from "../../../../../components/MediaLibrary/MediaLibrayForCourseCertificate";
import moment from "moment";
import axios from "axios";

// ? ==== Character limit ==== * //
const truncateText = (text, limit) => {
  return text.length > 20 ? text.substring(0, 20) + "..." : text;
};
function Certificates(props) {
  const { studentList, trackId, getStudentList } = props;

  const [rows, setRows] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [mediaItem, setMediaItem] = useState({});
  const [checked, setChecked] = React.useState(false);
  const [assignDialog, setAssignDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // * ======= Media lib Drawer start ===== * //
  const [openMedia, setOpenMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const handleDrawerOpen = () => {
    setOpenMedia(true);
  };
  const handleDrawerClose = () => {
    setOpenMedia(false);
  };
  // * ======= Media lib Drawer end ===== * //

  // * ====== Activity drawer start ===== * //
  const [activityDrawer, setActivityDrawer] = useState(false);
  // * ======  Activity drawer end ===== * //

  // * ====== email drawer start ===== * //
  const [emailDialog, setEmailDialog] = useState(false);
  // * ======  email drawer end ===== * //

  // * ======= Delete dialog ===== * //
  const [deleteDialog, setDeleteDialog] = useState(false);
  // * ======= Delete dialog ===== * //

  // const handleOpenDialog = (id) => {
  //   console.log(id);
  //   // setActiveCourseId(id);
  //   // setDialogTitle("Delete this track?");
  //   // setDialogBody("Are you sure, you want to delete a course record and it's related data?");
  //   setOpenDialog(true);
  // }

  // const onClick = () => {
  //   alert("onclick working fine!");
  // }

  const headCells = [
    {
      id: "Seq",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "40%",
    },
    {
      id: "Type",
      numeric: false,
      disablePadding: false,
      label: "Completion Certificate",
      width: "30%",
    },
    {
      id: "Track",
      numeric: true,
      disablePadding: false,
      label: "Last Activity",
      width: "18%",
    },
    {
      id: "",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "12%",
    },
  ];

  const processShowToastMessage = (response) => {
    // console.log(response.data);
    if (response.data.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);
      getStudentList();
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.message);
      getStudentList();
    }
  };

  const handleSendEmail = () => {
    let sendEmailData = {
      users: [updateId],
      trackId: parseInt(trackId),
      domain: window.location.origin,
    };
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .post(`${baseUrl}/orgPortal/sendTrackCertificates`, { ...sendEmailData })
      .then((response) => {
        processShowToastMessage(response);
        setEmailDialog(false);
      });
  };

  const assignAndSendEmail = async () => {
    clickSelectMediaItem();
    handleSendEmail();
    setChecked(false);
    setAssignDialog(false);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      let data = {
        userId: updateId,
        trackId: trackId,
        fileLink: mediaItem.file_url,
        fileName: mediaItem.name,
      };
      uploadedCertificate(data);
      setChecked(false);
      setAssignDialog(false);
      // console.log( trackData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
    setChecked(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const handleDrawerOpenForUploaded = (id) => {
    setUpdateId(id);
    setOpenSelectMedia(true);
  };

  const handleOpenDailogBoxForDelete = (id) => {
    setUpdateId(id);
    setDeleteDialog(true);
  };

  const handleOpenDailogBoxForSendEmail = (id) => {
    setUpdateId(id);
    setEmailDialog(true);
  };

  const handleDownload = (data) => {
    if (data) {
      const link = document.createElement("a");
      link.href = data;
      link.download = "certificate.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Certificate file link not available");
      // alert();
    }
  };

  useEffect(() => {
    if (studentList) {
      setRows(convertCellRows(studentList));
    }
  }, [studentList]);

  const convertCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => ({
        cell: item?.fileLink
          ? [
              {
                img: item?.image,
                imgStatus: true,
                imgText: `${item?.first_name} ${item?.last_name}`,
                align: "left",
              },
              {
                text: (
                  <Button
                    variant="text"
                    onClick={(e) => handleDownload(item?.fileLink)}
                    sx={{ textTransform: "initial" }}
                    startIcon={<TbFileDownload />}
                    style={{color : '#969696'}}
                  >
                    {truncateText(
                      item?.fileName ? item?.fileName : item?.fileLink
                    )}
                  </Button>
                ),
              },

              {
                text: item?.certificate_created_at
                  ? moment(item?.certificate_created_at).format("DD-MMM-YYYY")
                  : "N/A",
              },
              {
                ThreeDotButton: true,
                buttonLabel: <MoreVertIcon />,
                buttonTextcolor: "#004FE0",
                // buttonLink: "#",
                // buttonTarget: "_blank",
              },
            ]
          : [
              {
                img: item?.image,
                imgStatus: true,
                imgText: `${item?.first_name} ${item?.last_name}`,
                align: "left",
              },
              {
                Button: true,
                buttonAlign: "left",
                padding: "0px",
                buttonLabel: "Upload",
                buttonColor: "#FFFFFF",
                // buttonBorder: "#E4E8EC 1px solid",
                buttonTextcolor: "#004FE0",
                buttonIcon: "PlusIcon",
                justifyContent: "flex-start",
                onClick: (e) => handleDrawerOpenForUploaded(item?.id),
              },
              {},
              {},
            ],

        //======= Action Date Start===== //
        action: [
          {
            label: "Send Email",
            onClick: (e) => handleOpenDailogBoxForSendEmail(item?.id),
          },
          // { label: "Edit", onClick: onClick },
          {
            label: <span style={{ color: "#BA1A1A" }}>Delete</span>,
            onClick: (e) => handleOpenDailogBoxForDelete(item?.id),
          },
        ],
        //======= Action Date End===== //
      }));
    } else {
      return [];
    }
  };

  const uploadedCertificate = (data) => {
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
    axios
      .post(`${baseUrl}/orgPortal/uploadTrackCertificate`, data)
      .then((res) => {
        processShowToastMessage(res);
        getStudentList();
      });
  };

  const handleOpenNewMedia = async () => {
    // setDeleteDialog(false);
  };
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  const handleRemoveCertiFicate = () => {
    let deleteData = {
      userId: updateId,
      trackId: trackId,
    };
    const baseUrl =
      process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    axios
      .delete(`${baseUrl}/orgPortal/deleteTrackCertificate`, {
        data: deleteData,
      })
      .then((response) => processShowToastMessage(response));
    setDeleteDialog(false);
  };
  return (
    <>
      {/* <EmptyCard
        title="No certificate to upload yet"
        description="Certificates will be visible here"
      /> */}

      {/* <CustomizedSnackbar message="Track removed" severity="success" /> */}

      {/* <AlrtMessage message="Course added" background="#0B9444" /> */}

      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          AddButtonInner={false}
          addLabelInner="Export"
          InnerButtonColor="#E0EAFB"
          InnerButtonTextColor="#004FE0"
          InnderButtonIcon={<FileUploadOutlinedIcon />}
          //onClickInnerButton={handleDrawerOpen}
          // handleOpenDialog={handleOpenDialog}
          showSearchBox={true}
        />
      </div>

      {/* ========== Media Lib =========== */}
      <div>
        <SideDrawer
          title="Media Library"
          ButtonLabel="Assign"
          arrowIcon={true}
          //clickSaveButton={clickSaveButton}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={openSelectMedia}
          handleDrawerClose={() => setOpenSelectMedia(false)}
          FooterWithoutTab={true}
          LeftButton={true}
          ButtonLeft="Upload New"
          ButtonLeftIcon={<FileUploadOutlinedIcon />}
          clickButtonLeft={() => setOpenNewMedia(true)}
          clickSaveButton={() => setAssignDialog(true)}
          body={
            <MediaLibrayForCourseCertificate
              onClickRadioIcon={setSelectedValue}
              onItemSelected={getMediaItem}
            />
          }
        />

        <Externaluse
          open={openNewMedia}
          handleCloseButton={() => setOpenNewMedia(false)}
          handleOpenNewMedia={handleOpenNewMedia}
        />
      </div>
      <DraggableDialog
        openDialog={assignDialog}
        title={"Assign certificate to the learner?"}
        body={
          <div>
            The certificate will be available for the learner to view and
            download from their portal.
            <br />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckBox} />}
              label="Send email to the learner"
              labelPlacement="end"
            />
          </div>
        }
        actionButton={checked ? "Assign & Send Email" : "Assign"}
        handleCloseDialog={() => {
          setChecked(false);
          setAssignDialog(false);
        }}
        handleConfirmCloseDialog={
          !checked ? clickSelectMediaItem : assignAndSendEmail
        }
        ModalFooter={true}
      />

      {/* ======== Activity Drawer ============= */}
      <SideDrawer
        title="Activities"
        buttonLabel=""
        // buttonLink={}
        sideOpen="right"
        open={activityDrawer}
        handleDrawerClose={() => setActivityDrawer(false)}
        FooterWithoutTab={true}
        ButtonLabel=""
        cancelButtonLabel="Cancel"
        Checkbox={false}
        CheckboxLabel="Add another after saving"
        // clickSaveButton={(e) => inviteNewUser()}
        body={
          <>
            <div className="certificateActivityList">
              <ul>
                <li>Email sent by [Logged in user full name]</li>
                <li>New certificate assigned by [Logged in user full name]</li>
                <li>Certificate deleted by [Logged in user full name]</li>
                <li>Email sent by [Logged in user full name]</li>
                <li>New certificate assigned by [Logged in user full name]</li>
              </ul>
            </div>
          </>
        }
      />
      {/* ======== Email Modal ============= */}
      <DraggableDialog
        openDialog={emailDialog}
        title={"Send email to the learner?"}
        body={
          "Email the learner to inform them that their certificate is now available for download."
        }
        actionButton="Send Email"
        handleCloseDialog={() => setEmailDialog(false)}
        handleConfirmCloseDialog={handleSendEmail}
        ModalFooter={true}
      />

      {/* ======== Delete Modal ============= */}
      <DraggableDialog
        openDialog={deleteDialog}
        title={"Delete certificate"}
        body={"Are you sure, you want to delete this student certificate?"}
        actionButton="Delete"
        handleCloseDialog={() => setDeleteDialog(false)}
        handleConfirmCloseDialog={handleRemoveCertiFicate}
        ModalFooter={true}
      />
      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default Certificates;
