import React, { useEffect, useState } from "react";

import {
  isValidURL,
  timeIn15MinsInterval,
} from "../../../../../hooks/HelperFunctions";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import Grid from "@mui/material/Grid";
import SwitchButton from "../../../../../components/Form/Switch/Switch";
import SelectField from "../../../../../components/Form/SelectField/SelectField";
import ComboBox from "../../../../../components/Form/ComboBox/ComboBox";
import Datefield from "../../../../../components/Form/Datefield/Datefield";
import DateTimePickerField from "../../../../../components/Form/DateTimePicker/DateTimePicker";
import TextArea from "../../../../../components/Form/TextArea/TextArea";
import TextField from "../../../../../components/Form/TextField/TextField";
import Modals from "../../../../../components/Form/Modals/Modals";
import Button from "@mui/material/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import RedioBox from "../../../../../components/Form/RedioBox/RedioBox";
import TimePickerField from "../../../../../components/Form/TimePicker/TimePicker";
import FileUplaoder from "../../../../../components/Form/FileUplaoder/FileUplaoder";
import Modules from "../sub-components/Modules";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

//== Uload Media == //
//import sidedrawer
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../../components/Form/Button/Button";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import Avatar from "@mui/material/Avatar";
//import deletehoverrounded from mui component
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CheckBox from "../../../../../components/Form/CheckBox/CheckBox";
import Tooltip from "../../../../../components/Form/Tooltip/Tooltip";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { convertFileSize } from "../../../../../components/MediaLibrary/Functions";
import moment from "moment-timezone";

function Curriculum(props) {
  const {
    courseData,
    setCourseData,
    showSnackbarMessage,
    courseErrors,
    setCourseErrors,
    setShowSnackbar,
  } = props;
  // const { courseModules } = props.courseData;
  const { gradeLevels, ageGroups } = props.courseRelationalData;

  const [addAnother, setAddAnother] = useState(false);
  const [additionalInstructors, setAdditionalInstructors] = useState([]);

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [resourceUrlError, setResourceUrlError] = useState("");

  //=========================== seession ===================================================//
  const [prerercordedUploadeVideo, setPrerercordedUploadeVideo] =
    useState(false);
  const [prerercordedUploadeThumbnail, setPrerercordedUploadeThumbnail] =
    useState(false);
  //=========================== seession ===================================================//
  // splitting the string into its components (year, month, day, hour, minute, and AM/PM indicator)
  // function parseDateString(dateString) {
  //   const [datePart, timePart] = dateString.split(' ');
  //   const [year, month, day] = datePart.split('-');
  //   const [time, period] = timePart.split(' ');
  //   const [hour, minute] = time.split(':');
  //   let adjustedHour = parseInt(hour);

  //   if (period === 'PM') {
  //     adjustedHour += 12;
  //   }

  //   return new Date(Date.UTC(year, month - 1, day, adjustedHour, parseInt(minute)));
  // }

  function parseDateString(dateString) {
    const dateParts = dateString.split(/[- :]/);
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-based (0-11)
    const day = parseInt(dateParts[2]);
    const hours = parseInt(dateParts[3]);
    const minutes = parseInt(dateParts[4]);

    const period = dateParts[5]?.toUpperCase();
    let adjustedHours = hours;

    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0;
    }

    return new Date(year, month, day, adjustedHours, minutes);
  }

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    const getPrerecordedUploadType = localStorage.getItem(
      "prerecordedUploadType"
    );
    if (getPrerecordedUploadType === "Thumbnail") {
      setPrerercordedUploadeThumbnail(true);
    } else if (getPrerecordedUploadType === "Video") {
      setPrerercordedUploadeVideo(true);
    }
    setOpenNewMedia(false);
  };

  //deleteCourseImageSelection
  const deleteCourseImageSelection = () => {
    setTempResourse({ ...tempResourse, resourseFile: "" });
  };

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
    setPrerercordedUploadeVideo(false);
    setPrerercordedUploadeThumbnail(false);
  };
  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      //set the media item
      // setImageSelection(mediaItem.file_url);
      // handleChangeWithData("resourseFile", mediaItem.name);
      if (prerercordedUploadeVideo) {
        if (mediaItem?.file_type?.startsWith("video")) {
          //=================== formatted video duration ==============//
          const minutes = Math.floor(mediaItem?.video_duration / 60);
          const seconds = (mediaItem?.video_duration % 60).toFixed(2);

          // Format using Moment.js
          const formattedDuration = moment
            .utc()
            .minutes(minutes)
            .seconds(seconds)
            .format("mm:ss");
          //=================== formatted video duration ==============//
          setTempSession({
            ...tempSession,
            prerercordedVideo: mediaItem?.file_url,
            prerercordedVideoName: mediaItem?.name,
            prerercordedVideoSize: convertFileSize(mediaItem?.file_size),
            prerercordedVideoDuration: formattedDuration,
          });
          setOpenSelectMedia(false);
          setPrerercordedUploadeVideo(false);
          localStorage.removeItem("prerecordedUploadType");
        } else {
          showSnackbarMessage("error", "Please select only video file");
          setOpenSelectMedia(true);
        }
      } else if (prerercordedUploadeThumbnail) {
        if (mediaItem?.file_type?.startsWith("image/")) {
          setTempSession({
            ...tempSession,
            prerercordedThumbnail: mediaItem.file_url,
            prerercordedThumbnailName: mediaItem?.name,
            prerercordedThumbnailSize: Number(mediaItem?.file_size / 1024),
          });
          setOpenSelectMedia(false);
          setPrerercordedUploadeThumbnail(false);
          localStorage.removeItem("prerecordedUploadType");
        } else {
          showSnackbarMessage("error", "Please select only image file");
          setOpenSelectMedia(true);
        }
      } else {
        handleChangeWithData("resourseFile", mediaItem.file_url);
        setOpenSelectMedia(false);
      }

      // console.log( courseData)
    } else {
      alert("Please select any media");
    }
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
    console.log(mediaItem);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };

  const setImageSelection = (fileUrl) => {
    // console.log(fileUrl);
    let temp = courseData;
    temp.courseImage = fileUrl;
    setCourseData(temp);
    // console.log(courseData);
  };

  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = "";

    if (fieldName == "sessionLinkSetting") {
      fieldData = event.target.checked;
    } else {
      fieldData = event.target.value;
    }
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    // setCourseData({ ...courseData, [fieldName]: fieldData });
    // updateResoursesData("resourseFile", fieldData);
    setTempResourse({ ...tempResourse, [fieldName]: fieldData });
  };

  /** add new module related code */
  const [tempModuleTitle, setTempModuleTitle] = useState("");
  const [newModule, setNewModule] = useState(false);

  const handleOpenModuleAdd = () => {
    console.log("handleOpenModuleAdd");
    setNewModule(true);
  };

  const handleCloseModuleAdd = () => {
    console.log("handleCloseModuleEdit");
    setNewModule(false);
  };

  const [moduleError, setModuleError] = useState({
    tilte: "",
  });
  const [checkErrorStatus, setCheckErrorStatus] = useState(false);
  const checkModuleErrors = () => {
    let errorStatus = 0;
    let errorObject = { ...moduleError };
    if (tempModuleTitle == "") {
      errorStatus++;
      showSnackbarMessage("error", "Module title field is required!");
      errorObject.tilte = "Required";
    } else {
      errorObject.tilte = "";
    }

    setModuleError({ ...moduleError, ...errorObject });
    return errorStatus > 0 ? false : true;
  };

  useEffect(() => {
    if (checkErrorStatus) {
      checkModuleErrors();
    }
  }, [checkErrorStatus, tempModuleTitle]);

  const handleConfirmModuleAdd = () => {
    setCheckErrorStatus(true);
    const isvalid = checkModuleErrors();
    if (!isvalid) {
      return false;
    }

    courseData.courseModules.push({
      id: "0",
      title: tempModuleTitle,
      courseSessions: [],
      courseResourses: [],
    });
    setNewModule(false);
    setTempModuleTitle("");
    showSnackbarMessage("success", "New module added");
    setModuleError({ tilte: "" });
    setCheckErrorStatus(false);
  };

  const addModuleTitle = () => (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    setTempModuleTitle(fieldData);
  };

  /** End: add new module related code */

  /** Module Title update related code */
  const [currentModule, setCurrentModule] = useState({});
  const [editModule, setEditModule] = useState(false);

  const handleCloseModuleEdit = () => {
    setEditModule(false);
    setCheckErrorStatus(false);
    setModuleError({ tilte: "" });
    setShowSnackbar(false);
  };

  const handleConfirmModuleEdit = (index) => {
    setCheckErrorStatus(true);
    if (currentModule.title == "") {
      showSnackbarMessage("error", "Module title field is required!");
    } else {
      // update main course data array
      const moduleList = [...courseData.courseModules];
      console.log(moduleList);
      const currentModuleItem = moduleList.findIndex(
        (element, objIndex, array) => {
          return objIndex == index;
        }
      );

      moduleList[currentModuleItem].title = currentModule.title;

      setEditModule(false);
      showSnackbarMessage("success", "Module Title Updated");
      setCheckErrorStatus(false);
    }
  };

  const updateModuleTitle = (index) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldData);
    // update current module info
    const list = { ...currentModule };
    list.title = fieldData;
    setCurrentModule(list);
  };

  /** End: Module Title update related code */

  /** Module delete related code */

  const deleteModule = (index) => {
    // const moduleList = [...courseData.courseModules];
    // moduleList.splice(index, 1);
    // setInputList(list);
    courseData.courseModules.splice(index, 1);
    showSnackbarMessage("success", "Module and it's related data deleted.");
  };

  /** End: module delete related code */

  /** Sessions related code */
  const [tempSession, setTempSession] = useState({});
  const [tempModuleIndex, setTempModuleIndex] = useState(-1);
  const [tempSesIndex, setTempSesIndex] = useState(0);
  const [sessionModalTitle, setSessionModalTitle] = useState("");
  const [sessionModalConButton, setSessionModalConButton] = useState("");
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const fromattedTime = (timeValue) => {
    const timeData = timeIn15MinsInterval();
    const sessionTime = moment(timeValue, "HH:mm:ss");
    // Format the sessionTime to match the values in timeData
    const formattedTime = sessionTime.format("hh:mm A");

    // Find the corresponding object in timeData
    const matchedTimeObject = timeData.find(
      (item) => item.value === formattedTime
    );
    return matchedTimeObject?.value;
  };

  const handleOpenSessionModal = (moduleIndex, sesIndex = "") => {
    console.log("handleOpenSessionModal");
    console.log(sesIndex);
    if (sesIndex >= 0) {
      setTempSesIndex(sesIndex);
      setSessionModalTitle("Edit session");
      setSessionModalConButton("Save");
      // console.log(courseData.courseModules[moduleIndex].courseSessions[sesIndex]);
      setTempSession(
        courseData.courseModules[moduleIndex].courseSessions[sesIndex]
      );
      addSessionSetValues(
        courseData.courseModules[moduleIndex].courseSessions[sesIndex]
          ?.isPrerecorded
      );
    } else {
      addSessionSetValues(0);
      setTempSesIndex(-1);
      setSessionModalTitle("Add session");
      setSessionModalConButton("Add Now");
      setTempSession({
        sesId: 0,
        modId: courseData.courseModules[moduleIndex].modId,
        title: "",
        startDate: null,
        startTime: null,
        endTime: null,
        description: "",
        selectedEndTime: "",
        selectedStartTime: "",
        zoomLink: "",
        details: "",
        enableSession: "",
        isPrerecorded: 0,
        prerercordedVideo: "",
        prerercordedVideoName: "",
        prerercordedVideoSize: "",
        prerercordedVideoDuration: "",
        prerercordedThumbnail: "",
        prerercordedThumbnailName: "",
        prerercordedThumbnailSize: "",
        allowUserToPreview: 0,
        sessionAvailable: "Always",
        sessionAvailableDate: null,
        sessionAvailableTime: null,
        sessionUnavailable: "Never",
        sessionUnavailableDate: null,
        sessionUnavailableTime: null,
      });
    }

    setTempModuleIndex(moduleIndex);
    setOpenSessionModal(true);
  };

  //============================ testing data ============================================//
  const [tabvalue, addSessionSetValues] = React.useState(
    tempSession.isPrerecorded
  );

  const onChangeAddSession = (event, newValue) => {
    setTempSession((prevSession) => ({
      ...prevSession,
      isPrerecorded: newValue === 1 ? 1 : 0,
    }));
    addSessionSetValues(newValue);
  };

  const openSessionMediaUpload = () => {
    localStorage.setItem("prerecordedUploadType", "Video");
    setPrerercordedUploadeVideo(true);
    handleDrawerOpenSelectMedia();
  };
  const openSessionMediaThumbnail = () => {
    localStorage.setItem("prerecordedUploadType", "Thumbnail");
    setPrerercordedUploadeThumbnail(true);
    handleDrawerOpenSelectMedia();
  };

  // add another after save new one
  const addAnotherAfterSavingHandler = (event) => {
    console.log(event.target.checked);
    setAddAnother(event.target.checked);
  };

  const handleCloseSessionModal = () => {
    setOpenSessionModal(false);
    setCheckSessionErrorStatus(false);
    setSessionErrors(sessionErrorsInitialObject);
  };

  const isSameDate = (date1, date2, sessionModalTitle) => {
    const date2PreviousDay = new Date(date2);
    date2PreviousDay.setDate(date2.getDate() - 1);
    let date2Value =
      sessionModalTitle === "Edit session" ? date2PreviousDay : date2;
    return (
      date1.getFullYear() === date2Value.getFullYear() &&
      date1.getMonth() === date2Value.getMonth() &&
      date1.getDate() === date2Value.getDate()
    );
  };

  const sessionErrorsInitialObject = {
    title: "",
    startDate: "",
    selectedStartTime: "",
    selectedEndTime: "",
    zoomLink: "",
    sessionAvailableDate: "",
    sessionUnavailableDate: "",
    sessionAvailableTime: "",
    sessionUnavailableTime: "",
  };
  const [sessionErrors, setSessionErrors] = useState(
    sessionErrorsInitialObject
  );

  const [checkSessionErrorStatus, setCheckSessionErrorStatus] = useState(false);

  const checkSessionsErros = (tempSessionData) => {
    let error = 0;
    const courseDataSessionEndDate = new Date(courseData?.sessionEndDate);
    const startDateTime = new Date(tempSessionData.startDate);
    const sessionAvailableDateData = new Date(
      tempSessionData.sessionAvailableDate
    );
    const sessionUnavailableDateData = new Date(
      tempSessionData.sessionUnavailableDate
    );
    let errorObject = { ...sessionErrors };
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let date1 = new Date("2000-01-01 " + tempSessionData?.selectedStartTime);
    let date2 = new Date("2000-01-01 " + tempSessionData?.selectedEndTime);

    if (!tempSessionData.title) {
      error++;
      errorObject.title = "Required";
    } else {
      errorObject.title = "";
    }

    if (!tempSessionData.startDate && tabvalue === 0) {
      error++;
      errorObject.startDate = "Required";
    } else if (
      startDateTime < today &&
      tabvalue === 0 &&
      sessionModalTitle !== "Edit session"
    ) {
      error++;
      errorObject.startDate = "Backdate can't be accepted";
    } else {
      errorObject.startDate = "";
    }

    if (!tempSessionData.selectedStartTime && tabvalue === 0) {
      error++;
      errorObject.selectedStartTime = "Required";
    } else if (date1 > date2 && tabvalue === 0) {
      error++;
      errorObject.selectedStartTime =
        "The start time should be lower than the end time";
    } else {
      errorObject.selectedStartTime = "";
    }

    if (!tempSessionData.selectedEndTime && tabvalue === 0) {
      error++;
      errorObject.selectedEndTime = "Required";
    } else {
      errorObject.selectedEndTime = "";
    }

    if (
      courseData.sessionLinkSetting == false &&
      !tempSessionData.zoomLink &&
      tabvalue === 0
    ) {
      error++;
      errorObject.zoomLink = "Required";
    } else if (
      courseData.sessionLinkSetting == false &&
      tempSessionData.zoomLink &&
      !isValidURL(tempSessionData.zoomLink) &&
      tabvalue === 0
    ) {
      error++;
      errorObject.zoomLink = "Enter a valid zoom link";
    } else {
      errorObject.zoomLink = "";
    }

    if (
      tempSessionData.sessionAvailable === "On-date" &&
      !tempSessionData.sessionAvailableDate &&
      tabvalue === 1
    ) {
      error++;
      errorObject.sessionAvailableDate = "Required";
    } else if (
      sessionAvailableDateData < today &&
      tempSessionData.sessionAvailable === "On-date" &&
      tabvalue === 1 &&
      sessionModalTitle !== "Edit session"
    ) {
      error++;
      errorObject.sessionAvailableDate = "Backdate can't be accepted";
    } else if (
      tempSessionData.sessionAvailable === "On-date" &&
      courseDataSessionEndDate &&
      courseDataSessionEndDate < sessionAvailableDateData &&
      !isSameDate(
        courseDataSessionEndDate,
        sessionAvailableDateData,
        sessionModalTitle
      ) &&
      tabvalue === 1
    ) {
      error++;
      errorObject.sessionAvailableDate =
        "Selected date must be between course session start and end dates";
    } else if (
      sessionUnavailableDateData < sessionAvailableDateData &&
      tempSessionData.sessionUnavailable === "On-date" &&
      tempSessionData.sessionAvailable === "On-date" &&
      tabvalue === 1
    ) {
      error++;
      errorObject.sessionAvailableDate =
        "Session becomes unavailable select date can't be before session becomes  available select date";
    } else {
      errorObject.sessionAvailableDate = "";
    }

    if (
      tempSessionData.sessionAvailable === "On-date" &&
      !tempSessionData.sessionAvailableTime &&
      tabvalue === 1
    ) {
      error++;
      errorObject.sessionAvailableTime = "Required";
    } else {
      errorObject.sessionAvailableTime = "";
    }

    if (
      tempSessionData.sessionUnavailable === "On-date" &&
      !tempSessionData.sessionUnavailableDate &&
      tabvalue === 1
    ) {
      error++;
      errorObject.sessionUnavailableDate = "Required";
    } else if (
      sessionUnavailableDateData < today &&
      tempSessionData.sessionUnavailable === "On-date" &&
      sessionModalTitle !== "Edit session" &&
      tabvalue === 1
    ) {
      error++;
      errorObject.sessionUnavailableDate = "Backdate can't be accepted";
    } else {
      errorObject.sessionUnavailableDate = "";
    }

    if (
      tempSessionData.sessionUnavailable === "On-date" &&
      !tempSessionData.sessionUnavailableTime &&
      tabvalue === 1
    ) {
      errorObject.sessionUnavailableTime = "Required";
    } else {
      errorObject.sessionUnavailableTime = "";
    }
    setSessionErrors({ ...sessionErrors, ...errorObject });
    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (checkSessionErrorStatus) {
      checkSessionsErros(tempSession);
    }
  }, [checkSessionErrorStatus, tempSession]);
  const handleConfirmCloseSessionModal = () => {
    setCheckSessionErrorStatus(true);
    let isNew = false;
    const isValid = checkSessionsErros(tempSession);
    if (!isValid) {
      showSnackbarMessage("error", "Required felids are left");
      return false;
    } else if (tempSession.prerercordedVideoName === "" && tabvalue === 1) {
      showSnackbarMessage("error", "Please upload a video form media libaray");
      return false;
    } else {
      const list = { ...courseData };

      // copy startTime into startDate
      // tempSessionData.startDate = tempSession.startTime;
      let startDateTmp =
        typeof tempSession?.startDate == "string"
          ? tempSession?.startDate
          : tempSession?.startDate?.toISOString();
      console.log(startDateTmp);
      const truncatedDate = startDateTmp?.substring(0, 10);
      console.log(truncatedDate + " " + tempSession.selectedStartTime);
      let startTime = parseDateString(
        truncatedDate + " " + tempSession?.selectedStartTime
      );
      let endTime = parseDateString(
        truncatedDate + " " + tempSession?.selectedEndTime
      );

      startTime = zonedTimeToUtc(startTime, courseData?.timezone);
      endTime = zonedTimeToUtc(endTime, courseData?.timezone);

      tempSession.startTime = startTime;
      tempSession.endTime = endTime;

      let sessionAvailableDateFormatted =
        typeof tempSession.sessionAvailableDate == "string"
          ? tempSession.sessionAvailableDate
          : tempSession.sessionAvailableDate?.toISOString();

      tempSession.sessionAvailableDate = sessionAvailableDateFormatted;
      if (tempSesIndex >= 0) {
        list.courseModules[tempModuleIndex].courseSessions[tempSesIndex] =
          tempSession;
        showSnackbarMessage("success", "Session updated.");
      } else {
        //===================== updateing data based on isPrerecorded===================//
        if (tempSession.isPrerecorded === 1) {
          tempSession.startDate = null;
          tempSession.startDate = null;
          tempSession.startTime = null;
          tempSession.endTime = null;
          tempSession.selectedEndTime = "";
          tempSession.selectedStartTime = "";
          tempSession.zoomLink = "";
          tempSession.details = "";
          tempSession.enableSession = "";
        } else {
          tempSession.prerercordedVideo = "";
          tempSession.prerercordedVideoName = "";
          tempSession.prerercordedVideoSize = "";
          tempSession.prerercordedVideoDuration = "";
          tempSession.prerercordedThumbnail = "";
          tempSession.prerercordedThumbnailName = "";
          tempSession.prerercordedThumbnailSize = "";
          tempSession.sessionAvailable = "Always";
          tempSession.sessionAvailableDate = null;
          tempSession.sessionAvailableTime = null;
          tempSession.sessionUnavailable = "Never";
          tempSession.sessionUnavailableDate = null;
          tempSession.sessionUnavailableTime = null;
        }
        //===================== updateing data based on isPrerecorded===================//
        isNew = true;
        list.courseModules[tempModuleIndex].courseSessions.push(tempSession);
        showSnackbarMessage("success", "New session added.");
      }
      setCheckSessionErrorStatus(false);
      setSessionErrors(sessionErrorsInitialObject);
      setCourseData(list);
      setOpenSessionModal(false);

      setTempSession({});

      if (isNew && addAnother) {
        handleOpenSessionModal(tempModuleIndex, -1);
      }
    }
  };

  const handleDeleteSession = (moduleIndex, sesIndex) => {
    console.log("handleDeleteSession");
    const list = { ...courseData };
    list.courseModules[moduleIndex].courseSessions.splice(sesIndex, 1);
    setCourseData(list);
    showSnackbarMessage("success", "Session data deleted.");
  };

  const updateSessionData =
    (fieldName, fieldData = "") =>
    (event) => {
      fieldData = fieldData == "" ? event.target.value : fieldData;

      setTempSession({ ...tempSession, [fieldName]: fieldData });
    };

  const updateSessionDate = (fieldName, fieldData) => {
    setTempSession({ ...tempSession, [fieldName]: fieldData });
  };

  const getEnableSessionDataMap = () => {
    return [
      { value: 1, label: "At the time of session" },
      { value: 5, label: "5 minutes before" },
      { value: 10, label: "10 minutes before" },
      { value: 15, label: "15 minutes before" },
    ];
  };

  const deleteThmumbnil = () => {
    setTempSession({ ...tempSession, prerercordedThumbnail: "" });
  };
  const deletePrerercordedVideo = () => {
    setTempSession({ ...tempSession, prerercordedVideo: "" });
  };

  /** End: Sessions related code */

  /** start: Resourse related code */

  const [tempResourse, setTempResourse] = useState({});
  const [tempResIndex, setTempResIndex] = useState(0);
  const [resourseModalTitle, setResourseModalTitle] = useState("");
  const [resourseModalConButton, setResourseModalConButton] = useState("");
  const [openResourseModal, setOpenResourseModal] = useState(false);

  const tempResourseIntialError = {
    title: "",
    resourseLink: "",
    resourseFileName: "",
    resourseFile: "",
  };

  const [tempResourseError, setTempResourseError] = useState(
    tempResourseIntialError
  );

  const [tempResourseErrorStatus, setTempResourseErrorStatus] = useState(false);
  const checktempResourseError = () => {
    let error = 0;
    let errorObject = { ...tempResourseError };
    if (tempResourse.type === 0 && tempResourse.title === "") {
      error++;
      errorObject.title = "Required";
    } else {
      errorObject.title = "";
    }

    if (tempResourse.type === 0 && tempResourse.resourseLink === "") {
      error++;
      errorObject.resourseLink = "Required";
    } else {
      errorObject.resourseLink = "";
    }

    if (tempResourse.type == 1 && tempResourse.resourseFileName === "") {
      error++;
      errorObject.resourseFileName = "Required";
    } else {
      errorObject.resourseFileName = "";
    }

    if (tempResourse.type == 1 && tempResourse.resourseFile === "") {
      error++;
      errorObject.resourseFile = "Required";
    } else {
      errorObject.resourseFile = "";
    }

    setTempResourseError({ ...tempResourseError, ...errorObject });
    if (error > 0) {
      showSnackbarMessage(
        "error",
        "One or more fields are required or not valid!"
      );
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (tempResourseErrorStatus) {
      checktempResourseError();
    }
  }, [tempResourseErrorStatus]);

  const handleOpenResourseModal = (moduleIndex, resIndex = "") => {
    console.log("handleOpenResourseModal");
    // console.log(resIndex);
    if (resIndex >= 0) {
      setTempResIndex(resIndex);
      setResourseModalTitle("Update resourse data");
      setResourseModalConButton("Update Now");
      // console.log(courseData.courseModules[moduleIndex].courseSessions[sesIndex]);
      setTempResourse(
        courseData.courseModules[moduleIndex].courseResourses[resIndex]
      );
    } else {
      setTempResIndex(-1);
      setResourseModalTitle("Add resource");
      setResourseModalConButton("Add Now");
      setTempResourse({
        resId: 0,
        modId: courseData.courseModules[moduleIndex].modId,
        sesId: 0,
        type: 0,
        title: "",
        resourseLink: "",
        resourseFileName: "",
        resourseFile: "",
      });
      // console.log("Hi how are you?")
    }
    // console.log(tempResourse);
    setTempModuleIndex(moduleIndex);
    setOpenResourseModal(true);
  };

  const handleCloseResourseModal = () => {
    console.log("handleCloseResourseModal");
    setOpenResourseModal(false);
    setTempResourseErrorStatus(false);
    setTempResourseError(tempResourseIntialError);
  };

  const handleConfirmCloseResourseModal = () => {
    setTempResourseErrorStatus(true);
    const isValid = checktempResourseError();
    if (!isValid) {
      return false;
    } else {
      const list = { ...courseData };
      if (tempResIndex >= 0) {
        list.courseModules[tempModuleIndex].courseResourses[tempResIndex] =
          tempResourse;
        showSnackbarMessage("success", "Resourse updated.");
      } else {
        list.courseModules[tempModuleIndex].courseResourses.push(tempResourse);
        showSnackbarMessage("success", "New Resourse added.");
      }
      setTempResourseErrorStatus(false);
      setTempResourseError(tempResourseIntialError);
      setCourseData(list);
      setOpenResourseModal(false);
      setTempResourse({});
    }
  };

  const handleDeleteResourse = (moduleIndex, resIndex) => {
    console.log("handleDeleteSession");
    const list = { ...courseData };
    list.courseModules[moduleIndex].courseResourses.splice(resIndex, 1);
    setCourseData(list);
    showSnackbarMessage("success", "Resourses data deleted.");
  };

  const isValidURL = (url) => {
    try {
      const { protocol, hostname } = new URL(url);
      return (
        (protocol === "http:" || protocol === "https:") &&
        hostname !== null &&
        /\.[^.]+$/.test(hostname)
      ); // Check if there's at least one character after the dot
    } catch (error) {
      return false;
    }
  };

  const updateResoursesData =
    (fieldName, fieldData = "") =>
    (event) => {
      fieldData = fieldData == "" ? event.target.value : fieldData;
      console.log(fieldName, fieldData);
      if (fieldName == "resourseLink") {
        if (isValidURL(fieldData) || fieldData == "") {
          setResourceUrlError("");
        } else {
          setResourceUrlError("It's not a valid URL");
        }
      }
      setTempResourse({ ...tempResourse, [fieldName]: fieldData });
    };

  const updateResourseWithParemData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setTempResourse({ ...tempResourse, [fieldName]: fieldData });
  };

  const getAllSessions = () => {
    let dataArray = [];
    dataArray.push({ value: 0, label: "None" });
    if (
      tempModuleIndex >= 0 &&
      typeof courseData.courseModules[tempModuleIndex] !== "undefined"
    ) {
      const sessions = courseData.courseModules[tempModuleIndex].courseSessions;
      sessions.map((element) => {
        // dataArray.push({ value: element.sesId + 1, label: element.title });
        if (element.sesId > 0) {
          dataArray.push({ value: element.sesId, label: element.title });
        }
      });

      return dataArray;
    }

    return dataArray;
  };

  /** End: Resourse related code */

  //==== radio button//
  const [radiovalue, radiosetValue] = React.useState("female");

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };
  //==== radio button End//

  // ==== Date Picker === //
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };

  // ==== Date Picker end === //

  // ==== Level Value === //
  const [age, setAge] = React.useState("");
  const handleChangesSessionBecomes = (event, fieldName) => {
    setAge(event.target.value);
    setTempSession({
      ...tempSession,
      [fieldName]: event.target.value,
    });
  };

  const selectValue = [
    {
      label: "Beginner level",
      value: "Beginner level",
    },
    {
      label: "Intermediate level",
      value: "Intermediate level",
    },
    {
      label: "Expert level",
      value: "Expert level",
    },

    {
      label: "All levels",
      value: "All levels",
    },
  ];
  // ==== Level Value end === //

  const SessionBecomesAvailableAlways = [
    {
      label: "Always",
      value: "Always",
      subtitle: "Available for registered users",
    },
    {
      label: "Private",
      value: "Private",
      subtitle: "Only visible to me",
    },
    {
      label: "On date",
      value: "On-date",
    },
  ];

  const SessionBecomesAvailableForever = [
    {
      label: "Never",
      value: "Never",
      subtitle: "Always available",
    },

    {
      label: "On date",
      value: "On-date",
    },
  ];

  // ==== SessionBecomesAvailable end === //

  // Modal Module //

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    console.log("close");
    setOpen(false);
  };

  const handleConfirm = () => {
    console.log("close");
    setOpen(false);
  };

  // Modal Module  End//

  const [openResource, setOpenResource] = useState(false);

  const handleOpenResource = () => {
    setOpenResource(true);
  };

  const handleCloseModalResource = () => {
    console.log("close");
    setOpenResource(false);
  };

  const handleConfirmResource = () => {
    console.log("close");
    setOpenResource(false);
  };

  // Modal Resource End //

  // Text Field //
  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });

  // const handleChanges = (prop) => (event) => {
  //   setValues({
  //     ...values,
  //     [prop]: event.target.value,
  //   });
  // };
  // Text Field //

  // Redio Box //

  const tabsData = [
    {
      title: "External Resource",
      description: "Link of an external site",
    },

    {
      title: "Downloadable File",
      description: "Upload a resource",
    },
  ];

  // Redio Box //

  const [valuesUrl, setValuesUrl] = React.useState({
    name: "https://example.com",
    number: "",
  });

  const handleChangesUrl = (prop) => (event) => {
    setValuesUrl({
      ...valuesUrl,
      [prop]: event.target.value,
    });
  };

  // console.log(tempSession, tempResourse);

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeModule = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      setTempSession({
        ...tempSession,
        allowUserToPreview: 1,
      });
    } else {
      setTempSession({
        ...tempSession,
        allowUserToPreview: 0,
      });
    }
  };

  const addSessiondate = [
    {
      title: "Live",
      description: "Add Zoom link",
      disabled: sessionModalTitle === "Edit session" ? true : false,
    },

    {
      title: "Pre-recorded video",
      description: "Uplaod video",
      disabled: sessionModalTitle === "Edit session" ? true : false,
    },
  ];

  useEffect(() => {
    console.log(sessionErrors, 345);
  }, [sessionErrors]);

  const addSessionPanelDate = [
    {
      id: 0,
      panel: (
        <Grid container spacing={3}>
          {sessionModalTitle === "Edit session" && (
            <Grid item xs={12} sm={12} md={12}>
              <p>Session Type*</p>
              <p>Live</p>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Session Title"
              required
              count={60}
              value={tempSession?.title}
              handleChange={updateSessionData("title")}
              CharAlignMid="CharAlignMid"
              error={sessionErrors?.title}
              helperText={sessionErrors?.title}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6}>
            <DateTimePickerField
              label="Start Date & Time"
              validation={true}
              value={tempSession.startTime}
              handleChange={(selectedTime) => updateSessionDate("startTime", selectedTime)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <DateTimePickerField
              label="End Date & Time"
              validation={true}
              value={tempSession.endTime}
              handleChange={(selectedTime) => updateSessionDate("endTime", selectedTime)}

            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
            <Datefield
              label="Start Date"
              placeholder="Select date"
              validation={false}
              value={tempSession.startDate}
              timezone={courseData.timezone}
              handleChange={(selectedDate) =>
                updateSessionDate("startDate", selectedDate)
              }
              error={sessionErrors?.startDate ? sessionErrors?.startDate : ""}
              helperText={
                sessionErrors?.startDate ? sessionErrors?.startDate : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ComboBox
              label="Start Time"
              placeholder="Select time"
              required
              validation={false}
              error={
                sessionErrors?.selectedStartTime
                  ? sessionErrors?.selectedStartTime
                  : ""
              }
              helperText={
                sessionErrors?.selectedStartTime
                  ? sessionErrors?.selectedStartTime
                  : ""
              }
              options={timeIn15MinsInterval()}
              selectedOption={tempSession.selectedStartTime}
              handleChange={(fieldData) =>
                updateSessionDate("selectedStartTime", fieldData)
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ComboBox
              label="End Time"
              placeholder="Select time"
              required
              options={timeIn15MinsInterval()}
              selectedOption={tempSession.selectedEndTime}
              error={
                sessionErrors?.selectedEndTime
                  ? sessionErrors?.selectedEndTime
                  : ""
              }
              helperText={
                sessionErrors?.selectedEndTime
                  ? sessionErrors?.selectedEndTime
                  : ""
              }
              handleChange={(fieldData) =>
                updateSessionDate("selectedEndTime", fieldData)
              }
            />
          </Grid>

          <div
            className="FieldLabel"
            style={{
              marginBottom: "10px",
              padding: "0 20px",
              marginTop: "7px",
            }}
          >
            {/* <Tooltip TooltipText="Text" Tooltip={<InfoOutlinedIcon />} />{" "} */}
            <InfoOutlinedIcon />
            Timezone: America/Los Angeles (GMT-07:00)
          </div>

          <Grid item xs={12} sm={12} md={12}>
            <div className="FixedSizeTextArea">
              <TextArea
                label="Description"
                placeholder=""
                validation={false}
                helperText="Add details and instruction for students to join"
                count="300"
                value={tempSession.description}
                handleChange={updateSessionData("description")}
              />
            </div>
          </Grid>
          {!courseData.sessionLinkSetting && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Zoom Link"
                  required={true}
                  //count={80}
                  value={tempSession.zoomLink}
                  error={sessionErrors?.zoomLink ? sessionErrors?.zoomLink : ""}
                  helperText={
                    sessionErrors?.zoomLink ? sessionErrors?.zoomLink : ""
                  }
                  handleChange={updateSessionData("zoomLink")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextArea
                  label="Details"
                  count="300"
                  validation={false}
                  helperText="Add details and instruction for students to join"
                  value={tempSession.details}
                  handleChange={updateSessionData("details")}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Allow Learners to Join"
              validation={true}
              options={getEnableSessionDataMap()}
              selectedOption={
                tempSession.enableSession ? tempSession.enableSession : "1"
              }
              handleChange={updateSessionData("enableSession")}
            />
          </Grid>
        </Grid>
      ),
    },

    {
      id: 1,
      panel: (
        <Grid container spacing={3}>
          {sessionModalTitle === "Edit session" && (
            <Grid item xs={12} sm={12} md={12}>
              <p>Session Type*</p>
              <p>Pre-recorded video</p>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Session Title"
              count={60}
              required
              value={tempSession.title}
              handleChange={updateSessionData("title")}
              CharAlignMid="CharAlignMid"
              error={sessionErrors?.title ? sessionErrors?.title : ""}
              helperText={sessionErrors?.title ? sessionErrors?.title : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="FixedSizeTextArea">
              <TextArea
                label="Description"
                placeholder=""
                validation={false}
                count="200"
                value={tempSession.description}
                handleChange={updateSessionData("description")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="FieldLabel" style={{ marginBottom: "10px" }}>
              Upload Video<span>*</span>
              <Tooltip
                TooltipText="Film and export in HD to create videos at a minimum of 720p, or preferably 1080p. Each video should not exceed 1.0 GB in size."
                Tooltip={<InfoOutlinedIcon />}
              />
            </div>
            {(
              tempSession.prerercordedVideo
                ? tempSession.prerercordedVideo.length
                : false
            ) ? (
              <>
                <div className="TableSection">
                  <div
                    style={{
                      padding: "0.2rem",
                      // background: "#C3C3C3",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "48px",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span className="CellImg">
                        <PlayCircleFilledOutlinedIcon />
                      </span>
                      <span>
                        <p
                          style={{
                            color: "#0B132B",
                            fontSize: "14px",
                            margin: "0",
                            fontWeight: "600",
                          }}
                        >
                          {tempSession?.prerercordedVideoName}
                        </p>
                        <p
                          style={{
                            color: "#676C7B",
                            fontSize: "12px",
                            margin: "0",
                          }}
                        >
                          {tempSession?.prerercordedVideoSize
                            ? tempSession?.prerercordedVideoSize
                            : "0 MB"}{" "}
                          ● {tempSession?.prerercordedVideoDuration}
                        </p>
                        {/* {" "}
                        <a href={tempResourse?.resourseFile}>
                          Download
                        </a>{" "} */}
                      </span>
                    </div>
                    <span
                      role="button"
                      className="CellImg"
                      onClick={deletePrerercordedVideo}
                      style={{
                        color: "#676C7B",
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{ display: "inline-block", fontSize: "14px" }}
                      >
                        Remove
                      </span>{" "}
                      <DeleteForeverRoundedIcon
                        sx={{ mb: -0.5, color: "#676C7B" }}
                      />
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <SideDrawer
                  title="Media Library"
                  ButtonLabel="Add"
                  //clickSaveButton={clickSaveButton}
                  cancelButtonLabel="Cancel"
                  sideOpen="right"
                  open={openSelectMedia}
                  handleDrawerClose={handleDrawerCloseSelectMedia}
                  FooterWithoutTab={true}
                  LeftButton={true}
                  ButtonLeft="Upload New"
                  ButtonLeftIcon={<FileUploadOutlinedIcon />}
                  clickButtonLeft={handleDrawerOpenNewMedia}
                  clickSaveButton={clickSelectMediaItem}
                  body={
                    <MediaLibrarySelectMedia
                      onClickRadioIcon={setSelectedValue}
                      onItemSelected={getMediaItem}
                    />
                  }
                />

                <Externaluse
                  open={openNewMedia}
                  handleCloseButton={handleMediaUploadClose}
                  handleOpenNewMedia={handleOpenNewMedia}
                />

                <div className="TableSection">
                  <Buttons
                    label="Upload"
                    buttonColor="transparent"
                    border="#E4E8EC 1px solid"
                    icon={<AddRoundedIcon />}
                    color="#004FE0"
                    width=""
                    height="48px"
                    fontSize="14px"
                    link=""
                    onClick={() => openSessionMediaUpload()}
                  />
                </div>
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="FieldLabel" style={{ marginBottom: "10px" }}>
              Thumbnail{" "}
              <Tooltip
                TooltipText="Upload a picture that shows what's in your video. Please ensure that your thumbnail is 1280 by 720 pixels (16:9 ratio) and is less than 2MB in size. Use a JPG or PNG file format. If there’s no thumbnail, we will display the course image."
                Tooltip={<InfoOutlinedIcon />}
              />
            </div>
            {(
              tempSession?.prerercordedThumbnail
                ? tempSession?.prerercordedThumbnail?.length
                : false
            ) ? (
              <>
                <div className="TableSection">
                  <div
                    style={{
                      padding: "0.2rem",
                      // background: "#C3C3C3",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "48px",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span className="CellImg">
                        <Avatar
                          variant="rounded"
                          src={tempSession?.prerercordedThumbnail}
                          style={{ width: "28px", height: "28px" }}
                        />
                      </span>
                      <span>
                        <p
                          style={{
                            color: "#0B132B",
                            fontSize: "14px",
                            margin: "0",
                            fontWeight: "600",
                          }}
                        >
                          {tempSession?.prerercordedThumbnailName}
                        </p>
                        <p
                          style={{
                            color: "#676C7B",
                            fontSize: "12px",
                            margin: "0",
                          }}
                        >
                          {tempSession?.prerercordedThumbnailSize
                            ? parseFloat(
                                tempSession?.prerercordedThumbnailSize
                              )?.toFixed(2)
                            : 0}{" "}
                          MB
                        </p>
                        {/* {" "}
                        <a href={tempResourse?.resourseFile}>
                          Download
                        </a>{" "} */}
                      </span>
                    </div>
                    <span
                      role="button"
                      className="CellImg"
                      onClick={deleteThmumbnil}
                      style={{
                        color: "#676C7B",
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{ display: "inline-block", fontSize: "14px" }}
                      >
                        Remove
                      </span>{" "}
                      <DeleteForeverRoundedIcon
                        sx={{ mb: -0.5, color: "#676C7B" }}
                      />
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <SideDrawer
                  title="Media Library"
                  ButtonLabel="Add"
                  //clickSaveButton={clickSaveButton}
                  cancelButtonLabel="Cancel"
                  sideOpen="right"
                  open={openSelectMedia}
                  handleDrawerClose={handleDrawerCloseSelectMedia}
                  FooterWithoutTab={true}
                  LeftButton={true}
                  ButtonLeft="Upload New"
                  ButtonLeftIcon={<FileUploadOutlinedIcon />}
                  clickButtonLeft={handleDrawerOpenNewMedia}
                  clickSaveButton={clickSelectMediaItem}
                  body={
                    <MediaLibrarySelectMedia
                      onClickRadioIcon={setSelectedValue}
                      onItemSelected={getMediaItem}
                    />
                  }
                />

                <Externaluse
                  open={openNewMedia}
                  handleCloseButton={handleMediaUploadClose}
                  handleOpenNewMedia={handleOpenNewMedia}
                />

                <div className="TableSection">
                  <Buttons
                    label="Upload"
                    buttonColor="transparent"
                    border="#E4E8EC 1px solid"
                    icon={<AddRoundedIcon />}
                    color="#004FE0"
                    width=""
                    height="48px"
                    fontSize="14px"
                    link=""
                    onClick={() => openSessionMediaThumbnail()}
                  />
                </div>
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <CheckBox
              label="Allow users to watch this video for free as a preview."
              checked={tempSession?.allowUserToPreview !== 0 ? true : false}
              onChange={handleChangeCheck}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Session Becomes Available"
              validation
              options={SessionBecomesAvailableAlways}
              handleChange={(e) =>
                handleChangesSessionBecomes(e, "sessionAvailable")
              }
              selectedOption={tempSession?.sessionAvailable}
            />
          </Grid>
          {tempSession?.sessionAvailable === "On-date" && (
            <>
              <Grid item xs={6} sm={6} md={6} sx={{ marginTop: "-22px" }}>
                <Datefield
                  label="Select Date"
                  validation={false}
                  timeZone={courseData.timezone}
                  value={tempSession?.sessionAvailableDate}
                  timezone={courseData.timezone}
                  error={
                    sessionErrors?.sessionAvailableDate
                      ? sessionErrors?.sessionAvailableDate
                      : ""
                  }
                  helperText={
                    sessionErrors?.sessionAvailableDate
                      ? sessionErrors?.sessionAvailableDate
                      : ""
                  }
                  handleChange={(selectedDate) =>
                    updateSessionDate("sessionAvailableDate", selectedDate)
                  }
                  // disablePast={true}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} sx={{ marginTop: "-22px" }}>
                <ComboBox
                  // label="Select Time*"
                  placeholder="Select time"
                  options={timeIn15MinsInterval()}
                  selectedOption={fromattedTime(
                    tempSession?.sessionAvailableTime
                  )}
                  error={
                    sessionErrors?.sessionAvailableTime
                      ? sessionErrors?.sessionAvailableTime
                      : ""
                  }
                  helperText={
                    sessionErrors?.sessionAvailableTime
                      ? sessionErrors?.sessionAvailableTime
                      : ""
                  }
                  handleChange={(fieldData) =>
                    updateSessionDate("sessionAvailableTime", fieldData)
                  }
                />
              </Grid>
            </>
          )}

          {tempSession?.sessionAvailable !== "Private" && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  label="Session Becomes Unavailable"
                  validation
                  options={SessionBecomesAvailableForever}
                  handleChange={(e) =>
                    handleChangesSessionBecomes(e, "sessionUnavailable")
                  }
                  selectedOption={tempSession?.sessionUnavailable}
                />
              </Grid>
              {tempSession?.sessionUnavailable === "On-date" && (
                <>
                  <Grid item xs={6} sm={6} md={6} sx={{ marginTop: "-22px" }}>
                    <Datefield
                      // label="Select Date*"
                      placeholder="Select Date"
                      value={tempSession.sessionUnavailableDate}
                      timezone={courseData.timezone}
                      error={
                        sessionErrors?.sessionUnavailableDate
                          ? sessionErrors?.sessionUnavailableDate
                          : ""
                      }
                      helperText={
                        sessionErrors?.sessionUnavailableDate
                          ? sessionErrors?.sessionUnavailableDate
                          : ""
                      }
                      // timezone="America/New_York"
                      handleChange={(selectedDate) =>
                        updateSessionDate(
                          "sessionUnavailableDate",
                          selectedDate
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} sx={{ marginTop: "-22px" }}>
                    <ComboBox
                      // label="Select Time*"
                      placeholder="Select time"
                      options={timeIn15MinsInterval()}
                      error={
                        sessionErrors?.sessionUnavailableTime
                          ? sessionErrors?.sessionUnavailableTime
                          : ""
                      }
                      helperText={
                        sessionErrors?.sessionUnavailableTime
                          ? sessionErrors?.sessionUnavailableTime
                          : ""
                      }
                      selectedOption={fromattedTime(
                        tempSession?.sessionUnavailableTime
                      )}
                      handleChange={(fieldData) =>
                        updateSessionDate("sessionUnavailableTime", fieldData)
                      }
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      ),
    },
  ];

  const getVisibleTabs = () => {
    if (sessionModalTitle === "Edit session") {
      return [];
    } else {
      return addSessiondate; // Show both panels
    }
  };
  const getVisiblePanels = () => {
    if (
      sessionModalTitle === "Edit session" &&
      tempSession.isPrerecorded === 1
    ) {
      return [addSessionPanelDate[1]]; // Show only panel 1
    } else if (
      sessionModalTitle === "Edit session" &&
      tempSession.isPrerecorded === 0
    ) {
      return [addSessionPanelDate[0]]; // Show only panel 0
    } else {
      return addSessionPanelDate; // Show both panels
    }
  };

  // Use the visible panels to render
  const visiblePanels = getVisiblePanels();
  const visibleTabs = getVisibleTabs();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection Section">
            <div className="MdTitle">Session Link Setting</div>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <SwitchButton
                  title="Same link for all lectures"
                  subtitle="Students can join one link for all lectures"
                  BorderLine={false}
                  handleChange={handleChanges("sessionLinkSetting")}
                  checked={courseData.sessionLinkSetting}
                />
              </Grid>
              {courseData.sessionLinkSetting && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Zoom Link"
                      required={true}
                      number="url"
                      value={courseData.zoomLink}
                      handleChange={handleChanges("zoomLink")}
                      email={false}
                      error={
                        courseErrors?.zoomLink ? courseErrors?.zoomLink : ""
                      }
                      helperText={
                        courseErrors?.zoomLink ? courseErrors?.zoomLink : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextArea
                      label="Details"
                      placeholder="Enter details here."
                      validation={false}
                      helperText="Add more Zoom meeting details and instructions for students to join"
                      count={300}
                      value={courseData.zoomDescription}
                      handleChange={handleChanges("zoomDescription")}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="formSection">
            {courseData.courseModules.map((module, index) => {
              return (
                <Modules
                  index={index}
                  id={module.id}
                  title={module.title}
                  courseSessions={module.courseSessions}
                  courseResourses={module.courseResourses}
                  setCurrentModule={setCurrentModule}
                  setEditModule={setEditModule}
                  deleteModule={deleteModule}
                  handleOpenSessionModal={handleOpenSessionModal}
                  handleDeleteSession={handleDeleteSession}
                  handleOpenResourseModal={handleOpenResourseModal}
                  handleDeleteResourse={handleDeleteResourse}
                  panel={`module-${index}`}
                  handleChange={handleChangeModule(`module-${index}`)}
                  expanded={expanded}
                />
              );
            })}
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Button
            className="SessionButton"
            style={{ width: "100%" }}
            onClick={() => handleOpenModuleAdd(true)}
          >
            <AddRoundedIcon />
            Module
          </Button>
        </Grid>
      </Grid>

      {/* Add Module */}
      <Modals
        ModalTitle="Add Module"
        handleClose={handleCloseModuleAdd}
        handleConfirm={() => handleConfirmModuleAdd()}
        open={newModule}
        ButtonClose="Cancel"
        ButtonConfirm="Add Now"
        width="552px"
        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}
        ModalBody={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Module Title"
                  required={true}
                  count={80}
                  value={tempModuleTitle}
                  handleChange={addModuleTitle()}
                  error={moduleError?.tilte}
                  helperText={moduleError?.tilte}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
      {/* Add Module */}

      {/* Edit Module */}
      <Modals
        ModalTitle="Edit Module"
        handleClose={handleCloseModuleEdit}
        handleConfirm={() => handleConfirmModuleEdit(currentModule.index)}
        open={editModule}
        ButtonClose="Cancel"
        ButtonConfirm="Save"
        width="552px"
        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}
        ModalBody={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Module Title"
                  required={true}
                  count={80}
                  value={currentModule.title}
                  handleChange={updateModuleTitle(currentModule.index)}
                  error={moduleError?.tilte}
                  helperText={moduleError?.tilte}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
      {/* Edit Module */}

      {/* Session Modal */}
      <SideDrawer
        title={sessionModalTitle}
        ButtonLabel={sessionModalConButton}
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={openSessionModal}
        handleDrawerClose={() => handleCloseSessionModal()}
        clickSaveButton={() => handleConfirmCloseSessionModal()}
        CheckboxLabel="Add another after saving"
        onCheckboxClicked={(event) => addAnotherAfterSavingHandler(event)}
        Checkbox={sessionModalTitle == "Add session" ? true : false}
        FooterWithoutTab={true}
        body={
          <div>
            <RedioBox
              tabsData={visibleTabs?.length > 0 ? visibleTabs : []}
              panelDate={visiblePanels}
              value={tabvalue}
              onChange={onChangeAddSession}
              HideTabs={visibleTabs?.length > 0 ? "" : "HideTabs"}
            />
          </div>
        }
      />

      {/* <Modals
        ModalTitle={sessionModalTitle}
        handleClose={() => handleCloseSessionModal()}
        handleConfirm={() => handleConfirmCloseSessionModal()}
        open={openSessionModal}
        ButtonClose="Cancel"
        ButtonConfirm={sessionModalConButton}
        width="552px"
        ModalFooter={true}

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"

        ModalBody={
          <p></p>
        }

      /> */}
      {/* Session Modal  End*/}

      {/* Resource Modal */}

      <SideDrawer
        title={resourseModalTitle}
        ButtonLabel={resourseModalConButton}
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={openResourseModal}
        handleDrawerClose={handleCloseResourseModal}
        clickSaveButton={() => handleConfirmCloseResourseModal()}
        FooterWithoutTab={true}
        body={
          <div className="ResorceDrwContainer">
            <Grid container spacing={3}>
              {getAllSessions().length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <SelectField
                    label="Select Session (Optional)"
                    validation={false}
                    options={getAllSessions()}
                    selectedOption={
                      typeof tempResourse.sesId == "undefined"
                        ? ""
                        : tempResourse.sesId
                    }
                    handleChange={updateResoursesData("sesId")}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <RedioBox
                      label="Resource Type"
                      validation
                      tabsData={tabsData}
                      value={tempResourse.type}
                      onChange={(event, tabsData) =>
                        updateResourseWithParemData("type", tabsData)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {tempResourse.type == 0 ? (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Title"
                      required={true}
                      count={60}
                      value={tempResourse.title}
                      handleChange={updateResoursesData("title")}
                      placeholder="A descriptive title"
                      error={tempResourseError?.title}
                      helperText={tempResourseError?.title}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="URL"
                      placeholder="https://example.com"
                      required={true}
                      value={tempResourse.resourseLink}
                      error={tempResourseError?.resourseLink}
                      helperText={tempResourseError?.resourseLink}
                      handleChange={updateResoursesData("resourseLink")}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <FileUplaoder
                      label="Upload file"
                      required={true}
                      filePath="modules/resource-files/"
                      uploadButton="Upload File"
                      file={tempResourse.resourseFile}
                      fileType="*"
                      onUpload={(filePath) =>
                        updateResourseWithParemData("resourseFile", filePath)
                      }
                      helpertext="Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf."
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="File Name"
                      placeholder=""
                      validation={true}
                      value={tempResourse.resourseFileName}
                      handleChange={updateResoursesData("resourseFileName")}
                      count={60}
                      error={tempResourseError?.resourseFileName}
                      helperText={tempResourseError?.resourseFileName}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="FieldLabel">
                      Upload File<span>*</span>
                    </div>
                    {(
                      tempResourse.resourseFile
                        ? tempResourse.resourseFile.length
                        : false
                    ) ? (
                      <>
                        <div className="TableSection">
                          <div
                            style={{
                              padding: "0.2rem",
                              // background: "#C3C3C3",
                              border: "1px solid #E4E8EC",
                              borderRadius: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "48px",
                              padding: "0 10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span className="CellImg">
                                <Avatar
                                  variant="rounded"
                                  src={tempResourse.resourseFile}
                                  style={{ width: "28px", height: "28px" }}
                                />
                              </span>
                              <span>
                                {" "}
                                <a href={tempResourse?.resourseFile}>
                                  Download
                                </a>{" "}
                              </span>
                            </div>
                            <span
                              role="button"
                              className="CellImg"
                              onClick={deleteCourseImageSelection}
                            >
                              <DeleteForeverRoundedIcon
                                sx={{ mb: -0.5, color: "#676C7B" }}
                              />
                            </span>
                          </div>
                          <p
                            class="HelperText"
                            style={{ marginBottom: "10px" }}
                          >
                            Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf.
                          </p>
                        </div>
                      </>
                    ) : (
                      <div>
                        <SideDrawer
                          title="Media Library"
                          ButtonLabel="Add"
                          //clickSaveButton={clickSaveButton}
                          cancelButtonLabel="Cancel"
                          sideOpen="right"
                          open={openSelectMedia}
                          handleDrawerClose={handleDrawerCloseSelectMedia}
                          FooterWithoutTab={true}
                          LeftButton={true}
                          ButtonLeft="Upload New"
                          ButtonLeftIcon={<FileUploadOutlinedIcon />}
                          clickButtonLeft={handleDrawerOpenNewMedia}
                          clickSaveButton={clickSelectMediaItem}
                          body={
                            <MediaLibrarySelectMedia
                              onClickRadioIcon={setSelectedValue}
                              onItemSelected={getMediaItem}
                            />
                          }
                        />

                        <Externaluse
                          open={openNewMedia}
                          handleCloseButton={handleMediaUploadClose}
                          handleOpenNewMedia={handleOpenNewMedia}
                        />

                        <div className="TableSection">
                          <Buttons
                            label="Add From Media Library"
                            buttonColor="transparent"
                            border="#E4E8EC 1px solid"
                            icon={<AddRoundedIcon />}
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => handleDrawerOpenSelectMedia()}
                          />
                          <p
                            class="HelperText"
                            style={{ marginBottom: "10px" }}
                          >
                            Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf.
                          </p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        }
      />

      {/* <Modals
        ModalTitle={resourseModalTitle}
        handleClose={handleCloseResourseModal}
        handleConfirm={() => handleConfirmCloseResourseModal()}
        open={openResourseModal}
        ButtonClose="Cancel"
        ButtonConfirm={resourseModalConButton}
        width="552px"

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}

        ModalBody={
          
        }

      /> */}
      {/* Resource Modal End*/}
    </>
  );
}

export default Curriculum;
