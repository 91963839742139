import React from "react";


const StudentInfo = () => {
  return (
    <div className="studentInfo">
      <div className="studentDetailsContainer">
        <h3>Welcome Back, Shayan 👋</h3>
        <div className="studentDtailsDateInfo">
          <p>SHAʻBAN 28, 1444 AH</p>
          <span></span>
          <p>MONDAY, MARCH 20TH</p>
        </div>
      </div>
    </div>
  );
};

export default StudentInfo;
