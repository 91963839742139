import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SelectField from "../Form/SelectField/SelectField";
import CheckBox from "../Form/CheckBox/CheckBox";
// import SelectField from '../../components/Form/SelectField/SelectField';
// import CheckBox from '../../components/Form/CheckBox/CheckBox';

function ExportFilterUser(props) {
  const { filterInfo, setFilterInfo, exportError } = props;
  const [age, setAge] = React.useState("");
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const Format = [
    {
      label: "Excel",
      value: "excel",
    },

    {
      label: "CSV",
      value: "csv",
    },
  ];

  const Range = [
    {
      label: "Last 30 days",
      value: "30 days",
    },

    {
      label: "Last 3 months",
      value: "3 months",
    },

    {
      label: "Last 6 months",
      value: "6 months",
    },

    {
      label: "Last 12 months",
      value: "12 months",
    },

    {
      label: "All",
      value: "all",
    },
  ];

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  //=== User check Box == //
  const usersCheck = [
    {
      label: "Joined",
      value: "joined",
    },
    {
      label: "First name",
      value: "first_name",
    },
    {
      label: "Last name",
      value: "last_name",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Phone number",
      value: "phone_number",
    },
    {
      label: "State/Province",
      value: "state",
    },
    {
      label: "Country",
      value: "country",
    },
    {
      label: "Age group",
      value: "ageGroup",
    },
    {
      label: "Timezone",
      value: "timezone",
    },
  ];
  // { label: "First name" },
  // { label: "Last name" },
  // { label: "Email" },
  // { label: "Phone number" },
  // { label: "State/Province" },
  // { label: "Country" },
  // { label: "Gender" },
  // { label: "Age group" },
  // { label: "Timezone" },

  //=== Stream check Box == //
  const stream = [
    { label: "Online", value: "online" },
    { label: "Manual", value: "manual" },
  ];

  //=== Invoices check Box == //
  const invoices = [
    { label: "Pending", value: "pending" },
    { label: "Paid", value: "paid" },
  ];

  //=== Gender check Box == //
  const gender = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Undefined", value: "undefined" },
  ];

  //=== Status check Box == //
  const courses = [
    { label: "All enrolled courses", value: "all_enrolled_courses" },
    { label: "Current courses", value: "current_courses" },
    { label: "Total number of courses", value: "total_number_of_courses" },
    { label: "All enrolled tracks", value: "all_enrolled_tracks" },
    { label: "Current tracks", value: "current_tracks" },
    { label: "Total number of tracks", value: "total_number_of_tracks" },
  ];

  const checkUserColumnHandler = (e, value, propertyName) => {
    let newUserCheckBoxInfo = [...filterInfo[propertyName]];

    if (e.target.checked) {
      if (!newUserCheckBoxInfo?.includes(value)) {
        newUserCheckBoxInfo.push(value);
      }
    } else {
      if (newUserCheckBoxInfo?.includes(value)) {
        newUserCheckBoxInfo = filterInfo[propertyName]?.filter(
          (singleInfo) => singleInfo != value
        );
      }
    }
    setFilterInfo({ ...filterInfo, [propertyName]: newUserCheckBoxInfo });
  };

  const optionSelectHandler = (e, propertyName) => {
    setFilterInfo({ ...filterInfo, [propertyName]: e.target.value });
  };

  // useEffect(() => {
  //   console.log(filterInfo, 200);
  // }, [filterInfo]);

  return (
    <>
      <div className="ExportStudents">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Select Format"
              required={filterInfo.format === "" ? true : false}
              error={exportError.format}
              helperText={exportError.format}
              options={Format}
              handleChange={(e) => optionSelectHandler(e, "format")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              label="Select Date Range"
              required={filterInfo.dateRange === "" ? true : false}
              error={exportError.dateRange}
              helperText={exportError.dateRange}
              options={Range}
              handleChange={(e) => optionSelectHandler(e, "dateRange")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="FieldLabel">Users</div>
            <div style={{ height: "8px" }}></div>
            <div className="ExportStudentsCheckBox">
              {usersCheck.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={filterInfo?.users?.includes(item.value)}
                  onChange={(e) =>
                    checkUserColumnHandler(e, item.value, "users")
                  }
                />
              ))}
              <div className="FieldLabel TopSpacing">Stream</div>
              <div style={{ height: "8px" }}></div>
              {stream.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={filterInfo?.stream?.includes(item.value)}
                  onChange={(e) =>
                    checkUserColumnHandler(e, item.value, "stream")
                  }
                />
              ))}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {/* <div className="FieldLabel">Invoices</div> */}
            {/* <div style={{ height: "8px" }}></div> */}
            <div className="ExportStudentsCheckBox">
              {/* {invoices.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={filterInfo?.payment?.includes(item.value)}
                  onChange={(e) =>
                    checkUserColumnHandler(e, item.value, "payment")
                  }
                />
              ))} */}
              <div className="FieldLabel TopSpacing">Gender</div>
              <div style={{ height: "8px" }}></div>
              {gender.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={filterInfo?.gender?.includes(item.value)}
                  onChange={(e) =>
                    checkUserColumnHandler(e, item.value, "gender")
                  }
                />
              ))}
              {/* <div className="FieldLabel TopSpacing">Courses/Tracks</div> */}
              {/* <div style={{ height: "8px" }}></div> */}
              {/* {courses.map((item, i) => (
                <CheckBox
                  label={item.label}
                  checked={filterInfo?.status?.includes(item.value)}
                  onChange={(e) =>
                    checkUserColumnHandler(e, item.value, "status")
                  }
                />
              ))} */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ExportFilterUser;
