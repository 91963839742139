import React, { useEffect, useState } from "react";
import AlrtMessage from "../../../../components/Form/AlrtMessage/AlertMessage";
import Table from "../../../../components/Table/Table";
import TeamImg from "../../../../assets/imgs/tableImg.png";
import Crown from "../../../../assets/imgs/Crown.svg";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import moment from "moment-timezone";

function Courses({ userCourseData }) {
  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleOpenNewCategory = () => {
    setOpenNewCategory(true);
  };

  const handleCloseModalNewCategory = () => {
    console.log("close");
    setOpenNewCategory(false);
  };

  const handleConfirmNewCategory = () => {
    console.log("close");
    setOpenNewCategory(false);
  };

  // Modal Add Category  start//

  const onClick = () => {
    alert("onclick working fine!");
  };

  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (userCourseData?.length > 0) {
      setRows(convertDataToCellRows(userCourseData));
      // console.log(userCourseData, 345);
    }
  }, [userCourseData]);
  const headCells = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Course",
      width: "30%",
    },
    {
      id: "Role",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Start date",
      width: "20%",
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Completed",
      width: "35%",
    },
    { id: "", numeric: true, disablePadding: false, label: "", width: "10%" },
  ];
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        //course start and end time
        let sessionStartDate = item?.course?.sessionStartDate
          ? moment(item.course.sessionStartDate)
          : "N/A";
        let sessionEndDate = item?.course?.sessionEndDate
          ? moment(item.course.sessionEndDate)
          : "N/A";

        let today = moment();

        //status
        let label = "";
        if (sessionStartDate && today.isBefore(sessionStartDate)) {
          label = "Upcoming";
        } else if (
          sessionStartDate &&
          sessionEndDate &&
          today.isBetween(sessionStartDate, sessionEndDate)
        ) {
          label = "In Progress";
        } else if (sessionEndDate && today.isAfter(sessionEndDate)) {
          label = "Completed";
        } else {
          label = "N/A";
        }

        let background = "";
        let color = "";
        if (label === "Upcoming") {
          background = "#FFF5E6";
          color = "#FFAE35";
        } else if (label === "In Progress") {
          background = "#EEECFF";
          color = "#7266FB";
        } else if (label === "Completed") {
          background = "#E1F2E8";
          color = "#0B9444";
        } else {
          background = "";
          color = "";
        }

        return {
          cell: [
            { textBold: item?.course?.courseTitle || "N/A" },
            {
              Status: true,
              background: background,
              color: color,
              label: label,
            },

            {
              date: item?.course?.sessionStartDate
                ? sessionStartDate.format("MMM DD, YYYY")
                : "N/A",
            },
            {
              date: item?.course?.sessionEndDate
                ? sessionEndDate.format("MMM DD, YYYY")
                : "N/A",
            },
            {
              Button: true,
              buttonLabel: "Details",
              buttonTextcolor: "#004FE0",
              buttonLink: `/course-details/${item?.courseId}`,
              buttonTarget: "_blank",
              buttonBorder: "#E4E8EC 1px solid",
            },
          ],
        };
      });
    } else {
      return [];
    }
  };

  // const rows = [
  //   {
  //     cell: [
  //       { textBold: "Introduction to Islamic Economics and Finance" },
  //       {
  //         Status: true,
  //         background: "#EEECFF",
  //         color: "#7266FB",
  //         label: "In Progress",
  //       },
  //       { date: "Jan 12, 2022" },
  //       { date: "Sep 10, 2022" },
  //       {
  //         Button: true,
  //         buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0",
  //         buttonLink: "/team-details",
  //         buttonTarget: "_blank",
  //         buttonBorder: "#E4E8EC 1px solid",
  //       },
  //     ],
  //   },

  //   {
  //     cell: [
  //       { textBold: "Introduction to Islamic Economics and Finance" },
  //       {
  //         Status: true,
  //         background: "#FFF5E6",
  //         color: "#FFAE35",
  //         label: "Upcoming",
  //       },
  //       { date: "Jan 12, 2022" },
  //       { date: "Sep 10, 2022" },
  //       {
  //         Button: true,
  //         buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0",
  //         buttonLink: "/team-details",
  //         buttonTarget: "_blank",
  //         buttonBorder: "#E4E8EC 1px solid",
  //       },
  //     ],
  //   },

  //   {
  //     cell: [
  //       { textBold: "Introduction to Islamic Economics and Finance" },
  //       {
  //         Status: true,
  //         background: "#E1F2E8",
  //         color: "#0B9444",
  //         label: "Completed",
  //       },
  //       { date: "Jan 12, 2022" },
  //       { date: "Sep 10, 2022" },
  //       {
  //         Button: true,
  //         buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0",
  //         buttonLink: "/team-details",
  //         buttonTarget: "_blank",
  //         buttonBorder: "#E4E8EC 1px solid",
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      <AlrtMessage
        message="Invite sent"
        //message="Information updated"
        background="#0B9444"
      />

      <div className="TableSection">
        <Table
          innerHeader={true}
          innerTitle="Courses"
          headCells={headCells}
          rows={rows}
          footer={true}
        />
      </div>
    </>
  );
}

export default Courses;
