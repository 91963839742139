import React, { useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";
import {
  dateFormatter,
  getAgeGroupBasedOnBirthDate,
} from "../../../../../hooks/HelperFunctions";

import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import UserIcon from "../../../../../assets/imgs/Session-img.png";

import SelectFieldMulticolor from "../../../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor";
import axios from "axios";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);

function Profile(props) {
  const {
    userId,
    trackId,
    getStudentList
  } = props;

  const [userInfo, setUserInfo] = useState(null)
  useEffect(() => {
    if(trackId && userId && trackId > 0 && userId > 0){
      const baseUrl = process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;
      
      let postData = {
        trackId: trackId,
        userId: userId
      };

      axios.post(`${baseUrl}/orgPortal/getSingleStudentTrackInfo`, postData)
      .then(async (response) => {
        if(response && response?.data && response?.data?.body){
          setUserInfo(response?.data?.body)
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          console.error("error", err.response.data.message);
        }
      });
    }
  }, [userId, trackId]);


  const statustData = [
    { value: "active", statuscolor: "#0B9444", select: "Active" },
    { value: "dropped", statuscolor: "#FF6600", select: "Dropped" },
    { value: "blocked", statuscolor: "#BA1A1A", select: "Blocked" },
  ];

  const handleChange = (event, value) => {
    const baseUrl = process.env.REACT_APP_PROJECT_ENV === "prod"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

    const status = event.target.value;
    
    let postData = {
      trackId: trackId,
      userId: userId,
      status : status
    };
    axios.post(`${baseUrl}/orgPortal/updateStudentStatusOfATrack`, postData)
      .then(async (response) => {
        if(response && response?.data && !response?.data?.error){
          getStudentList()
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          console.error("error", err.response.data.message);
        }
      });
    // changeStudentStatus(userInfo.userId, status);
  };

  // const capitalizeFirstChar = (str) => {
  //   if (str.length === 0) {
  //     return str; // Return an empty string if the input is empty
  //   } else {
  //     return str.charAt(0).toUpperCase() + str.slice(1);
  //   }
  // };

  return (
    <>
      <div className="TableContainer oddEvenTable BorderTable CondensedTable">
        <div className="TableInnerHeader">
          <div className="PeronalInfocard">
            <div className="UserIcon">
              {/* <Avatar sx={{ width: 80, height: 80 }} alt="Remy Sharp" src={studentData.image} /> */}
              {userInfo?.image ? (
                <Avatar
                  sx={{ width: 80, height: 80 }}
                  src={userInfo?.image ? userInfo?.image : ""}
                  alt={(userInfo?.first_name || userInfo?.last_name) ? `${userInfo?.first_name} ${userInfo?.last_name}` : "N/A" }
                ></Avatar>
              ) : (
                <Avatar sx={{ width: 80, height: 80 }}>
                  {" "}
                  {userInfo?.first_name
                    ? Array.from(userInfo?.first_name)[0]
                    : ""}{" "}
                </Avatar>
              )}
            </div>

            <div className="PeronalInfocardDetail">
              <div>
                <h1>{(userInfo?.first_name || userInfo?.last_name) ? `${userInfo?.first_name} ${userInfo?.last_name}` : "N/A"}</h1>
                <p>{userInfo?.address || "N/A"}</p>
              </div>
              {/* <Chip label="Online" /> */}
            </div>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Status
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {userInfo?.student_track_status && <SelectFieldMulticolor
                    labelTop={false}
                    labelinsideStatus={true}
                    statustData={statustData}
                    defaultValue={userInfo?.student_track_status ? userInfo?.student_track_status : ""}
                    handleChange={handleChange}
                    width="140px"
                    height="40px"
                    Status={true}
                  />}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Enrolled
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    userInfo?.formatted_enrolled_at ? "TableInfo" : "TableLabel"
                  }
                >
                  {userInfo?.formatted_enrolled_at
                    ? userInfo?.formatted_enrolled_at
                    : "No data available"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Country
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    userInfo?.country ? "TableInfo" : "TableLabel"
                  }
                >
                  {userInfo?.country
                    ? userInfo?.country
                    : "No data available"}
                </StyledTableCell>
              </StyledTableRow>


              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  State/Province/District
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={
                    userInfo?.address ? "TableInfo" : "TableLabel"
                  }
                >
                  {userInfo?.address
                    ? userInfo?.address
                    : "No data available"}
                </StyledTableCell>
              </StyledTableRow>

              {/* <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">State/Province/District</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">New York</StyledTableCell>
              </StyledTableRow> */}

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Gender
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={userInfo?.gender ? "TableInfo" : "TableLabel"}
                >
                  {userInfo?.gender
                    ? userInfo?.gender
                    : "No data available"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Age Group
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={userInfo?.birthdate ? "TableInfo" : "TableLabel"}
                >
                  {userInfo?.birthdate
                    ? getAgeGroupBasedOnBirthDate(userInfo?.birthdate)
                    : "No data available"}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Timezone
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={userInfo?.timezone ? "TableInfo" : "TableLabel"}
                >
                  {userInfo?.timezone
                    ? userInfo?.timezone
                    : "No data available"}
                </StyledTableCell>
              </StyledTableRow>

              {userInfo && (
                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Course fee
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    // className={
                    //   courseOrTrack.priceCat ? "TableInfo" : "TableLabel"
                    // }
                  >
                    <div className="FeeChip">
                      <Chip
                        label="Paid"
                      />{" "}
                      <a href={`/student-details/${userId}?activeTab=payment`}>See payment history</a>{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {/* {trackData && (
                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Track fee
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={
                      courseOrTrack.totalTrackPrice ? "TableInfo" : "TableLabel"
                    }
                  >
                    <div className="FeeChip">
                      {" "}
                      {trackPriceOption && (
                        <Chip label={trackPriceOption} />
                      )}{" "}
                      <a href={userDetailsPageLink}>See payment history</a>{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Profile;
