import React, { useState, useEffect } from "react";
import { getDataMap } from "../../../../hooks/HelperFunctions";
import Grid from "@material-ui/core/Grid";
import TextField from "../../../../components/Form/TextField/TextField";
import Datefield from "../../../../components/Form/Datefield/Datefield";
import ComboBox from "../../../../components/Form/ComboBox/ComboBox";
import SwitchButton from "../../../../components/Form/Switch/Switch";

function Schedule(props) {
  const {
    trackData,
    setTrackData,
    timezones,
    showSeatField,
    setShowSeatField,
    trackErrors,
  } = props;

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // === onChange event handler, get field data from event === //
  const handleChanges = (fieldName) => (event) => {
    let fieldData = event.target.value;
    console.log(fieldName, fieldData);
    if (fieldName == "totalMonths") {
      fieldData = event.target.value;
      if (!isNaN(fieldData) && fieldData == "") {
        fieldData = "";
      } else if (isNaN(fieldData) && fieldData != "") {
        fieldData = 1;
      } else if (fieldData < 1) {
        fieldData = 1;
      }
    }
    setTrackData({ ...trackData, [fieldName]: fieldData });
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    // if (fieldName == "enrollmentStartDate") {
    //   console.log(fieldData.getDate());
    // }
    setTrackData({ ...trackData, [fieldName]: fieldData });
  };

  // start: track image related code

  const [openSelectMedia, setOpenSelectMedia] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [mediaItem, setMediaItem] = useState({});

  const [openNewMedia, setOpenNewMedia] = useState(false);

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  //deleteCourseImageSelection
  const deleteCourseImageSelection = () => {
    setTrackData({ ...trackData, trackImage: "" });
  };

  const setImageSelection = (fileUrl) => {
    // console.log(fileUrl);
    let temp = trackData;
    temp.trackImage = fileUrl;
    setTrackData(temp);
    // console.log(trackData);
  };

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };
  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  //handleDrawerOpenSelectMedia
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      // console.log(mediaItem);
      //set the media item
      setImageSelection(mediaItem.file_url);
      handleChangeWithData("trackImageName", mediaItem.name);

      // console.log( trackData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  // start: track image related code

  const tabsData = [
    {
      title: "Diploma",
      description: "It's a diploma track",
    },

    {
      title: "Certificate",
      description: "It's a certificate track",
    },
  ];

  const handleNumberOfSeat = (e) => {
    setShowSeatField(e.target.checked);
  };

  useEffect(() => {
    setTrackData({ ...trackData, enableSeatNumber: showSeatField });
  }, [showSeatField]);

  return (
    <div>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={12} md={12}>
          <SwitchButton
            title="Enable multi-term mode"
            handleChange={handleChange}
            // disabled
            checked={state.checkedB}
          />
        </Grid> */}

        <Grid item xs={12} sm={12} md={12}>
          <div className="termOneTitle">
            <TextField
              label="Term 1 Title"
              validation={true}
              error={trackErrors?.trackTermName}
              helperText={
                trackErrors?.trackTermName
                  ? trackErrors?.trackTermName
                  : "You will be able to add more terms later."
              }
              count={50}
              value={trackData.trackTermName}
              handleChange={handleChanges("trackTermName")}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Datefield
            label="Enrollment Start Date"
            validation={false}
            requiredField={true}
            timezone={trackData.timezone}
            disablePast={true}
            value={
              trackData.enrollmentStartDate ? trackData.enrollmentStartDate : ""
            }
            handleChange={(selectedDate) =>
              handleChangeWithData("enrollmentStartDate", selectedDate)
            }
            error={trackErrors?.enrollmentStartDate}
            helperText={trackErrors?.enrollmentStartDate}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Datefield
            label="Enrollment End Date"
            validation={false}
            requiredField={true}
            disablePast={true}
            timezone={trackData.timezone}
            value={
              trackData.enrollmentEndDate ? trackData.enrollmentEndDate : ""
            }
            handleChange={(selectedDate) =>
              handleChangeWithData("enrollmentEndDate", selectedDate)
            }
            error={trackErrors?.enrollmentEndDate}
            helperText={trackErrors?.enrollmentEndDate}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Datefield
            label="Session Start Date"
            validation={false}
            requiredField={true}
            timezone={trackData.timezone}
            disablePast={true}
            value={trackData.sessionStartDate ? trackData.sessionStartDate : ""}
            handleChange={(selectedDate) =>
              handleChangeWithData("sessionStartDate", selectedDate)
            }
            error={trackErrors?.sessionStartDate}
            helperText={trackErrors?.sessionStartDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Datefield
            label="Session End Date"
            disablePast={true}
            requiredField={true}
            validation={false}
            timezone={trackData.timezone}
            value={trackData.sessionEndDate ? trackData.sessionEndDate : ""}
            error={trackErrors?.sessionEndDate}
            helperText={trackErrors?.sessionEndDate}
            handleChange={(selectedDate) =>
              handleChangeWithData("sessionEndDate", selectedDate)
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="TotalMonth">
            <TextField
              label="Total Months"
              validation={false}
              required={true}
              number={true}
              value={trackData.totalMonths}
              error={trackErrors?.totalMonths}
              helperText={trackErrors?.totalMonths}
              handleChange={handleChanges("totalMonths")}
            />
            <p className="MonthLabel">months</p>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <SwitchButton
            title="Number of Seats"
            subtitle="You can limit the number of students that can enroll to this term"
            label={showSeatField ? "" : "Unlimited"}
            handleChange={(e) => handleNumberOfSeat(e)}
            // disabled
            checked={showSeatField}
          />
        </Grid>

        {showSeatField && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="TotalMonth">
              <TextField
                label="Max number of students"
                validation={true}
                number={true}
                value={trackData.seatNumber}
                error={trackErrors?.seatNumber}
                helperText={trackErrors?.seatNumber}
                handleChange={handleChanges("seatNumber")}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Schedule;
