import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import PhoneInputField from "../../components/Form/PhoneInputField/PhoneInputField";
import Header from "./header/Header";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import "./Form.css";
import axios from "axios";
import AlrtMessage from "../../components/Form/AlrtMessage/AlertMessage";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

import CustomizedSnackbar from "../../hooks/Snackbar";

function SignInAddPhoneNumber(props) {
  //base url from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV == "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");

  const [changePhoneNumber, setChangePhoneNumber] = useState(false);
  const [phone, setPhone] = React.useState({
    country: {},
    number: "",
  });
  const [phoneNumberFormated, setPhoneNumberFormated] = useState("");

  useEffect(() => {
    // console.log("useEffect");
    //get query params
    const params = new URLSearchParams(window.location.search);
    const token = params.get("changePhoneNumber");
    const oldPhoneNumber = params.get("oldPhoneNumber");
    const oldCountry = params.get("oldCountry");
    // console.log(token);
    if (token) {
      setChangePhoneNumber(true);
      setPhone({ country: { dialCode: oldCountry }, number: oldPhoneNumber });
    }
  }, []);

  const alertCloseHandler = () => {
    setAlertOpen(false);
  };

  //handle phone change event
  const handlePhoneChange = (phone, country) => {
    // console.log( country )
    // console.log("+"  + phone );
    setPhone({ country: country, number: phone });
  };

  //handle submit event
  const handleSubmit = async () => {
    console.log("submit");
    const data = {
      phone_number: phone.number,
      country_code: phone.country.dialCode,
      phone_number_formatted: phoneNumberFormated,
    };
    console.log(data);
    try {
      const result = await axios.post(baseUrl + "/setPhoneNumber", data);
      console.log(result);
      if (result.status == 200) {
        setAlertSeverity("success");
        setAlertMessage(result.data.message);
        setAlertOpen(true);
        //redirect to otp page
        window.location.replace(
          `${window.location.origin}/verify-otp?settings=settings&changePhoneNumber=1`
        );
      } else {
        console.log(result);
        setAlertSeverity("error");
        setAlertMessage(result.data.message);
        setAlertOpen(true);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      console.log(errorMessage);
      setAlertSeverity("error");
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    }
  };
  //handle skipBackToSettings
  const skipBackToSettings = () => {
    console.log("skipBackToSettings");

    //redirect to settings page
    window.location.replace(`${window.location.origin}/settings`);
  };

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <CustomizedSnackbar
            open={alertOpen}
            severity={alertSeverity}
            message={alertMessage}
            handleClose={handleSnackbarClose}
          />
          <div className="SignForm">
            <div className="FormHeader">
              <span></span>
              <h1>
                {changePhoneNumber
                  ? "Change phone number"
                  : "Add a phone number"}{" "}
              </h1>
              <span></span>
            </div>

            <div className="formContainer">
              <div className="SignFormGrid">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <p>
                      Be sure to enter a phone number you can always access.
                      Confirming your mobile number helps you reset your
                      password easily and secure your account.
                    </p>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <PhoneInputField
                      phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                      country={"us"}
                      value={phone.number}
                      countryCodeEditable={false}
                      onChange={(phone, country) =>
                        handlePhoneChange(phone, country)
                      }
                      onBlur={(e) => setPhoneNumberFormated(e.target.value)}
                      onKeyDown={(e) => setPhoneNumberFormated(e.target.value)}
                      variant="outlined"
                      // onlyCountries={["us", "ca", "gb", "my"]}
                      containerStyle={{}}
                      buttonStyle={{}}
                      inputStyle={{
                        marginLeft: "0px",
                        height: "40px",
                        width: "100%",
                        fontFamily: "Roboto-Regular",
                        fontSize: "16px",
                      }}
                      containerClass={{}}
                      errorText={"Phone number is required"}
                      errorStyles={{ marginBottom: "5px" }}
                      autoFormat={true}
                      disableDropdown={false}
                      disabled={false}
                      disableCountryCode={false}
                      enableSearch={false}
                      disableSearchIcon={false}
                      placeholder={""}
                      inputProps={{}}
                      specialLabel={"Primary Phone"}
                      // preferredCountries={[]}
                      // excludeCountries={[]}
                      //error="error"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Buttons
                      label="Continue"
                      buttonColor="#004FE0"
                      border="#ccc 1px solid"
                      color="#fff"
                      width="100%"
                      height="48px"
                      fontSize="14px"
                      link=""
                      disabled={phone.number.length < 11 ? "disabled" : ""}
                      onClick={handleSubmit}
                    />
                    <div className="BottomButton">
                      <Buttons
                        label={changePhoneNumber ? "Cancel" : "Skip for now"}
                        color="#004FE0"
                        width=""
                        height="48px"
                        fontSize="14px"
                        link=""
                        onClick={skipBackToSettings}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignInAddPhoneNumber;
