// TodosCarousel
import React from "react";
import Slider from "react-slick";
import "./carouselCss/slick-two.css";
import "./carouselCss/slick-theme-two.css";

import TodosCard from "./TodosCard/TodosCard";

import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const TodosCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <KeyboardArrowRightRoundedIcon
        sx={{
          color: "#fff",
          background: "#9195A0",
          margin: "-132px 0 0 0",
          "@media (max-width: 768px)": {
            icon: {
              margin: "10px 0 0 10px", // Adjust the margin values according to your needs
            },
          },
        }}
      />
    ),
    prevArrow: (
      <KeyboardArrowLeftRoundedIcon
        sx={{
          color: "#fff",
          background: "#9195A0",
          margin: "-132px 0 0 255px",
          "@media (max-width: 768px)": {
            icon: {
              margin: "10px 0 0 10px", // Adjust the margin values according to your needs
            },
          },
        }}
      />
    ),
  };
  return (
    <div
     className="todosContainer"
    >
      <p style={{ marginLeft: "20px", fontSize: "16px", fontWeight: "700" }}>
        To dos
      </p>
      <Slider {...settings}>
        <div>
          <TodosCard todosCardData={todosCardData} />
        </div>
        <div>
          <TodosCard todosCardData={todosCardDataTwo} />
        </div>
      </Slider>
    </div>
  );
};

export default TodosCarousel;

const todosCardData = {
  icon: (
    <WarningRoundedIcon
      sx={{ width: "48px", color: "#BA1A1A" }}
      fontSize="large"
    />
  ),
  message: "Add a phone number to help keep your account safe",
  buttonlabel: "Add phone number",
  messageType: "error",
};

const todosCardDataTwo = {
  icon: (
    <WarningRoundedIcon
      sx={{ width: "48px", color: "#FF6200" }}
      fontSize="large"
    />
  ),
  message: "Add missing information",
  buttonlabel: "Add phone number",
  messageType: "warnning",
};


