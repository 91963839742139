import React, { useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { IconButton } from "@mui/material";

const QuickwaysGetStarts = ({ QuickwaysGetStartsCardData }) => {
  const [isSectionVisible, setSectionVisible] = useState(true);

  const handleToggleSection = () => {
    setSectionVisible(!isSectionVisible);
  };

  return (
    <>
      {
        isSectionVisible && (
          <div className="CardSection QuickwaysGetStartsContainer">
            <div className="QuickwaysGetStartsHeader">
              <div className="CardSectionTitle">Quick ways to get started</div>
              <IconButton
                sx={{ p: "5px 0 10px 0" }}
                onClick={handleToggleSection}
              >
                <CancelRoundedIcon sx={{ color: "#676C7B" }} />
              </IconButton>
            </div>
            <div className="QuickwaysGetStartsBody">
              {QuickwaysGetStartsCardData.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="QuickwaysGetStartsCard"
                    style={{ background: `${item.bgColor}` }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "0.6rem",
                      }}
                    >
                      <div className="QuickCardIcon" style={{color: `${item.buttoncolor}`,}}>
                        {item.icon}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <p style={{ margin: "5px 0 0 0", color:"#0B132B", }}>{item.cardDetials}</p>
                        <a
                          href={item.buttonLink}
                          style={{
                            marginTop: "12px",
                            textDecoration: "none",
                            color: `${item.buttoncolor}`,
                            fontWeight: "700",
                            fontSize:"14px",
                          }}
                        >
                          {item.buttonlabel}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}

            </div>
          </div>
        )
      }
    </>
  );
};

export default QuickwaysGetStarts;
