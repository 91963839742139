import React, { useState, useEffect } from "react";
import "./MultipleSeletChip.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function MultipleSeletChip(props) {
  const {
    label,
    validation,
    required,
    options,
    selectedOption,
    handleChange,
    limitTags,
    error,
    helperText,
    placeholder,
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  // useEffect(() => {
  //   if (required || validation) {
  //     console.log(selectedOption);
  //     if (!selectedOption || (selectedOption && selectedOption.length == 0)) {
  //       setValidationError(true);
  //       setValidationText("Required");
  //     } else {
  //       setValidationError(false);
  //       setValidationText("");
  //     }
  //   }
  // }, [selectedOption]);

  return (
    <div className="MultipleSeletChip">
      <div className="FieldLabel">
        {label} {(validation || required) && <span>*</span>}
      </div>

      <Autocomplete
        multiple
        limitTags={typeof limitTags == "undefined" ? 2 : limitTags}
        id="multiple-limit-tags"
        options={options}
        value={selectedOption}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error || validationError}
            helperText={helperText || validationText}
          />
        )}
        sx={{ width: "500px" }}
      />
    </div>
  );
}

export default MultipleSeletChip;
