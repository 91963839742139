import React, { useEffect, useState } from "react";

import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

function Security({ userInfo }) {
  return (
    <>
      <div className="TableContainer oddEvenTable">
        <div className="TableInnerHeader">
          <h3>Access details</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Email address
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ display: "inline-block" }}>
                      {userInfo?.email || "N/A"}
                    </span>
                    {userInfo?.status && (
                      <span style={{ display: "inline-block" }}>
                        <span
                          style={{ display: "flex", alignItems: "center" }}
                          className="status rounded success"
                        >
                          <VerifiedUser style={{ fontSize: "16" }} />
                          Verified
                        </span>
                      </span>
                    )}
                  </span>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Phone
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  {userInfo?.phone_number || "N/A"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Security;
