import React, { useEffect, useState } from "react";
import "../assets/css/inbox.css";

import Table from "../../../../components/Table/Table";
import img from "../assets/images/user-img.jpg";
import Search from "../components/Search/Search";
import EmptyCard from "../components/EmptyCard/EmptyCard";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";

function Courses({
  allCoursesOfAnOrganizationWithStudents,
  inboxInfo,
  setInboxInfo,
  course_id,
}) {
  const orgId = localStorage.getItem("orgId");
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const [rows, setRows] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [formattedUserData, setFormattedUserData] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    if (allCoursesOfAnOrganizationWithStudents?.length > 0) {
      setRows(convertRowToCell(allCoursesOfAnOrganizationWithStudents));
      setTempArray(allCoursesOfAnOrganizationWithStudents);
    }
  }, [allCoursesOfAnOrganizationWithStudents]);

  useEffect(() => {
    if (course_id) {
      setSelectedValue(parseInt(course_id));
    }
  }, [course_id]);

  useEffect(() => {
    if (selectedValue) {
      let findUsers = allCoursesOfAnOrganizationWithStudents?.find(
        (item) => item?.courseId == selectedValue
      );
      let formattedData = [];
      if (findUsers) {
        findUsers?.students?.map((item) =>
          formattedData.push({
            user_id: item,
            participant_type: "student",
          })
        );
      }

      setFormattedUserData(formattedData);
      setRows(convertRowToCell(tempArray));
    }
    // setRows(convertRowToCell(tempArray));
  }, [selectedValue, allCoursesOfAnOrganizationWithStudents]);

  useEffect(() => {
    setInboxInfo({
      ...inboxInfo,
      participants: formattedUserData,
      course_id: selectedValue,
    });
  }, [formattedUserData, selectedValue, setInboxInfo]);

  const handleChangeRedio = (event) => {
    setSelectedValue(event.target.value);
  };

  const convertRowToCell = (array) => {
    if (array?.length > 0) {
      return array?.map((item) => {
        return {
          cell: [
            {
              img: item?.courseImage,
              imgAvatarColor: "#004FE0",
              ImgSize: "60%",
              imgStatus: true,
              imgTitle: item?.courseTitle.charAt(0).toUpperCase(),
              imgText: item?.courseTitle,
              subText: (
                <div className="InboxProductID">
                  {item?.courseRefId} <FiberManualRecordIcon />
                  {item?.students?.length > 1
                    ? `${item?.students?.length} learners`
                    : `${item?.students?.length} learner`}
                </div>
              ),
              Radio: true,
              handleChangeRedio: handleChangeRedio,
              RedioName: "course_title",
              RedioValue: item.courseId,
              selectedValue: selectedValue == item.courseId,
            },
          ],
        };
      });
    } else {
      return [];
    }
  };
  //==================================== search part ==========================================//
  const handleSearchText = (event) => {
    event.preventDefault();
    setSearchedText(event.target.value);
    searchCoursesForConversation(event.target.value);
  };
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     searchCoursesForConversation(searchedText);
  //   }, 800);
  //   return () => clearTimeout(timeoutId);
  // }, [searchedText]);
  const searchCoursesForConversation = (searchData) => {
    let data = {
      orgId: orgId,
      searchText: searchData,
    };
    axios
      .post(`${baseUrl}/orgPortal/searchCoursesForConversation`, data)
      .then((result) => {
        let newData = result?.data?.body;
        if (searchData === "") {
          // If searchData is empty, show all data
          newData = allCoursesOfAnOrganizationWithStudents;
        }
        setRows(convertRowToCell(newData));
        setTempArray(newData);
        // console.log(newData?.lenght, 345);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="InboxTableSection">
      <Search handleSearchText={handleSearchText} searchedText={searchedText} />
      <div className="InboxTypeTableContainer">
        <Table rows={rows} footer={true} />
      </div>

      <EmptyCard
        message="No public courses or tracks found"
        icon={<PriorityHighIcon />}
      />
    </div>
  );
}

export default Courses;
