
import React from "react";
import CoursesCard from '../../../../components/CoursesCard/CoursesCard';
import CourseImg from '../../../../assets/imgs/course-image.png';
import EmptyCard from '../../../../components/EmptyCard/EmptyCard';



const Upcoming = () => {


  const sessionData = [

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },
    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

    {
      img: CourseImg,
      date: "Sun, Mar 13",
      price: "Free",
      title: "Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin",
      name: "Sheikh Muhammad Jamal Uddin",
      link: "/course-details",
      tracklabel: "Diploma TRACK",
    },

  ];




  return (
    <>
      <div>
        <CoursesCard
          sessionData={sessionData}
          ScrollView="scroll"
          TrackSection={true}
        />

        <div className="EmptyCardWithoutBorder">
          <EmptyCard
            description="You will find upcoming courses here"
            showButton={true}
            btnLabel="Explore Courses"
          />
        </div>
      </div>
    </>
  );
};

export default Upcoming;

