import React, { useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";
import { dateFormatter } from "../../hooks/HelperFunctions";

import Grid from "@mui/material/Grid";
import Buttons from "../../components/Form/Button/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Tooltip from "../../components/Form/Tooltip/Tooltip";
import DraggableDialog from "../../hooks/Dialog";
import { deepPurple } from "@mui/material/colors";
import VideoImg from "../../assets/imgs/Session-img.png";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import ModalVideo from "react-modal-video";
import "../../assets/css/ModalVideo/modal-video.css";
import Avatar from "@mui/material/Avatar";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import axios from "axios";
import Modals from "../Form/Modals/Modals";
import { Modal, Box, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useParams } from "react-router-dom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

function CourseSession(props) {
  const {
    modIndex,
    courseSessions,
    sessionCompleteCounter,
    totalSession,
    showZoomModal,
    orgPortal,
    changeSessionStatus,
    startStopSessionHandler,
  } = props;

  let [newCourseSessions, setNewCourseSessions] = useState([]);

  useEffect(() => {
    let newData = [...courseSessions];
    let upcomingFixedYet = false;
    newData?.map((courseSession) => {
      console.log(
        courseSession?.startDate,
        courseSession?.startTime,
        "how caw"
      );
      // Define the date and time strings
      let startDate =
        courseSession?.startDate == null
          ? new Date().toISOString()
          : courseSession?.startDate;
      let startTime =
        courseSession?.startTime == null
          ? new Date().toISOString()
          : courseSession?.startTime;

      // Extract the date from startDate
      let datePart = startDate.split("T")[0];

      // Extract the time from startTime
      let timePart = startTime.split("T")[1];

      // Combine the extracted date and time
      let combinedDateTimeStr = `${datePart}T${timePart}`;

      // Create a new Date object from the combined string
      let combinedDateTime = new Date(combinedDateTimeStr);

      // Get the current date and time
      let currentDateTime = new Date();

      // Compare the combined date and time with the current time
      let isAfter = combinedDateTime > currentDateTime;

      let openLockBefore =
        courseSession?.enableSession == null ? 1 : courseSession?.enableSession;

      let minutesBefore = new Date(
        combinedDateTime.getTime() - openLockBefore * 60000
      );

      if (!upcomingFixedYet) {
        upcomingFixedYet = isAfter;
        courseSession.upcoming = isAfter;
      } else {
        courseSession.upcoming = false;
      }

      courseSession.locked = minutesBefore > currentDateTime;
    });

    setNewCourseSessions(newData);
  }, [courseSessions]);
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  let { courseId } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [sessionExpanded, setSessionExpanded] = React.useState("");
  const [studentSessionStatus, setStudentSessionStatus] = React.useState([]);
  const [mediaData, setMediaData] = React.useState({});
  const [timezone, setTimezone] = useState(() => {
    const timezone = localStorage.getItem("course_timezone");
    return timezone;
  });
  const [getCourseImage, setGetCourseImage] = useState(() => {
    const image = localStorage.getItem("courseImage");
    return image;
  });

  const handleChangeSessionPannel = (panel) => (event, isExpanded) => {
    setSessionExpanded(isExpanded ? panel : false);
  };

  const [sessionChildExpanded, setSessionChildExpanded] = React.useState("");

  const handleChangeSessionChildPannel =
    (panel, sessionData) => (event, isExpanded) => {
      setSessionChildExpanded(isExpanded ? panel : false);
      setMediaData(sessionData);
    };

  //=== Dialog start===
  const [markCompletedSessionData, setMarkCompletedSessionData] = useState([]);
  const [openSessionCompleteDialog, setOpenSessionCompleteDialog] =
    useState(false);

  const handleOpenSessionCompleteDialog = (sessionData) => {
    setMarkCompletedSessionData(sessionData);
    setOpenSessionCompleteDialog(true);
  };

  const handleCloseSessionCompleteDialog = () => {
    setOpenSessionCompleteDialog(false);
  };
  const handleConfirmCloseSessionCompleteDialog = () => {
    setOpenSessionCompleteDialog(false);
    if (!orgPortal) {
      changeSessionStatusForStutdent(
        !markCompletedSessionData?.session_id
          ? markCompletedSessionData?.sesId
          : markCompletedSessionData?.session_id,
        !markCompletedSessionData?.session_id
          ? 1
          : markCompletedSessionData?.completed === 0
          ? 1
          : 0
      );
    } else {
      changeSessionStatus(
        markCompletedSessionData.sesId,
        markCompletedSessionData.sessionStatus
      );
    }
  };
  useEffect(() => {
    console.log(markCompletedSessionData, 404);
  }, [markCompletedSessionData]);
  //=== Dialog end===//
  //======================== change completed status for particular users =================//
  useEffect(() => {
    if (!orgPortal) {
      getLearnerSessionStatus();
    }
  }, []);
  const changeSessionStatusForStutdent = (sesId, sessionStatus) => {
    let data = {
      userId: userData?.id,
      sessionId: sesId,
      courseId: courseId,
      completed: sessionStatus,
    };
    axios
      .post(`${baseUrl}/studentPortal/updateLearnerSessionStatus`, data)

      .then((result) => {
        if (result?.status === 200) {
          getLearnerSessionStatus(result?.data?.studentSessionStatus);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLearnerSessionStatus = () => {
    let data = {
      userId: userData?.id,
      courseId: courseId,
    };
    axios
      .post(`${baseUrl}/studentPortal/getLearnerSessionStatus`, data)

      .then((result) => {
        if (result?.status === 200) {
          setStudentSessionStatus(result?.data?.studentSessionStatus);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //======================== change completed status for particular users =================//
  //================================ media player part =================================//
  useEffect(() => {
    if (mediaData?.prerercordedThumbnail) {
      getLinkForPrivateImage(mediaData);
    }
  }, [mediaData]);
  const [link, setLink] = useState("");
  const [imageLink, setImageLink] = useState("");

  const handleOpen = (data) => {
    setDeleteMedia(true);
    getLinkForPrivateVideo(data);
  };

  const [deleteMedia, setDeleteMedia] = useState(false);
  const getLinkForPrivateVideo = async (mediaFile) => {
    if (mediaFile) {
      let data = {
        key: mediaFile?.prerercordedVideo,
      };
      try {
        const response = await axios.post(
          `${baseUrl}/getPresignedUrlWithCloudFront`,
          data
        );
        console.log(response, 346);
        if (response?.status === 200) {
          setLink(response?.data?.url);
        }
      } catch (error) {
        console.error("Error uploading file", error, 346);
      }
    }
  };
  const getLinkForPrivateImage = async (mediaFile) => {
    if (mediaFile) {
      let data = {
        key: mediaFile?.prerercordedThumbnail,
      };
      try {
        const response = await axios.post(
          `${baseUrl}/getPresignedUrlWithCloudFront`,
          data
        );
        console.log(response, 346);
        if (response?.status === 200) {
          setImageLink(response?.data?.url);
        }
      } catch (error) {
        console.error("Error uploading file", error, 346);
      }
    }
  };
  //================================ media player part =================================//
  return (
    <>
      {" "}
      <Accordion
        expanded={sessionExpanded === `session-${modIndex}`}
        onChange={handleChangeSessionPannel(`session-${modIndex}`)}
      >
        <AccordionSummary
          expandIcon={
            sessionExpanded === `session-${modIndex}` ? (
              <RemoveRoundedIcon />
            ) : (
              <AddRoundedIcon />
            )
          }
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(0) !important",
            },
          }}
        >
          <div className="accTitleSection accTitleSectionWidth">
            <Typography
              sx={{
                width: "33% !important",
              }}
            >
              Sessions
            </Typography>
            <div className="SessionStatus">
              Completed {sessionCompleteCounter}/{totalSession}
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <div className="AccThreeStep">
            {newCourseSessions.map((sessionData, index) => {
              const getCompletedStatus = studentSessionStatus?.find(
                (session) => session?.session_id == sessionData?.sesId
              );

              return (
                <Accordion
                  expanded={
                    sessionChildExpanded ===
                      `session-child-${modIndex}${index}` ||
                    sessionData.isUpcoming
                  }
                  onChange={handleChangeSessionChildPannel(
                    `session-child-${modIndex}${index}`,
                    sessionData
                  )}
                  disabled={
                    sessionData.upcoming || sessionData?.locked
                      ? "disabled"
                      : ""
                  }
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownRoundedIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="accTitleSection">
                      <div className="accTitleSectionIcon">
                        {sessionData?.locked ? (
                          <Tooltip
                            TooltipText="Locked"
                            Tooltip={
                              <div className="SessionLockedIcon">
                                <LockRoundedIcon />
                              </div>
                            }
                          />
                        ) : (
                          <>
                            {sessionData.sessionStatus == "Completed" ||
                            getCompletedStatus?.completed === 1 ? (
                              <div className="SessionIconCheck">
                                <CheckCircleRoundedIcon />
                              </div>
                            ) : (
                              <div className="SessionIconDefault">
                                <CircleRoundedIcon />
                              </div>
                            )}
                          </>
                        )}
                        <div>
                          <h4>{sessionData.title}</h4>
                          {sessionData?.isPrerecorded === 1 ? (
                            <p>
                              Video &nbsp;●&nbsp;
                              {sessionData?.prerercordedVideoDuration}
                            </p>
                          ) : (
                            <p>
                              {dateFormatter(
                                sessionData.startDate,
                                timezone,
                                "ddd, MMM Do, YYYY z"
                              )}{" "}
                              <FiberManualRecordRoundedIcon className="SmallDot" />{" "}
                              {dateFormatter(
                                sessionData.startTime,
                                timezone,
                                "hh:mm a"
                              )}{" "}
                              to{" "}
                              {dateFormatter(
                                sessionData.endTime,
                                timezone,
                                "hh:mm a"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <span
                        className={`SesstionStatus ${!sessionData.sessionStatus ? "" : "LockedIconBox"} status rounded ${!sessionData.sessionStatus ? "" : sessionData.cssClass}`}
                      >
                        {sessionData?.sessionStatus === "Lock"
                          ? "Locked"
                          : sessionData?.sessionStatus}
                      </span> */}
                      {sessionData?.upcoming && (
                        <span
                          className={`SesstionStatus status rounded warning`}
                        >
                          Upcoming
                        </span>
                      )}
                      {sessionData?.locked && (
                        <span
                          className={`SesstionStatus status rounded Locked LockedIconBox`}
                        >
                          Locked
                        </span>
                      )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    {/*  ==== Pre-recored-video section start */}
                    {sessionData?.isPrerecorded === 1 ? (
                      <>
                        {" "}
                        <div className="VideoSessionContainer">
                          <div
                            className="VideoSessionImg"
                            onClick={() => handleOpen(sessionData)}
                          >
                            <img
                              src={
                                sessionData?.prerercordedThumbnail ? (
                                  imageLink
                                ) : getCourseImage ? (
                                  getCourseImage
                                ) : (
                                  <Avatar
                                    sx={{
                                      bgcolor: deepPurple[300],
                                      width: 96,
                                      height: 96,
                                    }}
                                  >
                                    {" "}
                                    {sessionData?.title
                                      ? sessionData?.title
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                        sessionData?.title?.slice(1)
                                      : ""}{" "}
                                  </Avatar>
                                )
                              }
                              alt={sessionData?.title}
                            />
                            <span>
                              <PlayCircleIcon />
                            </span>
                          </div>

                          <div className="VideoSessionText">
                            {sessionData.description}
                          </div>
                        </div>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className="MarkCheck">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      sessionData.sessionStatus ==
                                        "Completed" ||
                                      getCompletedStatus?.completed === 1
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleOpenSessionCompleteDialog(
                                        getCompletedStatus
                                          ? getCompletedStatus
                                          : sessionData
                                      )
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={
                                  sessionData.sessionStatus == "Completed" ||
                                  getCompletedStatus?.completed === 1
                                    ? "Mark Uncompleted"
                                    : "Mark Completed"
                                }
                              />
                            </div>

                            <DraggableDialog
                              title={
                                markCompletedSessionData?.completed !== 1
                                  ? "Mark completed?"
                                  : "Mark Uncompleted?"
                              }
                              body={
                                markCompletedSessionData?.completed !== 1
                                  ? "Are you sure you want to mark this session as completed? Learners will see this session as completed."
                                  : "Are you sure you want to mark this session as uncompleted? Learners will see this session as uncompleted."
                              }
                              openDialog={openSessionCompleteDialog}
                              handleCloseDialog={
                                handleCloseSessionCompleteDialog
                              }
                              handleConfirmCloseDialog={
                                handleConfirmCloseSessionCompleteDialog
                              }
                              ModalFooter={true}
                              actionButton={
                                markCompletedSessionData?.completed !== 1
                                  ? "Mark Completed"
                                  : "Mark Uncompleted"
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <div className="ScardDetails">
                        {/* <p>{sessionData.details}</p> */}

                        {sessionData.sessionStatus == "Upcoming" && (
                          <Grid container spacing={3}>
                            {orgPortal ? (
                              <Grid item xs={12} sm={8} md={8}>
                                <Buttons
                                  label={
                                    sessionData.classStarted == 0
                                      ? "Start Session"
                                      : "Stop Session"
                                  }
                                  buttonColor="#004FE0"
                                  color="#fff"
                                  width="100%"
                                  height="48px"
                                  fontSize="14px"
                                  onClick={startStopSessionHandler}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={8} md={8}></Grid>
                            )}
                          </Grid>
                        )}

                        <Grid container>
                          {orgPortal && (
                            <Grid item xs={12} sm={12} md={12}>
                              <div className="MarkCheck">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        sessionData.sessionStatus == "Completed"
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        handleOpenSessionCompleteDialog(
                                          sessionData
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={
                                    sessionData.sessionStatus == "Completed"
                                      ? "Mark Uncompleted"
                                      : "Mark Completed"
                                  }
                                />
                              </div>
                              <DraggableDialog
                                title={
                                  markCompletedSessionData.sessionStatus !==
                                  "Completed"
                                    ? "Mark completed?"
                                    : "Mark Uncompleted?"
                                }
                                body={
                                  markCompletedSessionData.sessionStatus !==
                                  "Completed"
                                    ? "Are you sure you want to mark this session as completed? Learners will see this session as completed."
                                    : "Are you sure you want to mark this session as uncompleted? Learners will see this session as uncompleted."
                                }
                                openDialog={openSessionCompleteDialog}
                                handleCloseDialog={
                                  handleCloseSessionCompleteDialog
                                }
                                handleConfirmCloseDialog={
                                  handleConfirmCloseSessionCompleteDialog
                                }
                                ModalFooter={true}
                                actionButton={
                                  markCompletedSessionData.sessionStatus !==
                                  "Completed"
                                    ? "Mark Completed"
                                    : "Mark Uncompleted"
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>

          {/*** ================== MediaPlayer  player added ==========================***/}
          <Modal
            open={deleteMedia}
            onClose={() => setDeleteMedia(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height: "auto",
                width: "600px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "black",
                boxShadow: 24,
                position: "relative",
              }}
            >
              <IconButton
                onClick={() => setDeleteMedia(false)}
                sx={{
                  padding: 0,
                  position: "absolute",
                  top: 0,
                  right: "-44px",
                  color: "#fff",
                  zIndex: 99,
                  borderRadius: "50%",
                }}
              >
                <CloseRoundedIcon
                  fontSize="large"
                  // sx={{ boxShadow: "0 0 5px black" }}
                ></CloseRoundedIcon>
              </IconButton>

              <MediaPlayer title={mediaData?.title} src={link}>
                <MediaProvider />
                <DefaultVideoLayout
                  thumbnails={
                    mediaData?.prerercordedThumbnail
                      ? mediaData?.prerercordedThumbnail
                      : getCourseImage
                  }
                  icons={defaultLayoutIcons}
                />
              </MediaPlayer>
            </Box>
          </Modal>
          {/*** ================== MediaPlayer  player added ==========================***/}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default CourseSession;
