import React, { useEffect, useState } from "react";
import axios from 'axios';

import Table from '../../../../../components/Table/Table';
import Grid from '@mui/material/Grid';
import Modals from '../../../../../components/Form/Modals/Modals';
import FileUplaoder from '../../../../../components/Form/FileUplaoder/FileUplaoder';
import TextArea from '../../../../../components/Form/TextArea/TextArea';
import TextField from '../../../../../components/Form/TextField/TextField';
import SelectField from '../../../../../components/Form/SelectField/SelectField'
import RedioBox from '../../../../../components/Form/RedioBox/RedioBox';
import TimePickerField from '../../../../../components/Form/TimePicker/TimePicker';
import { getDataMap } from '../../../../../hooks/HelperFunctions';

import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import Avatar from "@mui/material/Avatar";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import Buttons from "../../../../../components/Form/Button/Button";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

function Resources(props) {

  const { courseResources, getCourseMaterial, handleOpenSnackbar } = props;
  const { moduleDataArray, sessionDataArray, resourcesDataArray } = props.resourseModalData;

  const [resourcesModalTitle, setResourcesModalTitle] = useState("");
  const [resourcesModalActionBtn, setResourcesModalActionBtn] = useState("");

  const [tempResource, setTempResource] = useState({});

  const headCells = [
    { id: 'Resource', numeric: false, disablePadding: true, label: 'Resource', width: "60%" },
    { id: 'Added', numeric: true, disablePadding: false, label: 'Added', width: "20%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "20%" },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: "20%" },

  ];

  const updateResourceData = (fieldName, fieldData = "") => (event) => {
    fieldData = fieldData == "" ? event.target.value : fieldData;
    console.log(fieldName, fieldData);
    setTempResource({ ...tempResource, [fieldName]: fieldData });
  }

  const updateResourceWithParemData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setTempResource({ ...tempResource, [fieldName]: fieldData });
  };

  const iconButtonHandler = (operationType, id) => {
    console.log(operationType, id);
    if (operationType == "edit") {
      handleOpenEditResource(id);
    } else if (operationType == "delete") {
      handleOpenDeleteResource(id)
    }
  }

  // Modal Edit announcement  start//
  const [openEditResource, setOpenEditResource] = useState(false);

  const handleOpenAddResource = () => {
    setTempResource({ "type": 0 });
    setResourcesModalTitle("Add resource");
    setResourcesModalActionBtn("Add New");
    setOpenEditResource(true);
  };

  const handleOpenEditResource = (resId) => {

    const activeItem = resourcesDataArray.findIndex((element) => {
      return (resId == element.resId);
    });

    setTempResource({
      ...tempResource,
      ["resId"]: resourcesDataArray[activeItem]?.resId,
      ["modId"]: resourcesDataArray[activeItem]?.modId,
      ["sesId"]: resourcesDataArray[activeItem]?.sesId,
      ["type"]: resourcesDataArray[activeItem]?.type,
      ["title"]: resourcesDataArray[activeItem]?.title,
      ["resourseFileName"]: resourcesDataArray[activeItem]?.resourseFileName,
      ["resourseLink"]: resourcesDataArray[activeItem]?.resourseLink,
      ["resourseFile"]: resourcesDataArray[activeItem]?.resourseFile
    });

    setResourcesModalTitle("Edit Resource");
    setResourcesModalActionBtn("Save");
    setOpenEditResource(true);
  };

  const handleCloseModalEditResource = () => {
    console.log('close')
    setOpenEditResource(false)
  };

  const handleConfirmEditResource = () => {
    console.log('edit resource');
    let validationError = false;
    console.log(tempResource);
    // check form validation
    if (!tempResource?.modId) {
      validationError = true;
    }

    if (tempResource?.type == 0) {
      if (tempResource?.title?.length == undefined || tempResource?.title?.length == 0) {
        validationError = true;
      }
      if (tempResource?.resourseLink?.length == undefined || tempResource?.resourseLink?.length == 0) {
        validationError = true;
      }
    } else {
      if (!tempResource.resourseFileName || !tempResource.resourseFile) {
        validationError = true;
      }
    }

    if (validationError) {
      handleOpenSnackbar("error", "Please fill up all required fields.");
    } else {
      handleSaveResourceData(tempResource);
    }

  };
  // Modal Edit announcement  End//


  // Modal Delete announcement  start//
  const [openDeleteResource, setOpenDeleteResource] = useState(false);

  const handleOpenDeleteResource = (resId) => {
    setOpenDeleteResource(true);
    setTempResource({ ...tempResource, ["resId"]: resId });
  };

  const handleCloseModalDeleteResource = () => {
    console.log('close')
    setOpenDeleteResource(false)
  };

  const handleConfirmDeleteResource = () => {

    const apiBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    axios.get(`${apiBaseUrl}/orgPortal/deleteResource?resId=${tempResource?.resId}`).then((result) => {
      handleOpenSnackbar("success", "Resource deleted");
      setOpenDeleteResource(false);
      getCourseMaterial();
    }).catch((err) => {
      console.log(err);
    });

  };

  // Modal Delete announcement  End//

  // useEffect(() => {
  //   setValues({...values, ["courseId"]: courseId}); 
  // }, []);

  // add/update announcement

  const showDataPostMessage = (response) => {
    if (response.data.error) {
      handleOpenSnackbar("error", response.data.message);
    } else {
      setOpenEditResource(false);
      if (tempResource.resId) {
        handleOpenSnackbar("success", "Resource updated");
      } else {
        handleOpenSnackbar("success", "Resource added");
      }

      getCourseMaterial();
    }

  }


  const handleSaveResourceData = (dataArray) => {
    const apiBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    console.log(dataArray);
    axios.post(`${apiBaseUrl}/orgPortal/updateResource`, dataArray)
      .then(response => showDataPostMessage(response));
  }

  // Redio Box //

  const tabsData = [
    {
      title: "External Resource",
      description: "Link of an external site"
    },

    {
      title: "Downloadable File",
      description: "Upload a resource"
    },

  ];

  
  // === Media uplaoder Start ==//

  // const [tempResource, setTempResource] = useState({});
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);

  //deleteCourseImageSelection
  const deleteCourseImageSelection = () => {
    // setCourseData({ ...courseData, ["courseImage"]: "" });
    //set tempResource resourseFile and resourseLink to empty 
    setTempResource({ ...tempResource, ["resourseFile"]: "" });
  };

  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };

  //handleDrawerOpenNewMedia
  const handleDrawerOpenNewMedia = () => {
    console.log("handleDrawerOpenNewMedia");
    setOpenNewMedia(true);
  };

  //clickSelectMediaItem
  const clickSelectMediaItem = () => {
    //check if any item is selected
    if (selectedValue) {
      // console.log(mediaItem);
      //set the media item
      // setImageSelection(mediaItem.file_url);
      // handleChangeWithData("resourseFile", mediaItem.name);
      handleChangeWithData("resourseFile", mediaItem.file_url);

      // console.log( courseData)
      setOpenSelectMedia(false);
    } else {
      alert("Please select any media");
    }
  };

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    // setCourseData({ ...courseData, [fieldName]: fieldData });
    // updateResoursesData("resourseFile", fieldData);
    setTempResource({ ...tempResource, [fieldName]: fieldData });
  };

  //getMediaItem
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
    console.log(mediaItem)
  };

  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };

    //handleDrawerOpenSelectMedia
    const handleDrawerOpenSelectMedia = () => {
      setOpenSelectMedia(true);
    };



  // === Media uplaoder End ==//


  const getAllSessions = () => {
    let dataArray = [];
    dataArray.push({ value: 0, label: "None" });
    const sessionsArr = getDataMap(sessionDataArray, "sesId", "title", "", "", "modId", tempResource.modId);
    const mergedArray = dataArray.concat(sessionsArr);
    console.log(dataArray, sessionsArr, mergedArray);
    // console.log(mergedArray);
    return mergedArray;   
  }




  return (
    <>
      <div className="TableSection">
        <Table
          AddButtonInner={true}
          addLabelInner="+ Resource"
          onClickInnerButton={() => handleOpenAddResource(true)}
          innerTitle="Resources"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          innerHeader={true}
          headCells={headCells}
          rows={courseResources}
          perPageItems={10}
          footer={true}
          iconButtonHandler={iconButtonHandler}
        />
      </div>








      {/* Resource Drawer */}
      <SideDrawer
        title={resourcesModalTitle}
        ButtonLabel={resourcesModalActionBtn}
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={openEditResource}
        handleDrawerClose={handleCloseModalEditResource}
        clickSaveButton={() => handleConfirmEditResource()}
        FooterWithoutTab={true}
        body={
          <div className="ResorceDrwContainer">

            <div>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  {moduleDataArray?.length > 0 &&
                    <SelectField
                      label="Select Module"
                      validation={true}
                      options={getDataMap(moduleDataArray, "modId", "title")}
                      selectedOption={tempResource.modId}
                      handleChange={updateResourceData("modId")}
                    />}

                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {sessionDataArray?.length > 0 &&
                    <SelectField
                      label="Select Session (Optional)"
                      validation={false}
                      options={getAllSessions()}
                      selectedOption={tempResource.sesId}
                      handleChange={updateResourceData("sesId")}
                    />}

                </Grid>

                <Grid item xs={12} sm={12} md={12}>

                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={12} md={12}>
                      <div className="HideBody">
                      <RedioBox
                        label="Resource Type *"
                        tabsData={tabsData}
                        value={tempResource.type}
                        onChange={(event, tabsData) => updateResourceWithParemData("type", tabsData)}
                      />
                      </div>
                    </Grid>

                  </Grid>



                </Grid>
                {tempResource.type == 0 ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="Title"
                        required={true}
                        count={80}
                        value={tempResource.title}
                        handleChange={updateResourceData("title")}
                        placeholder="A descriptive title"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="URL"
                        placeholder="https://example.com"
                        required={true}
                        value={tempResource.resourseLink}
                        handleChange={updateResourceData("resourseLink")}
                      />
                    </Grid>
                  </>)
                  : (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            label="File Name"
                            placeholder=""
                            validation={true}
                            value={tempResource.resourseFileName}
                            handleChange={updateResourceData("resourseFileName")}
                            count={60}
                          />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        {/* <FileUplaoder
                          label="Upload file"
                          required={true}
                          filePath="modules/resource-files/"
                          uploadButton="Upload File"
                          file={tempResource.resourseFile}
                          fileType="*"
                          onUpload={(filePath) => updateResourceWithParemData("resourseFile", filePath)}
                          helpertext="Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf."
                        /> */}



                        <div className="FieldLabel">Upload File<span>*</span></div>
                        {(tempResource.resourseFile ? tempResource.resourseFile.length : false) ? (
                          <>
                            <div className="TableSection">
                              <div
                                style={{
                                  padding: '0.2rem',
                                  // background: "#C3C3C3",
                                  border: '1px solid #E4E8EC',
                                  borderRadius: '10px',
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  height: "48px",
                                  padding: "0 10px",
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                  <span className="CellImg">
                                    <Avatar variant="rounded" src={tempResource.resourseFile} style={{ width: "28px", height: "28px" }} />
                                  </span>
                                  <span> <a href={tempResource?.resourseFile}>Download</a> </span>
                                </div>
                                <span className="CellImg">
                                  <DeleteForeverRoundedIcon
                                    onClick={deleteCourseImageSelection}
                                    sx={{ mb: -0.5, color: '#676C7B' }}
                                  />
                                </span>
                              </div>
                              <p class="HelperText" style={{ marginBottom: "10px" }}>Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf.</p>
                            </div>
                          </>
                        ) : (
                          <div>
                            <SideDrawer
                              title="Media Library"
                              ButtonLabel="Add"
                              //clickSaveButton={clickSaveButton}
                              cancelButtonLabel="Cancel"
                              sideOpen="right"
                              open={openSelectMedia}
                              handleDrawerClose={handleDrawerCloseSelectMedia}
                              FooterWithoutTab={true}
                              LeftButton={true}
                              ButtonLeft="Upload New"
                              ButtonLeftIcon={<FileUploadOutlinedIcon />}
                              clickButtonLeft={handleDrawerOpenNewMedia}
                              clickSaveButton={clickSelectMediaItem}
                              body={
                                <MediaLibrarySelectMedia
                                  onClickRadioIcon={setSelectedValue}
                                  onItemSelected={getMediaItem}
                                />
                              }
                            />

                            <Externaluse
                              open={openNewMedia}
                              handleCloseButton={handleMediaUploadClose}
                              handleOpenNewMedia={handleOpenNewMedia}
                            />

                            <div className="TableSection">
                              <Buttons
                                label="Add From Media Library"
                                buttonColor="transparent"
                                border="#E4E8EC 1px solid"
                                icon={<AddRoundedIcon />}
                                color="#004FE0"
                                width="100%"
                                height="48px"
                                fontSize="14px"
                                link=""
                                onClick={() => handleDrawerOpenSelectMedia()}
                              />
                              <p class="HelperText" style={{ marginBottom: "10px" }}>Maximum size of 10.0MB. .jpg, .jpeg, .png, or .pdf.</p>
                            </div>
                          </div>
                        )}


                      </Grid>
                    </>
                  )}

              </Grid>

            </div>

          </div>
        }
      />
      {/* Resource Drawer End*/}





      {/* Delete announcement Modal */}
      <Modals
        ModalTitle="Delete this resource?"
        handleClose={handleCloseModalDeleteResource}
        handleConfirm={handleConfirmDeleteResource}
        open={openDeleteResource}
        ButtonClose="Cancel"
        ButtonConfirm="Delete"
        width="552px"

        Headerbackground="#fff"
        Headercolor="#0B132B"
        Footerbackground="#fff"
        ModalFooter={true}

        ModalBody={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ textAlign: "center", }}>Are you sure you want to delete this? Once you delete it, you can't get it back.</div>

              </Grid>
            </Grid>

          </div>
        }

      />
      {/*  Edit announcement Modal  End*/}
    </>

  );
}


export default Resources;

