import React from "react";
import SlateEditor from "../../../../../components/SlateEditor/SlateEditor";

function Description(props) {
  const { courseData, setCourseData } = props;

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    console.log(fieldName, fieldData);
    setCourseData({ ...courseData, [fieldName]: fieldData });
  };

  return (
    <div className="formSection">
      <div
        className="formSection Section textEditorContainer"
        style={{ paddingBottom: "65px" }}
      >
        <SlateEditor
          label="Description"
          required={false}
          onChange={(fieldData) =>
            handleChangeWithData("description", fieldData)
          }
          value={courseData.description}
          readOnly={false}
          charLimit={2000}
          height="440px"
        />
      </div>
    </div>
  );
}

export default Description;
