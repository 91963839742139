import React, { useEffect, useState } from "react";
import SwitchAccordionRolls from "../../../../components/SwitchAccordion/SwitchAccordionRolls";
import MultipleSelet from "../../../../components/Form/MultipleSelet/MultipleSelet";
import SwitchButtonIOS from "../../../../components/Form/SwitchButtonIOS/SwitchButtonIOS";
import CheckBox from "../../../../components/Form/CheckBox/CheckBox";
import Grid from "@mui/material/Grid";
import { capitalizeFirstLetter } from "../../../../hooks/HelperFunctions";
//import { capitalizeFirstLetter } from "../../../../hooks/HelperFunctions";

const Permissions = (props) => {
  const {
    rolesPermissionsObject,
    setRolesPermissionsObject,
    errors,
    setErrors,
    permissions,
  } = props;
  const [expanded, setExpanded] = React.useState(false);
  const handleChangeAcc = (panel) => (event, isExpanded) => {
    console.log(panel, 157, isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  const operationCheckHandler = (e, moduleName, operationName) => {
    let { permissions: currentPermissions } = rolesPermissionsObject;

    let foundModule = currentPermissions?.find(
      (singleModule) => singleModule.name === moduleName
    );
    if (!foundModule) {
      foundModule = {
        name: moduleName,
        enabled: true,
        operations: [],
      };
    }
    let foundOperation = foundModule?.operations?.find(
      (operation) => operation.name === operationName
    );
    let moduleExceptCurrentOne = currentPermissions?.filter(
      (singlePermission) => singlePermission.name != moduleName
    );
    let newOperations = foundModule?.operations
      ? [...foundModule?.operations]
      : [];
    if (e.target.checked && !foundOperation) {
      newOperations.push({
        name: operationName,
      });
    } else {
      if (foundOperation) {
        newOperations = newOperations?.filter(
          (operation) => operation.name !== operationName
        );
      }
    }

    let newModule = { ...foundModule };
    newModule.operations = newOperations;
    setRolesPermissionsObject({
      ...rolesPermissionsObject,
      permissions: [...moduleExceptCurrentOne, newModule],
    });
  };

  const isOperationChecked = (moduleName, operationName) => {
    let { permissions: currentPermissions } = rolesPermissionsObject;

    let foundModule = null;
    foundModule = currentPermissions?.find(
      (singleModule) => singleModule.name === moduleName
    );
    let foundOperation = null;
    foundOperation = foundModule?.operations?.find(
      (operation) => operation.name === operationName
    );

    return (
      foundModule &&
      Object.keys(foundModule).length > 0 &&
      foundOperation &&
      Object.keys(foundOperation).length > 0
    );
  };

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const changeSwitchHandler = (event, moduleName) => {
    let { permissions: currentPermissions } = rolesPermissionsObject;
    let foundModule = currentPermissions?.find(
      (permission) => permission.name === moduleName
    );
    let allOperations = permissions.find(
      (permission) => permission.name === moduleName
    );
    // return false
    if (!foundModule) {
      foundModule = {
        name: moduleName,
        enabled: true,
        operations: [],
      };
    }

    foundModule.operations = allOperations?.operations?.map(
      (singleOperation) => {
        return { name: singleOperation.name };
      }
    );

    let moduleExceptCurrentOne = currentPermissions?.filter(
      (singlePermission) => singlePermission.name != moduleName
    );
    if (event.target.checked) {
      setRolesPermissionsObject({
        ...rolesPermissionsObject,
        permissions: [...moduleExceptCurrentOne, foundModule],
      });
    } else {
      setRolesPermissionsObject({
        ...rolesPermissionsObject,
        permissions: [...moduleExceptCurrentOne],
      });
    }
  };

  const isModuleEnabled = (moduleName) => {
    let { permissions: currentPermissions } = rolesPermissionsObject;
    let foundModule = currentPermissions?.find(
      (singleModule) => singleModule.name === moduleName
    );
    return foundModule?.operations?.length > 0 && foundModule ? true : false;
  };

  const onSelectAllPermissionsHandler = (e, moduleName) => {
    let { permissions: currentPermissions } = rolesPermissionsObject;
    // let found = currentPermissions?.find(permission => permission?.name === moduleName)
    let foundModule = currentPermissions?.find(
      (permission) => permission?.name === moduleName
    );
    // let foundModule = { ...found }
    let allOperations = permissions.find(
      (permission) => permission.name === moduleName
    );
    // return false
    if (!foundModule) {
      foundModule = {
        name: moduleName,
        enabled: true,
        operations: [],
      };
    }

    // foundModule.operations = allOperations?.operations?.map((singleOperation) => {
    //   return { name: singleOperation?.name }
    // })
    foundModule = {
      ...foundModule,
      operations: allOperations?.operations?.map((singleOperation) => ({
        name: singleOperation?.name,
      })),
    };

    let moduleExceptCurrentOne = currentPermissions?.filter(
      (singlePermission) => singlePermission.name != moduleName
    );
    if (e.target.checked) {
      setRolesPermissionsObject({
        ...rolesPermissionsObject,
        permissions: [...moduleExceptCurrentOne, foundModule],
      });
    } else {
      setRolesPermissionsObject({
        ...rolesPermissionsObject,
        permissions: [...moduleExceptCurrentOne],
      });
    }
  };

  const attendance = [
    { label: "Take Attendance" },
    { label: "Add Manual Attendance" },
    { label: "Update Attendance" },
    { label: "Add Clock-In / Clock-Out Time." },
    { label: "Add Clock-In / Clock-Out Time." },
    { label: "Add Clock-In / Clock-Out Time." },
    { label: "Add Clock-In / Clock-Out Time." },
    { label: "Manage Attendance Settings" },
  ];

  const users = [
    { label: "Qadhi  Ashraful" },
    { label: "Shayan Mahmud" },
    { label: "Abdullah Ibn Mas’ud" },
    { label: "Qadhi  Ashraful" },
    { label: "Qadhi  Ashraful" },
    { label: "Qadhi  Ashraful" },
    { label: "Qadhi  Ashraful" },
    { label: "Qadhi  Ashraful" },
  ];

  const selectedOperationsLength = (permissionName) => {
    return rolesPermissionsObject?.permissions?.find((singlePermission) => {
      return singlePermission?.name === permissionName ? true : false;
    })?.operations?.length;
  };

  return (
    <div className="RoleDetails">
      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>Home</h1>
          <MultipleSelet
            option={home}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}

      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>My Courses</h1>
          <MultipleSelet
            option={courses}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}

      {/* <div className="PrmSection AccBox">
        <SwitchAccordion
          title="Attendance"
          description="Allow users to manage attendance, codes, and history."
          expanded={expanded === 'panel'}
          onChange={handleChangeAcc('panel')}
          //handleChangeSwitch={handleChange}
          body={
            <>
              <CheckBox
                label="Full permission"
                checked={state.checkedB}
                onChange={handleChangeCheck}
                color="primary"
              />

              <Grid container spacing={0}>
                {attendance.map((item, i) => (
                  <Grid item xs={12} sm={6} md={6}>
                    <CheckBox
                      label={item.label}
                      checked={state.checkedB}
                      onChange={item.onChange}
                      color="primary"
                    />
                  </Grid>
                ))}
              </Grid>

            </>

          }
        />
      </div> */}

      {permissions?.length &&
        permissions?.map((permission, i) => {
          {
            /* console.log("permissions", permission) */
          }
          return (
            <div className="PrmSection AccBox">
              <SwitchAccordionRolls
                title={capitalizeFirstLetter(permission?.name)}
                description={permission?.description}
                selectedOption={selectedOperationsLength(permission?.name)}
                // expanded={expanded === permission?.name}
                // onChange={handleChangeAcc(permission?.name)}
                handleChangeSwitch={(e) =>
                  changeSwitchHandler(e, permission?.name)
                }
                Switchexpanded={
                  isModuleEnabled(permission?.name) ? true : false
                }
                body={
                  <>
                    <CheckBox
                      label="Full permission"
                      checked={
                        selectedOperationsLength(permission?.name) ==
                        permission?.operations.length
                      }
                      onChange={(e) =>
                        onSelectAllPermissionsHandler(e, permission?.name)
                      }
                      color="primary"
                    />

                    <Grid container spacing={0}>
                      {permission?.operations?.map((operation, i) => (
                        <Grid operation xs={12} sm={6} md={6}>
                          <CheckBox
                            label={operation?.nameToShow}
                            checked={
                              isOperationChecked(
                                permission?.name,
                                operation?.name
                              )
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              operationCheckHandler(
                                e,
                                permission?.name,
                                operation?.name
                              )
                            }
                            color="primary"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                }
              />
            </div>
          );
        })}

      {/* <div className="PrmSection AccBox">
        <SwitchAccordion
          title="Users"
          description="Allow users to invite users, manage groups, and roles & permissions."
          expanded={expanded === 'pane2'}
          onChange={handleChangeAcc('pane2')}
          //handleChangeSwitch={handleChange}
          body={
            <>
              <CheckBox
                label="Full permission"
                checked={state.checkedB}
                onChange={handleChangeCheck}
                color="primary"
              />

              <Grid container spacing={0}>
                {users.map((item, i) => (
                  <Grid item xs={12} sm={6} md={6}>
                    <CheckBox
                      label={item.label}
                      checked={state.checkedB}
                      onChange={item.onChange}
                      color="primary"
                    />
                  </Grid>
                ))}
              </Grid>

            </>

          }
        />
      </div> */}

      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>Messages</h1>
          <MultipleSelet
            option={courses}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}

      {/* <div className="PrmSection AccBox">
        <SwitchAccordion
          title="User"
          expanded={expanded === 'pane3'}
          onChange={handleChangeAcc('pane3')}
          //handleChangeSwitch={handleChange}
          body={
            <>
              <div className="PrmSection">
                <div className="PrmSectionIn">
                  <h1>Academic Years</h1>
                  <MultipleSelet
                    option={courses}
                    handleChange={handleChange}
                    value={value}
                  />

                </div>
              </div>

            </>

          }
        />
      </div> */}

      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>Orders</h1>
          <MultipleSelet
            option={courses}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}

      {/* <div className="PrmSection AccBox">
        <SwitchAccordion
          title="Academics"
          expanded={expanded === 'pane3'}
          onChange={handleChangeAcc('pane3')}
          //handleChangeSwitch={handleChange}
          body={
            <>
              <div className="PrmSection">
                <div className="PrmSectionIn">
                  <h1>Academic Years</h1>
                  <MultipleSelet
                    option={courses}
                    handleChange={handleChange}
                    value={value}
                  />

                </div>
              </div>
              <div className="PrmSection">
                <div className="PrmSectionIn">
                  <h1> Class/Level </h1>
                  <MultipleSelet
                    option={courses}
                    handleChange={handleChange}
                    value={value}
                  />

                </div>
              </div>

              <div className="PrmSection">
                <div className="PrmSectionIn">
                  <h1>Attendance Code</h1>
                  <MultipleSelet
                    option={courses}
                    handleChange={handleChange}
                    value={value}
                  />

                </div>
              </div>
            </>

          }
        />
      </div> */}

      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>Calendar</h1>
          <MultipleSelet
            option={courses}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}

      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>Settings</h1>
          <MultipleSelet
            option={courses}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}

      {/* <div className="PrmSection">
        <div className="PrmSectionIn">
          <h1>Profile</h1>
          <MultipleSelet
            option={courses}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Permissions;

// import React, { useEffect, useState } from "react";
// import SwitchAccordion from "../../../../components/SwitchAccordion/SwitchAccordion";
// import MultipleSelet from "../../../../components/Form/MultipleSelet/MultipleSelet";
// import SwitchButtonIOS from "../../../../components/Form/SwitchButtonIOS/SwitchButtonIOS";
// import CheckBox from "../../../../components/Form/CheckBox/CheckBox";
// import Grid from "@mui/material/Grid";
// import { capitalizeFirstLetter } from "../../../../hooks/HelperFunctions";
// //import { capitalizeFirstLetter } from "../../../../hooks/HelperFunctions";

// const Permissions = (props) => {
//   const {
//     rolesPermissionsObject,
//     setRolesPermissionsObject,
//     errors,
//     setErrors,
//     permissions,
//   } = props;
//   const [expanded, setExpanded] = React.useState(false);
//   const handleChangeAcc = (panel) => (event, isExpanded) => {
//     console.log(panel, 157, isExpanded);
//     setExpanded(isExpanded ? panel : false);
//   };

//   const operationCheckHandler = (e, moduleID, operationID) => {
//     let { permissions: currentPermissions } = rolesPermissionsObject;

//     let foundModule = currentPermissions?.find(
//       (singleModule) => singleModule.id === moduleID
//     );
//     if (!foundModule) {
//       foundModule = {
//         id: moduleID,
//         // name: moduleName,
//         // enabled: true,
//         operations: [],
//       };
//     }
//     let foundOperation = foundModule?.operations?.find(
//       (operation) => operation === operationID
//     );
//     let moduleExceptCurrentOne = currentPermissions?.filter(
//       (singlePermission) => singlePermission.id != moduleID
//     );
//     let newOperations = foundModule?.operations
//       ? [...foundModule?.operations]
//       : [];
//     if (e.target.checked && !foundOperation) {
//       newOperations.push(operationID);
//     } else {
//       if (foundOperation) {
//         newOperations = newOperations?.filter(
//           (operation) => operation !== operationID
//         );
//       }
//     }

//     let newModule = { ...foundModule };
//     newModule.operations = newOperations;

//     setRolesPermissionsObject({
//       ...rolesPermissionsObject,
//       permissions: [...moduleExceptCurrentOne, newModule],
//     });
//   };

//   const isOperationChecked = (moduleID, operationId) => {
//     let { permissions: currentPermissions } = rolesPermissionsObject;

//     let foundModule = null;
//     foundModule = currentPermissions?.find(
//       (singleModule) => singleModule.id === moduleID
//     );
//     let foundOperation = null;
//     foundOperation = foundModule?.operations?.find(
//       (operation) => operation === operationId
//     );

//     // return foundOperation;
//     return foundModule && foundOperation;
//   };

//   const [state, setState] = React.useState({
//     checkedA: true,
//   });

//   const handleChangeCheck = (event) => {
//     setState({ ...state, [event.target.name]: event.target.checked });
//   };

//   const changeSwitchHandler = (event, moduleID) => {
//     let { permissions: currentPermissions } = rolesPermissionsObject;
//     let foundModule = currentPermissions?.find(
//       (permission) => permission.id === moduleID
//     );
//     let allOperations = permissions.find(
//       (permission) => permission.id === moduleID
//     );
//     // return false
//     if (!foundModule) {
//       foundModule = {
//         id: moduleID,
//         // name: moduleName,
//         // enabled: true,
//         operations: [],
//       };
//     }

//     foundModule.operations = allOperations?.operations?.map(
//       (singleOperation) => singleOperation.id
//     );
//     // foundModule.operations = allOperations?.operations?.map(
//     //   (singleOperation) => {
//     //     return { name: singleOperation.id };
//     //   }
//     // );

//     let moduleExceptCurrentOne = currentPermissions?.filter(
//       (singlePermission) => singlePermission?.id != moduleID
//     );
//     if (event.target.checked) {
//       setRolesPermissionsObject({
//         ...rolesPermissionsObject,
//         permissions: [...moduleExceptCurrentOne, foundModule],
//       });
//     } else {
//       setRolesPermissionsObject({
//         ...rolesPermissionsObject,
//         permissions: [...moduleExceptCurrentOne],
//       });
//     }
//   };

//   const isModuleEnabled = (moduleID) => {
//     let { permissions: currentPermissions } = rolesPermissionsObject;
//     let foundModule = currentPermissions?.find(
//       (singleModule) => singleModule.id === moduleID
//     );
//     // console.log(foundModule, 345, foundModule?.operations?.length);
//     return foundModule?.operations?.length > 0 && foundModule ? true : false;
//   };

//   const onSelectAllPermissionsHandler = (e, moduleID) => {
//     let { permissions: currentPermissions } = rolesPermissionsObject;
//     // let found = currentPermissions?.find(permission => permission?.name === moduleName)
//     let foundModule = currentPermissions?.find(
//       (permission) => permission?.id === moduleID
//     );

//     // let foundModule = { ...found }
//     let allOperations = permissions.find(
//       (permission) => permission.id === moduleID
//     );
//     // return false
//     if (!foundModule) {
//       foundModule = {
//         id: moduleID,
//         // name: moduleName,
//         // enabled: true,
//         operations: [],
//       };
//     }

//     // foundModule.operations = allOperations?.operations?.map((singleOperation) => {
//     //   return { name: singleOperation?.name }
//     // })
//     // console.log(foundModule, 328);
//     foundModule = {
//       ...foundModule,
//       operations: allOperations?.operations?.map(
//         (singleOperation) => singleOperation?.id
//       ),
//     };

//     let moduleExceptCurrentOne = currentPermissions?.filter(
//       (singlePermission) => singlePermission?.id != moduleID
//     );
//     if (e.target.checked) {
//       setRolesPermissionsObject({
//         ...rolesPermissionsObject,
//         permissions: [...moduleExceptCurrentOne, foundModule],
//       });
//     } else {
//       setRolesPermissionsObject({
//         ...rolesPermissionsObject,
//         permissions: [...moduleExceptCurrentOne],
//       });
//     }
//   };
//   // console.log(rolesPermissionsObject, 345);
//   const attendance = [
//     { label: "Take Attendance" },
//     { label: "Add Manual Attendance" },
//     { label: "Update Attendance" },
//     { label: "Add Clock-In / Clock-Out Time." },
//     { label: "Add Clock-In / Clock-Out Time." },
//     { label: "Add Clock-In / Clock-Out Time." },
//     { label: "Add Clock-In / Clock-Out Time." },
//     { label: "Manage Attendance Settings" },
//   ];

//   const users = [
//     { label: "Qadhi  Ashraful" },
//     { label: "Shayan Mahmud" },
//     { label: "Abdullah Ibn Mas’ud" },
//     { label: "Qadhi  Ashraful" },
//     { label: "Qadhi  Ashraful" },
//     { label: "Qadhi  Ashraful" },
//     { label: "Qadhi  Ashraful" },
//     { label: "Qadhi  Ashraful" },
//   ];

//   const selectedOperationsLength = (permissionid) => {
//     return rolesPermissionsObject?.permissions?.find((singlePermission) => {
//       return singlePermission?.id === permissionid ? true : false;
//     })?.operations?.length;
//   };
//   // console.log(rolesPermissionsObject, 345);
//   return (
//     <div className="RoleDetails">
//       {permissions?.length &&
//         permissions?.map((permission, i) => {
//           {
//             /* console.log("permissions", permission) */
//           }
//           return (
//             <div className="PrmSection AccBox">
//               <SwitchAccordion
//                 title={capitalizeFirstLetter(permission?.name)}
//                 description={permission?.description}
//                 selectedOption={selectedOperationsLength(permission?.id)}
//                 // expanded={expanded === permission?.name}
//                 // onChange={handleChangeAcc(permission?.name)}
//                 handleChangeSwitch={(e) =>
//                   changeSwitchHandler(e, permission?.id)
//                 }
//                 Switchexpanded={isModuleEnabled(permission?.id) ? true : false}
//                 body={
//                   <>
//                     <CheckBox
//                       label="Full permission"
//                       checked={
//                         selectedOperationsLength(permission?.id) ==
//                         permission?.operations.length
//                       }
//                       onChange={(e) =>
//                         onSelectAllPermissionsHandler(
//                           e,
//                           permission?.id
//                           // permission?.id
//                         )
//                       }
//                       color="primary"
//                     />

//                     <Grid container spacing={0}>
//                       {permission?.operations?.map((operation, i) => (
//                         <Grid operation xs={12} sm={6} md={6}>
//                           <CheckBox
//                             label={operation?.nameToShow}
//                             checked={
//                               isOperationChecked(permission?.id, operation?.id)
//                                 ? true
//                                 : false
//                             }
//                             onChange={(e) =>
//                               operationCheckHandler(
//                                 e,
//                                 permission?.id,
//                                 operation?.id
//                               )
//                             }
//                             color="primary"
//                           />
//                         </Grid>
//                       ))}
//                     </Grid>
//                   </>
//                 }
//               />
//             </div>
//           );
//         })}
//     </div>
//   );
// };

// export default Permissions;
