import React from "react";
import './ProgressBar.css';


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';




function ProgressBar(props) {
  const { breadcrumbsData } = props;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 2,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#0B9444' : '#0B9444',
    },
  }));

  return (
    <div className="ProgressBar">
      <BorderLinearProgress variant="determinate" value={props.value} />
      <div className="ProgressBarValue">{props.value}%  complete</div>
    </div>
  );
}


export default ProgressBar;

