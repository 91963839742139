import React, { useState, useEffect } from "react";
import Table from "../Table/Table";

import axios from "axios";
import { generateTableRows } from "./Functions";

function MediaLibrayForCourseCertificate(props) {
  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV == "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));

  const [items, setItems] = useState([]);

  const [totalItems, setTotalItems] = useState([]);

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [rows, setRows] = useState([]);

  const handleChangeRedio = (event) => {
    // console.log("handleChangeRedio", event.target.value)

    let data = totalItems;
    // setTotalItems(data);
    let temp = generateTableRows(data, handleChangeRedio, event.target.value);
    setRows(temp);
    setSelectedValue(event.target.value);
    props.onClickRadioIcon(event.target.value);
    //find the selected item from totalItems array
    let selectedItem = totalItems.find((item) => item.id == event.target.value);
    props.onItemSelected(selectedItem);
  };

  //useeffect
  useEffect(() => {
    //get media data from api

    axios
      .get(`${baseUrl}/getAllMediaByOrgId?orgId=${orgId}`, {})
      .then(function (response) {
        // console.log(response.data.data);

        let data = response.data;
        let showOnlyPDFandImageFile = data?.filter(
          (item) =>
            item?.file_type.startsWith("image/") ||
            item?.file_type.startsWith("application/pdf")
        );
        setTotalItems(showOnlyPDFandImageFile);

        // console.log(data, 328, showOnlyPDFandImageFile?.length);
        let temp = generateTableRows(
          showOnlyPDFandImageFile,
          handleChangeRedio,
          selectedValue
        );

        setRows(temp);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [totalItems.length]);

  const headCells = [
    {
      id: "Media",
      numeric: false,
      disablePadding: true,
      label: "Media",
      width: "65%",
    },
    {
      id: "Type",
      numeric: true,
      disablePadding: false,
      label: "Type",
      width: "20%",
    },
    {
      id: "Size",
      numeric: true,
      disablePadding: false,
      label: "Size",
      width: "20%",
    },
  ];

  const handleSearchText = (event) => {
    // console.log("handleSearchText", event.target.value)
    let searchText = event.target.value;
    let data = totalItems;
    let temp = [];
    if (searchText.length > 0) {
      data.map((item, index) => {
        if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
          temp.push(item);
        }
      });
    } else {
      temp = data;
    }
    setItems(temp);
  };

  const handleSearch = () => {
    // console.log("handleSearch")
    let data = items;
    let temp = generateTableRows(data, handleChangeRedio, selectedValue);
    setRows(temp);
  };

  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          FilterButton={props?.FilterButton}
          showSearchBox={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
        />
      </div>
    </>
  );
}

export default MediaLibrayForCourseCertificate;
