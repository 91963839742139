import React from "react";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import imgOne from "../../../../../assets/imgs/quran-slider-img.png";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

const ReminderCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <KeyboardArrowRightRoundedIcon sx={{ color: "#C8BEBE" }} />,
    prevArrow: <KeyboardArrowLeftRoundedIcon sx={{ color: "#C8BEBE" }} />,
  };
  return (
    <div style={{ background: "#fff", borderRadius: '12px', overflow: 'hidden', height: '250px', }}>
      <Slider {...settings} autoplay={true} autoplaySpeed={3000}>
        <div>
          <img src={imgOne} alt="img" height="100%" width="100%" style={{objectFit: 'cover'}} />
        </div>
        <div>
          <img src={imgOne} alt="img" height="100%" width="100%" style={{objectFit: 'cover'}} />
        </div>
        <div>
          <img src={imgOne} alt="img" height="100%" width="100%" style={{objectFit: 'cover'}} />
        </div>
        <div>
          <img src={imgOne} alt="img" height="100%" width="100%" style={{objectFit: 'cover'}} />
        </div>
        <div>
          <img src={imgOne} alt="img" height="100%" width="100%" style={{objectFit: 'cover'}} />
        </div>
        <div>
          <img src={imgOne} alt="img" height="100%" width="100%" style={{objectFit: 'cover'}} />
        </div>
      </Slider>
    </div>
  );
};

export default ReminderCarousel;
