import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import AlrtMessage from "../../components/Form/AlrtMessage/AlertMessage";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import OTPInput from "otp-input-react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CustomizedSnackbar from "../../hooks/Snackbar";
import "./Form.css";
import SimpleBackdrop from "../../components/Form/Backdrop/SimpleBackdrop";

function Otp(props) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");

  const [OTP, setOTP] = useState("");
  const [token, setToken] = useState("");

  const [renderButton, setRenderButton] = useState(true);
  const [renderTime, setRenderTime] = useState(9);

  const [continueButton, setContinueButton] = useState("disabled");

  const [resendOtpButton, setResendOtpButton] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // handle reference login
  let [searchParams, setSearchParams] = useSearchParams();

  let userToken = searchParams.get("token");
  let settings = searchParams.get("settings");
  let changePhoneNumber = searchParams.get("changePhoneNumber");
  const [countdown, setCountdown] = useState(120);

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const handleSetOtp = (otp) => {
    console.log(otp);
    setOTP(otp);
    if (otp.length == 6) {
      setContinueButton("");
    } else {
      setContinueButton("disabled");
    }
  };

  const processResponse = (response) => {
    console.log(response, 345);
    if (response.status === 200) {
      setAlertSeverity("success");
      setAlertMessage(response.data.message);
      setAlertOpen(true);
      //redirect to settings page
      setTimeout(() => {
        setAlertMessage(
          `Redirecting to ${
            settings === "settings" ? "settings" : "sign"
          } in page...`
        );
        window.location.replace(
          `${window.location.origin}/${
            settings === "settings" ? "settings" : "signin"
          }`
        );
      }, 3000);
    } else {
      setAlertSeverity("error");
      setAlertMessage(response.data.body.message);
      setAlertOpen(true);
    }
  };

  const sendResetPasswordRequest = () => {
    // console.log(OTP);

    if (OTP == "") {
      setAlertSeverity("error");
      setAlertMessage("You should put an valid OTP");
      setAlertOpen(true);
      return false;
    } else {
      setOpenBackdrop(true);
      setContinueButton("disabled");
      const postData = { otp_code: OTP };
      let verifyOtpRoute =
        changePhoneNumber && changePhoneNumber == "1"
          ? "verifyOtpCodeToChange"
          : "verifyOtpCode";
      axios
        .post(`${baseUrl}/${verifyOtpRoute}`, postData)
        .then((response) => {
          console.log(response, 345);
          setOpenBackdrop(false);
          setContinueButton("");
          processResponse(response);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message) {
            setAlertSeverity("error");
            setAlertMessage(err.response.data.message);
            setAlertOpen(true);
            setOpenBackdrop(false);
            setContinueButton("");
          }
        });

      return true;
    }
  };

  const alertCloseHandler = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    if (userToken) {
      // console.log(userToken);
      // userToken = token.replace(" ", "+");
      // console.log(userToken);
      setToken(userToken);
    }
  }, []);

  //once resendOtpButton is disalbed then wait 2 mins and enable it
  useEffect(() => {
    if (resendOtpButton == "disabled") {
      const interval = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendOtpButton]);

  const resendOtpCode = async () => {
    setResendOtpButton("disabled");
    const postData = {};

    let resendOtpRoute =
      changePhoneNumber && changePhoneNumber == "1"
        ? "resendOtpCodeToChange"
        : "resendOtpCode";
    //make await axios call
    const response = await axios.post(`${baseUrl}/${resendOtpRoute}`, postData);
    console.log(response);
    if (response.status == 200) {
      setAlertSeverity("success");
      setAlertMessage(response.data.message);
      setAlertOpen(true);
      //redirect to settings page
      // window.location.replace(
      //   `${window.location.origin}/${
      //     settings === "settings" ? "settings" : "signin"
      //   }`
      // );
    } else {
      setAlertSeverity("error");
      setAlertMessage(response.data.body.message);
      setAlertOpen(true);
    }
  };

  return (
    <>
      <Header />
      <SimpleBackdrop open={openBackdrop} />
      <div className="SignContainer">
        <div className="SignContainerInner">
          {/* <AlrtMessage open={alertOpen} message={alertMessage} severity={alertSeverity} closeHandler={alertCloseHandler} /> */}
          <CustomizedSnackbar
            open={alertOpen}
            severity={alertSeverity}
            message={alertMessage}
            handleClose={alertCloseHandler}
          />

          <div className="SignForm">
            <div className="FormHeader">
              {/* <button
                className="BackButton"
                onClick={() =>
                  window.location.replace(
                    `${window.location.origin}/add-phone-number`
                  )
                }
              >
                <ArrowBackIosNewRoundedIcon />
              </button> */}
              <h1>Verify phone number</h1>
              <span></span>
            </div>

            <div className="SignFormGrid">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <div className="OtpFields">
                    <OTPInput
                      className="OtpField"
                      value={OTP}
                      onChange={handleSetOtp}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                      style={false}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <p style={{ textAlign: "center" }}>
                    An SMS with a verification code has been sent to your mobile
                    number. Enter the code to continue.
                  </p>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Buttons
                    label="Verify"
                    buttonColor="#004FE0"
                    border="#ccc 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    disabled={continueButton}
                    link=""
                    onClick={sendResetPasswordRequest}
                  />
                  <div className="BottomButton">
                    <Buttons
                      label="Resend Code"
                      buttonColor="#FFF"
                      border="#ccc 0px solid"
                      color="#004FE0"
                      width=""
                      height="48px"
                      fontSize="14px"
                      disabled={resendOtpButton}
                      link=""
                      onClick={resendOtpCode}
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <ResendOTP className="ResendButton" renderButton={renderButton} renderTime={renderTime} maxTime={10}/> 
                </Grid> */}
              </Grid>
            </div>
          </div>
          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp;
